import React, { Component } from "react";
import { withApollo } from "react-apollo";
import { withI18n } from "@lingui/react";
import { withRouter } from "react-router-dom";
import * as PropTypes from "prop-types";
import {
  Col,
  Row,
  DateRangePicker,
  SelectNewVersion,
  SwitchButton3,
  TextInputV2,
  IconCircle,
  Validation,
  TextInput,
  FormGroup
} from "cf-neo-ui";
import { t, Trans } from "@lingui/macro";
import "../styles.scss";
import { inject, observer } from "mobx-react";
import validate from "../../../utils/validators";

const oneGroupData2 = i18n => {
  return {
    groupA: [
      { text: i18n._(t`Normal`), value: "normal" },
      { text: i18n._(t`Supplémentaire`), value: "extra" },
      { text: i18n._(t`Astreinte`), value: "penalty" }
    ]
  };
};

@inject("newTimesheetStore")
@observer
class AddPeriodForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      period: null,
      theme: "N/A",
      place: "on-site",
      hours: 0,
      minutes: 0,
      type: null,
      clicked: false,
      isValidPeriod: "",
      isValidType: "",
      isHoursValid: true,
      isValidHours: "",
      isMinutesValid: true,
      isValidMinutes: ""
    };

    this.dataPreparation = this.dataPreparation.bind(this);
  }

  dataPreparation = () => {
    const { newTimesheetStore, i18n } = this.props;
    const { themes, themeToPreselect } = newTimesheetStore;

    const themesList = [];
    themes.map(theme => {
      theme.name &&
        themesList.push({
          text: theme.name,
          value: theme.name,
          selected:
            themeToPreselect && theme.name == themeToPreselect ? true : false,
          icon: { type: "dot", color: theme.color }
        });
    });

    themesList.push({
      text: i18n._(t`Ajouter thème`),
      value: "ADD_NEW_THEME",
      icon: { type: "plus", color: "#242424" },
      last: true
    });

    return themesList;
  };

  onSelectThemeFunc = opt => {
    if (opt.value === "ADD_NEW_THEME") {
      const { newTimesheetStore } = this.props;
      const {
        openClosePopUps,
        themes,
        changeThemeToPreselect,
        LIMIT_THEMES
      } = newTimesheetStore;
      changeThemeToPreselect(true);
      if (themes && themes.length === LIMIT_THEMES)
        openClosePopUps("limitOfThemes");
      else openClosePopUps("addTheme");
    } else {
      this.setState({ theme: opt.value });
    }
  };

  onSelectTypeFunc = opt => {
    this.setState({ type: opt.value, isValidType: "" });
  };

  keyDownfn = () => {};

  enterKeyPressedfn = () => {};

  escapeKeyPressedfn = () => {};

  onIconClicked = () => {};

  onChangeHours = e => {
    const { i18n } = this.props;
    if (e.target.value > 24) {
      this.setState({
        hours: e.target.value,
        isHoursValid: false,
        isValidHours: i18n._(t`Max 24h`)
      });
    } else {
      this.setState({
        hours: e.target.value,
        isValidHours: "",
        isHoursValid: true
      });
    }
  };

  onChangeMinutes = e => {
    const { i18n } = this.props;
    if (e.target.value > 60) {
      this.setState({
        minutes: e.target.value,
        isMinutesValid: false,
        isValidMinutes: i18n._(t`Max 60m`)
      });
    } else {
      this.setState({
        minutes: e.target.value,
        isMinutesValid: true,
        isValidMinutes: ""
      });
    }
  };

  addPeriod = e => {
    const { newTimesheetStore, i18n } = this.props;
    const {
      addPeriod,
      changeThemeToPreselect,
      themeToPreselect
    } = newTimesheetStore;
    const {
      period,
      theme,
      place,
      hours,
      minutes,
      type,
      clicked,
      isHoursValid,
      isMinutesValid
    } = this.state;
    let valid = true;
    if (!period) {
      this.setState({ isValidPeriod: i18n._(t`Ce champs est requis`) });
      valid = false;
    }
    if (hours == 0 && minutes == 0) {
      this.setState({
        isHoursValid: false,
        isValidHours: i18n._(t`Ce champs est requis`)
      });
      valid = false;
    } else {
      if (!isHoursValid || !isMinutesValid) {
        valid = false;
      }
    }
    if (!type) {
      this.setState({ isValidType: i18n._(t`Ce champs est requis`) });
      valid = false;
    }

    if (valid) {
      addPeriod({
        start: period.startDate,
        end: period.endDate,
        hours: parseInt(hours, 10),
        minutes: parseInt(minutes, 10),
        themeName: theme || themeToPreselect,
        type,
        description: "",
        place
      });
      this.setState({
        period: null,
        theme: "N/A",
        place: "on-site",
        hours: 0,
        minutes: 0,
        type: null,
        clicked: !clicked
      });
      changeThemeToPreselect(null);
    }
  };

  render() {
    const { newTimesheetStore, i18n } = this.props;
    const { themes, timesheet } = newTimesheetStore;
    const {
      isValidPeriod,
      place,
      isValidType,
      clicked,
      isValidHours,
      isHoursValid,
      isValidMinutes,
      isMinutesValid
    } = this.state;
    const themesList = this.dataPreparation();
    return (
      <div style={{ padding: "0 15px" }}>
        <div
          style={{
            fontFamily: "Montserrat",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "16px",
            color: "#000000",
            marginBottom: "21px",
            marginLeft: "-15px"
          }}
        >
          <Trans>Ajouter des heures</Trans>
        </div>
        <form key={clicked}>
          <Row>
            <Col xs={6} sm={12} md={10} lg={10} xl={10}>
              <Row style={{ marginBottom: "18px" }}>
                <Col lg={4} xl={4} style={{ padding: "0 6px" }}>
                  <FormGroup>
                    <label className="timesheet-form-label" htmlFor="period">
                      <Trans>Date</Trans>
                    </label>
                    <Validation
                      errorMessage={isValidPeriod}
                      valid={isValidPeriod == ""}
                    >
                      <DateRangePicker
                        placeholder={i18n._(t`Aujourd’hui`)}
                        onChange={v => {
                          this.setState({ period: v, isValidPeriod: "" });
                        }}
                        acceptWhenOutsideClick={true}
                        year={timesheet.year}
                        month={timesheet.month}
                        //size="small"
                      />
                    </Validation>
                  </FormGroup>
                </Col>
                <Col lg={4} xl={4} style={{ padding: "0 6px" }}>
                  <FormGroup>
                    <label className="timesheet-form-label" htmlFor="theme">
                      <Trans>Thème</Trans>
                    </label>
                    <Validation errorMessage="" valid={true}>
                      <SelectNewVersion
                        key={themes.length}
                        options={{ groupA: themesList }}
                        placeholder={i18n._(t`Sélectionner`)}
                        onSelect={this.onSelectThemeFunc}
                        valid={true}
                        disabled={false}
                      />
                    </Validation>
                  </FormGroup>
                </Col>
                <Col lg={4} xl={4} style={{ padding: "0 6px" }}>
                  <Row>
                    <Col lg={6} xl={6} style={{ marginRight: "inherit" }}>
                      <FormGroup>
                        <label className="timesheet-form-label" htmlFor="hours">
                          <Trans>Heures</Trans>
                        </label>
                        <Validation
                          errorMessage={isValidHours}
                          valid={isHoursValid}
                        >
                          <TextInputV2
                            placeholder="00h"
                            type="number"
                            min="0"
                            max="24"
                            onEnterPressed={this.enterKeyPressedfn}
                            onEscapePressed={this.escapeKeyPressedfn}
                            onKeyDown={this.keyDownfn}
                            onChange={this.onChangeHours}
                          />
                        </Validation>
                      </FormGroup>
                    </Col>
                    <Col lg={6} xl={6}>
                      <FormGroup>
                        <label
                          className="timesheet-form-label"
                          htmlFor="minutes"
                        >
                          <Trans>Minutes</Trans>
                        </label>
                        <Validation
                          errorMessage={isValidMinutes}
                          valid={isMinutesValid}
                        >
                          <TextInputV2
                            placeholder="00m"
                            type="number"
                            min="0"
                            max="60"
                            onEnterPressed={this.enterKeyPressedfn}
                            onEscapePressed={this.escapeKeyPressedfn}
                            onKeyDown={this.keyDownfn}
                            onChange={this.onChangeMinutes}
                          />
                        </Validation>
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row style={{ marginBottom: "-20px" }}>
                <Col lg={4} xl={4} style={{ padding: "0 6px" }}>
                  <FormGroup>
                    <label className="timesheet-form-label" htmlFor="type">
                      <Trans>Type</Trans>
                    </label>
                    <Validation
                      errorMessage={isValidType}
                      valid={isValidType == ""}
                    >
                      <SelectNewVersion
                        options={oneGroupData2(i18n)}
                        placeholder={i18n._(t`Sélectionner`)}
                        onSelect={this.onSelectTypeFunc}
                        valid={true}
                        disabled={false}
                      />
                    </Validation>
                  </FormGroup>
                </Col>
                <Col lg={4} xl={4} style={{ padding: "0 6px" }}>
                  <FormGroup>
                    <label className="timesheet-form-label" htmlFor="format">
                      <Trans>Format</Trans>
                    </label>
                    <Validation errorMessage="" valid={true}>
                      <SwitchButton3
                        defaultTab={place === "on-site" ? 1 : 2}
                        SegmentText1={i18n._(t`Présentiel`)}
                        SegmentText2={i18n._(t`Télétravail`)}
                        onClick={() => {
                          this.setState({
                            place:
                              SwitchButton3.getSelectedTab() === 1
                                ? "on-site"
                                : "remote"
                          });
                        }}
                        style={{ marginBottom: "50px" }}
                      />
                    </Validation>
                  </FormGroup>
                </Col>
                <Col lg={4} xl={4} style={{ padding: "0 6px" }} />
              </Row>
            </Col>
            <Col xs={6} sm={12} md={2} lg={2} xl={2}>
              <div style={{ margin: "auto" }}>
                <IconCircle
                  type="plus"
                  dimension={60}
                  innerColor="#FFFFFF"
                  innerColor2="#FFFFFF"
                  color="#8D0417"
                  color2="#D3354A"
                  iconWidth={24}
                  iconHeight={24}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    this.addPeriod();
                  }}
                />
                <div
                  style={{
                    fontFamily: "Montserrat",
                    fontStyle: "normal",
                    fontWeight: "600",
                    fontSize: "12px",
                    lineHeight: "14px",
                    color: "#242424",
                    textAlign: "center",
                    marginTop: "12px"
                  }}
                >
                  <Trans> Ajouter </Trans>
                </div>
              </div>
            </Col>
          </Row>
        </form>
      </div>
    );
  }
}

AddPeriodForm.wrappedComponent.propTypes = {
  newTimesheetStore: PropTypes.shape({
    themes: PropTypes.array,
    timesheet: PropTypes.shape({}),
    addTheme: PropTypes.func,
    openClosePopUps: PropTypes.func,
    addPeriod: PropTypes.func,
    LIMIT_THEMES: PropTypes.number
  }).isRequired
};
export default withApollo(withI18n()(withRouter(AddPeriodForm)));
