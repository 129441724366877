import React, { Component } from "react";
import * as PropTypes from "prop-types";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { inject, observer } from "mobx-react";
import cookie from "react-cookies";
import { Mutation } from "react-apollo";
import queryString from "query-string";
import { withI18n } from "@lingui/react";
import { Trans, t } from "@lingui/macro";

import gql from "graphql-tag";
import {
  Row,
  Col,
  FormGroup,
  FormCard,
  Button,
  PasswordInput,
  Validation,
  toaster,
  Spinner
} from "cf-neo-ui";

import validate from "../../utils/validators";
import { scorePassword } from "../../utils/helpers";

import "./styles.scss";

import Routes from "../layout/routes/index";
import { cookieSaveToken } from "../../configs/domainConfigs";
import runtimeVars from "../../configs/runTimeVars";
import metaTags from "../../utils/editMetaData";

const { RESOURCES_URL, AUTH_TOKEN } = runtimeVars;

const RESET_PASSWORD_MUTATION = gql`
  mutation ResetPasswordMutation(
    $forgottenPasswordToken: String!
    $password: String!
  ) {
    resetpassword(
      forgottenPasswordToken: $forgottenPasswordToken
      password: $password
    ) {
      token
      account {
        name
      }
    }
  }
`;

@inject("sessionStore", "appStore")
@observer
class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      confirmPassword: "",
      forgottenPasswordToken: "",
      isPasswordValid: true,
      passwordValidationMessage: "",
      isConfirmPasswordValid: true,
      confirmPasswordValidationMessage: ""
    };
  }

  componentDidMount() {
    window.scroll(0, 0);
    const { location } = this.props;
    const query = location.search;
    const { token } = queryString.parse(query);
    if (token) this.setState({ forgottenPasswordToken: token });
  }

  handleEvaluator = v => {
    return scorePassword(v);
  };

  passwordChangeHandler = v => {
    this.setState({ password: v });
    this.validatePassword(v);
  };

  confirmPasswordChangeHandler = v => {
    this.setState({ confirmPassword: v });
    this.validateConfirmPassword(v);
  };

  ResetPasswordMutationCompletedHandler = async data => {
    const { token } = data.resetpassword;
    const { sessionStore, history } = this.props;
    localStorage.setItem(AUTH_TOKEN, token);
    cookie.save(AUTH_TOKEN, token, cookieSaveToken);
    const config = {
      url: `${RESOURCES_URL}/cookie/setCookie`,
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      data: {
        token
      },
      withCredentials: true
    };
    try {
      await axios(config);
    } catch (e) {}

    sessionStore.changeAuthToken(token);
    history.push(Routes.LpbLayoutRoutes.Dashboard.path);
  };

  isFormValid() {
    const { password, confirmPassword } = this.state;
    let valid = true;
    if (!this.validatePassword(password)) valid = false;
    if (!this.validateConfirmPassword(confirmPassword)) valid = false;

    return valid;
  }

  validatePassword(value) {
    const { i18n } = this.props;
    if (!value) {
      this.setState({
        isPasswordValid: false,
        passwordValidationMessage: i18n._(t`Ce champs est requis`)
      });
      return false;
    }

    if (value.length < 8) {
      this.setState({
        isPasswordValid: false,
        passwordValidationMessage: i18n._(
          t`Doit contenir au moins 8 caractères`
        )
      });
      return false;
    }

    if (!/[a-z]/.test(value)) {
      this.setState({
        isPasswordValid: false,
        passwordValidationMessage: i18n._(
          t`Doit contenir au moins une minuscule`
        )
      });
      return false;
    }
    if (!/[A-Z]/.test(value)) {
      this.setState({
        isPasswordValid: false,
        passwordValidationMessage: i18n._(
          t`Doit contenir au moins une majuscule`
        )
      });
      return false;
    }
    if (!/\d/.test(value)) {
      this.setState({
        isPasswordValid: false,
        passwordValidationMessage: i18n._(t`Doit contenir au moins un chiffre`)
      });
      return false;
    }
    if (!/\W/.test(value)) {
      this.setState({
        isPasswordValid: false,
        passwordValidationMessage: i18n._(
          t`Doit contenir au moins un caractère spécial !*#@...`
        )
      });
      return false;
    }
    this.setState({
      isPasswordValid: true,
      passwordValidationMessage: ""
    });
    return true;
  }

  validateConfirmPassword(value) {
    const { i18n } = this.props;
    const { password } = this.state;
    const res = validate(value, ["required", { similarTo: password }], i18n);
    this.setState({
      isConfirmPasswordValid: res.isValid,
      confirmPasswordValidationMessage: res.message
    });
    return res.isValid;
  }

  render() {
    const {
      confirmPassword,
      password,
      forgottenPasswordToken,
      isPasswordValid,
      passwordValidationMessage,
      isConfirmPasswordValid,
      confirmPasswordValidationMessage
    } = this.state;

    const { i18n, appStore } = this.props;
    const { currentLanguage } = appStore;
    var url = "";

    if (typeof window !== "undefined") {
      url = window.location.href;
    }

    return (
      <div className="session  login">
        {metaTags(url, null, null, null, null, null, null, null, false)}

        <br />

        <Row>
          <Col className="center" lg={8} xl={8}>
            <br />
            <div className="center-txt bold">
              <h1 className="title">
                <Trans>Changer votre mot de passe</Trans>
              </h1>
            </div>
            <br />
          </Col>
        </Row>

        <br />

        <Row>
          <Col className="center " lg={8} xl={8}>
            <FormCard className="login-card center">
              <div className="hello-txt center-txt">
                <Trans>Ravis de vous revoir</Trans>! <br />
              </div>

              <br />
              <FormGroup>
                <label className="form-label" htmlFor="passwordReset">
                  <Trans>Mot de passe</Trans>
                </label>
                <Validation
                  errorMessage={passwordValidationMessage}
                  valid={isPasswordValid}
                >
                  <PasswordInput
                    id="passwordReset"
                    placeholder={i18n._(t`Entrer un mot de passe`)}
                    value={password}
                    onChange={this.passwordChangeHandler}
                    passwordEvaluator={this.handleEvaluator}
                    message={i18n._(t`Niveau de sécurité`)}
                  />
                </Validation>
              </FormGroup>
              <div>
                <FormGroup>
                  <label className="form-label" htmlFor="passwordConfirm">
                    <Trans>Confirmez votre mot de passe</Trans>
                  </label>
                  <Validation
                    errorMessage={confirmPasswordValidationMessage}
                    valid={isConfirmPasswordValid}
                  >
                    <PasswordInput
                      id="passwordConfirm"
                      value={confirmPassword}
                      onChange={this.confirmPasswordChangeHandler}
                      className="form-input"
                      placeholder={i18n._(t`Confirmez votre mot de passe`)}
                    />
                  </Validation>
                </FormGroup>

                <Row className="login-buttons">
                  <Col lg={8} xl={8} />
                  <Col lg={4} xl={4}>
                    <Mutation
                      mutation={RESET_PASSWORD_MUTATION}
                      variables={{
                        forgottenPasswordToken,
                        password
                      }}
                      onCompleted={data =>
                        this.ResetPasswordMutationCompletedHandler(data)
                      }
                      onError={errors => {
                        errors.graphQLErrors.forEach(({ message, data }) => {
                          if (data.isCustomError) {
                            toaster.error({
                              title: i18n._(t`Erreur`),
                              description: message
                            });
                          }
                        });
                      }}
                    >
                      {(mutation, { loading }) => (
                        <Button
                          icon="chevron-right"
                          onClick={() => {
                            if (this.isFormValid()) return mutation();
                            return null;
                          }}
                        >
                          {loading ? (
                            <Spinner
                              type="pointed-circular"
                              color="#FFFFFF"
                              size={12}
                            />
                          ) : (
                            <Trans>Réinitialisez votre mot de passe</Trans>
                          )}
                        </Button>
                      )}
                    </Mutation>
                  </Col>
                </Row>
              </div>
            </FormCard>
          </Col>
        </Row>
      </div>
    );
  }
}

ResetPassword.wrappedComponent.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  sessionStore: PropTypes.shape({
    changeAuthToken: PropTypes.func
  }).isRequired,
  appStore: PropTypes.shape({}).isRequired
};

export default withI18n()(withRouter(ResetPassword));
