import React, { PureComponent } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Trans } from "@lingui/macro";
import classes from "./errorPage.module.scss";
import ServerStatus from "react-server-status";
import { Col, Row } from "cf-neo-ui";

class MaintenancePage extends PureComponent {
  render() {
    return (
      <Col
        xl={12}
        lg={12}
        md={12}
        sm={12}
        xs={6}
        style={{
          "flex-direction": "initial",
          paddingLeft: "0%",
          "justify-content": "center",
          "align-items": "center"
        }}
        className={classes.containerMaintenance}
      >
        <img
          src="/assets/svg/leftSide.svg"
          alt="left side"
          style={{ height: "100%" }}
        />

        <Col xl={10} lg={10} md={10} sm={10} xs={4}>
          <div
            className={classes.container}
            style={{
              display: "flex",
              "flex-direction": "column",
              "align-items": "center",
              paddingTop: "1%"
            }}
          >
            <picture
              className={classes.mqPicture}
              style={{ "text-align": "center" }}
            >
              <source
                type="images/webp"
                srcSet="/assets/images/logo_mindquest_dash.webp"
              />
              <img
                className={classes.mqLogo}
                style={{ width: "50%", marginBottom: "2%" }}
                src="/assets/images/logo_mindquest_dash.webp"
                alt="mindquest"
              />
            </picture>
            <p
              className={classes.textMaintenance}
              style={{
                "font-size": "25px",
                "line-height": " 34.13px",
                "font-family": "Montserrat",
                "margin-bottom": "0%",
                "margin-top": "0%"
              }}
            >
              <Trans>
                Nous déployons de <strong>nouvelles fonctionnalités</strong> sur
                le site Mindquest,{" "}
                <strong>temporairement en maintenance.</strong> Revenez d’ici
                quelques minutes et actualisez la page. Merci de votre
                compréhension
              </Trans>
            </p>

            <div>
              <img src="/assets/svg/maintenance.svg" alt="Maintenance" />
            </div>

            <p
              className={classes.textMaintenance}
              style={{
                "font-size": "25px",
                "line-height": " 34.13px",
                "font-family": "Montserrat",
                "margin-bottom": "0%",
                "margin-top": "0%"
              }}
            >
              <Trans>
                Pour toute demande urgente contactez-nous par email :{" "}
                <a href="contact@mindquest.io">contact@mindquest.io</a>
              </Trans>
            </p>
          </div>
        </Col>
      </Col>
    );
  }
}

MaintenancePage.propTypes = {
  message: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func
  }),
  href: PropTypes.string,
  withButton: PropTypes.bool
};

MaintenancePage.defaultProps = {
  message: "404 Not Found",
  href: "/",
  withButton: true
};

export default withRouter(MaintenancePage);
