import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { H1 } from "cf-neo-ui";
import { inject, observer } from "mobx-react";
import { I18nProvider } from "@lingui/react";
import Blogger from "../blogger/Blogger";
// import Quote from "../quote/Quote";
import Share from "../share/Share";
import MoreArticles from "./more-articles/MoreArticles";
import classes from "./Article.module.scss";
import routes from "../../../layout/routes/index";
import decode from "../../../../utils/Utf8Text";
import { categoriesRefFr, categoriesRefEn } from "../../query";
import catalogEn from "../../../../locales/en/messages";

const catalogs = {
  en: catalogEn
};

@inject("appStore")
@observer
class Article extends Component {
  categoryClickHandler = cat => {
    const { history } = this.props;
    history.push(`${routes.BlogLayoutRoutes.Filter.path}?category=${cat.id}`);
  };

  tagClickHandler = tag => {
    const { history } = this.props;
    history.push(`${routes.BlogLayoutRoutes.Filter.path}?tag=${tag.id}`);
  };

  render() {
    const {
      id,
      content,
      categories,
      tags,
      title,
      cover,
      blogger,
      addDate,
      updateDate,
      relatedPosts,
      appStore
    } = this.props;
    const { currentLanguage } = appStore;
    let categoriesRef = categoriesRefFr;
    if (currentLanguage === "en") categoriesRef = categoriesRefEn;
    const currentBlog = {
      id,
      type: categories.find(cat => cat.id === categoriesRef.videos)
        ? "video"
        : "article"
    };
    return (
      <I18nProvider language="en" catalogs={catalogs}>
        <div>
          <div className={classes.limitedBody}>
            <header>
              <div className={classes.categories}>
                {!!categories.length &&
                  categories.map(
                    cat =>
                      cat.id !== categoriesRef.featured && (
                        <button
                          type="button"
                          key={cat.id}
                          onClick={() => this.categoryClickHandler(cat)}
                        >
                          {decode(cat.name)}
                        </button>
                      )
                  )}
              </div>
              <H1 className={classes.title}>{title}</H1>
              <Blogger
                name={decode(blogger.name)}
                avatar={blogger.avatar_url}
                addDate={addDate}
                updateDate={updateDate}
              />
            </header>
            {currentBlog.type === "article" && cover && (
              <section className={classes.cover}>
                <img
                  className={classes.cover}
                  src={cover}
                  alt={`${title} cover`}
                />
              </section>
            )}
            <div className={classes.articleSection}>
              <div dangerouslySetInnerHTML={{ __html: content }} />
              {/*
            <section>
              <p>
                Lancer son activité d’autoentrepreneur s’accompagne d’un choix
                important sur le plan fiscal. Il s’agit du choix entre le régime
                normal à l’impôt sur le revenu (IR) et le prélèvement fiscal
                libératoire sous le régime micro-fiscal simplifié. Cette niche
                fiscale peut être intéressante pour les travailleurs
                indépendants et sera fonction du chiffre d’affaires et de la
                situation personnelle de chacun. L’intérêt est de choisir en
                connaissance de cause au moment de la création de l’activité ou
                bien d’en changer par la suite. Cette option est possible sous
                certaines conditions et répond à des formalités précises. Tour
                d’horizon du versement libératoire de l’impôt sur le revenu du
                travailleur micro-entrepreneur.
              </p>
            </section>
            <section>
              <Quote quote={quote.quote} author={quote.author} />
            </section>
            <section>
              <h3>En quoi consiste le prélèvement forfaitaire libératoire ?</h3>
              <p>
                Le statut du micro-entrepreneur s’accompagne d’une fiscalité
                intéressante. Le taux d’imposition est relativement bas et les
                revenus se déclarent simplement. Le prélèvement forfaitaire
                libératoire de l’impôt (PFL), aussi appelé versement libératoire
                de l’impôt sur le revenu micro-entrepreneur, correspond à la
                possibilité pour un travailleur indépendant de s’acquitter de
                ses impôts sur les revenus à la source. Seul le régime
                micro-fiscal permet cette option simplifiée.
              </p>
            </section>
            <section>
              <figure className={classes.figure}>
                <img
                  alt="wiiiw"
                  src="https://www.crockerriverside.org/sites/main/files/imagecache/medium/main-images/camera_lense_0.webp"
                />
                <figcaption>
                  Dans le cas où vous souhaitez opter pour le prélèvement
                  forfaitaire libératoire, vous devrez nécessairement opter pour
                  le régime micro-social, le 13 avril. Eric Petry et Lucas
                  Zappi.
                </figcaption>
              </figure>
            </section>
            <section>
              <List>
                <List.Item className={classes.listItem}>
                  Travailleur indépendant, célibataire et sans revenus annexes :
                  si votre chiffre d’affaires est inférieur ou égal à 25 620
                  euros, le régime classique à l’impôt sur le revenu est le plus
                  avantageux. Au-delà de ce montant, le prélèvement forfaitaire
                  libératoire est à choisir
                </List.Item>
                <List.Item className={classes.listItem}>
                  Travailleur indépendant, <strong>célibataire et</strong> sans
                  revenus annexes : si votre chiffre d’affaires est inférieur ou
                  égal à 25 620 euros, le régime classique à l’impôt sur le
                  revenu est le plus avantageux. Au-delà de ce montant, le
                  prélèvement forfaitaire libératoire est à choisir
                </List.Item>
                <List.Item className={classes.listItem}>
                  Travailleur indépendant, célibataire et sans revenus annexes :
                  si votre chiffre d’affaires est inférieur ou égal à 25 620
                  euros, le régime classique à l’impôt sur le revenu est le plus
                  avantageux. Au-delà de ce montant, le prélèvement forfaitaire
                  libératoire est à choisir
                </List.Item>
              </List>
            </section>
            */}
              {!!tags.length && (
                <section className={classes.tagList}>
                  {tags.map(tag => (
                    <button
                      type="button"
                      key={tag.id}
                      onClick={() => this.tagClickHandler(tag)}
                    >
                      #{decode(tag.name)}
                    </button>
                  ))}
                </section>
              )}
              <section>
                <Share id={id} />
              </section>
            </div>
          </div>
          <MoreArticles
            currentBlog={currentBlog}
            blogger={blogger}
            relatedPosts={relatedPosts}
          />
        </div>
      </I18nProvider>
    );
  }
}

Article.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  content: PropTypes.string.isRequired,
  blogger: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    avatar_url: PropTypes.string
  }).isRequired,
  addDate: PropTypes.string.isRequired,
  updateDate: PropTypes.string,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string
    })
  ),
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string
    })
  ),
  title: PropTypes.string.isRequired,
  cover: PropTypes.string,
  relatedPosts: PropTypes.arrayOf(PropTypes.string),
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  appStore: PropTypes.shape({
    currentLanguage: PropTypes.string
  }).isRequired
};

Article.defaultProps = {
  categories: [],
  tags: [],
  relatedPosts: [],
  cover: "",
  updateDate: ""
};

export default withRouter(Article);
