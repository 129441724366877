import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import { inject, observer } from "mobx-react/index";
import { withI18n } from "@lingui/react";
import { t } from "@lingui/macro";
import { MenuDashBoard, ItemMenu, Icon, Modal, ToastCard } from "cf-neo-ui";
import { Query, withApollo } from "react-apollo";
import SiderMenu from "./SiderMenu";
import SiderMenuItem from "./SiderMenuItem";
import Routes from "../../routes";
import DisponibilityCard from "./DisponibilityCard";
import PublicProfile from "./PublicProfile";
import { USER_PROFILE_STRENGTH } from "../queries";
import configs from "../../../../configs/club-freelance-globals";
import { LOGOUT_MUTATION } from "../../hbfLayout/mutation";
import SiderMenuSupport from "./SiderMenuSupport";
import SiderMenuItemSupport from "./SiderMenuItemSupport";
import { Divider } from "cf-neo-ui";
@inject("sessionStore", "referenceTask", "candidatesSearchStore")
@observer
class Sider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: false
    };
    this.myProfileHandler = this.myProfileHandler.bind(this);
    this.myInformationHandler = this.myInformationHandler.bind(this);
    this.myJobHandler = this.myJobHandler.bind(this);
    this.myJobHandler = this.myJobHandler.bind(this);
    this.myExperienceHandler = this.myExperienceHandler.bind(this);
    this.myEducationHandler = this.myEducationHandler.bind(this);
    this.mySearchHandler = this.mySearchHandler.bind(this);
  }

  logoutClickHandler = () => {
    const {
      sessionStore,
      history,
      client,
      candidatesSearchStore,
      apolloClient
    } = this.props;
    const { clearSearch } = candidatesSearchStore;
    const { Home } = Routes.HbfLayoutRoutes;
    apolloClient.mutate({
      mutation: LOGOUT_MUTATION,
      variables: { token: sessionStore.authToken }
    });
    sessionStore.logout();
    clearSearch();
    client.cache.reset();
    history.push(Home.path);
  };

  myJobHandler = () => {
    const { i18n, sessionStore } = this.props;
    const { generalSaveCase } = sessionStore;
    this.setState({ isVisible: !!generalSaveCase });
    sessionStore.changeGeneralSaveCase(null);
    if (!generalSaveCase) {
      const { MyJob } = Routes.LpbLayoutRoutes;
      const { history, referenceTask } = this.props;
      const { setSideMenu } = referenceTask;
      setSideMenu();
      history.push(MyJob.path);
    }
  };

  myExperienceHandler = () => {
    const { i18n, sessionStore } = this.props;
    const { generalSaveCase } = sessionStore;
    this.setState({ isVisible: !!generalSaveCase });
    sessionStore.changeGeneralSaveCase(null);
    if (!generalSaveCase) {
      const { MyExperience } = Routes.LpbLayoutRoutes;
      const { history, referenceTask } = this.props;
      const { setSideMenu } = referenceTask;
      setSideMenu();
      history.push(MyExperience.path);
    }
  };
  myEducationHandler = () => {
    const { i18n, sessionStore } = this.props;
    const { generalSaveCase } = sessionStore;
    this.setState({ isVisible: !!generalSaveCase });
    sessionStore.changeGeneralSaveCase(null);
    if (!generalSaveCase) {
      const { MyEducation } = Routes.LpbLayoutRoutes;
      const { history, referenceTask } = this.props;
      const { setSideMenu } = referenceTask;
      setSideMenu();
      history.push(MyEducation.path);
    }
  };

  mySearchHandler = () => {
    const { i18n, sessionStore } = this.props;
    const { generalSaveCase } = sessionStore;
    this.setState({ isVisible: !!generalSaveCase });
    sessionStore.changeGeneralSaveCase(null);
    if (!generalSaveCase) {
      const { MySearch } = Routes.LpbLayoutRoutes;
      const { history, referenceTask } = this.props;
      const { setSideMenu } = referenceTask;
      setSideMenu();
      history.push(MySearch.path);
    }
  };

  myProfileHandler = () => {
    const { i18n, sessionStore } = this.props;
    const { generalSaveCase } = sessionStore;
    this.setState({ isVisible: !!generalSaveCase });
    sessionStore.changeGeneralSaveCase(null);
    if (!generalSaveCase) {
      const { MyProfile } = Routes.LpbLayoutRoutes;
      const { history, referenceTask } = this.props;
      const { setSideMenu } = referenceTask;
      setSideMenu();
      history.push(MyProfile.path);
    }
  };

  myInformationHandler = () => {
    const { i18n, sessionStore } = this.props;
    const { generalSaveCase } = sessionStore;
    this.setState({ isVisible: !!generalSaveCase });
    sessionStore.changeGeneralSaveCase(null);
    if (!generalSaveCase) {
      const { MyInformation } = Routes.LpbLayoutRoutes;
      const { history, referenceTask } = this.props;
      const { setSideMenu } = referenceTask;
      setSideMenu();
      history.push(MyInformation.path);
    }
  };

  myParamsHandler = () => {
    const { sessionStore } = this.props;
    const { generalSaveCase } = sessionStore;
    this.setState({ isVisible: !!generalSaveCase });
    sessionStore.changeGeneralSaveCase(null);
    if (!generalSaveCase) {
      const { MyParams } = Routes.LpbLayoutRoutes;
      const { history, referenceTask } = this.props;
      const { setSideMenu } = referenceTask;
      setSideMenu();
      history.push(MyParams.path);
    }
  };

  render() {
    const { history, location, sessionStore, i18n, referenceTask } = this.props;
    const { pictureUrl, profilePhoto, firstName, lastName } = sessionStore;
    const { LpbLayoutRoutes, HbfLayoutRoutes, HbLayoutRoutes } = Routes;
    const { isSideMenuOpen, setSideMenu } = referenceTask;
    const { isVisible } = this.state;
    return (
      <aside className={`lpb-layout-sider ${isSideMenuOpen ? "active" : ""}`}>
        <div className="sider-logo">
          <Link to={HbfLayoutRoutes.Home.path}>
            <picture>
              <source
                type="image/webp"
                srcSet="/assets/images/logo_mindquest_dash.webp"
              />
              <img
                src="/assets/images/logo_mindquest_dash.webp"
                alt="mindquest"
              />
            </picture>
          </Link>
          <p className="between_logos">&</p>
          <Link to={HbfLayoutRoutes.Home.path}>
            <picture>
              <source
                type="image/jpg"
                srcSet="/assets/images/clubfreelance.jpg"
              />
              <img src="/assets/images/clubfreelance.jpg" alt="mindquest" />
            </picture>
          </Link>
        </div>
        <div className="sider-close">
          <Icon type="close" width={12} height={11} onClick={setSideMenu} />
        </div>
        <SiderMenu>
          <SiderMenuItem
            active={location.pathname === LpbLayoutRoutes.Dashboard.path}
            icon="speed-meter"
            title={i18n._(t`Dashboard`)}
            onClick={() => {
              history.push(LpbLayoutRoutes.Dashboard.path);
              setSideMenu();
            }}
          />
          <SiderMenuItem
            active={location.pathname === LpbLayoutRoutes.Missions.path}
            icon="flag"
            title={i18n._(t`Offres`)}
            onClick={() => {
              history.push(LpbLayoutRoutes.Missions.path);
              setSideMenu();
            }}
          />
          <SiderMenuItem
            active={location.pathname.split("/")[2] === "timesheets"}
            icon="clock"
            title={i18n._(t`Timesheets`)}
            onClick={() => {
              history.push(LpbLayoutRoutes.Timesheets.path);
              setSideMenu();
            }}
          />
          <SiderMenuItem
            active={location.pathname.split("/")[2] === "documentsContracts"}
            icon="document"
            title={i18n._(t`Documents`)}
            onClick={() => {
              history.push(LpbLayoutRoutes.DocumentsContracts.path);
              setSideMenu();
            }}
          />
        </SiderMenu>
        <div style={{ height: "15px", marginTop: "-20px" }}>
          <Divider
            orientation="horizontal"
            color="#dadee3"
            type="solid"
            thickness="2px"
          />
        </div>
        <SiderMenuSupport>
          <SiderMenuItemSupport
            active={location.pathname === LpbLayoutRoutes.Stats.path}
            iconType="guide"
            title={i18n._(t`Guide`)}
            onClick={() => {
              window.open(
                "https://mindquest.eb-help.com/help/collections/guide-utilistateurs",
                "_blank"
              );
            }}
          />
          <SiderMenuItemSupport
            active={location.pathname === HbLayoutRoutes.contact.path}
            iconType="contact"
            title={i18n._(t`Contactez nous`)}
            onClick={() => {
              window.open(
                window.location.origin + HbLayoutRoutes.contact.path,
                "_blank"
              );
              setSideMenu();
            }}
          />
        </SiderMenuSupport>
        <DisponibilityCard />

        <br />
        <Query query={USER_PROFILE_STRENGTH}>
          {({ loading, error, data }) => {
            if (loading) return null;
            if (error) return null;

            const { candidate } = data;
            return (
              <>
                {candidate.profileStrength.resumeGeneration && (
                  <PublicProfile />
                )}
                <div className="avatar-wrapper">
                  <div className="avatar">
                    <div>
                      <ToastCard
                        title={i18n._(t`Alerte`)}
                        description={i18n._(
                          t`Veuillez enregistrer avant de passer à l’étape suivante`
                        )}
                        expiresIn={0}
                        isVisible={isVisible}
                        closable
                        intent="error"
                        showButton
                        buttonText={i18n._(t`j'ai compris`)}
                        onClose={() => {
                          this.setState({ isVisible: false });
                        }}
                      />
                      <MenuDashBoard
                        userName="User Name"
                        percentage={candidate.profileStrength.score}
                        image={
                          pictureUrl || profilePhoto || "/defaultAvatar.webp"
                        }
                      >
                        <ItemMenu
                          label={i18n._(t`Mon profil`)}
                          value={1}
                          onClick={this.myProfileHandler}
                        />
                        <ItemMenu
                          label={i18n._(t`Mes Coordonnées`)}
                          value={1}
                          onClick={this.myInformationHandler}
                        />

                        <ItemMenu
                          label={i18n._(t`Mon métier`)}
                          value={1}
                          onClick={this.myJobHandler}
                        />
                        <ItemMenu
                          label={i18n._(t`Mon expérience`)}
                          value={1}
                          onClick={this.myExperienceHandler}
                        />
                        <ItemMenu
                          label={i18n._(t`Ma formation`)}
                          value={1}
                          onClick={this.myEducationHandler}
                        />
                        <ItemMenu
                          label={i18n._(t`Ma recherche`)}
                          value={1}
                          onClick={this.mySearchHandler}
                        />
                        <ItemMenu
                          label={i18n._(t`Mes paramètres`)}
                          value={1}
                          onClick={this.myParamsHandler}
                        />
                        <ItemMenu
                          label={i18n._(t`Logout`)}
                          value={2}
                          onClick={this.logoutClickHandler}
                        />
                      </MenuDashBoard>
                    </div>
                  </div>
                  <div className="user-name">
                    {firstName} {lastName}
                  </div>
                </div>
              </>
            );
          }}
        </Query>
      </aside>
    );
  }
}

Sider.wrappedComponent.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string
  }).isRequired,
  candidatesSearchStore: PropTypes.shape({
    clearSearch: PropTypes.func
  }).isRequired,
  sessionStore: PropTypes.shape({
    logout: PropTypes.func,
    authToken: PropTypes.string,
    pictureUrl: PropTypes.string,
    profilePhoto: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string
  }).isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  referenceTask: PropTypes.shape({
    isSideMenuOpen: PropTypes.bool,
    setSideMenu: PropTypes.func
  }).isRequired,
  client: PropTypes.shape({
    cache: PropTypes.shape
  }).isRequired,
  apolloClient: PropTypes.shape({
    mutate: PropTypes.func
  }).isRequired
};

export default withApollo(withI18n()(withRouter(Sider)));
