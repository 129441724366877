import React, { Component } from "react";
import * as PropTypes from "prop-types";
import {
  Container,
  Row,
  Col,
  Button,
  TextInput,
  toaster,
  Modal
} from "cf-neo-ui";
import { withI18n } from "@lingui/react";
import { Mutation, withApollo } from "react-apollo";
import { t, Trans } from "@lingui/macro";
import AboutWidget from "./AboutWidget";
import LinkWidget from "./LinkWidget";
import { links1, links2, links3 } from "./links";
import classes from "./footer.module.scss";
import { inject, observer } from "mobx-react";
import { Link } from "react-router-dom";
import { GET_USER_NEWSLETTER_SUBSCRIPTION } from "../blog-layout/queries";
import { SUB_TO_NEWSLETTER } from "./mutations";
import ConnectedAndSubscribedUserModal from "../blog-layout/news-banner/ConnectedAndSubscribedUserModal";
import { GET_USER_INFO } from "../../../components/hoc/queries";
import ConfirmModal from "../blog-layout/news-banner/ConfirmModal";

const EMAIL_REG = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

@inject("appStore", "sessionStore")
@observer
class Footer extends Component {
  constructor(props) {
    super(props);
    this.email = "";
    this.state = {
      valid: false,
      showModal: false,
      showIsConnectedAndSubscribedModal: false,
      toasterId: "nl_consultant",
      receiveNewsletterTalent: false,
      receiveNewsletterClient: false
    };
  }

  focusHandler = e => {
    const { sessionStore } = this.props;
    if (sessionStore.authToken && sessionStore.email) {
      e.target.value = sessionStore.email;
      this.email = sessionStore.email;
    }
  };

  changeHandler = e => {
    this.email = e.target.value;
  };

  subscribe = async () => {
    const { client, i18n } = this.props;
    const valid = EMAIL_REG.test(this.email);
    if (valid) {
      const { loading, error, data } = await client.query({
        query: GET_USER_NEWSLETTER_SUBSCRIPTION,
        variables: {
          email: this.email,
          language: localStorage.getItem("language")
        },
        fetchPolicy: "network-only"
      });
      if (!loading && !error) {
        const { getUserNewsLetterSubscriptions } = data;
        const {
          receiveNewsletterTalent,
          receiveNewsletterClient
        } = getUserNewsLetterSubscriptions;
        this.setState({
          receiveNewsletterClient:
            getUserNewsLetterSubscriptions.receiveNewsletterClient,
          receiveNewsletterTalent:
            getUserNewsLetterSubscriptions.receiveNewsletterTalent
        });
        if (receiveNewsletterTalent && receiveNewsletterClient)
          this.setState({ showIsConnectedAndSubscribedModal: true });
        else this.setState({ showModal: true });
      }
    } else {
      toaster.warning({
        title: i18n._(t`Avertissement`),
        description: i18n._(t`Veillez entrer un email valide`)
      });

      this.setState({ valid });
    }
  };

  register = (talent, client, mutation) => {
    this.setState({ showModal: false });
    const list = [];
    if (talent) list.push("talent");
    if (client) list.push("client");
    const input = {
      email: this.email,
      language: localStorage.getItem("language"),
      list
    };

    if (list.includes("client") && list.includes("talent"))
      this.setState({ toasterId: "nl_consultant_client" });
    else if (list.includes("client")) this.setState({ toasterId: "nl_client" });
    else if (list.includes("talent"))
      this.setState({ toasterId: "nl_consultant" });

    return mutation({ variables: input });
  };

  closeModal = () => {
    this.setState({ showIsConnectedAndSubscribedModal: false });
  };

  render() {
    const { i18n, appStore, sessionStore } = this.props;
    const { currentLanguage } = appStore;
    const { authToken } = sessionStore;
    const {
      showModal,
      showIsConnectedAndSubscribedModal,
      toasterId,
      receiveNewsletterTalent,
      receiveNewsletterClient
    } = this.state;

    return (
      <div>
        {" "}
        <section className={classes.footer}>
          <footer>
            <div className={classes.bgImg}>
              <picture>
                <source
                  type="images/webp"
                  srcSet="/assets/images/footerbg.webp"
                />
                <img src="/assets/images/footerbg.webp" alt="footer" />
              </picture>
            </div>
            <Container className={classes.container}>
              <div className={classes.content}>
                <div className={classes.newsSubscription}>
                  <div className={classes.description}>
                    <span>
                      <Trans>S'abonner à notre newsletter IT</Trans>
                    </span>
                  </div>
                  <h5 className={classes.subscribeHeader}>
                    <Trans>
                      Rejoindre Mindquest Connect et trouver les meilleurs idées
                      de IT professionnel
                    </Trans>
                  </h5>
                  <div className={classes.subscriptionInput}>
                    <TextInput
                      placeholder={i18n._(t`adresse email`)}
                      type="email"
                      spellCheck="false"
                      className={classes.emailInput}
                      onChange={this.changeHandler}
                      onEnterPressed={this.subscribe}
                      onFocus={this.focusHandler}
                    />
                    <Button
                      type="button"
                      variant="secondary"
                      className={classes.subscribeButton}
                      onClick={this.subscribe}
                    >
                      <Trans>s&apos;abonner</Trans>
                    </Button>
                  </div>
                </div>
                <Row>
                  <Col
                    className={classes.aboutWidget}
                    xs={12}
                    sm={3}
                    md={3}
                    lg={3}
                  >
                    <AboutWidget />
                  </Col>
                  <Col xs={12} sm={3} md={3} lg={3}>
                    <LinkWidget
                      title={i18n._(t`Freelances`)}
                      links={links1(i18n, currentLanguage)}
                    />
                  </Col>
                  <Col xs={12} sm={3} md={3} lg={3}>
                    <LinkWidget
                      title={i18n._(t`Entreprise_`)}
                      links={links2(i18n)}
                    />
                  </Col>
                  <Col xs={12} sm={3} md={3} lg={2}>
                    <LinkWidget
                      title={i18n._(t`Mindquest`)}
                      links={links3(i18n)}
                    />
                  </Col>
                </Row>
              </div>
            </Container>
          </footer>
        </section>
        <section>
          <footer className={classes.secondFooter}>
            <Container>
              <div className={classes.content}>
                <Row>
                  <Col xs={12} sm={3} md={2} lg={2}>
                    <Link to="/privacy-policy" className={classes.footerLinks2}>
                      <Trans> Politique de confidentialité </Trans>
                    </Link>
                  </Col>
                  <Col xs={12} sm={3} md={3} lg={2}>
                    <Link
                      to="/terms-and-conditions-of-use"
                      className={classes.footerLinks2}
                    >
                      <Trans> CGU et mentions légales </Trans>
                    </Link>
                  </Col>
                  <Col xs={12} sm={3} md={1} lg={2}>
                    <a href="/site-map" className={classes.footerLinks2}>
                      <Trans> Plan du site </Trans>
                    </a>
                  </Col>
                  <Col xs={12} sm={3} md={6} lg={6}>
                    <Link to="#" className={classes.footerLinks2}>
                      <Trans>
                        {" "}
                        Tous droits réservés Club Freelance Limited - sociéte de
                        recrutement spécialisée dans l'informatique.{" "}
                      </Trans>
                    </Link>
                  </Col>
                </Row>
              </div>
            </Container>
          </footer>
        </section>
        <Modal
          centred
          isVisible={showIsConnectedAndSubscribedModal}
          withCloseButton={false}
          position="fixed"
        >
          <ConnectedAndSubscribedUserModal close={this.closeModal} />
        </Modal>
        {showModal && (
          <Modal
            centred
            isVisible={showModal}
            onClose={() => this.setState({ showModal: false })}
          >
            <Mutation
              mutation={SUB_TO_NEWSLETTER}
              onCompleted={({ subToNewsletter }) => {
                if (subToNewsletter.status === "ok")
                  toaster.success({
                    id: toasterId,
                    title: i18n._(t`Merci`),
                    description: i18n._(
                      t`Vous êtes désormais abonné à la newsletter de Mindquest`
                    )
                  });
                if (subToNewsletter.status === "error")
                  toaster.error({
                    title: i18n._(t`Erreur`),
                    description: i18n._(
                      t`Un problème est survenu lors de la création de l’abonnement`
                    )
                  });
              }}
              onError={() =>
                toaster.error({
                  title: i18n._(t`Erreur`),
                  description: i18n._(
                    t`Un problème est survenu lors de la création de l’abonnement`
                  )
                })
              }
              ignoreResults
              refetchQueries={[
                {
                  query: GET_USER_INFO,
                  variables: { token: authToken }
                }
              ]}
            >
              {subToNewsletter => (
                <ConfirmModal
                  receiveNewsletterTalent={receiveNewsletterTalent}
                  receiveNewsletterClient={receiveNewsletterClient}
                  register={(talent, client) =>
                    this.register(talent, client, subToNewsletter)
                  }
                />
              )}
            </Mutation>
          </Modal>
        )}
      </div>
    );
  }
}

Footer.propTypes = {
  sessionStore: PropTypes.shape({
    authToken: PropTypes.string,
    email: PropTypes.string,
    isMapsScriptReady: PropTypes.bool
  }).isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired
};

export default withI18n()(withApollo(Footer));
