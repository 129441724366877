import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { FormCard, SwitchButton, DatePicker, toaster, Button } from "cf-neo-ui";
import { inject, observer } from "mobx-react";
import { ApolloConsumer, Mutation, withApollo } from "react-apollo";
import moment from "moment";
import { withI18n } from "@lingui/react";
import { Trans, t } from "@lingui/macro";
import uniqid from "uniqid";
import { UPDATE_CANDIDATE_MUTATION } from "../queries";
import { GET_USER_INFO } from "../../../../components/hoc/queries";
import runtimeVars from "../../../../configs/runTimeVars";
import { LOGOUT_MUTATION } from "../../hbfLayout/mutation";
import classes from "../../../candidate-to-client/CandidateToClient.module.scss";

@inject("sessionStore", "appStore", "candidatesSearchStore")
@observer
class AvailabilityCard extends Component {
  constructor(props) {
    super(props);
    const { sessionStore } = this.props;
    this.state = {
      available:
        !sessionStore.availability && sessionStore.availability <= Date.now()
    };
  }

  switchHandler = () => {
    const { sessionStore } = this.props;
    const { available } = this.state;
    available
      ? sessionStore.changeAvailability(null)
      : sessionStore.changeAvailability(
          new Date().setDate(new Date().getDate())
        );
    this.setState({ available: !available });
  };

  availabilityChangeHandler = value => {
    const { sessionStore } = this.props;
    if (value === null || value >= new Date().getTime()) {
      this.setState({ available: false });
    } else this.setState({ available: true });
    sessionStore.changeAvailability(value);
  };

  onErrorHandler = msg => {
    const { i18n } = this.props;
    toaster.error({
      title: i18n._(t`Erreur`),
      description: msg
    });
  };

  updateCandidateCompletedHandler() {
    const { i18n } = this.props;
    toaster.success({
      title: i18n._(t`Disponibilité`),
      description: i18n._(t`Enregistrement effectué avec succès`)
    });
  }

  render() {
    const { available } = this.state;
    const {
      sessionStore,
      appStore,
      candidatesSearchStore,
      apolloClient
    } = this.props;
    const { currentLanguage } = appStore;
    const { availability, _id, authToken, logout } = sessionStore;
    const { clearSearch } = candidatesSearchStore;
    const availabilityKey = uniqid();
    const switchButtonKey = uniqid();

    if (
      !available &&
      availability < new Date().setDate(new Date().getDate() + 1) &&
      availability !== null
    )
      this.setState({ available: true });
    if (
      available &&
      (availability > new Date().setDate(new Date().getDate() + 1) ||
        availability === null)
    )
      this.setState({ available: false });

    return (
      <ApolloConsumer>
        {apolloClient => (
          <Mutation
            mutation={UPDATE_CANDIDATE_MUTATION}
            variables={{
              _id,
              availability
            }}
            refetchQueries={[
              {
                query: GET_USER_INFO,
                variables: { token: authToken }
              }
            ]}
            onCompleted={data => this.updateCandidateCompletedHandler(data)}
            onError={errors => {
              errors.graphQLErrors.forEach(({ message, data }) => {
                if (message === "UNAUTHENTICATED") {
                  apolloClient.mutate({
                    mutation: LOGOUT_MUTATION,
                    variables: { token: sessionStore.authToken }
                  });
                  logout();
                  clearSearch();
                }
                if (data && data.isCustomError) {
                  this.onErrorHandler(message);
                }
              });
            }}
          >
            {mutation => (
              <div className="dispo-card">
                <FormCard style={{ zIndex: "1" }}>
                  <div className="title">
                    <Trans>disponibilite</Trans>
                  </div>
                  <div className="switch-btn">
                    <SwitchButton
                      key={switchButtonKey}
                      checked={available}
                      onSwitch={async () => {
                        await this.switchHandler();
                        return mutation();
                      }}
                    />
                  </div>
                </FormCard>
                {!available && (
                  <FormCard
                    style={{
                      top: "-20px",
                      overflow: "unset",
                      paddingLeft: "0",
                      paddingRight: "0",
                      width: "100%"
                    }}
                  >
                    <div className="sub-title">
                      <Trans>Disponible à partir de:</Trans>
                    </div>
                    <div className="date-input">
                      <DatePicker
                        key={availabilityKey}
                        dateFormat="dd/MM/yyyy"
                        defaultDate={
                          availability
                            ? moment
                                .unix(availability / 1000)
                                .format("DD/MM/YYYY")
                            : ""
                        }
                        onChange={async value => {
                          await this.availabilityChangeHandler(
                            moment(value).valueOf()
                          );
                          return mutation();
                        }}
                        disablePastDays
                        locale={currentLanguage || runtimeVars.APP_LANG}
                      />
                    </div>
                  </FormCard>
                )}
              </div>
            )}
          </Mutation>
        )}
      </ApolloConsumer>
    );
  }
}

AvailabilityCard.wrappedComponent.propTypes = {
  sessionStore: PropTypes.shape({
    availability: PropTypes.number,
    changeAvailability: PropTypes.func,
    authToken: PropTypes.string,
    logout: PropTypes.func,
    _id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }).isRequired,
  candidatesSearchStore: PropTypes.shape({
    clearSearch: PropTypes.func
  }).isRequired,
  appStore: PropTypes.shape({
    currentLanguage: PropTypes.string,
    width: PropTypes.number
  }).isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  apolloClient: PropTypes.shape({
    mutate: PropTypes.func
  }).isRequired
};

export default withApollo(withI18n()(AvailabilityCard));
