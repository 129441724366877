import MissionsPage from "../../missions-page";
import SearchCandidatesPage from "../../search-candidates-page";
import SingleMission from "../../single-mission";
import HomePageConsultant from "../../home-page-consultant";
import HomePageCompany from "../../home-page-company/index";
import AboutPage from "../../about";
import SourcerOffers from "../../sourcer-offers";
import HomePage from "../../home-page";
import SingleCandidatePage from "../../single-Candidate-page/SingleCandidatePage";
/** !!! For any modification in this file, please re-do it in src/utils/urls.js */

const HbfLayoutRoutes = {
  Home: { path: "/", component: HomePage },
  consultant: { path: "/consultant", component: HomePageConsultant },
  client: { path: "/enterprise", component: HomePageCompany },
  MissionsPage: { path: "/missions", component: MissionsPage },
  SearchCandidatesPage: {
    path: "/candidates",
    component: SearchCandidatesPage
  },
  about: { path: "/about", component: AboutPage },
  SingleMission: { path: "/missions/:id", component: SingleMission },
  SingleCandidatePage: {
    path: "/candidates/:id",
    component: SingleCandidatePage
  },
  PublicProfilPage: {
    path: "/talent/:id",
    component: SingleCandidatePage
  },
  sourcerOffers: { path: "/offers/:id", component: SourcerOffers }
};

export default HbfLayoutRoutes;
