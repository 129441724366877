import gql from "graphql-tag";

const SINGLE_TIME_SHEETS_QUERY = gql`
  query Timesheet($id: Int!) {
    timesheet(id: $id) {
      id
      title
      purchaseOrder
      client {
        id
        firstName
        lastName
        name
        email
        companyName
        isSigned
      }
      candidate {
        id
        firstName
        lastName
        name
        email
        city
      }
      placementID
      createdAt
      month
      year
      updatedAt
      status
      country {
      code
      name
      }
      workPlan {
        day
        minutes
        hours
        theme {
          name
          color
        }
        type
        place
        description
      }
      totals {
        normalTime
        specialTime
        extraTime
        remoteTime
      }
      conversation {
        timesheetID
        messages {
          date
          speaker
          action
          details {
            type
            text
          }
        }
      }
      approvingClient {
        id
        firstName
        lastName
        name
        email
        companyName
        isSigned
      }
      approvingClient2 {
        id
        firstName
        lastName
        name
        email
        companyName
        isSigned
      }
      holidays {
        month
        year
        country
        holidays {
          day
          name
        }
      }
      themes {
        name
        color
      }
      clientCorporation {
        id
        name
      }
    }
  }
`;

export { SINGLE_TIME_SHEETS_QUERY };
