import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withI18n } from "@lingui/react";
import { t } from "@lingui/macro";
import {
  Container,
  Row,
  Col,
  CandidateCardV2,
  toaster,
  Modal
} from "cf-neo-ui";
import FaveUnfave from "../../../../components/fave-unfave";
import routes from "../../../layout/routes/hbfLayoutRoutes";
import classes from "./CandidatesList.module.scss";
import SingleCandidateModal from "../../../single-Candidate-page/SingleCandidateBody/SingleCandidateBodyLeftSlide/SingleCandidateModal/SingleCandidateModal";
import Routes from "../../../layout/routes";
import { inject, observer } from "mobx-react";
import { Mutation } from "react-apollo";
import {
  DELETE_TALENT_MUTATION,
  UPDATE_Talent_POOL_MUTATION
} from "./mutation";

@inject("sessionStore", "candidatesSearchStore")
@observer
class CandidatesListForUsers extends Component {
  constructor(props) {
    super(props);
    this.listKey = 0;
    const { candidates } = this.props;
    const selectedCandidates = [];
    candidates.map(candidate => {
      if (candidate.existInSearchTalentPool) {
        selectedCandidates.push(candidate.candidate_id);
      }
    });
    this.state = {
      isVisible: false,
      candidateID: null,
      operation: "",
      error: false,
      selectedCandidates: selectedCandidates
    };
  }

  toDetails = id => {
    const { history } = this.props;
    history.push(`${routes.MissionsPage.path}/${id}`);
  };

  closeModal = () => {
    this.setState({ isVisible: false });
  };

  onShowDetailsHandler = candidateID => {
    const { sessionStore, history, i18n } = this.props;
    const {
      signUpUserRole,
      authToken,
      changeMessage,
      setBackRefCandidateSearch
    } = sessionStore;

    const { error } = this.state;
    if (!error) {
      if (authToken) {
        if (signUpUserRole === "CANDIDATE") {
          changeMessage(
            i18n._(
              t`Vous devez vous connecter avec un compte entreprise pour continuer.`
            )
          );
          setBackRefCandidateSearch("2");
          history.push(Routes.HbLayoutRoutes.UserRoleNotification.path);
        } else if (signUpUserRole === "CLIENT") {
          this.setState({
            candidateID: parseInt(candidateID)
          });
        }
      } else {
        setBackRefCandidateSearch("1");
        history.push(Routes.HbLayoutRoutes.Login.path);
      }
    }
  };

  getExperience = ex => {
    const { i18n } = this.props;
    switch (ex) {
      case 1:
        return i18n._(t`Junior (0 - 3)`);
      case 2:
        return i18n._(t`Confirmed (3 - 7)`);
      case 3:
        return i18n._(t`Senior (7 - 10)`);
      default:
        return i18n._(t`Expert (10+)`);
    }
  };
  onErrorHandlerupdate = msg => {
    const { i18n } = this.props;
    const { error } = this.state;

    if (
      msg ===
      'Unexpected error value: "Vous avez dépasser le nombre limité de vivier"'
    ) {
      this.setState({ isVisible: false });
      toaster.error({
        title: i18n._(t`Erreur`),
        description: i18n._(t`Vous avez dépasser le nombre limité de vivier`)
      });
    } else if (
      msg ===
      'Unexpected error value: "Vous avez dépasser le nombre limité de talent dans un vivier"'
    ) {
      this.setState({ isVisible: false });
      toaster.error({
        title: i18n._(t`Erreur`),
        description: i18n._(
          t`Vous ne pouvez intégrer que 15 talents à votre vivier par défaut "Mes recherches de talent IT"`
        )
      });
    } else if (msg === 'Unexpected error value: "Ce talent existe déjà"') {
      this.setState({ isVisible: false });
      toaster.success({
        title: i18n._(t`success`),
        description: i18n._(
          t`Ce talent existe déjà dans votre vivier par défaut "Mes recherches de talent IT"`
        )
      });
    } else {
      this.setState({ isVisible: false });
      toaster.error({
        title: i18n._(t`Erreur`),
        description: i18n._(
          t`Une erreur est survenue, veuillez réessayer ultérieurement`
        )
      });
    }
  };
  urlAccess = url => {
    window.open(url, "_blank");
  };
  getMutationVariables = (operations, candidateID) => {
    const { sessionStore } = this.props;
    const {
      client,
      email,
      companyName,
      lastName,
      firstName,
      companyID
    } = sessionStore;
    if (operations === "delete") {
      return {
        clientID: client && client._id,
        poolName: "Mes recherches de talent IT",
        creatorID: client && client._id,
        talentID: parseInt(candidateID)
      };
    } else {
      return {
        poolName: "Mes recherches de talent IT",
        clientID: client && client._id,
        clientEmail: email,
        clientCompanyName: companyName,
        clientName: firstName + " " + lastName,
        sourceTalentPool: "search",
        creatorID: client && client._id,
        shared: true,
        clientCompanyId: companyID,
        talentID: parseInt(candidateID)
      };
    }
  };
  addToSelectedCandidates = candidateID => {
    const { selectedCandidates } = this.state;
    if (selectedCandidates.includes(candidateID)) {
      const index = selectedCandidates.indexOf(candidateID);
      if (index > -1) {
        selectedCandidates.splice(index, 1);
      }
    } else {
      selectedCandidates.push(candidateID);
    }
    this.setState({ selectedCandidates: selectedCandidates });
  };

  render() {
    const {
      i18n,
      candidates,
      candidatesSearchStore,
      sessionStore,
      history
    } = this.props;
    const { changeDescription } = candidatesSearchStore;
    const {
      isVisible,
      candidateID,
      selectedCandidates,
      operation
    } = this.state;
    const { authToken, setBackRefCandidateSearch } = sessionStore;
    return (
      <Container className={classes.missionsListing}>
        <Modal
          centred
          isVisible={isVisible}
          withCloseButton={true}
          onClose={() => {
            this.setState({ isVisible: false });
            changeDescription("");
          }}
          position="fixed"
        >
          <SingleCandidateModal
            close={this.closeModal}
            candidateBhId={candidateID}
            operation={operation}
          />
        </Modal>
        <Row>
          {candidates.map(candidate => (
            <Col
              xl={3}
              lg={4}
              md={4}
              sm={6}
              xs={6}
              key={candidate.candidate_id}
              className={classes.missionCard}
            >
              <span>
                <FaveUnfave candidateId={candidate.candidate_id}>
                  <Mutation
                    mutation={
                      selectedCandidates.includes(candidate.candidate_id)
                        ? DELETE_TALENT_MUTATION
                        : UPDATE_Talent_POOL_MUTATION
                    }
                    variables={this.getMutationVariables(
                      selectedCandidates.includes(candidate.candidate_id)
                        ? "delete"
                        : "add",
                      candidate.candidate_id
                    )}
                    onCompleted={() => {
                      this.setState({
                        isVisible: true,
                        operation: selectedCandidates.includes(
                          candidate.candidate_id
                        )
                          ? "supprimer"
                          : "ajouter"
                      });
                      this.addToSelectedCandidates(candidate.candidate_id);
                    }}
                    onError={errors => {
                      this.setState({ error: true });
                      errors.graphQLErrors.forEach(({ message }) => {
                        if (message) {
                          this.onErrorHandlerupdate(message);
                        }
                      });
                    }}
                  >
                    {(mutationTalentPool, { loading }) => (
                      <CandidateCardV2
                        id={candidate.candidate_id}
                        experience={this.getExperience(candidate.experience)} // "10 ans d'experience"
                        address={`${candidate.countryCode} ${
                          !candidate.zipCode ||
                          candidate.zipCode === "0" ||
                          candidate.zipCode === 0
                            ? ""
                            : candidate.zipCode
                        }`}
                        publicProfile={candidate.publicProfil}
                        score={candidate.value}
                        loading={loading ? true : false}
                        occupation={candidate.occupation}
                        skills={candidate.skills}
                        required_skills={candidate.required_skills}
                        showHeart={loading ? false : true}
                        starred={selectedCandidates.includes(
                          candidate.candidate_id
                        )}
                        addClick={() => {
                          if (authToken) {
                            this.onShowDetailsHandler(candidate.candidate_id);
                            mutationTalentPool();
                          } else {
                            setBackRefCandidateSearch("1");
                            history.push(Routes.HbLayoutRoutes.Login.path);
                          }
                          return false;
                        }}
                        onShowDetails={() => {
                          const BHId = candidate?.candidate_id;
                          const slugedName = candidate?.firstName
                            .toLowerCase()
                            .replace(/ /g, "-")
                            .replace(/[^\w-]+/g, "");
                          const link = BHId
                            ? slugedName + "-" + BHId.toString()
                            : "";
                          if (authToken) {
                            this.urlAccess(`/talent/${link}`);
                          } else {
                            history.push(Routes.HbLayoutRoutes.Login.path);
                          }
                        }}
                        href="https://www.club-freelance.com"
                        showDetailsLabel={i18n._(t`intéressé par ce candidat`)}
                        requiredSkillsTitle={i18n._(
                          t`Compétences de la recherche`
                        )}
                        secondarySkillsTitle={i18n._(
                          t`Autres compétences du candidat`
                        )}
                      />
                    )}
                  </Mutation>
                </FaveUnfave>
              </span>
            </Col>
          ))}
        </Row>
      </Container>
    );
  }
}

CandidatesListForUsers.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  candidates: PropTypes.arrayOf(PropTypes.shape({})),
  sessionStore: PropTypes.shape({
    authToken: PropTypes.string,
    changeMessage: PropTypes.func,
    setBackRefCandidateSearch: PropTypes.func,
    signUpUserRole: PropTypes.string
  }).isRequired,
  candidatesSearchStore: PropTypes.shape({
    changeDescription: PropTypes.func
  }).isRequired
};

CandidatesListForUsers.defaultProps = {
  candidates: []
};

export default withI18n()(withRouter(CandidatesListForUsers));
