import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { Trans } from "@lingui/macro";
import { Link, withRouter } from "react-router-dom";
import { Container, Row, Col, StepBar, IconCircle } from "cf-neo-ui";
import { withI18n } from "@lingui/react";
import Routes from "../routes/index";

@inject("sessionStore", "postANeedStore")
@observer
class Header extends Component {
  navigateWithStepBar = (stepNumber, e) => {
    const { postANeedStore, history } = this.props;
    if (stepNumber <= postANeedStore.step) {
      if (stepNumber === 2) history.push(`/post-a-need/via-form/step-1`);
      if (stepNumber === 3) history.push(`/post-a-need/via-form/step-2`);
      if (stepNumber === 4) history.push(`/post-a-need/via-form/step-3`);
    }
  };

  stepBarConfiguration = () => {
    const { sessionStore, postANeedStore } = this.props;
    const { signUpUserRole, client } = sessionStore;
    if (signUpUserRole === "CANDIDATE")
      return <StepBar stepsNumber={5} activatedSteps={sessionStore.step} />;
    if (signUpUserRole === "CLIENT" && !client)
      return <StepBar stepsNumber={3} activatedSteps={sessionStore.step} />;
    if (signUpUserRole === "CLIENT" && client)
      return (
        <StepBar
          stepsNumber={4}
          activatedSteps={postANeedStore.step}
          onClick={(stepNumber, e) => {
            this.navigateWithStepBar(stepNumber, e);
          }}
        />
      );

    return null;
  };

  render() {
    const { withStepsBar, sessionStore } = this.props;
    const { Home } = Routes.HbfLayoutRoutes;
    const { DashboardCompany, Dashboard } = Routes.LpbLayoutRoutes;
    let { path } = Home;
    if (sessionStore.client) path = DashboardCompany.path;
    if (sessionStore.candidate) path = Dashboard.path;

    return (
      <div className="header">
        <Container>
          <Row>
            <Col className="logo-column">
              <div className="logo">
                <Link to={path}>
                  <picture>
                    <source
                      type="images/webp"
                      srcSet="/assets/images/logo_mind_quest.webp"
                    />
                    <img
                      src="/assets/images/logo_mind_quest.webp"
                      alt="Mindquest Logo"
                    />
                  </picture>
                </Link>
              </div>
            </Col>
            {withStepsBar && (
              <Col className="steps-bar-column">
                <div>{this.stepBarConfiguration()}</div>
              </Col>
            )}
            <Col className="back-to-site-column">
              <div className="back-to-site">
                <Link to={path}>
                  <IconCircle
                    type="chevron-left"
                    color="rgba(110,25,25,1)"
                    color2="rgba(255,0,0,1)"
                    innerColor="rgba(255,255,255,1)"
                    innerColor2="rgba(255,255,255,1)"
                    iconWidth={12}
                    iconHeight={12}
                    dimension={24}
                  />
                  <span>
                    {sessionStore.client ? (
                      <Trans>Retour au dashboard</Trans>
                    ) : (
                      <Trans>Retour au site</Trans>
                    )}
                  </span>
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

Header.wrappedComponent.propTypes = {
  withStepsBar: PropTypes.bool,
  history: PropTypes.shape({
    push: PropTypes.func,
    replace: PropTypes.func
  }).isRequired,
  sessionStore: PropTypes.shape({
    client: PropTypes.shape({}),
    candidate: PropTypes.shape({}),
    step: PropTypes.number,
    signUpUserRole: PropTypes.string
  }).isRequired,
  postANeedStore: PropTypes.shape({
    changeStep: PropTypes.func,
    step: PropTypes.number
  }).isRequired
};

Header.defaultProps = {
  withStepsBar: false
};

export default withI18n()(withRouter(Header));
