/* eslint-disable */ module.exports = {
  languageData: {
    plurals: function(n, ord) {
      var s = String(n).split("."),
        v0 = !s[1],
        t0 = Number(s[0]) == n,
        n10 = t0 && s[0].slice(-1),
        n100 = t0 && s[0].slice(-2);
      if (ord)
        return n10 == 1 && n100 != 11
          ? "one"
          : n10 == 2 && n100 != 12
          ? "two"
          : n10 == 3 && n100 != 13
          ? "few"
          : "other";
      return n == 1 && v0 ? "one" : "other";
    }
  },
  messages: {
    '"Vous devez vous connecter avec un compte entreprise pour avoir poster un besoin"':
      "Action restricted to Company accounts",
    "'' au vivier le": "' at the pool on ",
    "'' du vivier le": "' from the pool on ",
    "'' le": "' on ",
    "(Vivier par d\xE9faut)": "(Default talent pool)",
    "(vivier par d\xE9faut)": "(Default talent pool)",
    "(where you are a candidate) clients for the purpose of introducing candidates to them and providing information during the recruitment process;":
      "(where you are a candidate) clients for the purpose of introducing candidates to them and providing information during the recruitment process",
    "(where you are a client) candidates for the purpose of arranging interviews and engagements;":
      "(where you are a client) candidates for the purpose of arranging interviews and engagements;",
    "* Ce commentaire sera visible par le client":
      "* This comment will be visible by the client",
    "* Ce commentaire sera visible par le consultant":
      "* This comment will be visible by the consultant",
    ", mis \xE0 jour le": ", updated on",
    "...ou deposer votre fiche de poste ici":
      "...or include your job description here",
    "...ou simplement deposer votre CV ici": "... or simply drop your CV here",
    "1 min": "1 min",
    "1)   Definition of cookies": "1)   Definition of cookies",
    "1) Definition of cookies": "1)   Definition of cookies",
    "1.   Definitions": "1.   Definitions",
    "1. Definitions": "1). Definition",
    "10. Amendments to the Personal Data Protection Policy":
      "10. Amendments to the Personal Data Protection Policy",
    "1st Party": "1st Party",
    "2 \xE8me signataire": "second signatory",
    "2)   Managing cookies": "2)   Managing cookies",
    "2) Managing cookies": "2) Managing cookies",
    "2.   General rules on the collection and processing of personal data":
      "2.   General rules on the collection and processing of personal data",
    "2. General rules on the collection and processing of personal data":
      "2. General rules on the collection and processing of personal data",
    "3 RD Party": "3 RD Party",
    "3)   Cookies used by the company": "3)   Cookies used by the company",
    "3) Cookies used by the company": "3) Cookies used by the company",
    "3.   Nature of the collection of personal data":
      "3.   Nature of the collection of personal data",
    "3. Nature of the collection of personal data":
      "3. Nature of the collection of personal data",
    "30 jours": "30 days",
    "4.   Purpose of the collection of personal data":
      "4.   Purpose of the collection of personal data",
    "4. Purpose of the collection of personal data":
      "4. Purpose of the collection of personal data",
    "5.   Recipient (s) of the collection of personal data":
      "5.   Recipient (s) of the collection of personal data",
    "5. Recipient (s) of the collection of personal data":
      "5. Recipient (s) of the collection of personal data",
    "6.   Storage period for personal data":
      "6.   Storage period for personal data",
    "6. Storage period for personal data":
      "6. Storage period for personal data",
    "7.   Exercise of the rights of data subjects":
      "7.   Exercise of the rights of data subjects",
    "7. Exercise of the rights of data subjects":
      "7. Exercise of the rights of data subjects",
    "8.   Transfer of personal data outside the European Union":
      "8.   Transfer of personal data outside the European Union",
    "8. Transfer of personal data outside the European Union":
      "8. Transfer of personal data outside the European Union",
    "9.   Links to other websites and social media":
      "9.   Links to other websites and social media",
    "9. Links to other websites and social media":
      "9. Links to other websites and social media",
    "<0>  Club FREELANCE LIMITED </0>, a company registered in England and Wales under number 9050953, with  registered  office  at  Biscuit  Factory,  100  Drummond  Rd,  London  SE16 4DG, United Kingdom (\u2018the <1> Company</1>\u2019) attaches great importance to the protection of personal data which it has to collect and process in the course of its activity from both the users of its services and the platform it operates.":
      "<0>  Club FREELANCE LIMITED </0>, a company registered in England and Wales under number 9050953, with  registered  office  at  Biscuit  Factory,  100  Drummond  Rd,  London  SE16 4DG, United Kingdom (\u2018the <1> Company</1>\u2019) attaches great importance to the protection of personal data which it has to collect and process in the course of its activity from both the users of its services and the platform it operates.",
    "<0> Club FREELANCE LIMITED </0>, a company registered in England and Wales under number 9050953, with registered office at Biscuit Factory, 100 Drummond Rd, London SE16 4DG, United Kingdom (\u2018the <1> Company</1>\u2019) attaches great importance to the protection of personal data which it has to collect and process in the course of its activity from both the users of its services and the platform it operates.":
      "<0> Club FREELANCE LIMITED </0>, a company registered in England and Wales under number 9050953, with registered office at Biscuit Factory, 100 Drummond Rd, London SE16 4DG, United Kingdom (\u2018the <1> Company</1>\u2019) attaches great importance to the protection of personal data which it has to collect and process in the course of its activity from both the users of its services and the platform it operates.",
    "<0> Plateforme </0>  d\xE9signe  la  plateforme  de  mise  en  relation  accessible  en  ligne  \xE0 l\u2019adresse <1>https://mindquest.io</1> , inclut notamment le site internet pr\xE9cit\xE9, l\u2019ensemble des services pouvant y \xEAtre propos\xE9s, logiciels applicatifs prot\xE9g\xE9s ou non par tout droit de propri\xE9t\xE9 intellectuelle.":
      "<0>Platform </0> refers  to  the  online  recruitment  services  at <1>https://mindquest.io</1>,  including  theaforementioned website, where all services may be offered.",
    "<0> Plateforme </0> d\xE9signe la plateforme de mise en relation accessible en ligne \xE0 l\u2019adresse <1>https://mindquest.io</1> , inclut notamment le site internet pr\xE9cit\xE9, l\u2019ensemble des services pouvant y \xEAtre propos\xE9s, logiciels applicatifs prot\xE9g\xE9s ou non par tout droit de propri\xE9t\xE9 intellectuelle.":
      "<0> Platform </0>  refers  to  the  online  recruitment  services  at <1>https://mindquest.io</1>,  including  theaforementioned website, where all services may be offered.",
    "<0>A partir {0}</0>": function(a) {
      return ["<0>From ", a("0"), "</0>"];
    },
    "<0>Assignment</0> means  the  period  during  which  Talent  renders  services  as  a  Freelancer  or  an employee of an umbrella company.":
      "<0>Assignment</0> means  the  period  during  which  Talent  renders  services  as  a  Freelancer  or  an employee of an umbrella company.",
    "<0>Assignment</0> means the period during which Talent renders services as a Freelancer or an employee of an umbrella company.":
      "<0>Assignment</0> means the period during which Talent renders services as a Freelancer or an employee of an umbrella company.",
    "<0>Back to website</0>": "<0>Back to Mindquest</0>",
    "<0>CLUB FREELANCE LIMITED</0>, soci\xE9t\xE9 enregistr\xE9e en Angleterre et au Pays de Galles sous le num\xE9ro 9050953, dont le si\xE8ge social est Biscuit Factory, 100 Drummond road, London SE16 4DG,  Royaume-Uni, ayant  pour  num\xE9ro  individuel  d\u2019identification  TVA  britannique  GB19 4905473.":
      "<0>CLUB FREELANCE LIMITED</0>, soci\xE9t\xE9 enregistr\xE9e en Angleterre et au Pays de Galles sous le num\xE9ro 9050953, dont le si\xE8ge social est Biscuit Factory, 100 Drummond road, London SE16 4DG,  Royaume-Uni, ayant  pour  num\xE9ro  individuel  d\u2019identification  TVA  britannique  GB19 4905473.",
    "<0>CLUB FREELANCE LIMITED</0>, soci\xE9t\xE9 enregistr\xE9e en Angleterre et au Pays de Galles sous le num\xE9ro 9050953, dont le si\xE8ge social est Biscuit Factory, 100 Drummond road, London SE16 4DG, Royaume-Uni, ayant pour num\xE9ro individuel d\u2019identification TVA britannique GB19 4905473.":
      "<0>CLUB FREELANCE LIMITED</0>, soci\xE9t\xE9 enregistr\xE9e en Angleterre et au Pays de Galles sous le num\xE9ro 9050953, dont le si\xE8ge social est Biscuit Factory, 100 Drummond road, London SE16 4DG, Royaume-Uni, ayant pour num\xE9ro individuel d\u2019identification TVA britannique GB19 4905473.",
    "<0>Ce num\xE9ro est manquant ou erron\xE9 ? </0><1>Je le sugg\xE8re</1>":
      "<0>Is this number missing or wrong? </0><1>I suggest it</1>",
    "<0>Client  (s)</0>   d\xE9signe(nt)  toute  personne  morale  ou  physique  ayant cr\xE9\xE9  un  compte  et  pouvant proposer  une  offre  d\u2019emploi  ou  une  mission  dont les  Utilisateurs  r\xE9f\xE9renc\xE9s  et  inscrits  sur  la Plateforme peuvent postuler.":
      "<0>Client  (s)</0>  means  any  legal  or  natural  person  that  has  created  an  account  and  can  offer  a  job offer or assignment for which users registered on the Platform can apply.",
    "<0>Client (s)</0>  means any legal or natural person that has created an account and can offer a job offer or assignment for which users registered on the Platform can apply.":
      "<0>Client (s)</0>  means any legal or natural person that has created an account and can offer a job offer or assignment for which users registered on the Platform can apply.",
    "<0>Client (s)</0> d\xE9signe(nt) toute personne morale ou physique ayant cr\xE9\xE9 un compte et pouvant proposer une offre d\u2019emploi ou une mission dont les Utilisateurs r\xE9f\xE9renc\xE9s et inscrits sur la Plateforme peuvent postuler.":
      "<0>Client  (s)</0>  means  any  legal  or  natural  person  that  has  created  an  account  and  can  offer  a  job offer or assignment for which users registered on the Platform can apply.",
    "<0>Client (s)</0> means any legal or natural person that has created an account and can offer a job offer or assignment for which users registered on the Platform can apply.":
      "<0>Client (s)</0> means any legal or natural person that has created an account and can offer a job offer or assignment for which users registered on the Platform can apply.",
    "<0>Naviguer horizentalement ou basculez votre \xE9cran en position paysage pour consulter toutes les statistiques</0>":
      "Navigate horizontally or flip your screen to landscape position to view all stats.",
    "<0>Talent (s)</0> d\xE9signe(nt) toute personne candidate \xE0 un emploi en CDI ou d\xFBment inscrite \xE0 tout registre  du commerce  et  des  soci\xE9t\xE9s  ou  aupr\xE8s  de  toute  chambre  des  m\xE9tiers  ou  consid\xE9r\xE9e comme salari\xE9 port\xE9 ou et qui souhaite proposer ses services \xE0 des Clients et \xEAtre ainsi r\xE9f\xE9renc\xE9s sur la Plateforme.":
      "<0>Talent (s)</0> designate(s) any person applying for a permanent job, or duly registered in any trade and company register or employed by an umbrella company and wishes to offer their services to Clients and thus be referenced to the Platform.",
    "<0>Talent (s)</0> d\xE9signe(nt) toute personne candidate \xE0 un emploi en CDI ou d\xFBment inscrite \xE0 tout registre du commerce et des soci\xE9t\xE9s ou aupr\xE8s de toute chambre des m\xE9tiers ou consid\xE9r\xE9e comme salari\xE9 port\xE9 ou et qui souhaite proposer ses services \xE0 des Clients et \xEAtre ainsi r\xE9f\xE9renc\xE9s sur la Plateforme.":
      "<0>Talent (s)</0> designate(s) any person applying for a permanent job, or duly registered in any trade and company register or employed by an umbrella company and wishes to offer their services to Clients and thus be referenced to the Platform.",
    "<0>Timesheet  Manager</0>  d\xE9signe  l\u2019application  mise  en  place  au  sein  de  la  Plateforme  par  Club Freelance permettant  aux  Clients  de  valider  les  comptes-rendus  d\u2019activit\xE9  remplis  par  le(s) Utilisateur(s) durant une mission.":
      "<0>Timesheet Manager</0>  designates the application on the Platform owned by the Company that allows Clients to validate the timesheet filled in by Talent(s)  during an assignment. mission.",
    "<0>Timesheet Manager</0> d\xE9signe l\u2019application mise en place au sein de la Plateforme par Club Freelance permettant aux Clients de valider les comptes-rendus d\u2019activit\xE9 remplis par le(s) Utilisateur(s) durant une mission.":
      "<0>Timesheet Manager</0> designates the application on the Platform owned by the Company that allows Clients to validate the timesheet filled in by Talent(s)  during an assignment. mission. ",
    "<0>Utilisateurs</0>  d\xE9signe(nt)  les  Clients  (s)  et/ou  Consultant(s)  r\xE9f\xE9renc\xE9(s)  et  inscrit(s)  sur  la Plateforme.":
      "<0>Users</0>  refer to Clients (s) and/or Talent(s) (s) registered on the Platform.",
    "<0>Utilisateurs</0> d\xE9signe(nt) les Clients (s) et/ou Consultant(s) r\xE9f\xE9renc\xE9(s) et inscrit(s) sur la Plateforme.":
      "<0>Users</0> refer to Clients (s) and/or Talent(s) (s) registered on the Platform. ",
    "<0>Vos collaborateur </0>": "Your collaborators",
    "<0>www.ovh.com</0>": "<0>www.ovh.com</0>",
    "<0>\xC0 partir de {0}</0>": function(a) {
      return ["<0>From ", a("0"), "</0>"];
    },
    "<0>\u2018Controller\u2019</0>  means the natural or legal person, public authority, agency or other body which alone or jointly with others determines the purposes and means of the processing; where the purposes and means of such processing are determined by Union or Member State law, the controller may be designated or the specific criteria for its designation may be provided for by Union or Member State law. In the present case, the Company is the controller.":
      "<0>\u2018Controller\u2019</0>  means the natural or legal person, public authority, agency or other body which alone or jointly with others determines the purposes and means of the processing; where the purposes and means of such processing are determined by Union or Member State law, the controller may be designated or the specific criteria for its designation may be provided for by Union or Member State law. In the present case, the Company is the controller.",
    "<0>\u2018Controller\u2019</0> means the natural or legal person, public authority, agency or other body which alone or jointly with others determines the purposes and means of the processing; where the purposes and means of such processing are determined by Union or Member State law, the controller may be designated or the specific criteria for its designation may be provided for by Union or Member State law. In the present case, the Company is the controller.":
      "<0>\u2018Controller\u2019</0> means the natural or legal person, public authority, agency or other body which alone or jointly with others determines the purposes and means of the processing; where the purposes and means of such processing are determined by Union or Member State law, the controller may be designated or the specific criteria for its designation may be provided for by Union or Member State law. In the present case, the Company is the controller.",
    "<0>\u2018Personal data\u2019</0> means any information relating to an identified or identifiable natural person (\u2018data <1>subject</1>\u2019).":
      "<0>\u2018Personal data\u2019</0> means any information relating to an identified or identifiable natural person (\u2018data <1>subject</1>\u2019).",
    "<0>\u2018Processing\u2019</0>    means  any  operation  or  set  of  operations  which  is  performed  upon personal  data  or  sets  of  personal  data, whether  or  not  by  automatic  means,  such  as collection,   recording,   organisation,   structuring,   storage, adaptation   or   alteration, retrieval,  consultation,  use,  disclosure  by  transmission, dissemination  or  otherwise making available, alignment or combination, restriction, erasure or destruction.":
      "<0>\u2018Processing\u2019</0>    means  any  operation  or  set  of  operations  which  is  performed  upon personal  data  or  sets  of  personal  data, whether  or  not  by  automatic  means,  such  as collection,   recording,   organisation,   structuring,   storage, adaptation   or   alteration, retrieval,  consultation,  use,  disclosure  by  transmission, dissemination  or  otherwise making available, alignment or combination, restriction, erasure or destruction.",
    "<0>\u2018Processing\u2019</0> means any operation or set of operations which is performed upon personal data or sets of personal data, whether or not by automatic means, such as collection, recording, organisation, structuring, storage, adaptation or alteration, retrieval, consultation, use, disclosure by transmission, dissemination or otherwise making available, alignment or combination, restriction, erasure or destruction.":
      "<0>\u2018Processing\u2019</0> means any operation or set of operations which is performed upon personal data or sets of personal data, whether or not by automatic means, such as collection, recording, organisation, structuring, storage, adaptation or alteration, retrieval, consultation, use, disclosure by transmission, dissemination or otherwise making available, alignment or combination, restriction, erasure or destruction.",
    "<0>\u201CTalent\u201D</0>   means  any  person  applying  for  a  permanent  job,  or  duly  registered  in  any trade and company register or employed by an umbrella company and wishes to offer his  services to Clients.":
      "<0>\u201CTalent\u201D</0>   means  any  person  applying  for  a  permanent  job,  or  duly  registered  in  any trade and company register or employed by an umbrella company and wishes to offer his  services to Clients.",
    "<0>\u201CTalent\u201D</0> means any person applying for a permanent job, or duly registered in any trade and company register or employed by an umbrella company and wishes to offer his services to Clients.":
      "<0>\u201CTalent\u201D</0> means any person applying for a permanent job, or duly registered in any trade and company register or employed by an umbrella company and wishes to offer his services to Clients.",
    "A ce titre, il est \xE9galement pr\xE9vu que la Plateforme permettre la publication pseudonymis\xE9e des profils des Talents.":
      "Talent can decide whether his profile page is visible to the Company and Clients or only to the Company. If Talent makes his profile visible to Clients and the Company, the Company will pseudonymize it before publication.",
    "A cookie is a small file that is uploaded to a device (telephone, computer, etc.) when the user accesses an Internet site. Cookies are used by many websites, while others make it possible to remember user preferences, the language in which the website is displayed, to record the user\u2019s basket on a shopping website, etc.":
      "A cookie is a small file that is uploaded to a device (telephone, computer, etc.) when the user accesses an Internet site. Cookies are used by many websites, while others make it possible to remember user preferences, the language in which the website is displayed, to record the user\u2019s basket on a shopping website, etc.",
    "A copy of your driving license and/or passport/identity card; Photograph;":
      "A copy of your driving license and/or passport/identity card; Photograph;",
    "A d\xE9faut, elles entendent soumettre leur litige \xE0 la comp\xE9tence du tribunal comp\xE9tent selon la l\xE9gislation applicable.":
      "Failing that, they intend to submit their dispute to the competent court in accordance with the applicable legislation.",
    "A fin de faciliter les recherches pensez \xE0 indiquer dans votre fiche de poste:":
      "In order to facilitate research, please indicate in your job description:",
    "A l\u2019\xE9tude": "Pending confirmation",
    "A password policy is currently in place to ensure that the data stored on our systems is only accessible to authorised personnel. The passwords are changed on a regular basis. We regularly make backups of our data to ensure that it is readily available in the event of technical problems or disaster recovery.":
      "A password policy is currently in place to ensure that the data stored on our systems is only accessible to authorised personnel. The passwords are changed on a regular basis. We regularly make backups of our data to ensure that it is readily available in the event of technical problems or disaster recovery.",
    "A propos de Mindquest": "About Mindquest",
    "A tout le moins, il est recommand\xE9 de souscrire une police d\u2019assurance couvrant les dommages qui peuvent \xEAtre caus\xE9s en France.":
      "At the very least, it is recommended to take out an insurance policy covering damage that may be caused in the country User operates.",
    ABNORMAL_TIME_PER_DAY: "A day with more or less than normal time",
    "ARTICLE 1.    Objet": "ARTICLE 1.   Subject matter",
    "ARTICLE 1. Objet": "ARTICLE 1.   Subject matter",
    "ARTICLE 10.Propri\xE9t\xE9 intellectuelle":
      "ARTICLE 10.  Intellectual property",
    "ARTICLE 11. Assurances": "ARTICLE 11.  Insurance",
    "ARTICLE 12.  Liens vers d\u2019autres sites internet et r\xE9seaux sociaux":
      "ARTICLE 12.   Links to other websites and social media",
    "ARTICLE 12. Liens vers d\u2019autres sites internet et r\xE9seaux sociaux":
      "ARTICLE 12. Links to other websites and social media",
    "ARTICLE 13.   Dur\xE9e": "ARTICLE 13.  Duration",
    "ARTICLE 13. Dur\xE9e": "ARTICLE 13.  Duration",
    "ARTICLE 14.   R\xE9siliation, suspension":
      "ARTICLE 14.   Termination, suspension ",
    "ARTICLE 14. R\xE9siliation, suspension":
      "ARTICLE 14.   Termination, suspension",
    "ARTICLE 15.  Divers": "ARTICLE 15.   Miscellaneous",
    "ARTICLE 15. Divers": "ARTICLE 15. Miscellaneous",
    "ARTICLE 16.   Loi applicable et juridiction comp\xE9tente":
      "ARTICLE 16.   Applicable law and jurisdiction",
    "ARTICLE 16. Loi applicable et juridiction comp\xE9tente":
      "ARTICLE 16.   Applicable law and jurisdiction",
    "ARTICLE 2.    D\xE9finitions": "ARTICLE 2.   Definitions",
    "ARTICLE 2. D\xE9finitions": "ARTICLE 2.   Definitions",
    "ARTICLE 3.   Description de la Plateforme":
      "ARTICLE 3.  Description of the Platform",
    "ARTICLE 3. Description de la Plateforme":
      "ARTICLE 3.  Description of the Platform",
    "ARTICLE 4.    Modalit\xE9s d\u2019acc\xE8s et inscription \xE0 la Plateforme":
      "ARTICLE 4.   How to access and register with the Platform",
    "ARTICLE 4. Modalit\xE9s d\u2019acc\xE8s et inscription \xE0 la Plateforme":
      "ARTICLE 4.   How to access and register with the Platform",
    "ARTICLE 5.  Obligations des Utilisateurs":
      "ARTICLE 5.   Obligations of Users",
    "ARTICLE 5. Obligations des Utilisateurs":
      "ARTICLE 5.   Obligations of Users",
    "ARTICLE 6.   Responsabilit\xE9": "ARTICLE 6.   Liability",
    "ARTICLE 6. Responsabilit\xE9": "ARTICLE 6.   Liability",
    "ARTICLE 7.  Relation entre Talents et Clients":
      "ARTICLE 7.  Relationship between Talents and Clients",
    "ARTICLE 7. Relation entre Talents et Clients":
      "ARTICLE 7.  Relationship between Talents and Clients",
    "ARTICLE 8.   Evaluation des Utilisateurs":
      "ARTICLE 8.   Evaluation of users ",
    "ARTICLE 8. Evaluation des Utilisateurs":
      "ARTICLE 8.   Evaluation of users ",
    "ARTICLE 9.   Garantie relative \xE0 la mise \xE0 disposition de la Plateforme":
      "ARTICLE 9.   Guarantee that the Platform will be made available",
    "ARTICLE 9. Garantie relative \xE0 la mise \xE0 disposition de la Plateforme":
      "ARTICLE 9.   Guarantee that the Platform will be made available",
    AUTH_TOKEN: "AUTH_TOKEN",
    "About us": "About us",
    "About us - Mindquest Connect": "About us - Mindquest Connect",
    "Acceder \xE0 votre espace entreprise": "Access your employer dashboard",
    Accepter: "Accept",
    "Account Managers locaux": "Local account manager",
    Accueil: "Home",
    "Acc\xE8s non autoris\xE9": "Unauthorized",
    "Acc\xE9der au guide pour plus d\u2019information": "Check our user guides",
    "Acc\xE9der \xE0 la ressource": "Access the document",
    "Acc\xE9der \xE0 mes viviers": "Access my pools",
    "Acc\xE9der \xE0 mon espace client": "Access client space",
    "Acc\xE9der \xE0 mon espace personel": "Access your personal space",
    Actions: "Actions",
    "Activer mon profil pseudonymis\xE9 pour les d\xE9cideurs IT":
      "Activate my pseudonymized profile for IT decision makers",
    "Activit\xE9 sur toutes les offres": "Activity for all the offers",
    "Activit\xE9 sur vos offres": "Activity on your offers",
    "Actual and potential candidates:": "Actual and potential candidates:",
    Actualité: "News",
    "Actualit\xE9s, conseils pratiques, d\xE9cryptages, et points de vue d\u2019experts pour freelances informatiques et d\xE9cideurs IT en qu\xEAte de r\xE9ussite.":
      "News, practical advice, insights, and expert views for IT freelancers and IT decision-makers looking for success.",
    "Adequate, relevant and limited to what is necessary;":
      "Adequate, relevant and limited to what is necessary;",
    "Admin & L\xE9gal": "Admin & L\xE9gal",
    "Admin & l\xE9gal": "Admin & l\xE9gal",
    Adresse: "Location",
    "Adresse email": "Email address",
    "Adresse email non valide": "Invalid email address",
    "Adresse \xE9lectronique :<0>contact@mindquest.io</0>":
      "E-mail :<0>contact@mindquest.io</0>",
    "Advertising cookies": "Advertising cookies",
    "Afficher uniquement les profils publiques": "Show only public profiles",
    "Afin d'organiser un entretien avec le candidat, pourriez-vous nous donner des cr\xE9neaux de disponibilit\xE9?":
      "In order to organize an interview with the candidate, could you please provide your availability?",
    "Afin de mieux adapter nos recherches merci de nous indiquer les raisons de ce refus en commentaire?":
      "In order to better target our search, please tell us the reasons for this refusal in the comments section",
    Ajouter: "Add",
    "Ajouter Club Freelance \xE0 l\u2019\xE9cran d\u2019accueil":
      "Add Mindquest to your home screen",
    "Ajouter des heures": "Adding hours",
    "Ajouter heures": "Add hours",
    "Ajouter le code postal permettra de mettre en avant les profils les plus proches":
      "The ZIP Code allows us to select the consultants the closest to you",
    "Ajouter le nom de votre vivier": " Add your pool name",
    "Ajouter les environnements": "Add the technical",
    "Ajouter mon CV": "Add my resume",
    "Ajouter quand m\xEAme": "Add anyway",
    "Ajouter th\xE8me": "Add theme",
    "Ajouter un collaborateur": "Add new collaborator",
    "Ajouter un collaborateurs": "Add new collaborator",
    "Ajouter un commentaire": "Add a comment",
    "Ajouter un commentaire pour indiquer votre activit\xE9 au client":
      "Add a comment to indicate your activity to the client",
    "Ajouter un commentaire...": "Add a comment...",
    "Ajouter un message": "Add a message",
    "Ajouter un nouveau th\xE8me": "Add new theme",
    "Ajouter un vivier": "Add a new talent pool",
    "Ajouter une Certification": "Add Certification",
    "Ajouter une Formation": "Add Training",
    "Ajouter une annotation:": "Add an annotation:",
    "Ajouter une exp\xE9rience": "Add experience",
    "Ajouter une ligne": "Add a row",
    "Ajouter une nouvelle plage": "Add a new range",
    "Ajouter vos Comp\xE9tences": "Add your skills",
    "Ajouter vos soft skills": "Add your soft skills",
    "Ajouter votre/vos Zone(s) g\xE9ographique(s) souhait\xE9e(s)":
      "Add your desired geographic Zone (s)",
    "Ajouter \xE0 mes viviers": "Add to my talent pools",
    "Ajouter \xE0 un vivier": "Add to a talent pool",
    "Ajoutez l'email du signataire principal...":
      "Add the email of the principal signatory...",
    "Ajoutez l'email du signataire secondaire...":
      "Add the email of the secondary signatory...",
    "Ajoutez le nom du nouveau th\xE8me": "Add the name of the new theme",
    "Ajoutez un nom de projet pour retrouver facilement les offres associ\xE9es":
      "Add a project name to easily find related offers",
    "Ajoutez vos derni\xE8res exp\xE9riences pour nous aider \xE0 vous trouver un job qui vous correspond. N\u2019oubliez pas de renseigner vos r\xE9f\xE9rences pour un process plus rapide":
      "Adding your past experiences will help us find you a suitable job. Don\u2019t forget to provide references to speed up the process",
    "Ajoutez votre": "Add your",
    "Ajoutez votre commentaire puis validez": "Add your comment and validate",
    Alerte: "Warning",
    "Alerte !": "warning !",
    "Alerte Mission": "Job alert",
    "Alerte Suppression": "Deletion Alert",
    "Alerte cr\xE9\xE9e avec succ\xE8s": "Alert successfully created",
    All: "All",
    "All the endpoints of the company are protected with antivirus and firewalls.":
      "All the endpoints of the company are protected with antivirus and firewalls.",
    Allemand: "German",
    Alphabétique: "Alphabetical",
    "An insider's look at the tech industry through interviews with expert from all areas of it.":
      "An insider's look at the tech industry through interviews with expert from all areas of it.",
    "An insider's look at the tech industry through interviews with expert from all areas of it..":
      "An insider's look at the tech industry through interviews with expert from all areas of it..",
    "Analyse les tendances et \xE9volutions du monde des talents tech & IT.":
      "Mindquest Connect is your one-stop shop for IT recruitment and career  advice",
    Anglais: "English",
    Annuler: "Cancel",
    "Annuler le filtrage par projet": "Reset filter by project",
    "Any beneficiaries of a data transfer.":
      "Any beneficiaries of a data transfer.",
    Appels: "Calls",
    "Apple Safari": "Apple Safari",
    "Application de filtre": "Applying filter",
    Appliquer: "Apply",
    "Applying for jobs through a job aggregator, which may then redirect you to the Club Freelance website;":
      "Applying for jobs through a job aggregator, which may then redirect you to the Mindquest website;",
    "Apr\xE8s l\u2019importation, Vos donn\xE9es seront remplac\xE9es par les donn\xE9es LinkedIn. Voulez vous continuer?":
      "After importing, your data will be replaced by LinkedIn data. Would you like to continue?",
    Arabe: "Arabic",
    Arabic: "Arabic",
    "Articles Consulants IT - Develop your career: permanent & freelance - Mindquest Connect":
      "Articles Consulants IT - Develop your career: permanent & freelance - Mindquest Connect",
    "Articles Consulants IT - D\xE9velopper sa carri\xE8re : CDI & freelance - Mindquest Connect":
      "Articles Consulants IT - Develop your career: permanent & freelance - Mindquest Connect",
    "Articles Consultants IT - Admin & L\xE9gal freelance - Mindquest Connect":
      "Articles Consultants IT - Admin & L\xE9gal freelance - Mindquest Connect",
    "Articles Consultants IT - Comptabilit\xE9 en freelance - Mindquest Connect":
      "Articles Consultants IT - Comptabilit\xE9 en freelance - Mindquest Connect",
    "Articles Consultants IT - Devenir freelance - Mindquest Connect":
      "Articles Consultants IT - Devenir freelance - Mindquest Connect",
    "Articles Consultants IT - Fiscalit\xE9, imp\xF4ts & retraite en freelance - Mindquest Connect":
      "Articles Consultants IT - Fiscalit\xE9, imp\xF4ts & retraite en freelance - Mindquest Connect",
    "Articles Consultants IT - Nos partenaires - Mindquest Connect":
      "Articles Consultants IT - Nos partenaires - Mindquest Connect",
    "Articles Consultants IT - Portage salarial en freelance - Mindquest Connect":
      "Articles Consultants IT - Portage salarial en freelance - Mindquest Connect",
    "Articles Consultants IT - Quotidien de freelance - Mindquest Connect":
      "Articles Consultants IT - Quotidien de freelance - Mindquest Connect",
    "Articles Consultants IT - Se financer - Mindquest Connect":
      "Articles Consultants IT - Se financer - Mindquest Connect",
    "Articles D\xE9cideurs IT - Enjeux DSI - Mindquest Connect":
      "IT Decision Makers Articles - CIO Issues - Mindquest Connect",
    "Articles D\xE9cideurs IT - La strat\xE9gie des talents - Mindquest Connect":
      "IT Decision Makers Articles - Talent Strategy - Mindquest Connect",
    "Articles D\xE9cideurs IT - Les risques & erreurs \xE0 \xE9viter - Mindquest Connect":
      "IT Decision Makers Articles - Tips & errors to avoid - Mindquest Connect",
    "Articles D\xE9cideurs IT - L\xE9gal - Mindquest Connect":
      "IT Decision Makers Articles - Legal - Mindquest Connect",
    "Articles D\xE9cideurs IT - Recruter un consultant informatique - Mindquest Connect":
      "IT Decision Maker Articles - Recruiting IT Consultants - Mindquest Connect",
    "Articles qui peuvent vous int\xE9resser":
      "Articles you might be interested in",
    "As a recruitment business and recruitment agency we introduce candidates to clients for independent professional contracts. The exchange of personal data of our candidates and our client contacts is a fundamental, essential part of this process.":
      "As a recruitment business and recruitment agency we introduce candidates to clients for independent professional contracts. The exchange of personal data of our candidates and our client contacts is a fundamental, essential part of this process.",
    Astreinte: "On-call",
    Astreintes: "On-call duties",
    "Attention !": '"Warning !',
    "Attention, le jour s\xE9l\xE9ctionn\xE9 est f\xE9ri\xE9 en":
      "The selected day is bank holiday in ",
    Au: "To",
    "Aucun compte ne correspond \xE0 cet email":
      "The email you\u2019ve entered doesn\u2019t match any account",
    "Aucun contact indiqu\xE9": "No contact indicated",
    "Aucun itin\xE9raire n\u2019a pu \xEAtre trouv\xE9 entre l\u2019origine et la destination.":
      "No itinerary was found between origin and destination",
    "Aucun pr\xE9paiements": "No prepayments",
    "Aucun r\xE9sultat pour votre filtre.<0/>":
      "No result for your filter.<0/>",
    "Aucun r\xE9sultat pour votre recherche. Essayez avec d\u2019autres mots-cl\xE9s.":
      "No results for your search. Pelase try with other keywords.",
    "Aucun th\xE8me n'est associ\xE9 \xE0 ce Timesheet.":
      "There is no theme associated with this Timesheet.",
    "Aucune date n'est associ\xE9e \xE0 ce th\xE8me":
      "No date is associated with this theme",
    "Aucune donn\xE9e \xE0 exporter": "No data to export",
    "Aujourd\u2019hui": "Today",
    Autoentrepreneu: "Auto entrepreneur",
    Autoentrepreneur: "Auto entrepreneur",
    Autre: "Other",
    "Autres comp\xE9tences du candidat": "Other skills of the candidate",
    "Aux  fins  de  permettre  aux  Utilisateurs  d\u2019utiliser  la  Plateforme, la  Soci\xE9t\xE9  octroie  aux Utilisateurs  une  licence  d\u2019utilisation  strictement limit\xE9e  \xE0  l\u2019objet  des  pr\xE9sentes  et  en  France uniquement.":
      "Company grants Users a user licence strictly limited to the subject matter of the present and in the country User operates.",
    "Aux fins de permettre aux Utilisateurs d\u2019utiliser la Plateforme, la Soci\xE9t\xE9 octroie aux Utilisateurs une licence d\u2019utilisation strictement limit\xE9e \xE0 l\u2019objet des pr\xE9sentes et en France uniquement.":
      "Company grants Users a user licence strictly limited to the subject matter of the present and in the country User operates.",
    "Avant de soumettre votre Timesheet": "Before submitting your Timesheet",
    "Avant de soumettre votre Timesheet {0} au client, veuillez s'il vous plait indiquer en quelques lignes les actions effectu\xE9es ce mois.": function(
      a
    ) {
      return [
        "Before sending your Timesheet for ",
        a("0"),
        " to the client, please indicate briefly your tasks achieved this month."
      ];
    },
    Avantages: "Benefits",
    "Avec Mindquest, recrutez les meilleurs experts IT en CDI ou freelance (Data, D\xE9veloppement, Cloud, Gestion de projet, CRM, Support, BI, ERP, SAP, Oracle, Infra...)":
      "With Mindquest, recruit the best IT experts on permanent or freelance contracts (Data, Development, Cloud, Project Management, CRM, Support, BI, ERP, SAP, Oracle, Infra...)",
    "Avec Mindquest, trouver votre prochaine opportunit\xE9 Tech & IT en CDI ou freelance (Data, D\xE9veloppement, Cloud, Gestion de projet, CRM, Support, BI, ERP, SAP, Oracle, Infra...)":
      "With Mindquest, find your next Tech & IT job offer (Data, Development, Cloud, Project Management, CRM, Support, BI, ERP, SAP, Oracle, Infra...)",
    "Avec Mindquest, trouvez votre prochaine offre Tech & IT en CDI ou Freelance et recrutez les meilleurs consultants Data, D\xE9veloppement, Cloud, Gestion de projet, CRM, Support, BI, ERP, SAP, Oracle, Infra...":
      "With Mindquest, find your next Tech & IT permanent or freelance job and recruit the best Data, Development, Cloud, Project Management, CRM, Support, BI, ERP, SAP, Oracle, Infra consultants\u2026",
    "Avec des bureaux \xE0 Londres, Paris et Barcelone, Mindquest s\u2019appuie sur son exp\xE9rience ant\xE9rieure et son expertise dans l\u2019industrie des freelances informatiques \u2013 en tant que Club Freelance \u2013 pour aider les entreprises et les experts des technologies informatiques \xE0 r\xE9ussir leurs projets et faire prosp\xE9rer le march\xE9 informatique mondial.":
      "With offices in London, Paris and Barcelona, Mindquest draws on its previous experience and expertise in the IT freelance industry - as Club Freelance - to help companies and IT experts succeed in their projects and grow the global IT market.",
    Avertissement: "Warning",
    "Avis \xE9quipe": "Team review",
    "Back to website": "Back to website",
    "Bank account details your company;":
      "Bank account details for your company;",
    Barcelone: "Barcelona",
    "Bas\xE9s au sein de notre Sourcing Hub \xE0 Barcelone, nos Talent Community Specialists sont en contact permanent avec les meilleurs profils tech et IT. Ils sont en mesure d\u2019identifier et d\u2019accompagner les meilleurs talents dans toute l\u2019Europe.":
      "Based in our Sourcing Hub in Barcelona, our Talent Community Specialists are in constant contact with the best professionals within their technological domain. They are able to identify and accompany Europe\u2019s most brilliant minds",
    "Bas\xE9s localement et sp\xE9cialis\xE9s par secteur d\u2019activit\xE9, nos Key Account Managers accompagnent leurs entreprises clientes dans la recherche des meilleures talents pour leurs projets IT.":
      "Based in each region, our sector-specialized Key Account Managers help companies secure the best talent for all their IT projects across Europe.",
    "Be in the know": "Be in the know",
    "Behind the Screens": "Behind the Screens",
    "Besoin d\u2019un partenaire pour le recrutement de vos ressources IT ?":
      "Looking for an IT recruitment partner?",
    'Bien que la tendance \xE9volue, le travail en r\xE9gie est toujours la norme dans de nombreuses entreprises. Choissiez "indifferent" pour ne manquer aucune opportunit\xE9.':
      "Although the market is changing, working on-site is still the standard for many companies. Don\u2019t miss out any opportunities, select Indifferent.",
    "Bienvenue au club, Un email vous a \xE9t\xE9 envoy\xE9 pour confirmer votre inscription":
      "Welcome to the club, An email has been sent to confirm your registration",
    "Bienvenue au club, Un email vous a \xE9t\xE9 envoy\xE9 pour finaliser votre inscription et acc\xE9der \xE0 votre Dashboard":
      "Welcome to the quest! We have sent you an email to complete the registration process and access your personal dashboard",
    "Bienvenue au club, Vous pouvez mainetenant acc\xE9der \xE0 votre Dashboard":
      "Welcome to the club, You can now access your Dashboard",
    "Bienvenue au club, Vous pouvez maintenant acc\xE9der \xE0 votre Dashboard":
      "Welcome to the club, You can now access your Dashboard",
    "Bienvenue au club, un Key Account Manager va vous recontacter dans les plus brefs d\xE9lais pour faire le point sur vos recherches":
      "Welcome to Mindquest, a Key Account Manager will contact you as soon as possible to discuss your request",
    "Blah Blah Blah Bloo Bloo Blah Bloo Bloo":
      "Blah Blah Blah Bloo Bloo Blah Bloo Bloo",
    Blog: "Resources",
    "Bon de commande": "Purchase order",
    "Bon \xE0 savoir": "Good to know",
    "Bonne qualit\xE9 de profils et bonne r\xE9activit\xE9 ! Merci.":
      "Good quality profiles and good responsiveness! Thanks.",
    "Bouche \xE0 oreille": "word of mouth",
    "Brief profil": "Brief profile",
    "Business Lounge": "Business Lounge",
    "Business development": "Business development",
    "B\xE9n\xE9ficiez d\u2019offres s\xE9lectionn\xE9es pour votre profil.":
      "Get recommendations that check all your boxes",
    CDI: "Permanent",
    "CDI / Freelance": "Permanent / Contract",
    "CF Admin peut d\xE9sormais valider les Timesheets du contrat {placementId}": function(
      a
    ) {
      return [
        "CF Admin Can Now Validate Contract Timesheets ",
        a("placementId")
      ];
    },
    "CF Admin peut d\xE9sormais valider vos Timesheets":
      "CF Admin Can Now Validate Contract Timesheet",
    "CF Sourceur": "Mindquest sourcer",
    "CGU et mentions l\xE9gales": "Terms of use and legal notices",
    "CONFIDENTIALITY POLICY": "PRIVACY POLICY",
    "CRM / ATS": "CRM / ATS",
    "CRM Software": "CRM Software",
    CV: "Resume",
    "CV envoy\xE9": "CV sent",
    "CV envoy\xE9s": "CVs sent",
    "CV s\xE9lectionn\xE9s": "Shortlisted CVs",
    Calendrier: "Calendar",
    Candidat: "Candidates",
    "Candidate Data": "Candidate Data",
    "Candidate and Client data": "Candidate and Client data",
    Candidates: "Candidates",
    Candidats: "Candidates",
    Candidature: "Application",
    "Candidature cr\xE9\xE9e avec succ\xE8s":
      "Application created successfully",
    Candidatures: "Applications",
    "Capitalised terms have the following definition. Terms have the same definition regardless of whether they are used in singular or plural.":
      "Capitalised terms have the following definition. Terms have the same definition regardless of whether they are used in singular or plural.",
    "Captain's Log": "Captain's Log",
    "Captains\u2019s Log": "Captains\u2019s Log",
    "Captains\u2019s Log - Mindquest Connect":
      "Captains's Log - Mindquest Connect",
    "Career History;": "Career History;",
    "Career Spa": "Career Spa",
    Cdi: "Permanent",
    "Ce champ est requis": "Required field",
    "Ce champ ne contient pas la m\xEAme valeur":
      "This field does not contain the same value",
    "Ce champs est requis": "Required field",
    "Ce champs ne contient pas la m\xEAme valeur":
      "This field does not contain the same value",
    'Ce consultant a \xE9t\xE9 ajout\xE9 \xE0 votre vivier "Mes recherches de Talents IT".':
      'This consultant has been added to "My IT Talent search" .',
    'Ce consultant a \xE9t\xE9 supprim\xE9 de votre vivier "Mes recherches de Talents IT".':
      'This consultant has been deleted from "My IT Talent search" pool.',
    "Ce contact est d\xE9j\xE0 pr\xE9sent sur votre listing de collaboration. N'h\xE9sitez pas \xE0 renvoyer votre demande de collaboration en cliquant sur":
      "This contact is already present on your collaboration listing. Do not hesitate to send your request for collaboration by clicking on",
    "Ce format de fichier n\u2019est pas pris en charge. Veuillez choisir l\u2019une des options suivantes :":
      "This file format is not supported. Please choose one of the following options:",
    "Ce nom de th\xE8me existe d\xE9j\xE0 !": "This theme name already exists!",
    "Ce nom existe d\xE9j\xE0 !": "This name already exists !",
    "Ce site utilise des cookies pour offrir une meilleure exp\xE9rience de navigation. D\xE9couvrez comment nous utilisons les cookies dans notre":
      "This site uses cookies to offer a better browsing experience. Find out more about how we use cookies in our",
    'Ce talent existe d\xE9j\xE0 dans votre vivier par d\xE9faut "Mes recherches de talent IT"':
      "This talent already exists in your default pool 'My IT talent searches'",
    "Ce type de compte convient aux professionnels en mission ou aux candidats en recherche.<0/>Si vous souhaitez recruter ou que vous \xEAtes donneur d'ordre, cr\xE9ez plut\xF4t":
      "This type of account is suitable for professionals on a mission or for candidates seeking research. If you wish to recruit or you are an ordering party, create instead ",
    "Ce type de compte convient aux professionnels en mission ou aux candidats en recherche.<0/>Si vous souhaitez recruter ou que vous \xEAtes donneur d'ordre, cr\xE9ez plut\xF4t <1/>":
      "This type of account is suitable for professionals on a mission or for candidates seeking research. If you wish to recruit or you are an ordering party, create instead ",
    "Ce type de compte convient aux professionnels en mission ou aux candidats en recherche.<0/>Si vous souhaitez recruter ou que vous \xEAtes donneur d'ordre, cr\xE9ez plut\xF4t <1>un compte entreprise.</1>":
      "This type of account is suitable for professionals on a mission or for candidates seeking research. If you wish to recruit or you are an ordering party, create a company account instead.",
    "Ce type de compte convient aux professionnels en mission ou aux candidats en recherhche.<0/>Si vous souhaitez recruter ou que vous \xEAtes donneur d'ordre, cr\xE9ez plut\xF4t <1>un compte entreprise.</1>":
      "This type of account is suitable for professionals on a mission or for candidates seeking research. If you wish to recruit or you are an ordering party, create a company account instead.",
    "Certaines donn\xE9es n'ont pas \xE9t\xE9 enregistr\xE9es seront perdues. Etes-vous s\xFBr de vouloir passer \xE0 l\xE9tape suivante ?":
      "\u201CSome data that was not saved will be lost. Are you sure you want to go to the next step?",
    Certifications: "Certifications",
    "Ces mesures ne donnent pas droit \xE0 une quelconque indemnit\xE9 au b\xE9n\xE9fice de l\u2019Utilisateur.":
      "These measures shall not entitle User to any compensation.",
    "Ces statistiques correspondent aux actions r\xE9alis\xE9es par nos \xE9quipes sur chaque offre ajout\xE9e sur la periode s\xE9lectionn\xE9e.":
      "These statistics correspond to all actions carried by our teams on each offer posted over the selected period",
    "Ces statistiques correspondent \xE0 l\u2019ensemble des actions r\xE9alis\xE9es par nos \xE9quipes sur la p\xE9riode s\xE9lectionn\xE9e":
      "These statistics correspond to the total of the actions carried by our teams over the selected period",
    "Cet email est associ\xE9 \xE0 un compte consultant dans mindquest et ne peut pas ajout\xE9 en tant que collaborateur":
      "This email is registered to a Mindquest consultant account. It cannot be added as a collaborator.",
    "Cette adresse email est d\xE9j\xE0 associ\xE9e \xE0 un compte existant. Vous pouvez supprimer l'un de vos deux compte ou <0>contacter le support technique</0> pour les fusionner":
      "This email address is associated with an existing account. You can delete one of your two accounts or <0>contact technical support</0> to merge them",
    "Cette annotation sera visible par le canidat dans le module de chat mais pas sur le compte-rendu de Timesheet":
      "This annotation will be visible to the talent in the chat module but not on the Timesheet report",
    "Cette annotation sera visible par le client et dans le module de chat mais pas sur le compte-rendu de Timesheet":
      "This annotation will be visible by the client and in the chat module but not on the Timesheet report",
    "Cette date ne peut pas \xEAtre dans le future":
      "This date cannot be in the future",
    "Cette langue sera utilis\xE9e essentiellement pour les e-mails.":
      "This language will be used mainly for e-mails.",
    "Cette offre est ferm\xE9e et la disponibilit\xE9 de ce profil n'est plus d'actualit\xE9":
      "This offer is closed and this profile is no longer available",
    "Champ requis": "required field",
    "Champs requis": "Required field",
    "Changement de l'adresse email": "Change of email address",
    "Changement du mot de passe": "Change of password",
    "Changement en cours": "Your request will be effective soon",
    "Changer votre mot de passe": "Change password",
    "Chaque Utilisateur reconnait et accepte qu\u2019il est seul responsable et ma\xEEtre des contenus qu\u2019il publie par le biais de la Plateforme ainsi que des propos qui y sont \xE9chang\xE9s.":
      "Each User recognises and accepts that he is solely responsible for and master of the content he publishes and upload on the Platform and the comments exchanged there.",
    "Chaque offre de mission est prise en charge par un bin\xF4me de deux experts, qui entretiennent des relations de long-terme avec entreprises et consultants":
      "Every vacancy is managed by a two-person team, allowing for greater agility and deeper focus",
    Chargement: "Loading",
    "Chat avec candidat": "Chat with candidate",
    "Chat avec le candidat": "Chat with the candidate",
    "Chat avec le client": "Chat with client",
    "Chercher des comp\xE9tences": "Search by skills",
    "Chercher des sp\xE9cialit\xE9s": "Search specialties",
    "Chercher un document...": "Search for document",
    "Chercher un talent...": "Search for talent",
    "Chercher un vivier...": "Search for TalentPool",
    "Chercher une offre...": "Search for an offer",
    Chinese: "Chinese",
    Chinois: "Chinese",
    "Choisissez au moins un domaine": "Choose at least 1 area",
    "Choisissez au moins un secteur d\u2019activit\xE9":
      "Select at least one activity area",
    "Choisissez au moins une sp\xE9cialit\xE9": "Choose at least one specialty",
    "Choisissez entre deux et dix comp\xE9tences":
      "Choose between 2 and 10 skills",
    "Choisissez entre une et dix comp\xE9tences":
      "Choose between 1 and 10 skills",
    "Choisissez le mod\xE8le adapt\xE9 \xE0 votre situation":
      "Choose the suitable model for your situation",
    "Choissiez le mod\xE8le adapt\xE9 \xE0 votre situation":
      "Choose the model adapted to your situation",
    "Click here for whatever": "Click here for whatever",
    Client: "Client",
    "Client data": "Client data",
    "Client n'est pas encore inscrit": "Customer is not yet registered",
    Clients: "Clients",
    "Clients grands comptes qui nous font confiance":
      "Industry-leading companies that trust us",
    "Cliquez i\xE7i pr\xE9ciser le nombre d\u2019heures travaill\xE9s":
      "Click here to specify the number of hours worked",
    'Cliquez sur le cadre "Mots-cl\xE9s indispensables" ou "Mots-cl\xE9s importants" puis s\xE9lectionnez les mots-cl\xE9s IT souhait\xE9s':
      "Click on the \u2018Mandatory Keywords\u2019 or \u2018Desirable Keywords\u2019 box and select the most relevant keywords for your search",
    Cloud: "Cloud",
    "Club Freelance": "Mindquest",
    "Club Freelance - La communaut\xE9 des Freelances Informatique":
      "Mindquest - The community where top IT talent meets top jobs",
    "Club Freelance Iberica": "Mindquest Ibe.",
    "Club Freelance Insights | Admin & l\xE9gal":
      "Mindquest Connect | Admin & legal",
    "Club Freelance Insights | Captain's Log":
      "Club Freelance Insights | Captain's Log",
    "Club Freelance Insights | Cloud": "Mindquest Connect | Cloud",
    "Club Freelance Insights | Communiqu\xE9s de presse":
      "Mindquest Connect | Press Releases",
    "Club Freelance Insights | Comptabilit\xE9":
      "Mindquest Connect | Accounting",
    "Club Freelance Insights | Conseils Pratiques pour D\xE9cideurs IT":
      "Mindquest Connect | Practical Advice for IT Decision Maker",
    "Club Freelance Insights | Conseils Pratiques pour D\xE9cideurs Informatiques":
      "Mindquest Connect | Career Tips & Tricks",
    "Club Freelance Insights | Conseils Pratiques pour Freelances Informatiques":
      "Mindquest Connect | Career Tips & Tricks",
    "Club Freelance Insights | Consultant IT":
      "Mindquest Connect | Consultant It",
    "Club Freelance Insights | Consultant It":
      "Mindquest Connect | Consultant It",
    "Club Freelance Insights | Contenu pour consultants IT":
      "Mindquest Connect | Content for IT consultants",
    "Club Freelance Insights | Contenu pour d\xE9cideurs IT":
      "Mindquest Connect | Content for IT decision maker",
    "Club Freelance Insights | Cybersecurity":
      "Mindquest Connect | Cybersecurity",
    "Club Freelance Insights | Cybers\xE9curit\xE9":
      "Mindquest Connect | Cybersecurity",
    "Club Freelance Insights | DSI": "Mindquest Connect | DSI",
    "Club Freelance Insights | Data & Business Intelligence":
      "Mindquest Connect | Data & Business Intelligence",
    "Club Freelance Insights | Data & ERP": "Mindquest Connect | Data & ERP",
    "Club Freelance Insights | Devenir freelance":
      "Mindquest Connect | Become a freelancer",
    "Club Freelance Insights | Digital": "Mindquest Connect | Digital",
    "Club Freelance Insights | D\xE9cideur IT":
      "Mindquest Connect | IT Decision maker",
    "Club Freelance Insights | D\xE9cryptages des m\xE9tiers de l\u2019IT":
      "Mindquest Connect | Decoding of IT professions",
    "Club Freelance Insights | D\xE9veloppement":
      "Mindquest Connect | Web Developer",
    "Club Freelance Insights | D\xE9velopper sa carri\xE8re : CDI & freelance":
      "Mindquest Connect | Growing your career: permanent & freelance",
    "Club Freelance Insights | ERP & SAP": "Mindquest Connect | ERP & SAP",
    "Club Freelance Insights | Enjeux DSI":
      "Mindquest Connect | DSI challenges",
    "Club Freelance Insights | Fiches m\xE9tiers":
      "Mindquest Connect | Job Descriptions",
    "Club Freelance Insights | Fiscalit\xE9, imp\xF4ts & retraite":
      "Mindquest Connect | Taxation, taxes and retirement",
    "Club Freelance Insights | Infra": "Mindquest Connect | IT Infrastructure",
    "Club Freelance Insights | Intelligence artificielle":
      "Mindquest Connect | Artificial intelligence",
    "Club Freelance Insights | Job application advice":
      "Mindquest Connect | Job application advice",
    "Club Freelance Insights | Job search advice":
      "Mindquest Connect | Job search advice",
    "Club Freelance Insights | Le blog des talents IT":
      "Mindquest Connect - MC IT's resource hub",
    "Club Freelance Insights | L\xE9gal": "Mindquest Connect | Legal",
    "Club Freelance Insights | L\u2019actualit\xE9 des talents IT":
      "Mindquest Connect | IT talent news",
    "Club Freelance Insights | Nos actualit\xE9s": "Mindquest Connect | News",
    "Club Freelance Insights | Nos partenaires":
      "Mindquest Connect | Our partners",
    "Club Freelance Insights | On parle de nous":
      "Mindquest Connect | About us",
    "Club Freelance Insights | Podcast":
      "Podcast Interviews - Mindquest Connect",
    "Club Freelance Insights | Portage salarial":
      "Mindquest Connect | Wage portage",
    "Club Freelance Insights | Press review":
      "Mindquest Connect | Press review",
    "Club Freelance Insights | Project Management":
      "Mindquest Connect | Project Management",
    "Club Freelance Insights | Quotidien de freelance":
      "Mindquest Connect | Daily freelancer",
    "Club Freelance Insights | Rechercher un article":
      "Mindquest Connect | Search for an article",
    "Club Freelance Insights | Recrutement & IT":
      "Mindquest Connect | Recruitment & IT",
    "Club Freelance Insights | Recruter un consultant informatique":
      "Mindquest Connect | Hiring an IT consultant",
    "Club Freelance Insights | Revues de presse":
      "Mindquest Connect | Press review",
    "Club Freelance Insights | Risques & erreurs \xE0 \xE9viter":
      "Mindquest Connect | Tips & errors to avoid",
    "Club Freelance Insights | Se financer": "Mindquest Connect | finance",
    "Club Freelance Insights | Strat\xE9gie des talents":
      "Mindquest Connect | Talent Strategy",
    "Club Freelance Insights | Success": "Mindquest Connect | Success",
    "Club Freelance Insights | TOP 10 experts' articles":
      "Mindquest Connect | TOP 10 experts' articles",
    "Club Freelance Insights | Tech Magazine":
      "Mindquest Connect | Tech Magazine",
    "Club Freelance Insights | Tips for your daily life as a freelancer":
      "Mindquest Connect | Tips for your daily life as a freelancer",
    "Club Freelance Insights | T\xE9moignages":
      "Mindquest Connect | Testimonials",
    "Club Freelance Insights | Umbrella company":
      "Mindquest Connect | Umbrella company",
    "Club Freelance Insights | Webinars & Ressources":
      "Mindquest Connect | Webinars & Resources",
    "Club Freelance Insights | Webinars & replay":
      "Mindquest Connect | Webinars & replay",
    "Club Freelance Insights | cybersecurityEn":
      "Mindquest Connect | cybersecurityEn",
    "Club Freelance Insights | jobAppAdviceEn":
      "Mindquest Connect | jobAppAdviceEn",
    "Club Freelance Insights | pressReview": "Mindquest Connect | pressReview",
    "Club Freelance Insights | techmagazineEn":
      "Mindquest Connect | techmagazineEn",
    "Club Freelance Insights | topTenEn": "Mindquest Connect | topTenEn",
    "Club Freelance Limited": "Club Freelance Limited",
    "Club Freelance Limited (company number 9050953) and its affiliates respect your privacy and are committed to protecting the privacy and security of your personal information under applicable data privacy laws.":
      "Club Freelance Limited (company number 9050953) and its affiliates respect your privacy and are committed to protecting the privacy and security of your personal information under applicable data privacy laws.",
    "Club Freelance Limited is a staffing and sourcing company specialized in IT.":
      "Mindquest is a brand owned by Club Freelance Limited, a staffing and sourcing company specialized in IT.",
    "Club Freelance SAS": "Club Freelance SAS",
    "Club Freelance est une soci\xE9t\xE9 de placement de consultants ind\xE9pendants dans la technologie et l\u2019informatique.":
      "Mindquest is an IT staffing services company that matches businesses and IT professionals to complete critical IT projects, regardless of the type of contract.",
    "Club Freelance propose un service simple et efficace, facilit\xE9 par un espace personnel virtuel et la possibilit\xE9 de g\xE9rer toute sa documentation en ligne. Pour moi, c\u2019est la mani\xE8re la plus simple pour \xE9mettre une facture et se faire payer. En temps et en heure.":
      "Club Freelance propose un service simple et efficace, facilit\xE9 par un espace personnel virtuel et la possibilit\xE9 de g\xE9rer toute sa documentation en ligne. Pour moi, c\u2019est la mani\xE8re la plus simple pour \xE9mettre une facture et se faire payer. En temps et en heure.",
    "Club Freelance | Cr\xE9er un compte":
      "Mindquest | Sign up & start your journey towards IT success",
    "Club Freelance | Entourez-vous des meilleurs Talents Informatiques":
      "Mindquest | Top IT talent for all your business needs",
    "Club Freelance | Expert du sourcing IT digitalis\xE9":
      "Mindquest | About Us",
    "Club Freelance | Mon profil": "Mindquest | Mon profil",
    "Club Freelance | Nous contacter": "Mindquest | Contact Us",
    "Club Freelance | Partenaire des entreprises & freelances IT":
      "Mindquest | Your partner for IT jobs and talent recruitment",
    "Club Freelance | Recherche des candidats":
      "Mindquest | Search for candidates",
    "Club Freelance | Se connecter": "Mindquest | Se connecter",
    "Club Freelance | Votre prochaine mission Freelance Informatique":
      "Mindquest | Find your next contract & boost your IT career",
    "Club Freelance's Mission Control Center  \u2014 Interesting reads":
      "Mindquest Connect \u2014 Interesting reads",
    "Club Freelance's Mission Control Center \u2014 Behind the screen":
      "Club Freelance's Mission Control Center \u2014 Behind the screen",
    "Club Freelance's Mission Control Center \u2014 Business Lounge":
      "Mindquest Connect \u2014 Business Lounge",
    "Club Freelance's Mission Control Center \u2014 Captain's Log":
      "Mindquest Connect \u2014 Captain's Log",
    "Club Freelance's Mission Control Center \u2014 Career Tips & Tricks":
      "Mindquest Connect | Career Tips & Tricks",
    "Club Freelance's Mission Control Center \u2014 Horizon 2050":
      "Mindquest Connect | Horizon 2050",
    "Club Freelance's Mission Control Center \u2014 IT's Who is Who":
      "Mindquest Connect \u2014 IT's Who is Who",
    "Club Freelance's Mission Control Center \u2014 MCC: IT's resource hub":
      "Mindquest Connect \u2014 MC: IT's resource hub",
    "Club Freelance's Mission Control Center \u2014 Newsstand":
      "Mindquest Connect \u2014 Newsstand",
    "Club Freelance's Mission Control Center\u2014 Search all articles":
      "Mindquest Connect \u2014 Search all articles",
    "Club Freelance,": "Mindquest,",
    "Club Freelance, Freelance informatique, consultants Data, D\xE9veloppement, Cloud, Gestion de projet, CRM, Support, BI, Webdesign, ERP, SAP":
      "Mindquest, IT freelance, Data consultants, Development, Cloud, Project management, CRM, Support, BI, Webdesign, ERP, SAP ",
    "Club Freelance, IT careers, IT jobs, tech jobs, tech, careers, UK, job vacancies, IT vacancies,  Mission Control Center, blog, career advice, resources, industry trends, IT trends, IT recruitment trends, IT recruitment, IT staffing,  IT contractors, IT freelancers, IT talent, career tips, tips and tricks":
      "Mindquest, IT careers, IT jobs, tech jobs, tech, careers, UK, job vacancies, IT vacancies,  Mission Control Center, blog, career advice, resources, industry trends, IT trends, IT recruitment trends, IT recruitment, IT staffing,  IT contractors, IT freelancers, IT talent, career tips, tips and tricks",
    "Club Freelance, IT careers, IT jobs, tech jobs, tech, careers, UK, job vacancies, IT vacancies,  Mission Control Center, blog, career advice, resources, industry trends, IT trends, IT recruitment trends, IT recruitment, IT staffing, , IT contractors, IT freelancers, IT talent, tech profiles, career paths, news, tech news, newsstand":
      "Mindquest, IT careers, IT jobs, tech jobs, tech, careers, UK, job vacancies, IT vacancies,  Mission Control Center, blog, career advice, resources, industry trends, IT trends, IT recruitment trends, IT recruitment, IT staffing, , IT contractors, IT freelancers, IT talent, tech profiles, career paths, news, tech news, newsstand",
    "Club Freelance, IT careers, IT jobs, tech jobs, tech, careers, UK, job vacancies, IT vacancies,  Mission Control Center, blog, career advice, resources, industry trends, IT trends, IT recruitment trends, IT recruitment, IT staffing, , IT contractors, IT freelancers, IT talent, tech profiles, career paths, who is who, IT":
      "Mindquest, IT careers, IT jobs, tech jobs, tech, careers, UK, job vacancies, IT vacancies,  Mission Control Center, blog, career advice, resources, industry trends, IT trends, IT recruitment trends, IT recruitment, IT staffing, , IT contractors, IT freelancers, IT talent, tech profiles, career paths, who is who, IT",
    "Club Freelance, IT recruitment, IT staffing, UK, IT contractors, IT freelancers, IT talent, Mission Control Center, blog, business advice, resources, IT industry trends, IT trends, IT recruitment trends, talent management, business lounge":
      "Mindquest, IT recruitment, IT staffing, UK, IT contractors, IT freelancers, IT talent, Mission Control Center, blog, business advice, resources, IT industry trends, IT trends, IT recruitment trends, talent management, business lounge",
    "Club Freelance, IT recruitment, IT staffing, UK, IT contractors, IT freelancers, IT talent, Mission Control Center, blog, business advice, resources, IT industry trends, IT trends, IT recruitment trends, talent management, business lounge, company announcements":
      "Mindquest, IT recruitment, IT staffing, UK, IT contractors, IT freelancers, IT talent, Mission Control Center, blog, business advice, resources, IT industry trends, IT trends, IT recruitment trends, talent management, business lounge, company announcements",
    "Club Freelance, IT, IT news, tech, Tech news, Technilogy, science, science-fiction, sci-fi, future, pop culture, popular culture, fiction, short stories, horizon 2050, future tech":
      "Mindquest, IT, IT news, tech, Tech news, Technilogy, science, science-fiction, sci-fi, future, pop culture, popular culture, fiction, short stories, horizon 2050, future tech",
    "Club Freelance, IT, IT news, tech, Tech news, Technilogy, science, science-fiction, sci-fi, future, pop culture, popular culture, interesting reads":
      "Mindquest, IT, IT news, tech, Tech news, Technilogy, science, science-fiction, sci-fi, future, pop culture, popular culture, interesting reads",
    "Club Freelance, Mission Control Center, blog, career advice, resources, industry trends, IT trends, IT recruitment trends, IT recruitment, IT staffing, IT careers, IT jobs, tech jobs, tech, careers, UK, IT contractors, IT freelancers, IT talent, interesting reads, news, company announcements, short stories, sci-fi":
      "Mindquest, Mission Control Center, blog, career advice, resources, industry trends, IT trends, IT recruitment trends, IT recruitment, IT staffing, IT careers, IT jobs, tech jobs, tech, careers, UK, IT contractors, IT freelancers, IT talent, interesting reads, news, company announcements, short stories, sci-fi",
    "Club Freelance, Mission Control Center, blog, career advice, resources, industry trends, IT trends, IT recruitment trends, IT recruitment, IT staffing, IT careers, IT jobs, tech jobs, tech, careers, UK, IT contractors, IT freelancers, IT talent, search":
      "Mindquest, Mission Control Center, blog, career advice, resources, industry trends, IT trends, IT recruitment trends, IT recruitment, IT staffing, IT careers, IT jobs, tech jobs, tech, careers, UK, IT contractors, IT freelancers, IT talent, search",
    "Club Freelance, talent management, freelance informatique, trouver un freelance":
      "Mindquest, talent management, IT freelance, find a candidate",
    Club_Freelance: "Mindquest",
    "Co-fondateur": "Co-founder",
    "Code postal*": "ZIP Code*",
    "Collaborateur non-inscrit": "Collaborator without account",
    "Collaborateur(s)": "Collaborator(s)",
    "Collaborateurs \xE0 temps plein": "Full-time employees",
    Collaboratif: "Collaborative",
    "Collaboration Existante": "Existing Collaboration",
    "Collaboration sur le vivier": "Collaborate on the pool",
    Collaborators: "Collaborators",
    "Collected for specified, explicit and legitimate purposes;":
      "Collected for specified, explicit and legitimate purposes;",
    "Coller votre code ici": "Paste your code here",
    "Collez ici le contenu de votre fiche de poste...":
      "Paste your job description here",
    "Collez l\u2019URL de votre profil Linkedin pour pr\xE9remplir votre profil":
      "Paste your LinkedIn profile URL to pre-fill your profile",
    "Comment avez-vous entendu parlez de nous": "How did you hear about us",
    "Comment \xE7a marche ?": "How does it work?",
    Commentaire: "Comments",
    Commentaires: "Comments",
    "Communiqu\xE9s de presse": "Communiqu\xE9s de presse",
    "Company information available on local company registrars;":
      "Company information available on local company registrars;",
    "Compl\xE9ter mon profil": "Complete my profile",
    Comptabilité: "Comptabilit\xE9",
    "Compte non verifi\xE9": "Unverified account",
    "Compte-Rendu G\xE9n\xE9r\xE9": "Report generated",
    "Compte-rendu G\xE9n\xE9r\xE9": "Generated report",
    "Compte-rendus": "Reports",
    "Comptes rendus": "Reports",
    Compétence: "Skills",
    Compétences: "Skills",
    "Comp\xE9tences de la recherche": "Research skills",
    "Conditions G\xE9n\xE9rales d'Utilisation et Mentions L\xE9gales":
      "Terms and conditions",
    "Conditions G\xE9n\xE9rales d\u2019Utilisation":
      "Terms and conditions of use",
    "Conditions G\xE9n\xE9rales d\u2019Utilisation - Mindquest":
      "Terms of Service - Mindquest",
    "Conditions G\xE9n\xE9rales d\u2019Utilisation et mentions l\xE9gales":
      "Terms of use and legal notices",
    Confidentialité: "Privacy",
    "Confiez-nous votre prochain recrutement ?": "Let's find your next hire",
    Configuration: "Configuration",
    "Confirmation mot de passe": "Confirm password",
    "Confirmed (3 - 7)": "Confirmed (3 - 7)",
    "Confirmed (3-7)": "Confirmed",
    Confirmer: "Confirm",
    "Confirmer la suppression": "Confirm deletion",
    "Confirmer le nouveau mot de passe": "Confirm the new password",
    "Confirmer votre mot de passe": "Confirm password",
    "Confirmez le nouveau mot de passe": "Confirm the new password",
    "Confirmez votre mot de passe": "Confirm password",
    Confirmé: "Confirmed",
    Congrats: "Congrats",
    "Connect with the latest news in the IT and Tech sector":
      "Connect with the latest news in the IT and Tech sector",
    "Connectez-vous \xE0 votre compte Mindquest pour trouver votre prochaine offre Tech & IT en CDI ou Freelance ou recruter les meilleurs consultants Data, D\xE9veloppement, Cloud, Gestion de projet, CRM, Support, BI, ERP, SAP, Oracle, Infra...":
      "Login to your Mindquest account to find your next Tech & IT permanent or freelance vacancy or recruit the best Data, Development, Cloud, Project Management, CRM, Support, BI, ERP, SAP, Oracle, Infra consultants\u2026",
    "Connectez-vous \xE0 votre espace personnel Mindquest":
      "Login to your personal Mindquest space",
    "Connectez-vous \xE0 votre profil Club Freelance":
      "Login to your Mindquest profile",
    Connexion: "Login",
    "Connexion ou se connecter": "Login",
    "Conseils Pratiques pour Talents Tech & IT - Mindquest Connect":
      "Conseils Pratiques pour Talents Tech & IT - Mindquest Connect",
    "Conseils Pratiques pour entreprise - Mindquest Connect":
      "Conseils Pratiques pour entreprise - Mindquest Connect",
    "Conseils pratiques": "Practical advice",
    "Conseils pratiques Consultant": "Practical advice Candidate",
    "Conseils pratiques Entreprise": "Practical Tips IT decision maker",
    "Conseils pratiques Talents IT": "Practical tips Candidate",
    Consultant: "Consultant",
    "Consultant AWS Cloud Devops": "AWS Cloud DevOps Consultant",
    "Consultant IT": "IT Consultant",
    "Consultants IT - Recevez un contenu sp\xE9cifique aux consultants du secteur Tech & IT, tous les mois : articles, veilles, s\xE9lection d'opportunit\xE9s, nos avantages Mindquest, etc.":
      "IT Consultants - Receive specific content for Tech & IT consultants every month: articles, news, selected opportunities, our Mindquest benefits, etc.",
    "Consulter le Timesheet": "Check the Timesheet",
    "Consulter le compte-rendu": "Check the report",
    "Consulter le timesheet": "Check the timesheet",
    "Consulter notre <0>politique de confidentialit\xE9 </0> et nos <1>conditions g\xE9n\xE9rales d\u2019utilisations </1>":
      "View our <0>privacy policy </0> and <1>terms and conditions of use </1>",
    'Consultez et g\xE9rez vos viviers de talents au sein de la rubrique "Talents" de votre Dashbord.':
      'View and manage your talent pools in the "Talents" section of your Dashboard.',
    "Consultez nos offres CDI ou missions freelance pour consultants tech et trouvez votre prochain job IT : Data, D\xE9veloppement, Cloud, Gestion de projet, CRM, Support, BI, ERP, SAP, Oracle...":
      "Check out our permanent and freelance jobs for tech consultants and find your next IT job: Data, Development, Cloud, Project Management, CRM, Support, BI, ERP, SAP, Oracle\u2026",
    "Consultez vos emails pour finaliser l\u2019inscription":
      "Check out your email to complete the registration process",
    Contact: "Contact",
    "Contact de r\xE9f\xE9rences": "References contact",
    "Contact me if you are interested !": "Contact me if you are interested !",
    "Contact me to join our community!": "Contact me to join our community!",
    "Contact us": "Contact us",
    Contacter: "Contact",
    "Contacter Nous": "Contact Us",
    "Contacter notre \xE9quipe Mindquest": "Contact our Mindquest team",
    'Contactez notre \xE9quipe "Legal"': "Contact our Legal team",
    "Contactez nous": "Contact us",
    Contacts: "Contacts",
    "Contenu orient\xE9 aux candidats": "Content for candidates",
    "Contenu orient\xE9 aux entreprises": "Content for employers",
    "Contenu pour client": "Content for customer",
    "Contenu pour consultant": "Content for consultant",
    "Contenu pour consultants IT": "Content for IT consultant",
    "Contenu pour d\xE9cideurs IT": "Content for IT decision maker",
    "Contenu pour les d\xE9cideurs IT": "Content for IT decision maker",
    Context: "Context",
    "Contexte de recrutement": "Context for recruitment",
    "Contexte projet": "Context for the project",
    Continuer: "Continue",
    "Continuer ma recherche": "Continue my search",
    "Continuer sans accepter": "Deny & continue",
    Contrats: "Contracts",
    "Cookie of advertising": "Cookie of advertising",
    "Cookies policy": "Cookies policy",
    "Cookies strictly necessary": "Cookies strictly necessary",
    "Cookies vary according to their origin and lifespan.":
      "Cookies vary according to their origin and lifespan.",
    Coopter: "Recommend",
    "Copier et coller l'URL de votre profil LinkedIn":
      "Copy and paste your LinkedIn profile URL",
    Critères: "Criteria",
    "Cr\xE9ation de Club Freelance": "Club Freelance is born",
    Créer: "Create",
    "Cr\xE9er mon compte entreprise et rejoindre Mindquest":
      "Create my company account and join Mindquest",
    "Cr\xE9er mon compte talent et rejoindre Mindquest":
      "Create my talent account and join Mindquest",
    "Cr\xE9er mon espace personnel Talent": "Create my personal Talent space",
    "Cr\xE9er un compte": "Create account",
    "Cr\xE9er un mot de passe": "Create a password",
    "Cr\xE9er un nouveau mot de passe": "Create a new password",
    "Cr\xE9er un vivier": "Create a new pool",
    "Cr\xE9er une alerte sur ces crit\xE8res":
      "Create an alert with these criteria",
    "Cr\xE9er une offre": "Create an offer",
    "Cr\xE9er votre compte": "Create your account",
    "Cr\xE9ez un nouveau mot de passe": "Create a new password",
    "Cr\xE9ez votre profil Club Freelance en seulement 3 minutes":
      "Create your Mindquest account in less than 3 minutes",
    "Currently all the data that we collect from you may/will be transferred to, and stored at, a destination inside the European Economic Area (\u201DEEA\u201D). It may be transferred to third parties outside of the EEA for the purpose of our recruitment services. It may also be processed by staff operating outside the EEA who work for one of our suppliers. This includes staff engaged in, among other things, recruitment services and the provision of support services. By submitting your personal data, you agree to this transfer, storing or processing.":
      "Currently all the data that we collect from you may/will be transferred to, and stored at, a destination inside the European Economic Area (\u201DEEA\u201D). It may be transferred to third parties outside of the EEA for the purpose of our recruitment services. It may also be processed by staff operating outside the EEA who work for one of our suppliers. This includes staff engaged in, among other things, recruitment services and the provision of support services. By submitting your personal data, you agree to this transfer, storing or processing.",
    "Cyber security": "Cyber security",
    "Cybersecurity Content - Mindquest Connect":
      "CyberSecurity Content - Mindquest Connect",
    Cybersécurité: "Cyber security",
    DSI: "ISD",
    "Daily rate Range;": "Daily rate Range;",
    "Dans  le  cadre  des  services  propos\xE9s  et  mis  \xE0  disposition  par  la  Soci\xE9t\xE9  par  le  biais  de la Plateforme, les Talents s\u2019engagent \xE0 ne pas d\xE9marcher les Clients directement en dehors de la Plateforme et ce, afin d\u2019\xE9viter le recours \xE0 la Soci\xE9t\xE9.":
      "Talents  undertake  not  to  approach  Clients  directly  outside  the  Platform,  in  order  to  avoid recourse to Company.",
    "Dans l'hypoth\xE8se o\xF9 l\u2019Utilisateur fournirait des donn\xE9es fausses ou inexactes, la Soci\xE9t\xE9 sera en droit de suspendre ou de proc\xE9der \xE0 la fermeture de son compte et de lui refuser l'acc\xE8s \xE0 tout service fourni par la Soci\xE9t\xE9.":
      "Should User provide false or inaccurate information, Company shall be entitled to suspend or close its account and refuse access to any service provided by the Company.",
    "Dans le cadre des services propos\xE9s et mis \xE0 disposition par la Soci\xE9t\xE9 par le biais de la Plateforme, les Talents s\u2019engagent \xE0 ne pas d\xE9marcher les Clients directement en dehors de la Plateforme et ce, afin d\u2019\xE9viter le recours \xE0 la Soci\xE9t\xE9.":
      "Talents  undertake  not  to  approach  Clients  directly  outside  the  Platform,  in  order  to  avoid recourse to Company.",
    "Dans l\u2019hypoth\xE8se o\xF9 l\u2019une des clauses des pr\xE9sentes serait d\xE9clar\xE9e nulle et non avenue pour quelque  raison  que  ce  soit, cela  ne  saurait  en  aucun  cas  affecter  la  validit\xE9  et  le  respect des autres clauses.":
      "In the event that one of the clauses in these Terms of Use is declared null and void for whatever reason, that cannot in any event affect the validity and observance of the other clauses.",
    "Dans l\u2019hypoth\xE8se o\xF9 l\u2019une des clauses des pr\xE9sentes serait d\xE9clar\xE9e nulle et non avenue pour quelque raison que ce soit, cela ne saurait en aucun cas affecter la validit\xE9 et le respect des autres clauses.":
      "In the event that one of the clauses in these Terms of Use is declared null and void for whatever reason, that cannot in any event affect the validity and observance of the other clauses.",
    "Dans un rayon de": "Within",
    "Dans un rayon max de XX km": "Within XX km",
    Dashboard: "Dashboard",
    "Dashboard Enterprise": "Employer Dashboard",
    "Data  on  the  legal  structure  of  Talent:  name  of  the  company,  identification  number, legal representative of the company and legal documents.":
      "Data  on  the  legal  structure  of  Talent:  name  of  the  company,  identification  number, legal representative of the company and legal documents.",
    "Data  on  your  economic  and  socio-occupational  situation:  professional  experience, academic  training,  bank  details,  Curriculum  Vitae,  references  to  former  clients or employers, current and envisaged remuneration;":
      "Data  on  your  economic  and  socio-occupational  situation:  professional  experience, academic  training,  bank  details,  Curriculum  Vitae,  references  to  former  clients or employers, current and envisaged remuneration;",
    "Data & Business Intelligence job descriptions - Mindquest Connect":
      "Data & Business Intelligence job descriptions - Mindquest Connect",
    "Data & ERP": "Data & ERP",
    "Data & business Intelligence": "Data & business Intelligence",
    "Data Science & BI": "Data Science & BI",
    "Data Science & Bi": "Data Science & Bi",
    "Data on recruitment needs.": "Data on recruitment needs.",
    "Data on the legal structure of Talent: name of the company, identification number, legal representative of the company and legal documents.":
      "Data on the legal structure of Talent: name of the company, identification number, legal representative of the company and legal documents.",
    "Data on your economic and socio-occupational situation: professional experience, academic training, bank details, Curriculum Vitae, references to former clients or employers, current and envisaged remuneration;":
      "Data on your economic and socio-occupational situation: professional experience, academic training, bank details, Curriculum Vitae, references to former clients or employers, current and envisaged remuneration;",
    "Data subjects are therefore invited to read this document carefully to find out and understand the company\u2019s practices regarding the processing of users\u2019 personal data.":
      "Data subjects are therefore invited to read this document carefully to find out and understand the company\u2019s practices regarding the processing of users\u2019 personal data.",
    "Data subjects may be required to provide the Company, in its capacity as Controller within the meaning of the GDPR, with information and personal data relating to them.":
      "Data subjects may be required to provide the Company, in its capacity as Controller within the meaning of the GDPR, with information and personal data relating to them.",
    Date: "Date",
    "Date de d\xE9but": "Start date",
    "Date de d\xE9marrage": "Start date",
    "Date de fin": "End date",
    "Date de modification": "Modification date",
    "Date de publication": "Publication date",
    "Date d\u2019ajout": "Date Added",
    "Date of birth;": "Date of birth",
    "De  fa\xE7on  g\xE9n\xE9rale  de  manquer  \xE0  toutes  obligations  l\xE9gales  ou  r\xE9glementaires  qui s\u2019imposeraient de droit et de fait \xE0 eux.":
      "Generally, failure to comply with any legal or regulatory obligations which are legally and de facto binding on them.",
    "De  la  m\xEAme  mani\xE8re,  les  Clients  s\u2019engagent  \xE0  ne  pas  d\xE9marcher  les  Talents  directement en dehors de la Plateforme et ce, afin d\u2019\xE9viter le recours \xE0 la Soci\xE9t\xE9.":
      "Similarly, Clients undertake not to approach Talents directly outside the Platform, in order to avoid recourse to Company.",
    "De d\xE9nigrer la Plateforme et / ou la Soci\xE9t\xE9 et;":
      "Denigrating the Platform and/or the Company; and",
    "De fa\xE7on g\xE9n\xE9rale de manquer \xE0 toutes obligations l\xE9gales ou r\xE9glementaires qui s\u2019imposeraient de droit et de fait \xE0 eux.":
      "Generally, failure to comply with any legal or regulatory obligations which are legally and de facto binding on them.",
    "De la m\xEAme mani\xE8re, les Clients s\u2019engagent \xE0 ne pas d\xE9marcher les Talents directement en dehors de la Plateforme et ce, afin d\u2019\xE9viter le recours \xE0 la Soci\xE9t\xE9.":
      "Similarly, Clients undertake not to approach Talents directly outside the Platform, in order to avoid recourse to Company.",
    "De porter atteinte aux droits de propri\xE9t\xE9 intellectuelle de la Soci\xE9t\xE9 et au prestataire;":
      "Infringing the company\u2019s and the service provider\u2019s intellectual property rights;",
    "De tenter d\u2019obtenir l\u2019acc\xE8s non autoris\xE9 au syst\xE8me informatique de la Plateforme ou de se  livrer  \xE0  toute activit\xE9  perturbant,  diminuant  la  qualit\xE9  ou  interf\xE9rant  avec  les performances ou d\xE9t\xE9riorant les fonctionnalit\xE9s de la Plateforme;":
      "Attempting to obtain unauthorised access to the Platform\u2019s IT system or to engage in any activity that disrupts, reduces quality or interferes with performance or deteriorates the Platform\u2019s functionalities.",
    "De tenter d\u2019obtenir l\u2019acc\xE8s non autoris\xE9 au syst\xE8me informatique de la Plateforme ou de se livrer \xE0 toute activit\xE9 perturbant, diminuant la qualit\xE9 ou interf\xE9rant avec les performances ou d\xE9t\xE9riorant les fonctionnalit\xE9s de la Plateforme;":
      "Attempting to obtain unauthorised access to the Platform\u2019s IT system or to engage in any activity that disrupts, reduces quality or interferes with performance or deteriorates the Platform\u2019s functionalities.",
    "De vendre, copier, reproduire, louer, pr\xEAter, distribuer, transf\xE9rer ou conc\xE9der une sous-licence,  tout  ou  partie, des  contenus  figurant  sur  la  Plateforme  ou  de  d\xE9compiler, d\xE9sosser, d\xE9sassembler, modifier, tenter de d\xE9couvrir tout code source ou utiliser tout logiciel activant ou comprenant tout ou partie de la Plateforme;":
      "To  sell,  copy,  reproduce,  rent,  lend,  distribute,  transfer  or  sub-license,  in  whole  or  in part, content on the Platform or to decompile, debar, unassembled, modify, attempt to discover any source code or use any software activating or including all or part of the Platform;",
    "De vendre, copier, reproduire, louer, pr\xEAter, distribuer, transf\xE9rer ou conc\xE9der une sous-licence, tout ou partie, des contenus figurant sur la Plateforme ou de d\xE9compiler, d\xE9sosser, d\xE9sassembler, modifier, tenter de d\xE9couvrir tout code source ou utiliser tout logiciel activant ou comprenant tout ou partie de la Plateforme;":
      "To  sell,  copy,  reproduce,  rent,  lend,  distribute,  transfer  or  sub-license,  in  whole  or  in part, content on the Platform or to decompile, debar, unassembled, modify, attempt to discover any source code or use any software activating or including all or part of the Platform;",
    "Demande de modification": "Change request",
    "Demande par email renvoy\xE9e": "Email request sent again",
    "Depending on the relevant circumstances and applicable local laws and requirements, we may collect information to enable us to offer you potential employment opportunities which are tailored to your circumstances and your interests. This information may include:":
      "Depending on the relevant circumstances and applicable local laws and requirements, we may collect information to enable us to offer you potential employment opportunities which are tailored to your circumstances and your interests. This information may include:",
    "Derni\xE8re mise \xE0 jour : 1er f\xE9vrier 2022":
      "Last update: 1February 2022",
    "Des competences specifiques trouvees rapidement et efficacement.":
      "Specific skills found quickly and efficiently.",
    "Des comp\xE9tences specifiques trouv\xE9es rapidement et efficacement.":
      "Specific skills found quickly and efficiently.",
    "Des hommes et des algorithmes pour un matching parfait entre <0>entreprises</0> et <1>freelances IT</1>":
      "Where <0>IT talent</0> and <1>business</1> cross paths on their way to success",
    "Des hommes et des algorithmes pour un matching parfait entre entreprises et freelances en informatique. Nous accompagnons les meilleurs talents et les entreprises les plus innovantes.":
      "Where IT talent and business cross paths on their way to success. We support the best talents and the most innovative companies.",
    "Des hommes et des algorithmes pour un matching parfait entre entreprises et freelances informatique.":
      "Connecting top IT talent and the best industry jobs.",
    "Des services": "Our Services",
    "Description de l'offre": "Offer description",
    "Description de la mission": "Job description",
    "Design Graphique & UX/UI": "Design Graphique & UX/UI",
    "Details of your contact details: postal address, e-mail address, mobile phone number;":
      "Details of your contact details: postal address, e-mail address, mobile phone number;",
    "Devenez apporteur d\u2019affaire et faites-vous r\xE9mun\xE9rer":
      "Bring in new business and get paid",
    "Devenir Freelance": "Become Freelancer",
    Digital: "Digital",
    "Digital job descriptions - Mindquest Connect":
      "Digital job descriptions - Mindquest Connect",
    "Directeur de la publication : Thomas Delfort":
      "Director of publication: Thomas Delfort",
    "Discover all our practical tips for IT decision makers to effectively work with tech & IT talent.":
      "Discover all our practical tips for IT decision makers to effectively work with tech & IT talent.",
    "Discover all our tips for IT talent and consultants and expert views to succeed in your tech and IT business and find the best freelance or permanent opportunities.":
      "Discover all our tips for IT talent and consultants and expert views to succeed in your tech and IT business and find the best freelance or permanent opportunities.",
    "Discover all the latest news about Mindquest and our tech & IT jobs (Development, Infra, ERP & SAP, Project Management, Data & Business Intelligence, Digital).":
      "Discover all the latest news about Mindquest and our tech & IT jobs (Development, Infra, ERP & SAP, Project Management, Data & Business Intelligence, Digital).",
    "Discover all the latest news from Mindquest and our selection of articles for Tech Magazine (Cybersecurity, ERP &SAP, Artificial Intelligence, Digital).":
      "Discover all the latest news from Mindquest and our selection of articles for Tech Magazine (Cybersecurity, ERP &SAP, Artificial Intelligence, Digital).",
    "Discover our news, our job descriptions, our selection of articles for IT talents and consultants, decision-makers and IT recruiters, and all our webinars and resources to recruit the ideal IT profile or find the ideal IT assignment.":
      "Discover our news, our job descriptions, our selection of articles for IT talents and consultants, decision-makers and IT recruiters, and all our webinars and resources to recruit the ideal IT profile or find the ideal IT assignment. ",
    "Disponibile \xE0 partir de:": "Available from",
    Disponibilité: "Availability",
    "Disponibilit\xE9 \xE0 confirmer": "Not available",
    Disponible: "Available",
    "Disponible \xE0 partir de:": "Available from:",
    Distance: "Distance",
    "Dites-nous ce que nous pouvons faire pour vous.":
      "Tell us what we can do for you",
    Documents: "Documents",
    "Doit contenir au moins 8 caract\xE8res": "Must have at least 8 characters",
    "Doit contenir au moins un caract\xE8re sp\xE9cial !*#@...":
      "Must have at least one special character !*#@...",
    "Doit contenir au moins un chiffre": "Must have at least one digit",
    "Doit contenir au moins une majuscule":
      "Must have at least one capital letter",
    "Doit contenir au moins une minuscule":
      "Must have at least one lowercase letter",
    "Domaine d\u2019expertise": "Areas of expertise",
    "Domaines d'expertise": "Areas of expertise",
    "Domaines d\u2019expertise": "Areas of expertise",
    Du: "From",
    Duration: "Duration",
    Dutch: "Dutch",
    "D\xE8s lors que l\u2019Utilisateur continue d\u2019acc\xE9der et d\u2019utiliser la Plateforme, il sera r\xE9put\xE9 acceptent les conditions g\xE9n\xE9rales ainsi modifi\xE9es.":
      "Once User continues to access and use the Platform, he will be deemed to accept the terms and conditions thus amended.",
    "D\xE9cideur IT": "IT Decision Maker",
    "D\xE9cideurs IT": "D\xE9cideur IT",
    "D\xE9cideurs IT - Recevez le meilleur du contenu tech & IT, tous les mois : articles, veilles, s\xE9lection de profils IT ainsi que les derni\xE8res actualit\xE9s du secteur.":
      "IT Decision Makers - Receive the best of Tech & IT content, every month: articles, monitoring, selection of IT profiles and the latest industry news.",
    "D\xE9couvrez Club Freelance": "Discover Club Freelance",
    "D\xE9couvrez Mission Control Center : tous nos conseils pour les professionnels de l'IT":
      "Explore Mission Control Center your one-stop shop for career advice",
    "D\xE9couvrez les m\xE9tiers de l\u2019informatique et du digital d\xE9crypt\xE9s":
      "Discover the IT and digital professions decrypted",
    "D\xE9couvrez les tendances et \xE9volution des m\xE9tiers de l\u2019informatique & du digital \xE0 l\u2019heure du future of work. Fiches m\xE9tiers d\xE9taill\xE9es et analyses.":
      "Find all the latest Mindquest news and our interviews of top IT experts with our Podcast Mission Control Center.",
    "D\xE9couvrez nos mod\xE8les de facture, de CV et template de note de frais":
      "Explore our templates for CVs, invoices, expense reports...",
    "D\xE9couvrez nos ressources pour les entreprises":
      "Explore our resources for employers",
    "D\xE9couvrez notre service de recrutement freelance":
      "Discover our freelancer recruitment service",
    "D\xE9couvrez notre \xE9quipe": "Meet our team",
    "D\xE9couvrez tous nos conseils et points de vue d\u2019experts pour recruter les meilleurs talents et travailler efficacement avec des freelances IT.":
      "Discover all our advice and expert points of view to recruit the best talent and work effectively with IT freelancers.",
    "D\xE9couvrez tous nos conseils et points de vue d\u2019experts pour r\xE9ussir dans votre activit\xE9 de freelance IT et d\xE9crocher les meilleures missions.":
      "Discover all our advice and expert views to succeed in your IT freelance activity and land the best jobs.",
    "D\xE9couvrez tous nos conseils pour talent et consultants IT et points de vue d\u2019experts pour r\xE9ussir dans votre activit\xE9 dans la tech et l'informatique et d\xE9crocher les meilleures opportunit\xE9s en freelance ou CDI.":
      "Discover all our tips for IT talent and consultants and expert views to succeed in your tech and IT business and find the best freelance or permanent opportunities.",
    "D\xE9couvrez tous nos conseils pour travailler efficacement avec les Freelances IT":
      "Discover all our tips for IT decision markers to work effectively with IT Freelancers.",
    "D\xE9couvrez tous nos conseils pour une activit\xE9 de Freelance r\xE9ussie":
      "Discover all our advice for a successful Freelance activity",
    "D\xE9couvrez tous nos conseils pratiques pour d\xE9cideurs IT afin de travailler efficacement avec des Talents tech & IT.":
      "Discover all our tips for IT decision markers to work effectively with IT Freelancers.",
    "D\xE9couvrez toutes les offres de votre Talent Community Speciallist":
      "Discover all the offers of your Talent Community Speciallist",
    Découvrir: "Discover",
    "D\xE9couvrir Club Freelance": "Discover Mindquest",
    "D\xE9couvrir les ressources": "See resources",
    "D\xE9couvrir les rubriques": "Discover the categories",
    "D\xE9couvrir nos offres": "Discover our offers",
    "D\xE9couvrir nos partenaires": "Visit our partnerships page",
    Décryptage: "Decryption",
    Décryptages: "Decryption",
    "D\xE9lai moyen pour recevoir une shortlist de candidats qualifi\xE9s":
      "To get a shortlist of qualified candidates",
    Démarrage: "Start date",
    "D\xE9poser un projet": "Post a vacancy",
    "D\xE9scription de la mission": "Job description",
    Désolé: "Sorry",
    "D\xE9sol\xE9, aucun r\xE9sultat trouv\xE9.": "Sorry, no results found",
    "D\xE9sol\xE9, pas de candidats pour ces crit\xE8res":
      "Sorry, no candidates for these criteria",
    "D\xE9sol\xE9, pas de missions pour ces crit\xE8res":
      "Sorry, There is no jobs matching these criteria",
    "D\xE9tails de l'offre": "Offer details",
    Développement: "Web Developer",
    "D\xE9veloppement Logiciels & Syst\xE8mes d'Exploitation":
      "Software Development & Operating Systems",
    "D\xE9veloppement Mobile": "Mobile development",
    "D\xE9veloppement Web": "Web development",
    "D\xE9velopper sa carri\xE8re: CDI & freelance":
      "Growing your career: permanent & freelance",
    "D\u2019utiliser la Plateforme de toute mani\xE8re ill\xE9gale, pour toute finalit\xE9 ill\xE9gale ou de toute mani\xE8re incompatible avec les CGU;":
      "Use the Platform in any illegal way, for any illegal purpose or in any event incompatible with the Terms of Use;",
    "D\u2019utiliser la Plateforme \xE0 des fins abusives en y introduisant volontairement des virus ou tout autre programme malveillant et de tenter d\u2019acc\xE9der de mani\xE8re non autoris\xE9e \xE0 la Plateforme;":
      "Use  the  Platform  for  abusive  purposes  by  voluntarily  introducing  viruses  or  other malicious programmes and attempting unauthorised access to the Platform;",
    "E-commerce": "E-commerce",
    "E-mail": "E-mail",
    "ERP & SAP": "ERP & SAP",
    "ERP & SAP job description - Mindquest Connect":
      "ERP & SAP job description - Mindquest Connect",
    "ERP / EDI": "ERP / EDI",
    "ERP supply": "ERP supply",
    EURL: "EURL",
    "Each  individual  concerned  shall  have  the  right  to  lodge  a  complaint  with  a  competent supervisory authority.":
      "Each  individual  concerned  shall  have  the  right  to  lodge  a  complaint  with  a  competent supervisory authority.",
    "Each individual concerned shall have the right to lodge a complaint with a competent supervisory authority.":
      "Each individual concerned shall have the right to lodge a complaint with a competent supervisory authority.",
    "Echec de modification du mot de passe.": "Failed to change password.",
    Editer: "Edit",
    Editeur: "Editor",
    "Education & Qualifications;": "Education & Qualifications;",
    "Elles cessent le jour o\xF9 le compte de l\u2019Utilisateur est supprim\xE9 pour quelque motif que ce soit.":
      "They cease on the day the User's account is deleted for any reason whatsoever.",
    Email: "Email",
    "Email Envoy\xE9": "Email Sent",
    "Email d\xE9j\xE0 associ\xE9 \xE0 un compte existant":
      "Email already associated with an existing account",
    "Email envoy\xE9 avec succ\xE9es": "Email sent successfully",
    "Email invalide": "Invalid Email",
    "Embark on a journey to discover what lies ahead for IT and science. Short sci-fi stories posted every month for you to engage and especulate on the future of tech.":
      "Embark on a journey to discover what lies ahead for IT and science. Short sci-fi stories posted every month for you to engage and especulate on the future of tech.",
    "Embedded Sytems": "Embedded Sytems",
    "En attente": "sent",
    "En aucun cas, cette licence n\u2019autorise les Utilisateurs \xE0 reproduire, copier, dupliquer, modifier, transformer, traduire les diff\xE9rents \xE9l\xE9ments composant la Plateforme.":
      "Under no circumstances shall this licence authorise Users to reproduce, copy, duplicate, modify, transform or translate the various elements of the Platform.",
    "En aucun cas, cette \xE9valuation ne pourra \xEAtre utilis\xE9e contre les Consultants.":
      "Under no circumstances may this assessment be used against Talents.",
    "En aucun cas, la Soci\xE9t\xE9 n\u2019intervient pour assurer un r\xF4le de mod\xE9rateur \xE0 cet \xE9gard.":
      "Under no circumstances does Company intervene to act as a moderator in this respect.",
    "En cas de litige les parties feront leurs meilleurs efforts pour trouver un accord amiable.":
      "In the event of a dispute, the parties will make their best efforts to reach an amicable agreement.",
    "En cas de non-respect de cette obligation, la Soci\xE9t\xE9 pourra suspendre l\u2019acc\xE8s \xE0 la Plateforme pour une dur\xE9e qu\u2019elle d\xE9terminera ou y mettre d\xE9finitif sans pr\xE9judice de toute demande de dommages-int\xE9r\xEAts qu\u2019elle pourrait solliciter devant toute juridiction comp\xE9tente.":
      "In  the  event  of  failure  to  comply  with  this  obligation,  Company  may  suspend  access  to  the Platform for a period which it shall determine or terminate it without prejudice to any claim for damages which it may seek before any court having jurisdiction.",
    "En cas de non-respect des conditions de ladite licence d\u2019utilisation, les Utilisateurs s\u2019exposent \xE0 des sanctions et des poursuites sur le fondement de la contrefa\xE7on notamment.":
      "In the event of non-compliance with the conditions of said license of use, Users are exposed to penalties and prosecution on the basis of infringement in particular.",
    "En chargeant votre CV, vos informations seront pr\xE9remplies. Un CV au format Word offrira de meilleurs r\xE9sultats":
      "By uploading your CV, your details will be automatically filled in. A CV in Word format will provide the best results",
    "En cliquant sur le bouton ci-dessous, vous serez d\xE9connect\xE9 et redirig\xE9 vers la page de login":
      "By clicking on the button below, you will be disconnected and redirected to the login page",
    "En cliquant sur le button ci-dessous, vous serez d\xE9connect\xE9 et redirig\xE9 vers la page de login":
      "By clicking on the button below, you will be disconnected and redirected to the login page",
    "En construction": "Under construction",
    "En d\xE9pla\xE7ant ce candidat dans la colonne profils refus\xE9s, vous notifierez l'\xE9quipe Club Freelance de votre retour.":
      "By moving this candidate to the rejected profiles column, the Mindquest team will be notified",
    "En d\xE9pla\xE7ant ce candidat dans la colonne profils \xE0 rencontrer, vous notifierez l'\xE9quipe Club Freelance de votre retour.":
      "By moving this candidate to the profiles to meet column, the Mindquest team will be notified",
    "En d\xE9pla\xE7ant ce candidat dans la colonne proposition, vous notifierez l'\xE9quipe Club Freelance de votre retour.":
      "By moving this candidate to the proposal column, the Mindquest team will be notified",
    "En outre, il est pr\xE9cis\xE9 que les marques, logotypes, signes et tout autre \xE9l\xE9ment de la Plateforme appartiennent \xE0 la Soci\xE9t\xE9 et ne sauraient \xEAtre librement reproduits par un Utilisateur.":
      "Furthermore, it is stated that the marks, logotypes, signs and any other element of the Platform belong to Company and cannot be freely reproduced by a User.",
    "En outre, la Soci\xE9t\xE9 garantit qu\u2019il met en \u0153uvre tous les moyens \xE0 sa disposition pour assurer la s\xE9curit\xE9 et l\u2019int\xE9grit\xE9 de la Plateforme ainsi que des donn\xE9es qui y sont stock\xE9es.":
      "In addition, Company shall make its reasonable effort to ensure the security and integrity of the Platform and the data stored therein.",
    "En outre, la plateforme permet aux Talents en mission de faire valider chaque mois leur compte-rendu d\u2019activit\xE9 par le Client gr\xE2ce \xE0 l\u2019application Timesheet Manager. A ce titre, la Soci\xE9t\xE9 se r\xE9serve la possibilit\xE9 de cr\xE9er un identifiant unique relatif \xE0 un Utilisateur, de sorte \xE0 permettre la validation des comptes-rendus.":
      "In addition, the platform allows Talents on assignment with a Client to validate their monthly timesheet by using Timesheet Manager. For this sole purpose, Company reserves its right to create a User account to Client and/or Talent so that the timesheet can be validated.",
    "En outre, les Consultants s\u2019engagent \xE0 informer la Soci\xE9t\xE9 de tout d\xE9marchage ou sollicitation de la part de Clients avec lesquels ils auraient \xE9t\xE9 mis en relation dans le cadre de l\u2019utilisation de la Plateforme.":
      "In addition, Talents undertake to notify Company of any solicitation by Clients with which they have been in contact through  the use of the Platform.",
    "En rejetant le timesheet, celui-ci sera renvoy\xE9 au consultant pour modification. Merci d'indiquer en commentaire la raison de ce rejet de la fa\xE7on la plus pr\xE9cise possible.":
      "By rejecting the timesheet, it will be returned to the consultant for modification. Please indicate in your comments the reason for this rejection as precisely as possible.",
    "En savoir plus": "Find out more",
    "En transport en commun": "By public transport",
    "En voiture": "By car",
    "Encore quelques instants...": "Just a couple more minutes...",
    "Encryption prevents data from being easily accessible and recoverable in the event of theft or computer misuse. Our websites are protected by an SSL certificate.":
      '"Encryption prevents data from being easily accessible and recoverable in the event of theft or computer misuse. Our websites are protected by an SSL certificate.',
    "Encryption technology is in use to protect the integrity of the personal data we process.":
      "Encryption technology is in use to protect the integrity of the personal data we process.",
    Energy: "Energy",
    EngageBay: "EngageBay",
    English: "English",
    "Enjeux DSI": "DSI challenges",
    "Enlever de favoris": "Remove from favourites",
    "Enregistrement de la feuille de temps": "Saving the timesheet",
    "Enregistrement effectu\xE9 avec succ\xE8s":
      "Your data has been saved successfully",
    Enregistrer: "Save",
    "Enregistrer en brouillon": "Save as draft",
    "Enregistrer en favori": "Add to favorites",
    "Enregistrer en favoris": "Add to favorites",
    "Enter your email address": "Enter your email address",
    "Entering a competition through a social media channel such as Facebook or Twitter.":
      "Entering a competition through a social media channel such as Facebook or Twitter.",
    "Entering your details on the Club Freelance website or registering an account with us;":
      "Entering your details on Mindquest website or registering an account with us;",
    "Entering your personal details into a Club Freelance microsite; or":
      "Entering your personal details into Mindquest microsite; or",
    Entreprise: "Company",
    "Entreprise ?": "Employer ?",
    Entreprise_: "Employers",
    Entreprises: "Our clients",
    "Entrer le mot de passe actuel": "Enter current password",
    "Entrer les informations de mon entreprise": "Enter my company information",
    "Entrer mon email": "Enter my email",
    "Entrer un email valid": "Enter a valid email address",
    "Entrer un mot de passe": "Enter your password",
    "Entrer une adresse email": "Enter email address",
    "Entrer votre email": "Enter your email address",
    "Entrer votre mot de passe": "Enter your password",
    Entretien: "Interview",
    Entretiens: "Interviews",
    "Entrez ce m\xEAme mot de passe": "Confirm your new password",
    "Entrez ici vos mots-cl\xE9s IT": "Write your IT keywords here",
    "Entrez le mot de passe actuel": "Enter the current password",
    "Entrez un mot de passe": "Enter your new password",
    "Entrez votre nouvelle adresse email": "Enter your new email address",
    "Environements technique": "Technical environments",
    "Environnement technique": "Technical environment",
    Envoyer: "Send",
    "Envoyer Timesheet": "Send Timesheet",
    Erreur: "Error",
    "Erreur c\xF4t\xE9 de serveur. Essayez plus tard":
      "Server error. Try again later.",
    "Erreur de cr\xE9ation de besoin": "Error creating your need",
    "Erreur de cr\xE9ation de candidature": "Error while creating application",
    "Erreur de cr\xE9ation d\u2019alerte": "Error while creating alert",
    "Erreur non identifi\xE9e": "Unidentified error",
    "Erreur!": "Error!",
    Espagnol: "Spanish",
    "Essayez la d\xE9tection automatique": "Try the auto detect",
    "Et remplir mon profil \xE0 la main": "And fill out your profile",
    "Et trouvez les meilleurs consultants en informatique pour vos projets":
      "to find your next top performer",
    Etablissement: "Establishment",
    "Etape suivante": "Next step",
    "Etre recontact\xE9 par l'un de nos expert en recrutement":
      "Be contacted by one of our recruitment experts",
    "Ever wondered what it is that your colleagues in other areas of IT actually do?":
      "Ever wondered what exactly it is that your colleagues in other areas of IT actually do? Interested in a career change ?",
    "Ever wondered what it is that your colleagues in other areas of IT actually do? Curious about exploring new career paths in tech? Impove you awareness of the industy.":
      "Ever wondered what it is that your colleagues in other areas of IT actually do? Curious about exploring new career paths in tech? Impove you awareness of the industy.",
    "Evolutions des Jobs SAP": "SAP Jobs Evolution",
    "Ex : Architecte Java, Consultant SAP":
      "Ex : Java Architect, SAP Consultant",
    "Ex:Architecte Java, Consultant SAP": "Ex: Java Architect, SAP Consultant",
    Experiences: "Experiences",
    Expert: "Expert",
    "Expert (10+)": "Expert (10+)",
    Expertise: "We know tech",
    "Exporter en CSV": "Export CSV",
    Expérience: "Experience",
    Expériences: "Experiences",
    "Extra information that our clients may tell us about you / Performance information Extra information that you choose to tell us;":
      "Extra information that our clients may tell us about you / Performance information Extra information that you choose to tell us;",
    "Extra information that your referees choose to tell us about you. Please note that the above list of categories of personal data we may collect is not exhaustive.":
      "Extra information that your referees choose to tell us about you. Please note that the above list of categories of personal data we may collect is not exhaustive.",
    "Extra, extra! IT and tech news from the past week, every week. From Cloud and IoT to SAP's ERP and all things Microsoft, these are news stories you should keep in mind.":
      "Extra, extra! IT and tech news from the past week, every week. From Cloud and IoT to SAP's ERP and all things Microsoft, these are news stories you should keep in mind.",
    "FREELANCE ?": "Candidate ?",
    Favoris: "Favourites",
    "Fiches m\xE9tiers": "Fiches m\xE9tiers",
    "Fiches m\xE9tiers Data & Business Intelligence - Mindquest Connect":
      "Data & Business Intelligence job descriptions - Mindquest Connect",
    "Fiches m\xE9tiers Digital - Mindquest Connect":
      "Digital job descriptions - Mindquest Connect",
    "Fiches m\xE9tiers D\xE9veloppement - Mindquest Connect":
      "Fiches m\xE9tiers D\xE9veloppement - Mindquest Connect",
    "Fiches m\xE9tiers ERP & SAP - Mindquest Connect":
      "Fiches m\xE9tiers ERP & SAP - Mindquest Connect",
    "Fiches m\xE9tiers Infrastructure IT - Mindquest Connect":
      "IT Infrastructure job descriptions - Mindquest Connect",
    "Fiches m\xE9tiers Project Management - Mindquest Connect":
      "Project Management job descriptions - Mindquest Connect",
    "Fichier g\xE9n\xE9r\xE9 avec succ\xE8s": "File generated successfully",
    "Fichiers M\xE9tiers": "IT Job Descriptions",
    "Fichiers M\xE9tiers: D\xE9veloppement":
      "IT Job Descriptions: Web Developer",
    "Filtrer par projet": "Filter by project",
    "Filtrer par statut": "Filter by status",
    "Filtres S\xE9lectionn\xE9s :": "Selected Filters :",
    "Filtres Vide": "Empty filters",
    Filtré: "Filtered",
    "Find a blog post from our selection of content, news, how-to's and resources for IT Talent and Decision Makers.":
      "Find a blog post from our selection of content, news, how-to's and resources for IT Talent and Decision Makers.",
    "Find all the latest Mindquest news and our interviews of top IT experts with our Podcast Mission Control Center.":
      "Find all the latest Mindquest news and our interviews of top IT experts with our Podcast Mission Control Center.",
    "Find all the latest Mindquest news and our interviews on top IT experts with our Podcast Mission Control Center.":
      "Find all the latest Mindquest news and our interviews on top IT experts with our Podcast Mission Control Center.",
    "Find all the latest Mindquest news and our job descriptions about the skills, missions and specificities of digital jobs.":
      "Find all the latest Mindquest news and our job descriptions about the skills, missions and specificities of digital jobs.",
    "Find all the latest Mindquest news and our selection of Tech Magazine articles on the top 10 IT experts lists.":
      "Find all the latest Mindquest news and our selection of Tech Magazine articles on the top 10 IT experts lists.",
    "Find all the latest Mindquest news and our selection of Tech Magazine articles with the top 10 IT experts lists.":
      "Find all the latest Mindquest news and our selection of Tech Magazine articles with the top 10 IT experts lists.",
    "Find all the latest Mindquest news and our selection of articles for IT consultants on developing your career on a permanent or freelance basis on our blog Mindquest Connect.":
      "Find all the latest Mindquest news and our selection of articles for IT consultants on developing your career on a permanent or freelance basis on our blog Mindquest Connect.",
    "Find all the latest Mindquest news and our selection of articles for IT decision-makers (Talent strategy, Recruiting an IT consultant, Risks & mistakes to avoid, Legal, IT issues...).":
      "Find all the latest Mindquest news and our selection of articles for IT decision-makers (Talent strategy, Recruiting an IT consultant, Risks & mistakes to avoid, Legal, IT issues...).",
    "Find all the latest Mindquest news and our selection of articles for IT decision-makers on CIO issues on our blog Mindquest Connect.":
      "Find all the latest Mindquest news and our selection of articles for IT decision-makers on CIO issues on our blog Mindquest Connect.",
    "Find all the latest Mindquest news and our selection of articles for IT decision-makers on recruiting IT consultants and talent on our blog Mindquest Connect.":
      "Find all the latest Mindquest news and our selection of articles for IT decision-makers on recruiting IT consultants and talent on our blog Mindquest Connect.",
    "Find all the latest Mindquest news and our selection of articles for IT decision-makers on risks and mistakes to avoid on our blog Mindquest Connect.":
      "Find all the latest Mindquest news and our selection of articles for IT decision-makers on risks and mistakes to avoid on our blog Mindquest Connect.",
    "Find all the latest news about Mindquest and our selection of articles to find out about our news, press releases and testimonials in the About Us section.":
      "Find all the latest news about Mindquest and our selection of articles to find out about our news, press releases and testimonials in the About Us section.",
    "Find all the latest news from Mindquest and our job descriptions about the skills, missions, and specificities of Project Management jobs.":
      "Find all the latest news from Mindquest and our job descriptions about the skills, missions, and specificities of Project Management jobs.",
    "Find all the latest news from Mindquest and our job descriptions about the skills, missions, and specificities of the Data & Business Intelligence professions.":
      "Find all the latest news from Mindquest and our job descriptions about the skills, missions, and specificities of the Data & Business Intelligence professions.",
    "Find all the latest news from Mindquest and our job descriptions on the skills, missions and particularities of ERP & SAP jobs.":
      "Find all the latest news from Mindquest and our job descriptions on the skills, missions and particularities of ERP & SAP jobs.",
    "Find all the latest news from Mindquest and our job descriptions on the skills, missions and particularities of IT Infrastructure jobs.":
      "Find all the latest news from Mindquest and our job descriptions on the skills, missions and particularities of IT Infrastructure jobs.",
    "Find all the latest news from Mindquest and our selection of articles about Cybersecurity on our Tech Magazine.":
      "Find all the latest news from Mindquest and our selection of articles about Cybersecurity on our Tech Magazine.",
    "Find all the latest news from Mindquest and our selection of articles about cybersecurity on our Tech Magazine":
      "Find all the latest news from Mindquest and our selection of articles about cybersecurity on our Tech Magazine",
    "Find all the latest news from Mindquest and our selection of articles about job application advices for IT Consultants.":
      "Find all the latest news from Mindquest and our selection of articles about job application advices for IT Consultants.",
    "Find all the latest news from Mindquest and our selection of articles for IT Consultants about growing your career as permanent & freelance.":
      "Find all the latest news from Mindquest and our selection of articles for IT Consultants about growing your career as permanent & freelance.",
    "Find all the latest news from Mindquest and our selection of articles for IT Consultants about tips for your daily life as a freelancer .":
      "Find all the latest news from Mindquest and our selection of articles for IT Consultants about tips for your daily life as a freelancer .",
    "Find all the latest news from Mindquest and our selection of articles for IT Consultants about tips for your daily life as a freelancer.":
      "Find all the latest news from Mindquest and our selection of articles for IT Consultants about tips for your daily life as a freelancer.",
    "Find all the latest news from Mindquest and our selection of articles for Tech Magazine":
      "Find all the latest news from Mindquest and our selection of articles for Tech Magazine",
    "Find all the latest news from Mindquest and our selection of articles for consultants and freelance IT talent.":
      "Find all the latest news from Mindquest and our selection of articles for consultants and freelance IT talent.",
    "Find all the latest news from Mindquest and our selection of articles for it Consultants":
      "Find all the latest news from Mindquest and our selection of articles for it Consultants",
    "Find all the latest news from Mindquest and our selection of articles for our news":
      "Find all the latest news from Mindquest and our selection of articles for our news",
    "Find all the latest news from Mindquest and our selection of articles on our Tech Magazine.":
      "Find all the latest news from Mindquest and our selection of articles on our Tech Magazine.",
    "Find all the latest news from Mindquest and our selection of press reviews on our Tech Magazine":
      "Find all the latest news from Mindquest and our selection of press reviews on our Tech Magazine",
    "Find all the news from Mindquest about on our Captains's Log":
      "Find all the news from Mindquest about on our Captains's Log",
    "Find all the news from Mindquest and our advice on IT recruitment and Job Hunting.":
      "Find all the news from Mindquest and our advice on IT recruitment and Job Hunting.",
    "Find all the news from Mindquest and our advice on IT recruitment and Job search advice.":
      "Find all the news from Mindquest and our advice on IT recruitment and Job search advice.",
    "Find all the news from Mindquest on our Captains's Log":
      "Find all the news from Mindquest on our Captains's Log",
    "Find freelancer": "Find your candidate",
    "Find the advice and resources you're looking for at the Mission Control Center, your one-stop shop for IT talent management and career advice.":
      "Find the advice and resources you're looking for at the Mission Control Center, your one-stop shop for IT talent management and career advice.",
    "Fiscalit\xE9, imp\xF4ts & retraite": "Fiscalit\xE9, imp\xF4ts & retraite",
    "For Clients, the following data are collected:":
      "For Clients, the following data are collected:",
    "For Talents, the following data are collected:":
      "For Talents, the following data are collected:",
    "For the purpose of the GDPR, the data controller is, Club Freelance Limited, 100 Drummond Road SE 16 4DG London UK.":
      "For the purpose of the GDPR, the data controller is, Club Freelance Limited, 100 Drummond Road SE 16 4DG London UK.",
    Format: "Format",
    "Format d\u2019image invalide. Formats recommand\xE9s : .jpeg .png":
      "Invalid image format. recommended formats: .jpeg .png",
    "Format non valide": "Invalid format",
    "Formation et Certifications": "Trainings and Certifications",
    Français: "French",
    Freelance: "Freelance",
    "Freelance / Salari\xE9": "Freelance / Permanent",
    "Freelance informatique, consultant IT, mission freelance, mission sap, mission d\xE9veloppement, mission data":
      "IT freelance, IT consultant, freelance mission, sap mission, development mission, data mission",
    "Freelance/Contract": "Freelance/Contract",
    FreelanceType: "Freelance",
    Freelances: "Candidates",
    French: "French",
    "F\xE9licitations!": "Congrats!",
    "F\xE9licitations!! Tous les champs obligatoires de votre profil sont remplis, vous pouvez maintenant g\xE9n\xE9rer votre CV Club Freelance.":
      "Congratulation!! All the mandatory fields of your profile have been completed, you can now generate your Club Freelance CV.",
    "F\xE9licitations!! Votre profil est rempli \xE0 {0}%, vous pouvez maintenant g\xE9n\xE9rer votre CV Club Freelance.": function(
      a
    ) {
      return [
        "Congratulations!! Your profile is ",
        a("0"),
        "% complete, you can now generate your Mindquest CV."
      ];
    },
    "F\xE9licitations!! Votre profil est rempli \xE0 {0}%, vous pouvez maintenant g\xE9n\xE9rer votre CV Mindquest.": function(
      a
    ) {
      return [
        "Congratulations!! Your profile is ",
        a("0"),
        "% complete, you can now generate your Mindquest CV."
      ];
    },
    "F\xE9licitations, votre profil est compl\xE9t\xE9 \xE0 100% ! D\xE9couvrez d\xE8s maintenant nos offres recommand\xE9es pour votre profil.":
      "Congratulations, your profile is 100% complete! Discover now our recommended offers for your profile.",
    German: "German",
    "Gestion de projet & Coaching Agile": "Project management & Agile Coaching",
    "Gestion des collaborateurs": "Collaborators Management",
    "Google Adwords": "Google Adwords",
    "Google Analytics": "Google Analytics",
    "Google Chrome": "Google Chrome",
    "Google tag manager": "Google tag manager",
    "Gouvernance Informatique": "IT governance",
    "Grand Groupe (500+)": "Big group (500+)",
    "Grande entreprise (101-150)": "Big company (101-150)",
    "Grande entreprise (101-500)": "Large company (101-500)",
    Greek: "Greek",
    Greque: "Greek",
    "Gr\xE2ce \xE0 notre \xE9quipe d\u2019experts, nous recueillons quotidiennement les insights des entreprises et des candidats sur le march\xE9 des comp\xE9tences IT":
      "Thanks to our team of dedicated experts, we are able to deliver a perfect match betweeen project requirements and IT talent",
    Guide: "Guide",
    "G\xE9nerer votre CV": "Generate your CV",
    "G\xE9n\xE9rez d\xE8s maintenant votre CV Mindquest et diffusez le gr\xE2ce au lien partageable !":
      "Generate your Mindquest resume now and share it with our link!",
    "G\xE9rez vos candidatures et feuilles de temps depuis votre espace perso.":
      "Manage all your applications and job alerts from your dashboard",
    "G\xE9rez vos candidatures et feuilles de temps depuis votre espace personel.":
      "Manage all your applications from your Dashboard",
    "G\xE9rez vos documents administratifs":
      "Manage all your paperwork from on place",
    "G\xE9rez vos missions et vos contrats depuis votre espace client.":
      "Manage your missions and contracts from your customer area.",
    "G\xE9rez vos missions et vos contrats depuis votre espace personel.":
      "Manage all your vacancies and contracts from your dashboard",
    Herefish: "Herefish",
    Heures: "Hours",
    "Heures & Astreintes": "Hours & Penalties",
    "Heures (taux normal)": "Hours (normal rate)",
    "Heures Totales": "Total Hours",
    "Heures normales": "Normal hours",
    "Heures par jour (moyenne)": "Hours per day (average)",
    "Heures sp\xE9ciales": "Special hours",
    "Heures suppl\xE9mentaires": "Overtime",
    "Heures totales": "Total hours",
    Hindi: "Hindi",
    Historique: "History",
    "Horizon 2050": "Horizon 2050",
    "Hors Informatique": "Excluding IT",
    Hotjar: "Hotjar",
    "How can we help?": "Connecting top IT talent and the best industry jobs",
    "How can you access, amend, or take back the personal information that you have given us and what are your rights?":
      "How can you access, amend, or take back the personal information that you have given us and what are your rights?",
    "How do we collect your personal data?":
      "How do we collect your personal data?",
    "How do we store and transfer your data internationally?":
      "How do we store and transfer your data internationally?",
    "How do we use your personal data?": "How do we use your personal data?",
    "How long do we keep your personal data for?":
      "How long do we keep your personal data for?",
    "How to contact us about your personal information":
      "How to contact us about your personal information",
    "However, since some of the Company\u2019s partners and service providers may be located outside the European Union, personal data collected in this way may be transferred to countries which are not members of the European Union, whose legislation on the protection of personal data differs from the European Union. If the recipient country or countries do not ensure a level of data  protection  equivalent  to  the  European  Union, the  company  undertakes  to  take  all appropriate safeguards, either on the basis of an adequacy decision or, in the absence of such a decision,  on  the  basis  of  appropriate  safeguards  such  as  the  standard  contractual  clauses adopted.":
      "However, since some of the Company\u2019s partners and service providers may be located outside the European Union, personal data collected in this way may be transferred to countries which are not members of the European Union, whose legislation on the protection of personal data differs from the European Union. If the recipient country or countries do not ensure a level of data  protection  equivalent  to  the  European  Union, the  company  undertakes  to  take  all appropriate safeguards, either on the basis of an adequacy decision or, in the absence of such a decision,  on  the  basis  of  appropriate  safeguards  such  as  the  standard  contractual  clauses adopted.",
    "However, since some of the Company\u2019s partners and service providers may be located outside the European Union, personal data collected in this way may be transferred to countries which are not members of the European Union, whose legislation on the protection of personal data differs from the European Union. If the recipient country or countries do not ensure a level of data protection equivalent to the European Union, the company undertakes to take all appropriate safeguards, either on the basis of an adequacy decision or, in the absence of such a decision, on the basis of appropriate safeguards such as the standard contractual clauses adopted.":
      "However, since some of the Company\u2019s partners and service providers may be located outside the European Union, personal data collected in this way may be transferred to countries which are not members of the European Union, whose legislation on the protection of personal data differs from the European Union. If the recipient country or countries do not ensure a level of data protection equivalent to the European Union, the company undertakes to take all appropriate safeguards, either on the basis of an adequacy decision or, in the absence of such a decision, on the basis of appropriate safeguards such as the standard contractual clauses adopted.",
    "However, while these measures provide security against unauthorised access, loss, theft or manipulation from third parties, the internet is not a fully secure domain. For this reason, Club Freelance cannot guarantee the security or integrity of any personal information which is transferred from you or to you via email/ the Internet.":
      "However, while these measures provide security against unauthorised access, loss, theft or manipulation from third parties, the internet is not a fully secure domain. For this reason, Club Freelance cannot guarantee the security or integrity of any personal information which is transferred from you or to you via email/ the Internet.",
    "However, you have the possibility to refuse these cookies by setting your browser. In such a case, the site might suffer partial malfunctions.":
      "However, you have the possibility to refuse these cookies by setting your browser. In such a case, the site might suffer partial malfunctions.",
    "H\xE9bergeur du Site Internet": "H\xE9bergeur du Site Internet",
    "I'm looking for Jobs": "I'm looking for Jobs",
    "I'm looking for Talent": "I'm looking for Talent",
    "INFORMATION ON COOKIES": "INFORMATION ON COOKIES",
    "INVALID RECAPTCHA": "INVALID RECAPTCHA",
    "INVERIFIED ACCOUNT": "INVERIFIED ACCOUNT",
    "IP address National Insurance number or country equivalent;":
      "IP address National Insurance number or country equivalent;",
    "IT Consultants": "IT Consultants",
    "IT Deciders articles - Risks and mistakes to avoid - Mindquest Connect":
      "IT Deciders articles - Risks and mistakes to avoid - Mindquest Connect",
    "IT Decision Maker": "IT Decision Maker",
    "IT Decision Maker Articles - Recruiting IT Consultants - Mindquest Connect":
      "IT Decision Maker Articles - Recruiting IT Consultants - Mindquest Connect",
    "IT Decision Makers Articles - CIO Issues - Mindquest Connect":
      "IT Decision Makers Articles - CIO Issues - Mindquest Connect",
    "IT Decision maker": "IT decision maker",
    "IT Decision-makers": "IT Decision-makers",
    "IT Governance": "IT Governance",
    "IT Infrastructure job descriptions - Mindquest Connect":
      "IT Infrastructure job descriptions - Mindquest Connect",
    "IT and tech news of the week, every week. These are stories to keep in mind.":
      "Our weekly selection of technology news. These are the stories to keep in mind.",
    "IT career advice at zero price. Get the contract or job you always wanted.":
      "IT career advice at zero price. Be the professional you\u2019ve always wanted to be",
    "IT career advice at zero price. Valuable resources to help you navigate professional growth and get that contract or job you always wanted.":
      "IT career advice at zero price. Valuable resources to help you navigate professional growth and get that contract or job you always wanted.",
    "IT decision maker": "IT decision maker",
    "IT's Who is Who": "IT's Who is Who",
    "Identification data: surnames, forenames, date and place of birth, copy of your identity document, work permit;":
      "Identification data: surnames, forenames, date and place of birth, copy of your identity document, work permit;",
    "Identifiez des nouveaux talents pour votre entreprise.":
      "Find your next top performers",
    "If  personal  data  of  a  mandatory  nature  are  not  communicated,  Company  will  not  be  able  to reply to your requests, if any.":
      "If  personal  data  of  a  mandatory  nature  are  not  communicated,  Company  will  not  be  able  to reply to your requests, if any.",
    "If a Club Freelance entity or substantially all of its assets are acquired by a third party, in which case personal data held by it about its customers will be one of the transferred assets;":
      "If a Club Freelance entity or substantially all of its assets are acquired by a third party, in which case personal data held by it about its customers will be one of the transferred assets;",
    "If personal data of a mandatory nature are not communicated, Company will not be able to reply to your requests, if any.":
      "If personal data of a mandatory nature are not communicated, Company will not be able to reply to your requests, if any.",
    "If the Company amends this Personal Data Protection Policy, it will publish the new version on its website and update the \u201CLatest update\u201D date at the top of this Personal Data Protection Policy.":
      "If the Company amends this Personal Data Protection Policy, it will publish the new version on its website and update the \u201CLatest update\u201D date at the top of this Personal Data Protection Policy.",
    "If we are under a duty to disclose or share your personal data in order to comply with any legal obligation, or in order to enforce or apply our terms of use and other agreements; or to protect the rights, property, or safety of Club Freelance, our customers, or others. This includes exchanging information with other companies and organisations for the purposes of fraud protection and credit risk reduction.":
      "If we are under a duty to disclose or share your personal data in order to comply with any legal obligation, or in order to enforce or apply our terms of use and other agreements; or to protect the rights, property, or safety of Club Freelance, our customers, or others. This includes exchanging information with other companies and organisations for the purposes of fraud protection and credit risk reduction.",
    "If you believe, for any reason, that your Personal Data has been breached while in Club Freelance\u2019s care, custody or control please email Club Freelance immediately at contact@club-freelance.com.":
      "If you believe, for any reason, that your Personal Data has been breached while in Club Freelance\u2019s care, custody or control please email Club Freelance immediately at contact@club-freelance.com.",
    "If you fail to provide personal data":
      "If you fail to provide personal data",
    "If you have any questions about this privacy notice, including any requests to exercise your legal rights please contact please contact Club Freelance at contact@club-freelance.com or 0203 693 7476 or you can write to us at 100 Drummond Road SE 16 4DG London UK.":
      "If you have any questions about this privacy notice, including any requests to exercise your legal rights please contact please contact Club Freelance at contact@club-freelance.com or 0203 693 7476 or you can write to us at 100 Drummond Road SE 16 4DG London UK.",
    "If you were referred to us through an (Recruitment process Outsourcing) RPO or an Managed Services providers MSP supplier, they may share personal information about you with us.":
      "If you were referred to us through an (Recruitment process Outsourcing) RPO or an Managed Services providers MSP supplier, they may share personal information about you with us.",
    "If you wish, you have the right in certain circumstances to receive your personal data in a structured, commonly used and machine-readable format and have the right to transmit the data to another controller without hindrance from us.":
      "If you wish, you have the right in certain circumstances to receive your personal data in a structured, commonly used and machine-readable format and have the right to transmit the data to another controller without hindrance from us.",
    "If you \u2019like\u2019 our page on Facebook or \u2019follow\u2019 us on Twitter or LinkedIn, we will receive your personal information from those sites; and":
      "If you \u2019like\u2019 our page on Facebook or \u2019follow\u2019 us on Twitter or LinkedIn, we will receive your personal information from those sites; and",
    "Il est possible de supprimer uniquement votre compte ou supprimer toutes vos informations de notre base de donn\xE9es. Cependant, cela nous emp\xEAchera de vous proposer des talents IT \xE0 l\u2019avenir.":
      "You can either delete only your account, or all your information from our database. However, we will not be able to introduce you to IT talent in the future.",
    "Il est possible de supprimer uniquement votre compte ou supprimer toutes vos informations de notre base de donn\xE9es. Cette derni\xE8re action nous emp\xEAchera de vous contacter pour de nouvelles opportunit\xE9s.":
      "You can either delete only your account, or all your information from our database. We will not be able to provide you new opportunities without your data",
    "Il n'y a pas de projets pour les filtrer": "No projects to filter yet",
    "Il n'ya pas de num\xE9ro de bon de commande indiqu\xE9":
      "There is no purchase order number indicated",
    "Il n\u2019y a pas de projets pour les filtrer":
      "There are no projects to filter them",
    "Il n\u2019y a pas de sourcer avec cet Id":
      "There's no sourcer with this Id",
    "Il n\u2019y a pas de talents qualifi\xE9s par nos \xE9quipes pour le moment.":
      "No candidates have been vetted by our team yet",
    "Il n\u2019y a pas de temps enregistr\xE9 ce jour.":
      "There is no time filled this day.",
    "Il n\u2019y a pas des missions disponible a ce moment pour ce sourceur":
      "This Talent Community Specialist does not have any open positions at the moment",
    "Il n\u2019y a pas des missions similaires":
      "There is no similar open positions at the moment",
    "Il n\u2019y a pas des posts pour le moment dans cette section":
      "No posts in this section yet",
    "Il n\u2019y a pas des ressources disponibles pour le moment":
      "There are no resources available at the moment",
    "Il n\u2019y a pas encore de retour": "There is no feedback yet",
    "Il s'agit du dernier num\xE9ro de bon de commande li\xE9 \xE0 votre contrat connu dans notre syst\xE8me. S'il n'y en a pas vous pouvez ignorer ce champ.":
      "This is the last purchase order number linked to your contract known to our system. If there is none you can ignore this field.",
    "Il y'a pas encore des articles pour cette cat\xE9gorie.":
      "There are no articles for this category so far",
    "Ils ont r\xE9alis\xE9s des projets avec nos freelances":
      "These companies trust us for their IT talent needs",
    "Ils ont r\xE9alis\xE9s des projets avec nos freelances.":
      "These companies trust us for their IT talent needs",
    Importer: "Import",
    "Importer mon CV": "Import my resume",
    "Importer mon fichier": "Import job description",
    "Importer votre profil LinkedIn": "Import your LinkedIn profile",
    "Importer votre profil LinkedIn<0><1/><2>Importez les donn\xE9es de votre profil Linkedin depuis le lien public de celui-ci</2><3/></0>":
      "Import your LinkedIn profile <0> <1 /> <2> Import your LinkedIn profile data from its public link </2> <3 /> </0>",
    "Importez les donn\xE9es de votre profil Linkedin depuis le lien public de celui-ci":
      "Import your Linkedin profile data from its public link",
    "Importez un CV au format .docx, . doc ou . pdf. Vos donn\xE9es seront automatiquement extraites pour compl\xE9ter rapidement votre profil (recommand\xE9)":
      "Upload a CV in .docx format,. doc or. pdf. Your data will be automatically extracted to quickly complete your profile (recommended)",
    "Impossible de r\xE9cup\xE9rer vos informations":
      "Unable to retrieve your information",
    "In  addition,  you  have  the  possibility  at  any  time  to  delete or  disable  most  cookies  in  your browser.":
      "In  addition,  you  have  the  possibility  at  any  time  to  delete or  disable  most  cookies  in  your browser.",
    "In  order  to  comply  with  the  legal  and  regulatory  obligations  relating  to  conservation and archiving.":
      "In  order  to  comply  with  the  legal  and  regulatory  obligations  relating  to  conservation and archiving.",
    "In accordance with Article 5 of the General Data Protection Regulation No 2016/679 (GDPR), personal data must be:":
      "In accordance with Article 5 of the General Data Protection Regulation No 2016/679 (GDPR), personal data must be:",
    "In accordance with Article 6 of the GDPR, personal data shall be lawfully processed if such processing meets at least one of the following characteristics:":
      "In accordance with Article 6 of the GDPR, personal data shall be lawfully processed if such processing meets at least one of the following characteristics:",
    "In accordance with the rules on the protection of personal data, and in particular the General Data Protection   Regulation   No   2016/679   (GDPR),   everyone   has   the   right   of   access, rectification, restriction, deletion, where applicable portability of data concerning him or her, and the right to object, possibly subject to legitimate and compelling reasons, to the processing of information and personal data relating to him or her, to be exercised at any time by means of the data amendment request form: <0>https://mindquest.io/contact </0>":
      "In accordance with the rules on the protection of personal data, and in particular the General Data Protection   Regulation   No   2016/679   (GDPR),   everyone   has   the   right   of   access, rectification, restriction, deletion, where applicable portability of data concerning him or her, and the right to object, possibly subject to legitimate and compelling reasons, to the processing of information and personal data relating to him or her, to be exercised at any time by means of the data amendment request form: <0>https://mindquest.io/contact </0>",
    "In accordance with the rules on the protection of personal data, and in particular the General Data Protection Regulation No 2016/679 (GDPR), everyone has the right of access, rectification, restriction, deletion, where applicable portability of data concerning him or her, and the right to object, possibly subject to legitimate and compelling reasons, to the processing of information and personal data relating to him or her, to be exercised at any time by means of the data amendment request form: <0>https://mindquest.io/contact </0>":
      "In accordance with the rules on the protection of personal data, and in particular the General Data Protection Regulation No 2016/679 (GDPR), everyone has the right of access, rectification, restriction, deletion, where applicable portability of data concerning him or her, and the right to object, possibly subject to legitimate and compelling reasons, to the processing of information and personal data relating to him or her, to be exercised at any time by means of the data amendment request form: <0>https://mindquest.io/contact </0>",
    "In addition, you have the possibility at any time to delete or disable most cookies in your browser.":
      "In addition, you have the possibility at any time to delete or disable most cookies in your browser.",
    "In any event, such data shall be collected and processed on the basis of a legal obligation, a legitimate interest of the Company and/or the consent of the individual concerned.":
      "In any event, such data shall be collected and processed on the basis of a legal obligation, a legitimate interest of the Company and/or the consent of the individual concerned.",
    "In general, the Company keeps the personal data of the persons concerned in the territory of the European Union.":
      "In general, the Company keeps the personal data of the persons concerned in the territory of the European Union.",
    "In order to comply with the legal and regulatory obligations relating to conservation and archiving.":
      "In order to comply with the legal and regulatory obligations relating to conservation and archiving.",
    "In order to support our candidates\u2019 career aspirations and our clients\u2019 resourcing needs we require a database of candidate and client personal data containing historical information as well as current resourcing requirements.":
      "In order to support our candidates\u2019 career aspirations and our clients\u2019 resourcing needs we require a database of candidate and client personal data containing historical information as well as current resourcing requirements.",
    "In some circumstances we may anonymise your personal data (so that it can no longer be associated with you) for research or statistical purposes in which case we may use this information indefinitely without further notice to you.":
      "In some circumstances we may anonymise your personal data (so that it can no longer be associated with you) for research or statistical purposes in which case we may use this information indefinitely without further notice to you.",
    "In some circumstances you can ask us to delete your data: see Request erasure below for further information.":
      "In some circumstances you can ask us to delete your data: see Request erasure below for further information.",
    "In the event that we sell or buy any business or assets, in which case we will disclose your personal data to the prospective seller or buyer of such business or assets;":
      "In the event that we sell or buy any business or assets, in which case we will disclose your personal data to the prospective seller or buyer of such business or assets;",
    Indifferent: "Indifferent",
    Indifférent: "Indifferent",
    "Indiquer le ou les projets sur lesquels vous avez travaill\xE9":
      "Indicate the project(s) you have worked on",
    "Indiquer les t\xE2ches que vous avez effectu\xE9es":
      "Indicate the tasks you have completed",
    "Indiquez des informations de contexte comme la phase du projet, la composition de l\u2019\xE9quipe ou l\u2019organisation interne...":
      "Indicate all possible background information such as the phase of the project, the makeup of the team or your company's internal organisation ...",
    Individuel: "Individual",
    "Ind\xE9pendants : tout savoir sur le pr\xE9l\xE8vement forfaitaire lib\xE9ratoire":
      "How to ace an interview for an IT or tech position",
    "Information Security": "Information Security",
    "Informations personnelles": "Personal informations",
    "Informations sur le client": "Informations about the client",
    "Informations sur le signataire": "Signatory information",
    Infra: "IT Infrastructure",
    "Infrastructure & Cloud": "Infrastructure & Cloud",
    "Infrastructure & Cloud computing": "Infrastructure & Cloud computing",
    "Infrastructure IT": "IT Infrastructure",
    "Infrastructure et Cloud": "Infrastructure and Cloud",
    "Inscription - Je suis un Talent": "Sign up - I am a talent",
    "Inscription - Je suis une Entreprise": "Sign up - I am a company",
    "Inscription compl\xE8te": "Complete registration",
    "Inscription en ligne": "Online registration",
    "Inscription express": "Express sign-up",
    "Inscrivez-vous et recevez tous les articles":
      "Subscribe to our weekly newsletter to get the latest articles and technology news",
    "Insights pour professionels IT et entreprises":
      "Actionable insights for professionals and businesses",
    "Ins\xE9rez votre mot de passe": "Enter your password",
    "Intelligence Artificielle": "Intelligence Artificielle",
    "Intelligence artificielle": "Intelligence artificielle",
    "Interesting Reads": "Interesting Reads",
    "Internal cookies: are cookies created and used by the website;":
      "Internal cookies: are cookies created and used by the website;",
    Intitulé: "Job title",
    Introduction: "Introduction",
    Intéressé: "Interested",
    "Int\xE9ress\xE9 par Club Freelance ?":
      "Connecting top IT talent and the best industry jobs",
    "IoT & AI/ML & Blockchain": "Iot / AI / Blockchain",
    "IoT / AI / Blockchain": "IoT / AI / Blockchain",
    "It should be noted that the controller is, in accordance with the applicable legislation, the entity which defines and limits the data to be collected and the purposes of processing.":
      "It should be noted that the controller is, in accordance with the applicable legislation, the entity which defines and limits the data to be collected and the purposes of processing.",
    Italian: "Italian",
    Italien: "Italian",
    "J'accepte": "I agree",
    "J'ai compris": "Understood",
    Japanese: "Japanese",
    Japonais: "Japanese",
    "Je cherche des freelances": "I'm looking for Talent",
    "Je cherche des missions": "I'm looking for Jobs",
    "Je cherche un consultant IT": "I am looking for an IT consultant",
    "Je cherche une mission": "I am looking for a Job",
    "Je confirme n'avoir effectu\xE9 aucun jour pour ce mois":
      "I confirm I did not work any day during this month",
    "Je m\u2019abonne": "Subscribe",
    "Je poste un besoin": "Post a vacancy",
    "Je pr\xE9f\xE8re \xEAtre contact\xE9 par": "Preferred contact method",
    "Je pr\xE9f\xE8re \xEAtre contact\xE9 par :":
      "I prefer to be contacted by :",
    "Je pr\xE9f\xE8re \xEAtre contact\xE9 par:": "I prefer to be contacted by:",
    "Je recherche une opportunit\xE9": "I am searching for a job",
    "Je recherche une opportunit\xE9 dans l'IT":
      "I am looking for an opportunity in IT",
    "Je recrute un talent IT": "I am an IT recruiter",
    "Je recrute un talent dans l'IT": "I am an IT recruiter",
    "Je souhaite recevoir les actualit\xE9s, les nouveaux produits et services de Mindquest Talent":
      "I would like to receive news, new products and services from Mindquest Talent",
    "Je souhaite recevoir les derni\xE8res informations ainsi que les avantages communaut\xE9s de Mindquest Talent (webinaires, \xE9v\xE9nements, blog, r\xE9ductions, etc.)":
      "I would like to receive the latest information and community benefits from Mindquest Talent (webinars, events, blog, discounts, etc.)",
    "Je souhaite recevoir les derni\xE8res informations ainsi que les avantages communnaut\xE9s de Mindquest Talent (webinaires, \xE9v\xE9nements, blog, r\xE9ductions, etc.)":
      "I would like to receive the latest information and community benefits from Mindquest Talent (webinars, events, blog, discounts, etc.)",
    "Je souhaite recevoir les derni\xE8res informations ainsi que les avantages de Mindquest (webinaires, \xE9v\xE9nements, nouvelles fonctionnalit\xE9s, offres exclusives communaut\xE9s, etc.)":
      "I would like to receive the latest news and benefits from Mindquest (webinars, events, new features, exclusive community offers, etc.)",
    "Je souhaite recevoir les derni\xE8res informations de Mindquest (services, fonctionnalit\xE9s, webinaires, \xE9v\xE9nements, etc.)":
      "I would like to receive the latest information from Mindquest (services, features, webinars, events, etc.)",
    "Je souhaite recevoir les derni\xE8res informations, offres ainsi que les avantages de Mindquest Talent (webinaires, \xE9v\xE9nements, blog, r\xE9ductions, etc.)":
      "I would like to receive the latest information, offers as well as the benefits of Mindquest Talent (webinars, events, blog, discounts, etc.)",
    "Je suis ESN": "Are you a digital services company?",
    "Je suis tr\xE8s satisfaite de Club Freelance. Retour rapide sur la mission suite \xE0 un entretien, contrat envoy\xE9 dans la foul\xE9e et aucun probl\xE8me de facturation. L\u2019\xE9quipe est tr\xE8s disponible et la communication avec l\u2019ensemble des interlocuteurs est facile et agr\xE9able":
      "I am very satisfied with Club Freelance. Quick feedback on the mission following an interview, contract sent immediately and no invoicing problem. The team is very available and communication with all the interlocutors is easy and pleasant.",
    "Je suis tr\xE8s satisfaite de Mindquest. Retour rapide sur la mission suite \xE0 un entretien, contrat envoy\xE9 dans la foul\xE9e et aucun probl\xE8me de facturation. L\u2019\xE9quipe est tr\xE8s disponible et la communication avec l\u2019ensemble des interlocuteurs est facile et agr\xE9able":
      "I am really satisfied with Mindquest's services. Fast feedback after interviews, with the contract sent shortly after and no payment issues. The team is always available and working with them is easy and pleasant.",
    "Je suis un Talent": "I am a Talent",
    "Je suis un d\xE9cideur IT": "I am an IT decision maker",
    "Je suis un freelance": "Candidates",
    "Je suis un talent": "I am a talent",
    "Je suis une Entreprise": "I am a company",
    "Je suis une entreprise": "Employers",
    "Job Descriptions": "Job Descriptions",
    "Job Search advice": "Job Search advice",
    "Job application advice": "Job application advice",
    "Job application advice -  Mindquest Connect":
      "Job application advice -  Mindquest Connect",
    "Job search advice -  Mindquest Connect":
      "Job search advice -  Mindquest Connect",
    "Jour f\xE9ri\xE9 s\xE9lectionn\xE9": "Bank holiday selected",
    "Jours supprim\xE9s": "Deleted days",
    Junior: "Junior",
    "Junior (0 - 3)": "Junior (0 - 3)",
    "Junior (0-3)": "Junior (0-3)",
    "Jusqu\u2019\xE0 10 mots-cl\xE9s importants peuvent \xEAtre s\xE9lectionn\xE9s.":
      "Up to 10 important keywords can be selected.",
    "Jusqu\u2019\xE0 10 mots-cl\xE9s indispensables peuvent \xEAtre s\xE9lectionn\xE9s.":
      "Add up to 10 Mandatory Keywords.",
    "Jusqu\u2019\xE0 10 mots-cl\xE9s secondaires peuvent \xEAtre s\xE9lectionn\xE9s.":
      "Add up to 10 Desirable Keywords.",
    "J\u2019accepte <0>la politique de confidentialit\xE9s </0> Club Freelance":
      "I accept Mindquest's <0>Data Privacy policy</0>",
    "J\u2019accepte <0>la politique de confidentialit\xE9s </0> Mindquest ainsi que <1>les conditions g\xE9n\xE9rales d\u2019utilisation </1> .":
      "I agree to Mindquest's <0>Data Privacy policy </0> and <1>Terms and Conditions of use </1> .",
    "J\u2019accepte la": "I accept",
    "J\u2019occupe actuellement ce poste": "I currently hold this position",
    "Kept for no longer than is necessary for the purposes for which the data are processed;":
      "Kept for no longer than is necessary for the purposes for which the data are processed;",
    Kiosk: "Kiosk",
    "L'action que vous tentez d'appliquer exige que vous rentriez votre mot de passe.":
      "L'action que vous tentez d'appliquer exige que vous rentriez votre mot de passe.",
    "La  Plateforme  est  accessible  gratuitement  en  tout  lieu  et  en  tout  temps  dans  les  conditions d\xE9crites ci-avant \xE0 tout Utilisateur ayant un acc\xE8s \xE0 un poste informatique permettant l\u2019acc\xE8s \xE0 la Plateforme.":
      "The Platform shall be accessible free of charge at any place and at any time under the conditions described above to any User with access to a computer station allowing access to the Platform.",
    "La  Plateforme  offre  aux  Talents  la  possibilit\xE9  de  r\xE9pondre  aux  offres  d\u2019emploi  et/ou  aux missions propos\xE9es par les Clients.":
      "The Platform offers Talents the opportunity to apply to job vacancies and/or assignments posted by Clients.",
    "La  Plateforme  peut  occasionnellement  contenir  des  liens  vers  les  sites  Internet  de  nos partenaires ou de soci\xE9t\xE9s tierces.":
      "The Platform may occasionally contain links to the websites of our partners or third companies.",
    "La  Soci\xE9t\xE9  garantit  la  mise  \xE0  disposition  de  la  Plateforme  dans  des  conditions  d\u2019utilisation normales.":
      "The Company cannot guarantee that the Platform will be fault-free.",
    "La  Soci\xE9t\xE9  n\u2019a  pas  souscrit  de  police  d\u2019assurance  concernant  les  activit\xE9s  des Utilisateurs utilisant la Plateforme et ce, dans le cadre de leurs relations avec les Clients.":
      "Company  did  not  take  out  an  insurance  policy  in  respect  of  the  activities  of  Users  using  the Platform, in the context of their relations with the Clients before.",
    "La  Soci\xE9t\xE9  se  r\xE9serve  la  possibilit\xE9  de  modifier  tout  ou  partie  des  pr\xE9sentes. La  Soci\xE9t\xE9 informera ainsi les Utilisateurs par la mise en ligne d\u2019une version mise \xE0 jour des pr\xE9sentes.":
      "Company  reserves  the  right  to  modify  all  or  part  of  these  Terms  of  Use.  The  Company  will inform Users by uploading an updated version of these documents.",
    "La  Soci\xE9t\xE9  se  r\xE9serve  le  droit  de  permettre  aux  Clients  d\u2019effectuer  des  commentaires  et  une \xE9valuation concernant  les  Consultants.  Cette  \xE9valuation  est  uniquement  r\xE9alis\xE9e  \xE0  des  fins internes et priv\xE9es \xE0 la Soci\xE9t\xE9.":
      "Company  may  allow  Clients  to  comment  and  evaluate  Talents  after  an  Assignment.   This assessment is carried out only for internal and private purposes within the Company.",
    "La Plateforme est accessible gratuitement en tout lieu et en tout temps dans les conditions d\xE9crites ci-avant \xE0 tout Utilisateur ayant un acc\xE8s \xE0 un poste informatique permettant l\u2019acc\xE8s \xE0 la Plateforme.":
      "The Platform shall be accessible free of charge at any place and at any time under the conditions described above to any User with access to a computer station allowing access to the Platform. ",
    "La Plateforme est une solution informatique accessible en ligne depuis tout poste informatique ou t\xE9l\xE9phone pr\xE9alablement configur\xE9 et connect\xE9 au r\xE9seau Internet":
      "The Platform is an IT solution accessible online from any computer or telephone set previously configured and connected to the Internet network.",
    "La Plateforme n\u2019est accessible qu\u2019aux Utilisateurs s\u2019\xE9tant pr\xE9alablement inscrits et ayant d\xE9fini un identifiant, un mot de passe personnel et ayant valid\xE9 leur inscription.":
      "The  Platform  is  only  accessible  to  Users  who  have  previously  registered  and  have  created  a personal login and password and have validated their registration.",
    "La Plateforme offre aux Talents la possibilit\xE9 de r\xE9pondre aux offres d\u2019emploi et/ou aux missions propos\xE9es par les Clients.":
      "The Platform offers Talents the opportunity to apply to job vacancies and/or assignments posted by Clients.",
    "La Plateforme offre \xE9galement aux Clients la possibilit\xE9 de publier des offres, de proposer des missions et de consulter les profils des Talents (profils ayant \xE9t\xE9 pseudonymis\xE9es au pr\xE9alable par la Soci\xE9t\xE9). Si un Client est int\xE9ress\xE9 par le profil d\u2019un Talent, il en informe la Soci\xE9t\xE9 qui organisera la mise en la relation, notamment par le biais d\u2019un entretien.":
      "The Platform also offers the possibility for Clients to post vacancies, Assignments and consult Talent profiles. If a Client is interested in a Talent, it shall inform Company which will organise the connection, in particular by means of an interview.",
    "La Plateforme peut occasionnellement contenir des liens vers les sites Internet de nos partenaires ou de soci\xE9t\xE9s tierces.":
      "The Platform may occasionally contain links to the websites of our partners or third companies.",
    "La Soci\xE9t\xE9 garantit la mise \xE0 disposition de la Plateforme dans des conditions d\u2019utilisation normales.":
      "The Company cannot guarantee that the Platform will be fault-free.",
    "La Soci\xE9t\xE9 invite tout Utilisateur qui souhaite proposer ses services par le biais de la Plateforme \xE0  recourir  aux  services  d\u2019assurance  d\u2019une  compagnie  d\u2019assurance et  y  souscrire  un  contrat permettre de garantir sa responsabilit\xE9 civile professionnelle.":
      "The Company invites any User who wishes to offer his services through the Platform to use the insurance services of an insurance company and to take out a contract there to guarantee his professional liability.",
    "La Soci\xE9t\xE9 invite tout Utilisateur qui souhaite proposer ses services par le biais de la Plateforme \xE0 recourir aux services d\u2019assurance d\u2019une compagnie d\u2019assurance et y souscrire un contrat permettre de garantir sa responsabilit\xE9 civile professionnelle.":
      "The Company invites any User who wishes to offer his services through the Platform to use the insurance services of an insurance company and to take out a contract there to guarantee his professional liability.",
    "La Soci\xE9t\xE9 met \xE0 disposition une plateforme de mise en relation entre candidats \xE0 un emploi ou une mission et clients/donneurs d\u2019ordres (la \xAB <0>Plateforme</0> \xBB).":
      "The Company provides a platform for matching freelancers or job applicants with its clients. (the \u201C  <0>Platform</0>\u201D).",
    "La Soci\xE9t\xE9 n\u2019a pas souscrit de police d\u2019assurance concernant les activit\xE9s des Utilisateurs utilisant la Plateforme et ce, dans le cadre de leurs relations avec les Clients.":
      "Company  did  not  take  out  an  insurance  policy  in  respect  of  the  activities  of  Users  using  the Platform, in the context of their relations with the Clients before.",
    "La Soci\xE9t\xE9 se r\xE9serve la possibilit\xE9 de modifier tout ou partie des pr\xE9sentes. La Soci\xE9t\xE9 informera ainsi les Utilisateurs par la mise en ligne d\u2019une version mise \xE0 jour des pr\xE9sentes.":
      "Company  reserves  the  right  to  modify  all  or  part  of  these  Terms  of  Use.  The  Company  will inform Users by uploading an updated version of these documents.",
    "La Soci\xE9t\xE9 se r\xE9serve le droit de permettre aux Clients d\u2019effectuer des commentaires et une \xE9valuation concernant les Consultants. Cette \xE9valuation est uniquement r\xE9alis\xE9e \xE0 des fins internes et priv\xE9es \xE0 la Soci\xE9t\xE9.":
      "Company  may  allow  Clients  to  comment  and  evaluate  Talents  after  an  Assignment.   This assessment is carried out only for internal and private purposes within the Company.",
    "La Soci\xE9t\xE9 se r\xE9serve \xE9galement le droit de suspendre tout acc\xE8s \xE0 la Plateforme.":
      "Company also reserves the right to suspend access to the Platform.",
    "La date de d\xE9but doit \xEAtre toujours ant\xE9rieur \xE0 la date de fin":
      "Start date must always be prior to end date",
    "La date de fin doit \xEAtre toujours ult\xE9rieur \xE0 la date de d\xE9but":
      "The end date must always be later than the start date",
    "La garantie de paiement \xE0":
      "30-day payment guarantee for all contract jobs",
    "La garantie de paiement \xE0 30 jours":
      "30-day payment guarantee for all contract jobs",
    "La liste des mots non pris en compte":
      "The list of words not taken into account",
    "La localisation": "Location",
    "La mission se situe \xE0 {0} de cette adresse en {1} selon Google Maps": function(
      a
    ) {
      return [
        "The mission is at ",
        a("0"),
        " from this address in ",
        a("1"),
        " according to Google Maps"
      ];
    },
    "La strat\xE9gie des talents": "IT talent strategy",
    "La taille de votre fichier d\xE9passe": "Your file size exceeds",
    "La taille du fichier est trop petite, veuillez s\xE9lectionner un fichier de taille sup\xE9rieur \xE0":
      "The file size is too small, please select a file size larger than",
    "Laissez nous un message, notre \xE9quipe vous recontactera dans les plus brefs d\xE9lais.":
      "Tell us what we can do for you",
    "Lancement de Mindquest": "Mindquest launches",
    "Lancer le matching": "Start matching",
    "Languages;": "Languages;",
    "Langue du compte (Par d\xE9fault)": "Account language (Default)",
    "Langues Parl\xE9es": "Spoken languages",
    "Langues parl\xE9es": "Spoken languages",
    "Le Timesheet a \xE9t\xE9 enregistr\xE9 avec succ\xE8s en tant que brouillon.":
      "The Timesheet has been successfully saved as a draft.",
    "Le Timesheet de {0} {year} du contrat num\xE9ro: {placementId} a \xE9t\xE9 rejet\xE9 par le client": function(
      a
    ) {
      return [
        "The Timesheet of ",
        a("0"),
        " ",
        a("year"),
        " of contract number: ",
        a("placementId"),
        " has been rejected by the client"
      ];
    },
    "Le Timesheet de {0} {year} du contrat num\xE9ro: {placementId} a \xE9t\xE9 valid\xE9 par le client": function(
      a
    ) {
      return [
        "The Timesheet of ",
        a("0"),
        " ",
        a("year"),
        " of contract number: ",
        a("placementId"),
        " has been validated by the client"
      ];
    },
    "Le Timesheet de {0} {year} du contrat num\xE9ro: {placementId} est ouvert": function(
      a
    ) {
      return [
        "The Timesheet of ",
        a("0"),
        " ",
        a("year"),
        " of contract number: ",
        a("placementId"),
        " is open"
      ];
    },
    "Le Timesheet du mois de {0} {year} pour le contrat {placementId} attend votre validation": function(
      a
    ) {
      return [
        "The Timesheet of the month ",
        a("0"),
        " ",
        a("year"),
        " for the contract ",
        a("placementId"),
        " is waiting for your validation"
      ];
    },
    "Le Timesheet du mois de {month} {year} pour le contrat {placementID} attend votre validation": function(
      a
    ) {
      return [
        "The Timesheet of the month ",
        a("month"),
        " ",
        a("year"),
        " of the contract ",
        a("placementID"),
        " is awaiting your validation"
      ];
    },
    "Le Timesheet du mois de {m}/{y} pour le contrat {pID} attend votre validation": function(
      a
    ) {
      return [
        "The Timesheet of ",
        a("m"),
        "/",
        a("y"),
        " for the contract ",
        a("pID"),
        " is waiting for your approval"
      ];
    },
    "Le Timesheet {month}/{year} de contrat numero: {placementID} est ouvert au dashboard de votre consultant": function(
      a
    ) {
      return [
        "The Timesheet ",
        a("month"),
        "/",
        a("year"),
        " for the contract number: ",
        a("placementID"),
        " is opened on your candidate\u2019s dashboard"
      ];
    },
    "Le Timesheet {m}/{y} de contrat numero: {pID} est ouvert au dashboard de votre consultant": function(
      a
    ) {
      return [
        "The Timesheet of ",
        a("m"),
        "/",
        a("y"),
        " for the contract ",
        a("pID"),
        " has been opened on the consultant dashboard"
      ];
    },
    "Le blog": "Resources",
    "Le budget / package": "Budget / package",
    "Le client": "The customer",
    "Le client Mindquest a acc\xE8s \xE0 vos th\xE8mes":
      "Mindquest client has access to your themes",
    "Le client Mindquest y a acc\xE8s": "The Mindquest client has access",
    "Le client recevra votre Timesheet ce mois-ci":
      "The client will receive your Timesheet this month",
    "Le consultant IT recevra vos commentaires.":
      "The IT consultant will receive your comments.",
    "Le consultant IT sera inform\xE9.": "The IT consultant will be informed.",
    "Le consultant sera qualifi\xE9 par notre \xE9quipe de sourceurs.":
      "The consultant will be qualified by our sourcing team.",
    "Le consultant sera qualifi\xE9 par notre \xE9quipe de sourceurs. Nous vous contacteront au plus vite afin d'\xE9changer sur votre besoin. Merci.":
      "The consultant will be qualified by our team of sourcers. We will contact you as soon as possible to discuss your needs. Thank you.",
    "Le contexte du besoin": "Context for this request",
    "Le mois de {0} {year} est termin\xE9, n'oubliez pas d'envoyer votre Timesheet": function(
      a
    ) {
      return [
        "The month ",
        a("0"),
        " ",
        a("year"),
        " is over, don't forget to send your Timesheet"
      ];
    },
    "Le nombre d'heures doit \xEAtre entre 0 et 24":
      "The number of hours must be between 0 and 24",
    "Le nombre d'heurs doit etre entre 0 et 24":
      "The number of hours must be between 0 and 24",
    "Le nombre de CV envoy\xE9s par nos responsables de comptes \xE0 vos \xE9quipes":
      "The number of CVs sent by our account managers to your teams",
    "Le nombre de CV envoy\xE9s par nos responsables de comptes \xE0 vos \xE9quipes sur la p\xE9riode s\xE9lection\xE9e":
      "The total number of CVs our Key Account Manager sent to your team",
    "Le nombre de candidats envoy\xE9s par nos \xE9quipes, que vous avez recrut\xE9":
      "The number of candidates sent by our teams whom you have recruited",
    "Le nombre de candidats envoy\xE9s par nos \xE9quipes, que vous avez recrut\xE9 sur la p\xE9riode s\xE9lectionn\xE9e":
      "The total number of candidates you hired in the selected time period",
    "Le nombre de candidats pre-s\xE9lectionn\xE9s par nos Talent Community Specialist pour r\xE9pondre \xE0 cet offres":
      "The number of candidates pre-selected by our Talent Community Specialists to respond to this offer",
    "Le nombre de candidats pre-s\xE9lectionn\xE9s par nos Talent Community Specialist pour r\xE9pondre \xE0 vos offres, sur la p\xE9riode s\xE9lection\xE9e":
      "The total number of candidates our Talent Community Specialist shortlisted for your positions for the selected time period",
    "Le nombre de minutes doit \xEAtre compris entre 0 et 59":
      "The number of minutes must be between 0 and 59",
    "Le nombre d\u2019appels fait par nos Talent Community Specialist pour r\xE9pondre \xE0 cet offres":
      "The number of calls made by our Talent Community Specialists to respond to this offer",
    "Le nombre d\u2019entretiens organis\xE9es avec vos \xE9quipes et les candidats envoy\xE9s":
      "The number of interviews organised with your teams and candidates",
    "Le nombre d\u2019entretiens organis\xE9es avec vos \xE9quipes et les candidats envoy\xE9s, sur la p\xE9riode s\xE9lectionn\xE9e":
      "The number of interviews organized with your teams and the candidates sent, over the selected period",
    "Le nombre limite de th\xE8mes cr\xE9es a \xE9t\xE9 atteint.":
      "The limit number of themes created has been reached.",
    "Le portage salariale": "Wage portage ",
    "Le profil recherch\xE9 (comp\xE9tences, s\xE9niorit\xE9, certifications...)":
      "Required profile",
    "Le second signataire est en cours d\u2019ajout, il sera visible une fois la synchronisation effectu\xE9e":
      "The secondary signatory is being added, it will be visible once synchronization has been completed",
    "Le serveur ne r\xE9pond pas":
      "It seems that our server is taking a break...",
    "Le service du Club Freelance est rapide et efficace. Les membres de l\u2019\xE9quipe savent ce qu\u2019ils recherchent avec leurs partenaires: aucun contact n\u2019est destin\xE9 \xE0 cr\xE9er une base de donn\xE9es de CV mais \xE0 proposer des emplois int\xE9ressants. Je recommande fortement.":
      "Club Freelance's service is fast and efficient. Team members know what they are looking for with their partners: no contact is intended to create a CV database but to offer interesting jobs. I highly recommend.",
    "Le service du Mindquest est rapide et efficace. Les membres de l\u2019\xE9quipe savent ce qu\u2019ils recherchent avec leurs partenaires: aucun contact n\u2019est destin\xE9 \xE0 cr\xE9er une base de donn\xE9es de CV mais \xE0 proposer des emplois int\xE9ressants. Je recommande fortement.":
      "Mindquest's service is fast and efficient. The team knows what they are doing and they waste no one's time. They don't call you to build a database of CVs, but to actually offer you interesting jobs. I strongly recommend working with them.",
    "Le talent id\xE9al pour r\xE9soudre <0>les d\xE9fis IT</0>. <1/>Nous connectons <2>les meilleurs experts</2> aux <3>meilleures offres </3>pour un match <4>parfait</4> entre entreprises et talents informatiques.":
      "The right talent to solve IT challenges. We connect the best experts with the best offers for a perfect match between business and IT talent.",
    "Le talent id\xE9al pour r\xE9soudre les d\xE9fis IT.":
      "The right talent to solve IT challenges.",
    "Le talent y a acc\xE8s": "Talent has access",
    "Le type de contrat (CDI /Freelance)": "Contract type (Permanent/Contract)",
    "Leaving a hard copy CV at a Club Freelance recruitment event, job fair or office;":
      "Leaving a hard copy CV at a Mindquest recruitment event, job fair or office;",
    "Les  Utilisateurs  reconnaissent  et  acceptent  que  le  respect  de  cette  obligation constitue  une condition  essentielle  sans  laquelle  la  Soci\xE9t\xE9  ne  saurait  accepter de  mettre  \xE0  disposition  des Utilisateurs la Plateforme.":
      "Users  recognise  and  accept  that  compliance  with  this  obligation  is  an  essential  condition without which the Company would not agree to make the Platform available to Users.",
    "Les  Utilisateurs  sont  inform\xE9s  que  toute  utilisation  de  la  Plateforme et  de  tout  service  qui pourrait y \xEAtre associ\xE9 contraire aux pr\xE9sentes ainsi qu\u2019\xE0 toute r\xE9glementation applicable pourra se traduire par une r\xE9siliation des pr\xE9sentes et ce, sans pr\xE9avis.":
      "Users are hereby informed that any use of the Platform and any service which may be associated with it contrary to these provisions and any applicable rules may result in these being terminated without prior notice.",
    "Les  pr\xE9sentes  conditions  g\xE9n\xE9rales  d'utilisation  (\xAB <0>CGU</0> \xBB)  ont  pour  objet  d\u2019encadrer les modalit\xE9s de mise \xE0 disposition et d\u2019acc\xE8s de la Plateforme par les candidats \xE0 un emploi ou une  mission  afin  d\u2019entrer  en  relation  avec  toute  personne  morale  ou  physique ayant  cr\xE9\xE9  un compte et pouvant proposer une offre d\u2019emploi ou une mission.":
      "The purpose of these Terms of Use to govern the use of the Platform by a candidate looking for a  permanent  job  or  assignment,  or  a  person  acting  on  behalf  of  his  company  searching  for candidates.",
    "Les CGU doivent \xEAtre accept\xE9es par tout Utilisateur souhaitant acc\xE9der \xE0 la Plateforme. Elles constituent le contrat entre la Soci\xE9t\xE9 et l\u2019Utilisateur. L\u2019acc\xE8s \xE0 la Plateforme par l\u2019Utilisateur signifie son acceptation des pr\xE9sentes conditions g\xE9n\xE9rales d\u2019utilisation.":
      "Terms of Use must be accepted by any person wishing to access the Platform. They constitute the contract between the Company and the User. Access to the Platform by the User implies acceptance of these Termes of Use. ",
    "Les Utilisateurs reconnaissent et acceptent que le respect de cette obligation constitue une condition essentielle sans laquelle la Soci\xE9t\xE9 ne saurait accepter de mettre \xE0 disposition des Utilisateurs la Plateforme.":
      "Users  recognise  and  accept  that  compliance  with  this  obligation  is  an  essential  condition without which the Company would not agree to make the Platform available to Users.",
    "Les Utilisateurs reconnaissent et acceptent qu\u2019en cas de non-respect des CGU, leur acc\xE8s \xE0 la Plateforme  pourra  \xEAtre  suspendu et/ou  d\xE9finitivement  supprim\xE9  sans  pr\xE9avis  ni  indemnit\xE9  et sans pr\xE9judice de tout dommage-int\xE9r\xEAt que la Soci\xE9t\xE9 serait en droit de demander.":
      "Users shall recognise and accept that in the event of non-compliance with these Terms of Use, their access to the Platform may be suspended and/or permanently withdrawn without notice or compensation and without prejudice to any damages which the Company is entitled to claim.",
    "Les Utilisateurs reconnaissent et acceptent qu\u2019en cas de non-respect des CGU, leur acc\xE8s \xE0 la Plateforme pourra \xEAtre suspendu et/ou d\xE9finitivement supprim\xE9 sans pr\xE9avis ni indemnit\xE9 et sans pr\xE9judice de tout dommage-int\xE9r\xEAt que la Soci\xE9t\xE9 serait en droit de demander.":
      "Users shall recognise and accept that in the event of non-compliance with these Terms of Use, their access to the Platform may be suspended and/or permanently withdrawn without notice or compensation and without prejudice to any damages which the Company is entitled to claim.",
    "Les Utilisateurs reconnaissent et acceptent qu\u2019ils ne pourront en aucun cas \xEAtre indemnis\xE9s ou obtenir r\xE9paration pour quelque motif que ce soit concernant tout propos ou contenu \xE9chang\xE9s sur la Plateforme qu\u2019ils en soient \xE0 l\u2019origine ou les destinataires.":
      "Users  recognise  and  accept  that  under  no  circumstances  may  they  be  compensated  for  any reason whatsoever in respect of any comments or content exchanged on the Platform, whether originating or addressed.",
    "Les Utilisateurs sont inform\xE9s que toute utilisation de la Plateforme et de tout service qui pourrait y \xEAtre associ\xE9 contraire aux pr\xE9sentes ainsi qu\u2019\xE0 toute r\xE9glementation applicable pourra se traduire par une r\xE9siliation des pr\xE9sentes et ce, sans pr\xE9avis.":
      "Users are hereby informed that any use of the Platform and any service which may be associated with it contrary to these provisions and any applicable rules may result in these being terminated without prior notice.",
    "Les Utilisateurs s\u2019engagent \xE0 faire une utilisation de la Plateforme conforme \xE0 son objet et aux pr\xE9sentes conditions g\xE9n\xE9rales d\u2019utilisation.":
      "Users  undertake  to  use  the  Platform  in  accordance  with  its  purpose  and  with  these  Terms  of Use.",
    "Les acteurs IT parlent de nous": "Tech & IT experts talk about us",
    "Les collaborateurs": "Collaborators",
    "Les dates de d\xE9marrages et de fin contrat":
      "The start and end dates of the job",
    "Les derni\xE8res nouvelles dans le secteur de l'IT et de la Tech":
      "Connect with the latest news in the IT and tech sector",
    "Les diff\xE9rents litiges entre Utilisateurs devront se r\xE9gler entre eux hors la pr\xE9sence de la Soci\xE9t\xE9 et sans utilisation de la Plateforme.":
      "Disputes between Users must be settled between themselves without the presence of Company and without the use of the Platform.",
    "Les jours f\xE9ri\xE9s et les jours du Weekend doivent etre s\xE9l\xE9ctionn\xE9 un par un et doivent etre de type suppl\xE9mentaire ou astreinte.":
      "Holidays and weekend days must be selected one by one and must be of the overtime or on-call type.",
    "Les jours f\xE9ri\xE9s et les jours du Weekend doivent \xEAtre s\xE9lectionn\xE9s un par un et doivent \xEAtre de type suppl\xE9mentaire ou astreinte.":
      "Holidays and Weekend days must be selected one by one and must be of type extra or on-call.",
    "Les mission \xE0 r\xE9aliser": "Job objective",
    "Les modifications que vous avez apport\xE9es ne seront peut-\xEAtre pas enregistr\xE9es.":
      "The changes you have made may not be saved.",
    "Les pr\xE9sentes CGU sont applicables \xE0 compter de leur acceptation par les Utilisateurs et pendant toute la dur\xE9e d\u2019utilisation de la Plateforme par l\u2019Utilisateur.":
      "These  Terms  of  Use  shall  apply  as  from  User  accepts  them  or  starts  using  the  Platform,  and shall remain in full force and effect until User\u2019s account is deleted for whatever reason.",
    "Les pr\xE9sentes Conditions G\xE9n\xE9rales d\u2019Utilisation (ci-apr\xE8s les \xAB <0>CGU</0> \xBB) sont propos\xE9es par la soci\xE9t\xE9<1> CLUB FREELANCE LIMITED</1>, soci\xE9t\xE9 enregistr\xE9e en Angleterre et au Pays de Galles sous le num\xE9ro 9050953, dont le si\xE8ge social est Biscuit Factory, 100 Drummond Rd, London SE16 4DG, Royaume-Uni, son adresse de courrier \xE9lectronique est <2>contact@mindquest.io</2> et son num\xE9ro individuel d\u2019identification TVA britannique GB19 4905473, (ci-apr\xE8s la \xAB <3>Soci\xE9t\xE9</3> \xBB).":
      "These Terms and conditions of use (\xAB <0>Terms of Use</0> \xBB) are proposed by <1> CLUB FREELANCE LIMITED</1>,  a  company  registered  in  England  and  Wales  under  number  9050953,  with registered office at Biscuit Factory, 100 Drummond Rd, London SE16 4DG, United Kingdom (Company),  its  e-mail  address  is  <2>contact@mindquest.io</2>  and  its  VAT  identification  number  is GB19 4905473.",
    "Les pr\xE9sentes Conditions G\xE9n\xE9rales d\u2019Utilisation (ci-apr\xE8s les \xAB <0>CGU</0> \xBB) sont propos\xE9es par la soci\xE9t\xE9<1>CLUB  FREELANCE  LIMITED</1>,  soci\xE9t\xE9  enregistr\xE9e  en  Angleterre  et  au  Pays  de Galles sous le num\xE9ro 9050953, dont le si\xE8ge social est Biscuit Factory, 100 Drummond Rd, London    SE16    4DG,    Royaume-Uni,    son    adresse de    courrier    \xE9lectronique    est  <2>contact@mindquest.io</2>  et  son  num\xE9ro  individuel  d\u2019identification  TVA britannique  GB19 4905473, (ci-apr\xE8s la \xAB  <3>Soci\xE9t\xE9</3> \xBB).":
      "These Terms and conditions of use (\u2018<0>Terms of Use</0> \u2019) are proposed by <1>CLUB FREELANCE LIMITED</1>,  a  company  registered  in  England  and  Wales  under  number  9050953,  with registered office at Biscuit Factory, 100 Drummond Rd, London SE16 4DG, United Kingdom (Company),  its  e-mail  address  is  contact@mindquest.io  and  its  VAT  identification  number  is GB19 4905473.",
    "Les pr\xE9sentes Conditions G\xE9n\xE9rales d\u2019Utilisation (ci-apr\xE8s les \xAB <0>CGU</0> \xBB) sont propos\xE9es par la soci\xE9t\xE9<1>CLUB FREELANCE LIMITED</1>, soci\xE9t\xE9 enregistr\xE9e en Angleterre et au Pays de Galles sous le num\xE9ro 9050953, dont le si\xE8ge social est Biscuit Factory, 100 Drummond Rd, London SE16 4DG, Royaume-Uni, son adresse de courrier \xE9lectronique est <2>contact@mindquest.io</2> et son num\xE9ro individuel d\u2019identification TVA britannique GB19 4905473, (ci-apr\xE8s la \xAB <3>Soci\xE9t\xE9</3> \xBB).":
      "These Terms and conditions of use (\u2018<0>Terms of Use</0> \u2019) are proposed by <1>CLUB FREELANCE LIMITED</1>,  a  company  registered  in  England  and  Wales  under  number  9050953,  with registered office at Biscuit Factory, 100 Drummond Rd, London SE16 4DG, United Kingdom (Company),  its  e-mail  address  is  contact@mindquest.io  and  its  VAT  identification  number  is GB19 4905473.",
    "Les pr\xE9sentes conditions g\xE9n\xE9rales d'utilisation (\xAB <0>CGU</0> \xBB) ont pour objet d\u2019encadrer les modalit\xE9s de mise \xE0 disposition et d\u2019acc\xE8s de la Plateforme par les candidats \xE0 un emploi ou une mission afin d\u2019entrer en relation avec toute personne morale ou physique ayant cr\xE9\xE9 un compte et pouvant proposer une offre d\u2019emploi ou une mission.":
      "The purpose of these Terms of Use to govern the use of the Platform by a candidate looking for a  permanent  job  or  assignment,  or  a  person  acting  on  behalf  of  his  company  searching  for candidates.",
    "Les pr\xE9sentes conditions g\xE9n\xE9rales d\u2019utilisation sont soumises \xE0 la l\xE9gislation fran\xE7aise.":
      "These general conditions of use are subject to French legislation.",
    "Les risques & erreurs \xE0 \xE9viter": "Tips & errors to avoid",
    "Les talents du vivier": "Talents",
    "Les th\xE8mes d\xE9finis par le talent": "Themes defined by talent",
    "Les {0} articles les plus lus cette semaine": function(a) {
      return ["The ", a("0"), " most read articles this week"];
    },
    "Les \xE9l\xE9ments de contexte comme la phase du projet, la composition des \xE9quipes, ou encore l\u2019organisation interne nous aident mieux comprendre vos recherches":
      "Contextual information such as the project's phase, the makeup of the team, or your organisational structure help us better understand your needs",
    "Lien non valide": "Invalid link",
    "Lieu de travail": "Work place",
    "Lifetime of cookies": "Lifetime of cookies",
    "Limite de th\xE8mes atteint": "Theme limit reached",
    "Limited company information, their representative\u2019s information and payment and financial information and certificates included but not limited to certificate of incorporation, certificate of professional insurance;":
      "Limited company information, their representative\u2019s information and payment and financial information and certificates included but not limited to certificate of incorporation, certificate of professional insurance;",
    "LinkedIn Insights": "LinkedIn Insights",
    "Lire l\u2019article": "Read the article",
    Localisation: "Location",
    "Localisations strat\xE9giques": "Strategic locations",
    "Location of employment or workplace;":
      "Location of employment or workplace;",
    Logistic: "Logistic",
    Logout: "Logout",
    Londres: "London",
    "Longueur non valide": "Invalid length",
    Légal: "L\xE9gal",
    "L\u2019Utilisateur  doit  \xEAtre  \xE2g\xE9  d\u2019au  moins  18  ans  et  \xEAtre  capable  juridiquement  de  contracter et d\u2019utiliser le Site conform\xE9ment aux pr\xE9sentes. L\u2019Utilisateur est tenu de fournir des informations exactes qu\u2019il s\u2019engage \xE0 mettre \xE0 jour r\xE9guli\xE8rement.":
      "User must be at least 18 years old and have the capacity to contract under the applicable law and  using  the  Platform   in  accordance  with  these  Terms  of  Use.  User  is  required  to  provide accurate information which he undertakes to update regularly.",
    "L\u2019Utilisateur doit \xEAtre \xE2g\xE9 d\u2019au moins 18 ans et \xEAtre capable juridiquement de contracter et d\u2019utiliser le Site conform\xE9ment aux pr\xE9sentes. L\u2019Utilisateur est tenu de fournir des informations exactes qu\u2019il s\u2019engage \xE0 mettre \xE0 jour r\xE9guli\xE8rement.":
      "User must be at least 18 years old and have the capacity to contract under the applicable law and  using  the  Platform   in  accordance  with  these  Terms  of  Use.  User  is  required  to  provide accurate information which he undertakes to update regularly.",
    "L\u2019Utilisateur est seul responsable de tout usage qui pourrait \xEAtre fait de son identifiant et mot de passe, et seul garant de leur confidentialit\xE9, ainsi que de toute utilisation de son compte.":
      "User shall be solely responsible for any use which may be made of his or her login and password and shall be the sole responsible of their confidentiality, as well as for any use of their account.",
    "L\u2019Utilisateur s\u2019engage \xE0 ne reproduire aucun des \xE9l\xE9ments de la Plateforme.":
      "User undertakes not to reproduce any of the elements of the Platform.",
    "L\u2019acc\xE8s au compte Utilisateur cr\xE9\xE9 est prot\xE9g\xE9 par un identifiant et un mot de passe choisi par l\u2019Utilisateur lors de son inscription sur le Site.":
      "Access to the User account created is protected by a username and password chosen by the User when registering on the Site.",
    "L\u2019ajout d\u2019un nouveau signataire va ecraser l\u2019ancien..":
      "Adding a new signer will overwrite the old one..",
    "L\u2019ensemble des offres ajout\xE9s dans notre syst\xE8me pour votre entreprise, sur la p\xE9riode s\xE9lectionn\xE9e":
      "All the offers added to our system for your company, over the selected period",
    "L\u2019ensemble des \xE9l\xE9ments composant la Plateforme, en ce compris l\u2019interface de la Plateforme ainsi  que  son  arborescence, sont  la  propri\xE9t\xE9  exclusive  de  la  Soci\xE9t\xE9  ;  ce  que  les  Utilisateurs acceptent.":
      "All the elements on the Platform, including the Platform\u2019s interface and its tree structure, shall be the sole property of Company; what Users accept.",
    "L\u2019ensemble des \xE9l\xE9ments composant la Plateforme, en ce compris l\u2019interface de la Plateforme ainsi que son arborescence, sont la propri\xE9t\xE9 exclusive de la Soci\xE9t\xE9 ; ce que les Utilisateurs acceptent.":
      "All the elements on the Platform, including the Platform\u2019s interface and its tree structure, shall be the sole property of Company; what Users accept.",
    "L\u2019exp\xE9rience Club Freelance racont\xE9e par les Freelances informatiques et d\xE9cideurs IT":
      "The Mindquest experience told by IT Freelancers and IT decision makers",
    MAX_NORMAL_TIME_PER_DAY: "Maximum normal time allowed per day exceeded",
    MAX_SPECIAL_TIME_PER_DAY: "Maximum special time allowed per day exceeded",
    MAX_THEMES_PER_DAY: "Maximum themes allowed per day exceeded",
    MAX_TIME_PER_DAY: "Maximum time allowed per day exceeded",
    "MCC is a one-stop shop for IT career and talent acquisition advice, tech news, and in-depth analysis of industry trends. A guide for professional and business success.":
      "MC is a one-stop shop for IT career and talent acquisition advice, tech news, and in-depth analysis of industry trends. A guide for professional and business success.",
    "Ma Cer,,tification": "My Certification",
    "Ma Certification": "My Certification",
    "Ma Formation": "My training",
    "Ma formation": "My training",
    "Ma pr\xE9sentation": "My presentation",
    "Ma recherche": "My search",
    "Management of the customer relationship;":
      "Management of the customer relationship;",
    "Managing applications submitted by the Talents;":
      "Managing applications submitted by the Talents;",
    "Managing the Company\u2019s platform user account;":
      "Managing the Company\u2019s platform user account;",
    "Market research.": "Market research.",
    "Marketing activities": "Marketing activities",
    "Max 24h": "Max 24h",
    "Max 60m": "Max 60m",
    "Maximisez vos chances en indiquant un intitul\xE9 clair et identifiable":
      "Maximise your chances of success by indicating an easily identifiable job title",
    "Maximisez vos changes en indiquant un intitul\xE9 clair et identifiable":
      "Maximize your exchanges by indicating a clear and identifiable title",
    "Mentions l\xE9gales": "Legal notices",
    Merci: "Thanks",
    "Merci d'avoir partag\xE9'": "Thank you for sharing",
    "Merci d'effetuer les modifications n\xE9cessaires":
      "Please make the necessary changes",
    "Merci d'indiquer votre activit\xE9": "Please indicate your activity",
    "Merci de confirmer que vous n'avez pas travaill\xE9 ce mois":
      "Please confirm that you have not worked this month",
    "Merci de consulter votre email": "Please check your email",
    "Merci de d'indiquer le raison du rejet":
      "Please indicate the reason for rejection",
    "Merci de saisir votre mot de passe afin de confirmer votre action.":
      "Please enter your password to confirm your action.",
    "Merci de v\xE9rifier les champs erron\xE9s":
      "Please make sure the fields are correctly filled",
    "Merci de v\xE9rifier les informations": "Please check the information",
    "Merci de v\xE9rifier vos informations":
      "Please make sure everything is correct",
    "Merci!": "Thank you!",
    "Merci, c\u2019est tout bon !": "Thank you, you're all set!",
    "Merci, nos \xE9quipes vont \xE9tudier votre demande et revenir vers vous dans les plus brefs d\xE9lais.":
      "Thank you, our team will review your request and get back to you shortly",
    "Mes Certifications": "My Certifications",
    "Mes Collaborateurs": "My collaborators",
    "Mes Coordonnn\xE9es": "My details",
    "Mes Coordonn\xE9es": "My details",
    "Mes Formations": "My trainings",
    "Mes Softskills": "My Softskills",
    "Mes alertes": "My alerts",
    "Mes candidatures": "My applications",
    "Mes coordonn\xE9es": "My details",
    "Mes informations": "My details",
    "Mes offres archiv\xE9es": "My archived offers",
    "Mes offres en cours": "My active offers",
    "Mes param\xE8tres": "My settings",
    "Mes recherches de talent IT": "My IT Talent search",
    "Mes talents": "My talents",
    "Mes viviers de talents": "My talent pools",
    Message: "Message",
    "Message envoy\xE9": "Message sent",
    "Message trop court,plus d\xE9tails sera utile pour nous":
      "Message too short, more details would be useful",
    "Message:": "Message:",
    "Mettre en favori": "Add to favourites",
    "Mettre \xE0 jour mon adresse email": "Update my email address",
    "Microsoft Edge": "Microsoft Edge",
    "Microsoft Technologies": "Technologies Microsoft",
    Mindquest: "Mindquest",
    "Mindquest - Connecter les meilleurs talents aux meilleures offres IT":
      "Mindquest - Connecting the best talents to the best IT offers",
    "Mindquest - Cr\xE9er un compte entreprise":
      "Mindquest - Create a company account",
    "Mindquest - Cr\xE9er un compte talent":
      "Mindquest - Create a talent account",
    "Mindquest - D\xE9couvrez le match parfait entre entreprises et talents IT":
      "Mindquest - Discover the perfect match between companies and IT talent",
    "Mindquest - Etre recontact\xE9 par l'un de nos expert en recrutement":
      "Mindquest - Be contacted by one of our recruitment experts",
    "Mindquest - Nos conditions G\xE9n\xE9rales d'Utilisation":
      "Mindquest - Our Terms and Conditions",
    "Mindquest - Not Found": "Mindquest - Not Found",
    "Mindquest - Notre politique de confidentialit\xE9":
      "Mindquest - Our Confidentiality Policy",
    "Mindquest - Nous contacter": "Mindquest - Contact Us",
    "Mindquest - Plan du site": "Mindquest - Site map",
    "Mindquest - Se connecter \xE0 mon espace Mindquest":
      "Mindquest - Login to my Mindquest space",
    "Mindquest - Trouvez le talent id\xE9al pour r\xE9soudre vos d\xE9fis IT":
      "Mindquest - Find the right talent to solve your IT challenges",
    "Mindquest - Trouvez votre futur talent IT avec notre algorithme SMATCH":
      "Mindquest - Find your IT talent with our SMATCH matching algorithm",
    "Mindquest - Trouvez votre prochain job IT en CDI ou freelance":
      "Mindquest - Find your next permanent or freelance IT job",
    "Mindquest - Trouvez votre prochain job, et acc\xE9l\xE9rez dans votre carri\xE8re IT":
      "Mindquest - Find your next job, and boost your IT career",
    "Mindquest Connect": "Mindquest Connect",
    "Mindquest accorde une grande importance \xE0 la recherche de partenaires pour vous accompagner dans votre vie de freelance. Pour la cr\xE9ation de votre entreprise, votre gestion comptable ou le d\xE9veloppement votre productivit\xE9, nous vous avons d\xE9nich\xE9 les meilleures offres.":
      "Mindquest places great importance on finding partners to support you in your freelance life. For the launch of your company, the management of your accounting or the development of your productivity, we have found you the best offers.",
    "Mindquest accorde une grande importance \xE0 la recherche de partenaires pour vous accompagner dans votre vie de freelance.<0> Pour la cr\xE9ation de votre entreprise, votre gestion comptable ou le d\xE9veloppement votre productivit\xE9,</0>  nous vous avons d\xE9nich\xE9 <1> les meilleures offres.</1>":
      "Mindquest places great importance on finding partners to support you in your freelance life.<0> For the launch of your company, the management of your accounting or the development of your productivity,</0> we have found you <1> the best offers.</1>",
    "Mindquest accorde une grande importance \xE0 la recherche de partenaires pour vous accompagner dans votre vie de freelance.<0>Pour la cr\xE9ation de votre entreprise, votre gestion comptable ou le d\xE9veloppement votre productivit\xE9,</0> nous vous avons d\xE9nich\xE9 <1> les meilleures offres.</1>":
      "Mindquest places great importance on finding partners to support you in your freelance life.<0> For the launch of your company, the management of your accounting or the development of your productivity,</0> we have found you <1> the best offers.</1>",
    "Mindquest connecte les talents aux meilleures offres IT et accompagne les entreprises dans le recrutement du talent id\xE9al pour r\xE9soudre les d\xE9fis IT. Talents CDI ou consultants freelance.":
      "Mindquest connects talent to the best IT vacancies and assists companies in recruiting the right talent to solve their IT challenges. Permanent talents or freelance consultants.",
    "Mindquest propose un service simple et efficace, facilit\xE9 par un espace personnel virtuel et la possibilit\xE9 de g\xE9rer toute sa documentation en ligne. Pour moi, c\u2019est la mani\xE8re la plus simple pour \xE9mettre une facture et se faire payer. En temps et en heure.":
      "Mindquest offers a clear and straightforward process. Everything can be done online, and you have at your disposal templates for all the required documentation. It\u2019s incredibly easy to raise an invoice and get paid. And always on time.",
    "Mindquest vous permet d\u2019associer plusieurs crit\xE8res au sein d\u2018une m\xEAme alerte gr\xE2ce au format Bool\xE9en \u201COU\u201D/\u201COR\u201D. Vous recevrez par email les opportunit\xE9s correspondant \xE0 une OU plusieurs s\xE9lections par crit\xE8re.":
      'Mindquest allows you to associate multiple criteria within the same alert using the Boolean "OR" format. You will receive by email, all the opportunities corresponding to one OR several selections per criteria.',
    "Mindquest | Dashboard": "Mindquest | Dashboard",
    "Mindquest | Favoris": "Mindquest | Favorites",
    "Mindquest | Ma formation": "Mindquest | My training",
    "Mindquest | Ma recherche": "Mindquest | My search",
    "Mindquest | Mes Coordonn\xE9es": "Mindquest | My details",
    "Mindquest | Mes Param\xE8tres": "Mindquest | My Settings",
    "Mindquest | Mes alertes": "Mindquest | My alerts",
    "Mindquest | Mes candidatures": "Mindquest | My applications",
    "Mindquest | Mon CV": "Mindquest | My resume",
    "Mindquest | Mon exp\xE9rience": "Mindquest | My experience",
    "Mindquest | Mon m\xE9tier": "Mindquest | My job",
    "Mindquest | Offres recommand\xE9es": "Mindquest | Recommended offers",
    "Mindquest,<0/>Des hommes et des algorithmes pour un matching parfait entre entreprises et freelances informatique.":
      "Mindquest,<0/>Connecting top IT talent and the best industry jobs.",
    Minutes: "Minutes",
    Mission: "Mission",
    "Mission pour Freelances": "Where IT talent meets business success",
    Missions: "Jobs",
    "Mobile Development": "Mobile Development",
    Mobilité: "Mobility",
    Modifier: "Edit",
    "Modifier le Timesheet": "Edit Timesheet",
    "Modifier mon alerte avec ces crit\xE8res":
      "Update my alert with these criteria",
    "Modifier votre profil": "Edit your profile",
    "Mod\xE8les de CV Club Freelance": "Mindquest Resume Templates",
    "Mod\xE8les de CV Mindquest": "Mindquest Resume Templates",
    "Mon CV": "My resume",
    "Mon adresse email": "My email address",
    "Mon besoin": "My request",
    "Mon entreprise": "My company",
    "Mon exp\xE9rience": "My experience",
    "Mon m\xE9tier": "My job",
    "Mon profil": "My Resume",
    "More articles": "More articles",
    "Mot de passe": "Password",
    "Mot de passe actuel est invalide": "Current password is invalid",
    "Mot de passe erron\xE9": "Wrong password",
    "Mot de passe oubli\xE9": "Forgot your password",
    "Moteurs de recherche": "Search engine",
    "Mots-cl\xE9s importants": "Desirable Keywords",
    "Mots-cl\xE9s indispensables*": "Mandatory Keywords*",
    "Moyenne entreprise (51-100)": "Medium company (51-100)",
    "Mozilla Firefox": "Mozilla Firefox",
    "Multidisciplinary pools of high-calibre candidates":
      "Access our multidisciplinary pool of high-calibre candidates",
    "Mutuelle, formation, cr\xE9dit bancaire... d\xE9couvrez les offres privil\xE8gi\xE9es dont vous pouvez b\xE9n\xE9ficier aupr\xE8s de nos partenaires.":
      "Mutual, training, bank credit ... discover the privileged offers you can benefit from with our partners.",
    "M\xE9thode recommand\xE9e": "Recommended method",
    "N/A": "N/A",
    "NOTRE POLITIQUE DE CONFIDENTIALIT\xC9": "PRIVACY POLICY",
    Name: "Name",
    "Naviguer horizentalement ou basculez votre \xE9cran en position paysage pour consulter toutes les statistiques":
      "Navigate horizontally or flip your screen to landscape position to view all stats.",
    "Nb de viviers": "Number of pools",
    "Ne cherchez plus !": "Look no further",
    Neuvoo: "Neuvoo",
    Newsletter: "Newsletter",
    Newsstand: "Newsstand",
    "Niveau d'exp\xE9rience": "Level of experience",
    "Niveau de s\xE9curit\xE9": "Security level",
    "No fee usually required": "No fee usually required",
    Nom: "Last name",
    "Nom de famille": "Last name",
    "Nom de la certification": "Certification name",
    "Nom de l\u2019entreprise": "Company's name",
    "Nom du diplome": "Name of diploma",
    "Nom du r\xE9f\xE9rent": "Referrer's last name",
    "Nom du th\xE8me": "Theme name",
    "Nom du vivier": "Pool name",
    "Nom et pr\xE9nom": "Name and last name",
    "Nombre de jours total:": "Total number of days:",
    "Nombre de viviers ": " Number of pools",
    "Nombre non valide": "Invalid number",
    Non: "No",
    "Non Merci": "No, Thanks",
    "Non assign\xE9": "Not assigned",
    "Non disponible": "Not available",
    "Non mentionn\xE9": "Not mentioned",
    "Non pr\xE9cis": "Not mentioned",
    "Non renseign\xE9": "Not specified",
    "Non-IT": "Non-IT",
    Normal: "Normal",
    "Nos Partennaires": "Our Partners",
    "Nos actualit\xE9s": "Nos actualit\xE9s",
    "Nos bureaux": "Our offices",
    "Nos chiffres clefs": "Our figures and key data",
    "Nos conseils en vid\xE9o": "Our vide advice",
    "Nos conseils pour les freelance": "Our tips for IT pros",
    "Nos conseils pour recruter en freelance": "Our tips to recruit top talent",
    "Nos fiches m\xE9tiers - Mindquest Connect":
      "Our job descriptions - Mindquest Connect",
    "Nos partenaires": "Our partners",
    "Nos partenaires et nous d\xE9posons des cookies afin d'assurer la s\xE9curit\xE9, am\xE9liorer votre exp\xE9rience digitale et afficher des publicit\xE9s et contenus personnalis\xE9s Vous pouvez accepter ou refuser ces diff\xE9rentes op\xE9rations. Par ailleurs, conform\xE9ment \xE0 la l\xE9gislation fran\xE7aise, les cookies de mesure d'audience peuvent \xEAtre exempt\xE9s du recueil du consentement de l'internaute. En l'esp\xE8ce et sur nos sites, nos cookies Web Analytics sont dispens\xE9s du recueil du consentement. G\xE9rer vos pr\xE9f\xE9rences AT Internet":
      "Our partners and we place cookies in order to ensure security, improve your digital experience and display personalized advertising and content. You can accept or refuse these various operations. In addition, in accordance with French law, audience measurement cookies may be exempt from the collection of the user's consent. In this case and on our sites, our Web Analytics cookies are exempt from the collection of consent. Manage your AT Internet preferences",
    "Nos partenaires portage": "Wage portage ",
    "Nos r\xE9f\xE9rences clients": "Our clients",
    "Nos \xE9quipes innovation et support travaillent \xE0 l\u2019optimisation constante de nos outils et process pour vous proposer la meilleure exp\xE9rience possible.":
      "Our innovation and support teams are constantly optimising our tools and processes to provide our partners with the best possible experience.",
    "Nos \xE9quipes sont en contact permanent avec entreprises et freelances, permettant une connaissance pointue du march\xE9":
      "Our teams are in constant contact with professionals and businesses, providing both sides with valuable insights",
    "Nos \xE9quipes vous accompagnent pour recruter & int\xE9grer les meilleurs freelances IT pour vos projets. Solution compl\xE8te de Talent Management et conseils personnalis\xE9s":
      "Our teams support you to recruit and integrate the best IT candidates for your projects. Complete Talent Management solution and personalized advice",
    "Not Found": "Not Found",
    "Not Mentioned": "Not Mentioned",
    "Not found": "Not found",
    "Notamment, les Utilisateurs s\u2019interdisent:":
      "In particular, Users shall refrain from:",
    "Note: ce bilan d'activit\xE9 sera visible par le client sur votre compte-rendu de Timesheet":
      "Note: this activity report will be visible to the employer on your Timesheet report",
    "Notre accompagnement": "Our support",
    "Notre accompagnent": "Our support",
    "Notre algorithme de matching SMATCH est en pleine de recherche du profils parfait au sein de nos communaut\xE9s":
      "Our SMATCH matching algorithm is currently searching for the perfect profile within our communities",
    "Notre algorithme de matching SMATCH est en pleine recherche de profils correspondants au sein de nos communaut\xE9s":
      "Our SMATCH matching algorithm is currently searching for the perfect profile within our communities",
    "Notre communaut\xE9 au coeur de notre service.":
      "Our talent community is at the very heart of our service",
    "Notre mission est d\u2019une part d\u2019aider nos entreprises clientes \xE0 attirer et fid\xE9liser les meilleurs talents pour r\xE9pondre \xE0 leurs besoins strat\xE9giques, et d\u2019autre part d\u2019accompagner nos consultants dans leur carri\xE8re en les aidant \xE0 trouver les missions les plus adapt\xE9es \xE0 leur profil et leurs aspirations.":
      "With an open digital jobs platform supported by a dynamic group of expert tech recruiters, Mindquest combines the speed and convenience of the latest digital tools with human expertise to deliver a smooth and efficient experience.",
    "Notre newsletter bimensuelle comporte nos derniers articles, nos plus belles missions et du contenu sp\xE9cialement s\xE9lectionn\xE9 pour vous":
      "Our weekly newsletter comes packed with our latest career resources, a look at the week in tech news and much more",
    "Notre performance en chiffres": "Our performance, in numbers",
    "Notre service de facturation fera correspondre les heures normales et sp\xE9ciales avec les tarifs d\xE9finis dans votre contrat":
      "Our Invoicing service will match normal and special hours with the rates defined in your contract",
    "Notre \xE9quipe Mindquest \xE9changera avec le consultant afin":
      "Our Mindquest team will discuss with the consultant to",
    "Notre \xE9quipe Mindquest \xE9changera avec le consultant afin de   v\xE9rifier sa disponibilt\xE9. Nous reviendrons vers vous dans le plus breff d\xE9lais.":
      "Our Mindquest team will discuss with the consultant to to check its availability. We will come back to you as soon as possible.",
    "Nous accordons une grande place \xE0 l\u2019humain et nouons des relations de long terme avec nos clients et nos consultants.":
      "Each job posting is managed by a duo of professionals. That is, an account manager who knows the companies, their industry and their challenges, and a talent acquisition specialist who is an expert in a specific technology field. They work together to provide a complete support service to companies and candidates. This goes from the publication of the mission offer and the job search through to the offboarding.",
    "Nous avons apport\xE9 des modifications \xE0 nos conditions d'utilisation, ainsi qu'\xE0 notre<0/>politique de confidentialit\xE9 le 03 f\xE9vrier 2022. vous pouvez les consulter sur ces pages: <1/><2/><3>Nouvelles conditions g\xE9n\xE9rales d\u2019utilisation </3> <4>Nouvelle politique de confidentialit\xE9 </4>":
      "We made changes to our terms of use, as well as our<0/>privacy policy on February 03, 2022. you can view them on these pages: <1/><2/><3>New Terms of Use </3> <4>New Privacy Policy </4>",
    "Nous avons bien pris en compte votre demande. Les ajustements seront effectu\xE9s par nos \xE9quipes dans un d\xE9lai de 30 jours. Merci d\u2019avoir utilis\xE9 les services de Mindquest.":
      "We have taken into account your request. Adjustments will be made by our team within 30 days. Thank you for using Mindquest's services.",
    "Nous b\xE9n\xE9ficions d\u2019une connaissance approfondie des process m\xE9tiers par secteur d\u2019activit\xE9, et des comp\xE9tences techniques par domaine d\u2019expertise":
      "We meet both your technical and your industry-specific needs",
    "Nous connectons les meilleurs experts aux meilleures offres pour un match parfait entre entreprises et talents informatiques.":
      "We connect the best experts with the best offers for a perfect match between business and IT talent.",
    "Nous contacter": "Contact us",
    "Nous couvrons tous les m\xE9tiers de la technologie et de l\u2019informatique":
      "Access our multidisciplinary pool of high-calibre candidates",
    "Nous d\xE9ployons de <0>nouvelles fonctionnalit\xE9s</0> sur le site Mindquest, <1>temporairement en maintenance.</1> Revenez d\u2019ici quelques minutes et actualisez la page. Merci de votre compr\xE9hension":
      "We're rolling out <0>new features</0> on the Mindquest site, which is <1>temporarily under maintenance.</1> Please come back in a few minutes and refresh the page. Thank you for your understanding",
    "Nous identifions les esprits brillants et les associons avec les marques les plus innovantes.":
      "Connecting Europe\u2019s top IT talent with the most innovative brands",
    "Nous ne construisons pas des bases de donn\xE9es, nous construisons des communaut\xE9s. D\u2019o\xF9 des relations de proximit\xE9 avec nos consultants ind\xE9pendants, et une connaissance pointue des profils disponibles sur le march\xE9.":
      "We don't build databases, we build communities. It\u2019s thanks to our close relationship with our candidates that we can provide you with unique insight into the state of the IT talent market.",
    "Nous n\u2019avons pas pu g\xE9n\xE9rer le fichier demand\xE9":
      "We couldn't generate the requested file",
    "Nous rejoindre": "Join our team",
    "Nous sommes d\xE9sol\xE9, l'offre que vous cherchez n\u2019existe pas ou n\u2019est plus disponible":
      "We are sorry, but the offer you are looking for doesn't exist or is no longer available",
    "Nous sommes d\xE9sol\xE9, l'opportunit\xE9 que vous cherchez n\u2019existe pas ou n\u2019est plus disponible":
      "We are sorry, but the opportunity you are looking for doesn't exist or is no longer available",
    "Nous sommes d\xE9sol\xE9, la mission que vous cherchez n\u2019existe pas ou n\u2019est plus disponib le":
      "We are sorry, the mission you are looking for does not exist or is no longer available",
    "Nous sommes d\xE9sol\xE9, la mission que vous cherchez n\u2019existe pas ou n\u2019est plus disponible":
      "Apologies. The vacancy you are looking for does not exist or is no longer available",
    "Nous sommes d\xE9sol\xE9, le candidat que vous cherchez n\u2019existe pas ou n\u2019est plus disponible":
      "We are sorry, the candidate you are looking for does not exist or is no longer available",
    "Nous vous contacteront au plus vite afin d'\xE9changer sur votre besoin.":
      "We will contact you as soon as possible to discuss your needs.",
    "Nous vous recommandons fortement d\u2019utiliser le format Word.":
      "We strongly recommend using a document in Word format",
    "Nous y r\xE9pondrons dans les plus brefs d\xE9lais":
      "We will respond as soon as possible",
    "Nouveau Timesheet en attente": "New Timesheet pending",
    "Nouveau compte-rendu disponible pour le contrat {placementId}": function(
      a
    ) {
      return ["New report available for contract ", a("placementId")];
    },
    "Nouveau valideur pour le contrat {placementId}": function(a) {
      return ["New validator for contract ", a("placementId")];
    },
    "Nouveaux profils": "New profiles",
    "Nouvelle politique de confidentialit\xE9 et GCU":
      "New Privacy Policy and Terms and Conditions of use",
    Nouvelles: "News",
    "Num\xE9ro de bon de commande": "purchase Order",
    "Num\xE9ro non valide": "Invalid number",
    Néerlandais: "Dutch",
    "OS & Software development": "OS & Software development",
    OU: "Or",
    "Obtenir un lien partageable": "Get a shareable link",
    "Obtenir votre lien partageable": "Get your shareable link",
    "Obtenues sur cette mission": "obtained on this mission ",
    "Offer clients recruitment services (permanent jobs or contracts);":
      "Offer clients recruitment services (permanent jobs or contracts);",
    Offre: "Offer",
    "Offre Finance": "Offre Finance",
    Offres: "Offers",
    "Offres archiv\xE9es": "Closed Vacancies",
    "Offres en cours": "Open Vacancies",
    "Offres post\xE9es": "Posted Vacancies",
    "Offres recommand\xE9es": "Recommended offers",
    "Offres similaires": "Similar Vacancies",
    Ok: "Ok",
    "On parle de nous": "About us",
    "On parle de nous - Communiqu\xE9s de Presse - Mindquest Connect":
      "On parle de nous - Communiqu\xE9s de Presse - Mindquest Connect",
    "On parle de nous - Mindquest Connect": "About us - Mindquest Connect",
    "On parle de nous - Nos actualit\xE9s - Mindquest Connect":
      "On parle de nous - Nos actualit\xE9s - Mindquest Connect",
    "On parle de nous - T\xE9moignages - Mindquest Connect":
      "On parle de nous - T\xE9moignages - Mindquest Connect",
    "On s\u2019occupe de l\u2019admin. Plus qu\u2019\xE0 d\xE9marrer votre mission":
      "We guide you through the interview process",
    "On s\u2019occupe de l\u2019admin. Plus qu\u2019\xE0 d\xE9marrer votre nouveau job !":
      "We take care of the paperwork, you focus on a fresh start",
    "One of the GDPR\u2019s main objectives is to protect and clarify the rights of EU citizens and individuals in the EU with regards to data privacy. This means that you retain various rights in respect of your data, even once you have given it to us. These are described in more detail below.":
      "One of GDPR\u2019s main objectives is to protect and clarify the rights of EU citizens and individuals in the EU with regards to data privacy. This means that you retain various rights in respect of your data, even once you have given it to us. These are described in more detail below.",
    "Oops!": "Oops!",
    "Optimisation of navigation": "Optimisation of navigation",
    "Organisme de d\xE9livrance": "Training institution",
    "Origin of cookies": "Origin of cookies",
    "Other information relevant to help us provide recruitment services;":
      "Other relavant information to help us provide recruitment services;",
    Ou: "Or",
    Oui: "Yes",
    "Oups! il semblerait que vos donn\xE9es aient du mal \xE0 se synchroniser. Veuillez rafraichir la page dans un moment":
      "Oups! it seems that data has trouble downloading. Please try refreshing the page in a moment",
    "Our Clients may share personal information about you with us;":
      "Our Clients may share personal information about you with us;",
    "Our Tech Magazine -  Mindquest Connect":
      "Our Tech Magazine -  Mindquest Connect",
    "Our job descriptions - Mindquest Connect":
      "Our job descriptions - Mindquest Connect",
    "Our website may include links to third-party websites, plug-ins and applications. Clicking on those links or enabling those connections may allow third parties to collect or share data about you. We do not control these third-party websites and are not responsible for their privacy statements. When you leave our website, we encourage you to read the privacy notice of every website you visit.":
      "Our website may include links to third-party websites, plug-ins and applications. Clicking on those links or enabling those connections may allow third parties to collect or share data about you. We do not control these third-party websites and are not responsible for their privacy statements. When you leave our website, we encourage you to read the privacy notice of every website you visit.",
    "Ouvert aux opportunit\xE9s": "Open To new opportunity",
    Ouverte: "Open",
    Ouverts: "Open",
    "Ouverture du Sourcing Hub \xE0 Barcelone":
      "Our Sourcing Hub opens in Barcelona",
    "Ouverture du bureau commercial \xE0 paris":
      "Opening of the Paris sales office",
    "Ouvrir le CV en PDF": "Open CV in PDF",
    "PHARMA & CHEMICALS": "PHARMA & CHEMICALS",
    "PLAN DU SITE": "SITE MAP",
    PMO: "PMO",
    "PMO BI": "PMO BI",
    Pages: "Pages",
    Par: "By",
    "Par ailleurs, la Soci\xE9t\xE9, en tant qu\u2019op\xE9rateur ind\xE9pendant n\u2019intervenant pas dans le cadre d\u2019une relation entre le Client et un Utilisateur, ne saurait \xEAtre responsable de toute difficult\xE9 pouvant survenir entre un Utilisateur et un Client \xE0 ce stade de leur collaboration.":
      "Moreover, the Company, as an independent operator not acting on behalf of neither Client nor Talent, cannot be responsible for any difficulties which may arise between a Talent and a client at that stage of their cooperation.",
    "Par an": "Per year",
    "Par jour": "By day",
    Paramètres: "Settings",
    Paris: "Paris",
    "Part-time": "Part-time",
    Partager: "Share",
    "Partager l\u2019article": "Share this article",
    "Pas d'avis \xE9quipe pour ce talent!": "No team opinion for this talent!",
    "Pas de collaborateur": "No collaborator",
    "Pas de collaborateur pour ce vivier": "No collaborator for this pool",
    "Pas de commentaires pour ce talent!": "No comments for this talent!",
    "Pas de mod\xE8le de facture?": "No invoice template?",
    "Pas de num\xE9ro de bon de commande indiqu\xE9":
      "No purchase order number indicated",
    "Pas de r\xE9f\xE9rence pour ce talent!": "No reference for this talent!",
    "Pas de talent pour ce vivier": "No talent for this pool",
    "Pas de vivier": "No Talent Pool",
    "Pas d\u2019activit\xE9 jusqu\u2019\xE0 maintenant": "No activity so far",
    "Pas d\u2019activit\xE9 pendant la periode s\xE9lectionn\xE9e":
      "No activity found for the selected period",
    "Passer cette \xE9tape": "Skip this step",
    "Pensez \xE0 consulter vos courriers ind\xE9sirables":
      "Make sure to check your spam/junk mail folder",
    "Pensez \xE0 remplir votre Timesheet du contrat num\xE9ro: {pID} de {m}/{y}": function(
      a
    ) {
      return [
        "Make sure to fill out your Timesheet: ",
        a("pID"),
        " of ",
        a("m"),
        "/",
        a("y")
      ];
    },
    "Pensez \xE0 remplir votre Timesheet du contrat num\xE9ro: {placementID} de {month}/{year}": function(
      a
    ) {
      return [
        "Make sure to fill out your Timesheet: ",
        a("placementID"),
        " of ",
        a("month"),
        "/",
        a("year")
      ];
    },
    "Pensez \xE0 remplir votre Timesheet du contrat num\xE9ro: {placementId} de {0} {year}": function(
      a
    ) {
      return [
        "Remember to fill in your Timesheet for contract number: ",
        a("placementId"),
        " of ",
        a("0"),
        " ",
        a("year")
      ];
    },
    "Pens\xE9s pour les freelances.": "We\u2019ve got you covered",
    "Performance cookies": "Performance cookies",
    Permanent: "Permanent",
    "Persistent cookies: remain registered on your computer even when you leave your browser (are deleted after a deadline that is determined according to the purpose of cookies, the user also has the possibility to delete them manually).":
      "Persistent cookies: remain registered on your computer even when you leave your browser (are deleted after a deadline that is determined according to the purpose of cookies, the user also has the possibility to delete them manually).",
    "Personal contact details of company employees we are in contact with such as name, title, work addresses, telephone numbers, work email addresses, languages and skills;":
      "Personal contact details of company employees we are in contact with such as name, title, work addresses, telephone numbers, work email addresses, languages and skills;",
    "Personal contact details such as name, title, addresses, telephone numbers, personal and work email addresses, Skype or other web ID;":
      "Personal contact details such as name, title, addresses, telephone numbers, personal and work email addresses, Skype or other web ID;",
    "Personal data and/or information will be kept only:":
      "Personal data and/or information will be kept only:",
    "Personal data that we receive from other sources.":
      "Personal data that we receive from other sources.",
    "Personal data that you, as the Candidate or Client, give to us; and":
      "Personal data that you, as the Candidate or Client, give to us; and",
    "Personal data we receive from other sources":
      "Personal data we receive from other sources",
    "Personal data you give to us": "Personal data you give to us",
    "Petite entreprise (1-50)": "Small company (1-50)",
    Place: "Place",
    Placement: "Hires",
    Placements: "Hires",
    "Placements r\xE9ussis chez nos clients":
      "Successful hires across more than 100 happy clients",
    "Plages d'heures s\xE9lectionn\xE9es :": "Selected time ranges:",
    "Plan du site": "Site map",
    "Please  note  that  these  websites  have  their  own  confidentiality  policy  and  that  the Company accepts no responsibility for the use by these websites and the information collected when you click on these links. The Company invites you to read the confidentiality policies of these sites before sending them your personal data.":
      "Please  note  that  these  websites  have  their  own  privacy  policy  and  that  the Company accepts no responsibility for the use by these websites and the information collected when you click on these links. The Company invites you to read the privacy policies of these sites before sending them your personal data.",
    "Please note that these websites have their own confidentiality policy and that the Company accepts no responsibility for the use by these websites and the information collected when you click on these links. The Company invites you to read the confidentiality policies of these sites before sending them your personal data.":
      "Please note that these websites have their own privacy policy and that the Company accepts no responsibility for the use by these websites and the information collected when you click on these links. The Company invites you to read the privacy policies of these sites before sending them your personal data.",
    "Please read the following carefully to understand our views and practices regarding your personal data, how we will treat it and your rights.":
      "Please read the following information carefully to understand our views and practices regarding your personal data, how we will treat it and your rights.",
    "Plus d\u2019expertise": "More specialties",
    Podcast: "Podcast",
    "Podcast Interviews": "Podcast Interviews",
    "Podcast Interviews - Mindquest Connect":
      "Podcast Interviews - Mindquest Connect",
    Polish: "Polish",
    "Politique de confidentialit\xE9": "Privacy Policy",
    Polonais: "Polish",
    "Portage Salarial": "Wage portage ",
    "Portage salarial": "Portage salarial",
    "Portfolio, CV en ligne, site personnel":
      "Portfolio, online resume, personal website",
    Portugais: "Portuguese",
    Portuguese: "Portuguese",
    "Poste actuel": "Current position",
    "Poster un besoin via le formulaire": "Post a vacancy via our form",
    "Poster un besoin via une fiche de poste":
      "Post an offer with a job description",
    "Poster une offre": "Post an offer",
    Postuler: "Apply",
    Postulé: "Applied",
    "Pour   pouvoir   acc\xE9der   aux   services,   l\u2019Utilisateur   doit   cr\xE9er   un   compte   en   s\u2019inscrivant gratuitement sur le site":
      "To be able to access the services, the User must create an account by registering for free on the site.",
    "Pour   pouvoir   acc\xE9der   aux   services,   l\u2019Utilisateur   doit   cr\xE9er   un   compte   en   s\u2019inscrivant gratuitement sur le site.":
      "To be able to access the services, the User must create an account by registering for free on the site.",
    "Pour activer les fonctionnalit\xE9s de timesheet, vous devez contractualiser avec nos services.":
      "To unlock the timesheet feature, you have to contract on our consultant services.",
    "Pour activer les fonctionnalit\xE9s du Timesheets, vous devez contractualiser avec nos services.":
      "To activate the Timesheets features, you have to deal with our services.",
    "Pour d\xE9bloquer les fonctionnalit\xE9s de timesheet, vous devez d\xE9marrer une mission via nos services.":
      "To unlock the timesheet feature, you have to start a misssion on our consultant services.",
    "Pour d\xE9bloquer les fonctionnalit\xE9s du Timesheets, vous devez d\xE9marrer une mission via nos services.":
      "To unlock the Timesheet feature, you must start a contract job through our services.",
    "Pour d\xE9bloquer les fonctionnalit\xE9s du Timesheets, vous devez red\xE9marrer une mission via nos services.":
      "To unlock the functionality of the Timesheets, you have to restart a mission through our services",
    'Pour enregistrer les donn\xE9es saisies, fermez cette fen\xEAtre en cliquant sur "annuler" puis cliquez sur le bouton "enregistrer" \xE0 la fin du formulaire.':
      "To save the data entered, close this window by clicking on cancel then click on the  save button at the end of the form.",
    "Pour faciliter vos \xE9changes avec nous, n\u2019h\xE9sitez pas \xE0 cr\xE9er un compte":
      "Create an account to stay in touch and up-to-date with the latest developments regarding your processes",
    "Pour indiquer que vous avez effectivement travaill\xE9 ce jour et \xE0 votre TJM habituel, cliquez sur continuer.":
      "To indicate a workday at your usual rate please click on Continue.",
    "Pour ne manquer aucun article": "Be in the know",
    "Pour obtenir imm\xE9diatement des nouveaux talents":
      "Receive new candidate alerts in real time",
    "Pour obtenir imm\xE9diatement des offres personnalis\xE9es.":
      "Receive personalised offers right away",
    "Pour pouvoir acc\xE9der aux services, l\u2019Utilisateur doit cr\xE9er un compte en s\u2019inscrivant gratuitement sur le site.":
      "To be able to access the services, the User must create an account by registering for free on the site.",
    "Pour recevoir des profils, soumettez une offre.":
      "Please post an offer to start receiving candidate recommendations",
    "Pour toute demande urgente contactez-nous par email :  <0>contact@mindquest.io</0>":
      "For any emergency leave us an email at <0>contact@mindquest.io</0>",
    "Pour toute demande urgente contactez-nous par email : <0>contact@mindquest.io</0>":
      "For any emergency leave us an email at <0>contact@mindquest.io</0>",
    "Pour trouvez des talent, ajouter des talents \xE0 ce vivier.":
      "To find talents, add talents to this pool.",
    "Pour trouvez des viviers, soumettez un vivier.":
      "To find Talent-pools, submit a Talent-pool.",
    "Pourquoi nous rejoindre ?": "Why join us ?",
    "Practical Tips for IT decision maker - Mindquest Connect":
      "Practical Tips for IT decision maker - Mindquest Connect",
    "Practical Tips for Tech & IT Talent - Mindquest Connect":
      "Practical Tips for Tech & IT Talent - Mindquest Connect",
    "Press review": "Press review",
    "Press review - Mindquest Connect": "Press review - Mindquest Connect",
    Printed: "Printed",
    "Privacy Policy - Club Freelance": "Privacy Policy - Mindquest",
    "Privacy policy": "Privacy policy",
    "Processed lawfully, fairly and transparently in relation to the person concerned;":
      "Processed lawfully, fairly and transparently in relation to the person concerned;",
    "Processing is necessary for compliance with a legal obligation;":
      "Processing is necessary for compliance with a legal obligation;",
    "Processing is necessary for the performance of a contract;":
      "Processing is necessary for the performance of a contract;",
    "Processing is necessary for the performance of a task carried out in the public interest;":
      "Processing is necessary for the performance of a task carried out in the public interest;",
    "Processing is necessary to protect the vital interests of the data subject;":
      "Processing is necessary to protect the vital interests of the data subject;",
    "Prochain rendez-vous": "Other meetings",
    "Product Owner": "Product Owner",
    "Profil Pseudonymis\xE9": "Pseudonymized Profile",
    "Profil anonymis\xE9": "Anonymised profile",
    "Profil recherch\xE9": "Required profile",
    "Profils refus\xE9s": "Discarded profiles",
    "Profils \xE0 rencontrer": "Profiles to vet",
    "Profitez des avantages que nous avons n\xE9goci\xE9s pour vous":
      "Take advantage of the benefits we have negotiated for you",
    "Project Management": "Project Management",
    "Project Management job descriptions - Mindquest Connect":
      "Project Management job descriptions - Mindquest Connect",
    "Project management & Agile Coaching":
      "Project management & Agile Coaching",
    Projet: "Project",
    "Projet :": "Project :",
    "Propose IT assignments or CDI permanent job offers to Talents Consultants;":
      "Propose IT assignments or CDI permanent job offers to Talents Consultants;",
    Proposition: "Proposal",
    "Protected in their integrity and kept confidential.":
      "Protected in their integrity and kept confidential.",
    "Providing us with a copy of your CV or by corresponding with us by post, phone, email or otherwise or being interviewed by us;":
      "Providing us with a copy of your CV or by corresponding with us by post, phone, email or otherwise or being interviewed by us;",
    "Pr\xE9ciser la date": "Specify the date",
    "Pr\xE9ciser la recherche": "Specify the search",
    "Pr\xE9ciser le nombre d'heures": "Specify the number of hours",
    "Pr\xE9ciser le nombre de minutes": "Specify the number of minutes",
    "Pr\xE9ciser le type": "Specify the type",
    Prénom: "First name",
    "Pr\xE9nom Nom": "Name",
    "Pr\xE9nom du r\xE9f\xE9rent": "Referrer's first name",
    "Pr\xE9nom et Nom": "Name and Last Name",
    "Pr\xE9sent sur le vivier": "Present in the pool",
    Présentiel: "On-site",
    "Pr\xEAt \xE0 trouver une mission ?": "Ready to explore new opportunities?",
    "Pr\xEAt \xE0 trouver une nouvelle mission ?":
      "Ready to continue the quest?",
    "Publi\xE9 le": "Published",
    Purpose: "Purpose",
    Période: "Time range",
    "QA & Testing": "QA & Testing",
    "Que faut-il clarifier ?": "What needs to be clarified?",
    "Quel est votre domaine ?": "What is your area of expertise?",
    "Quel est votre statut": "What is your status",
    "Quelle est votre statut": "What is your status",
    "Quels contenus aimeriez vous recevoir":
      "What type of content would you like to receive ?",
    "Qui sommes nous?": "About us",
    "Qui sommes-nous ?": "Who are we ?",
    "Qui vous correspond": "We are.",
    "Quick and interesting reads for commuters and curious minds. Tech, IT and pop culture merge in this section for a quick break from work at any time of the day.":
      "Quick and interesting reads for commuters and curious minds. Tech, IT and pop culture merge in this section for a quick break from work at any time of the day.",
    Quicktip: "Quicktip",
    "Quotidien de freelance": "Daily freelancer",
    "Rafra\xEEchissez la page": "Refresh the page",
    Rappel: "Reminder",
    "Ravis de vous revoir": "Welcome back",
    "ReCAPCHA non valid": "Invalid ReCAPCHA",
    "Recevez des offres adapt\xE9es \xE0 vos crit\xE8res":
      "Start receiving offers in line with your professional and personal aspirations",
    "Recevez par email les futures opportunit\xE9s selon un ou plusieurs crit\xE8res configur\xE9s.":
      "Be notified by email of future opportunities, according to one or more configured criteria.",
    "Recevoir la newsletter Club Freelance.": "Receive Mindquest's newsletter",
    "Recevoir notre newsletter bimensuelle sp\xE9ciale Tech & IT (conseils pratiques, dossiers sp\xE9ciaux, s\xE9lection de missions, etc.).":
      "Receive our bi-monthly special Tech & IT newsletter (practical advice, special features, selection of assignments, etc)",
    Recherche: "Search by topic",
    Rechercher: "Search",
    "Rechercher des articles, des vid\xE9os et des ressources":
      "Search for all our articles and resources",
    "Rechercher un article sur le blog - Mindquest Connect":
      "Find a blog post - Mindquest Connect",
    "Rechercher un consultant": "Find a new consultant",
    "Rechercher un domaine, une comp\xE9tence...":
      "Search for an area, a skill...",
    "Rechercher un nouveau talent": "Search a new talent",
    "Rechercher un secteur d\u2019activit\xE9": "Search for a business sector",
    "Rechercher un talent": "Search a talent",
    "Rechercher un talent...": "Search a talent...",
    "Rechercher un vivier...": "Search a talent pool...",
    "Rechercher une ville, un domaine, une comp\xE9tence...":
      "Search for a city, a domain, a skill...",
    "Rechercher...": "Search...",
    "Recherchez les consultants gr\xE2ce au contenu de votre fiche de poste ou en ajoutant directement votre mots cl\xE9s IT":
      "Look for your future consultant using your job specifications",
    "Recherchez un article au sein de notre s\xE9lection pour Freelance informatiques et D\xE9cideurs IT":
      "Search an article within our selection for IT profesionals",
    "Recherchez un article sur le blog parmi notre s\xE9lection de contenus, actualit\xE9s, conseil pratiques et ressources pour Talents et D\xE9cideurs IT.":
      "Search by topic in our collection of IT articles.",
    "Recrutement & IT": "Recrutement & IT",
    "Recruter un consultant informatique": "Hiring an IT consultant",
    "Recruteur IT": "IT Recruiter",
    "Recrutez les meilleurs talents tech & IT":
      "Engage Europe\u2019s best IT professionals ",
    "References from past employers;": "References from past employers;",
    Rejeter: "Reject",
    "Rejeter Timesheet": "Reject Timesheet",
    "Rejeter le timesheet": "Reject the timesheet",
    Rejetée: "Rejected",
    Rejetés: "Rejected",
    "Rejoignez la communaut\xE9 des Freelances IT": "Join the quest",
    "Rejoignez le Club": "Join the quest",
    "Rejoignez le club": "Join the quest",
    "Rejoignez le club et trouvez<0/>votre prochaine mission freelance IT":
      "Join the Mindquest community and <0/>take your career to new heights",
    "Rejoignez le club pour recevoir<0/>des offres adapt\xE9es \xE0 vos envies.":
      "Join the Mindquest community to start receiving<0/>offers that meet all your needs",
    "Rejoindre Club Freelance": "Join the quest",
    "Rejoindre Mindquest Connect et trouver les meilleurs id\xE9es de IT professionnel":
      "Receive our latest expert interviews, news and tips regarding recruitment and talent strategy, tech and It opportunities/profiles",
    "Rejoindre Mission Control Center et trouver les meilleurs id\xE9es de IT professionnel":
      "Join Mission Control Center and beneftit from pointed and actionable insights for IT professionals",
    "Rejoindre la communaut\xE9 Mindquest - Cr\xE9er un compte entreprise pour trouver le talent id\xE9al pour r\xE9soudre les d\xE9fis IT - Le match parfait entre entreprises et talents IT.":
      "Rejoindre la communaut\xE9 Mindquest - Cr\xE9er un compte entreprise pour trouver le talent id\xE9al pour r\xE9soudre les d\xE9fis IT - Le match parfait entre entreprises et talents IT.",
    "Rejoindre la communaut\xE9 Mindquest - Cr\xE9er un compte talent pour \xEAtre connecter aux meilleurs offres IT, en CDI ou freelance - Le match parfait entre entreprises et talents IT.":
      "Rejoindre la communaut\xE9 Mindquest - Cr\xE9er un compte talent pour \xEAtre connecter aux meilleurs offres IT, en CDI ou freelance - Le match parfait entre entreprises et talents IT.",
    "Rejoindre le club": "Join the quest",
    "Rejoindre le club en 3 minutes": "Sign up in less than 3 minutes",
    "Rejoindre une communaut\xE9 de freelances":
      "The opportunity to join a unique talent community",
    "Rejoindre une communaut\xE9<0/>de freelances":
      "The opportunity to join <0/>a unique talent community",
    Remarques: "Notes",
    Remote: "Remote",
    "Remote partiel": "Part-time remote",
    "Remote uniquement": "Exclusively remote",
    "Remote/Pr\xE9sentiel": "Remote/On-site",
    "Remote/R\xE9gie": "Remote/On-site",
    "Remplir la timesheet": "Fill in the timesheet",
    "Remplir le Timesheet": "Complete the Timesheet",
    Renommer: "Rename",
    "Renommer le vivier": "Rename the pool",
    Renvoyer: "Resend",
    "Renvoyer l\u2019email de validation": "Resend validation email",
    "Responsable Achat IT": "Head of corporate Sourcing",
    "Responsable TMA RUN": "TMA RUN Director",
    Ressources: "Resources",
    "Restez connect\xE9s aux actualit\xE9s et derniers conseils pour Consultants Tech & IT - Mindquest Connect":
      "Stay connected to the latest news and tips for Tech & IT Consultants - Mindquest Connect",
    "Restez connect\xE9s aux actualit\xE9s et derniers conseils pour D\xE9cideurs IT - Mindquest Connect":
      "Stay tuned to the latest news and tips for IT Decision Makers - Mindquest Connect",
    "Restez connect\xE9s avec le secteur de Tech & IT": "Mindquest Connect",
    "Restez connect\xE9s avec le secteur de Tech & IT - Mindquest Connect":
      "Mindquest Connect",
    Retour: "Back",
    "Retour au dashboard": "Back to dashboard",
    "Retour au site": "Back to home",
    "Retour d\u2019exp\xE9rience": "Feedback",
    "Retour \xE0 la page recherche": "Back to search missions page",
    "Retourner a la mission": "Back to Vacancy",
    "Retrouvez des offres s\xE9lectionn\xE9s pour vous":
      "Explore exciting offers selected just for you",
    "Retrouvez des talents s\xE9lectionn\xE9s pour vous":
      "Explore your candidate shortlists",
    "Retrouvez nos actualit\xE9s, nos fiches m\xE9tiers, notre s\xE9lection d'articles pour talents et consultants IT, d\xE9cideurs et recruteurs IT et tous nos webinars et ressources pour recruter le profil ou trouver la mission informatique id\xE9al.":
      "Discover our news, our job descriptions, our selection of articles for IT talents and consultants, decision-makers and IT recruiters, and all our webinars and resources to recruit the ideal IT profile or find the ideal IT assignment.",
    "Retrouvez nos offres pour consultants IT : Data, D\xE9veloppement, Cloud, Gestion de projet, CRM, Support, BI, Webdesign, ERP, SAP, Oracle...":
      "Find our offers for IT consultants: Data, Development, Cloud, Project management, CRM, Support, BI, Webdesign, ERP, SAP, Oracle ...",
    "Retrouvez tous les replays de nos webinars pour d\xE9cideurs et consultants IT pr\xE9sent\xE9s par Mindquest et ses partenaires comme l'Expert-Comptable.":
      "Retrouvez tous les replays de nos webinars pour d\xE9cideurs et consultants IT pr\xE9sent\xE9s par Mindquest et ses partenaires comme l'Expert-Comptable.",
    "Retrouvez toute l'actualit\xE9 de Mindquest et nos fiches m\xE9tiers sur les comp\xE9tences, missions et particularit\xE9s des m\xE9tiers d'ERP & SAP.":
      "Find all the latest news from Mindquest and our job descriptions on the skills, missions and particularities of ERP & SAP jobs.",
    "Retrouvez toute l'actualit\xE9 de Mindquest et nos fiches m\xE9tiers sur les comp\xE9tences, missions et particularit\xE9s des m\xE9tiers d'Infrastructure IT.":
      "Find all the latest news from Mindquest and our job descriptions on the skills, missions and particularities of IT Infrastructure jobs.",
    "Retrouvez toute l'actualit\xE9 de Mindquest et nos fiches m\xE9tiers sur les comp\xE9tences, missions et particularit\xE9s des m\xE9tiers de Data & Business Intelligence.":
      "Find all the latest news from Mindquest and our job descriptions about the skills, missions, and specificities of the Data & Business Intelligence professions.",
    "Retrouvez toute l'actualit\xE9 de Mindquest et nos fiches m\xE9tiers sur les comp\xE9tences, missions et particularit\xE9s des m\xE9tiers de Project Management.":
      "Find all the latest news from Mindquest and our job descriptions about the skills, missions, and specificities of Project Management jobs.",
    "Retrouvez toute l'actualit\xE9 de Mindquest et nos fiches m\xE9tiers sur les comp\xE9tences, missions et particularit\xE9s des m\xE9tiers du Digital.":
      "Find all the latest Mindquest news and our job descriptions about the skills, missions and specificities of digital jobs.",
    "Retrouvez toute l'actualit\xE9 de Mindquest et nos fiches m\xE9tiers sur les comp\xE9tences, missions et particularit\xE9s des m\xE9tiers du D\xE9veloppement.":
      "Find all the latest news from Mindquest and our job descriptions on the skills, missions and particularities of Development jobs.",
    "Retrouvez toute l'actualit\xE9 de Mindquest et nos fiches m\xE9tiers tech & IT (D\xE9veloppement, Infra, ERP & SAP, Project Management, Data & Business Intelligence, Digital).":
      "Discover all the latest news about Mindquest and our tech & IT jobs (Development, Infra, ERP & SAP, Project Management, Data & Business Intelligence, Digital).",
    "Retrouvez toute l'actualit\xE9 de Mindquest et nos fiches m\xE9tiers tech & IT.":
      "Find all the latest news from Mindquest and our selection of IT Job Descriptions.",
    "Retrouvez toute l'actualit\xE9 de Mindquest et nos revues de presse sur la tech et l'informatique (DSI, D\xE9veloppement, Intelligence Artificielle, Cybers\xE9curit\xE9, Recrutement & IT, Data & ERP, Cloud)...":
      "Retrouvez toute l'actualit\xE9 de Mindquest et nos revues de presse sur la tech et l'informatique (DSI, D\xE9veloppement, Intelligence Artificielle, Cybers\xE9curit\xE9, Recrutement & IT, Data & ERP, Cloud)...",
    "Retrouvez toute l'actualit\xE9 de Mindquest et nos s\xE9lections d'articles pour Webinars & Ressources.":
      "Retrouvez toute l'actualit\xE9 de Mindquest et nos s\xE9lections d'articles pour Webinars & Ressources.",
    "Retrouvez toute l'actualit\xE9 de Mindquest et nos s\xE9l\xE9ctions d'articles pour Freelances informatiques":
      "Retrouvez toute l'actualit\xE9 de Mindquest et nos s\xE9l\xE9ctions d'articles pour Freelances informatiques",
    "Retrouvez toute l'actualit\xE9 de Mindquest et nos s\xE9l\xE9ctions d'articles pour Webinars & Ressources":
      "Find all the latest news from Mindquest and our selection of articles for Webinars & Resources",
    "Retrouvez toute l'actualit\xE9 de Mindquest et nos s\xE9l\xE9ctions d'articles pour Webinars & Ressources.":
      "Retrouvez toute l'actualit\xE9 de Mindquest et nos s\xE9l\xE9ctions d'articles pour Webinars & Ressources.",
    "Retrouvez toute l'actualit\xE9 de Mindquest et notre s\xE9lection d'articles pour connaitre les derni\xE8res actualit\xE9s de notre marque et les derniers t\xE9moignagnes de nos collaborateurs.":
      "Retrouvez toute l'actualit\xE9 de Mindquest et notre s\xE9lection d'articles pour connaitre les derni\xE8res actualit\xE9s de notre marque et les derniers t\xE9moignagnes de nos collaborateurs.",
    "Retrouvez toute l'actualit\xE9 de Mindquest et notre s\xE9lection d'articles pour connaitre les derni\xE8res actualit\xE9s de notre marque et nos derniers communiqu\xE9s de presse.":
      "Retrouvez toute l'actualit\xE9 de Mindquest et notre s\xE9lection d'articles pour connaitre les derni\xE8res actualit\xE9s de notre marque et nos derniers communiqu\xE9s de presse.",
    "Retrouvez toute l'actualit\xE9 de Mindquest et notre s\xE9lection d'articles pour connaitre les derni\xE8res actualit\xE9s de notre marque et nos derniers services...":
      "Retrouvez toute l'actualit\xE9 de Mindquest et notre s\xE9lection d'articles pour connaitre les derni\xE8res actualit\xE9s de notre marque et nos derniers services...",
    "Retrouvez toute l'actualit\xE9 de Mindquest et notre s\xE9lection d'articles pour connaitre nos actualit\xE9s, nos communiqu\xE9s de presse et nos t\xE9moignages dans la rubrique On parle de nous.":
      "Find all the latest news about Mindquest and our selection of articles to find out about our news, press releases and testimonials in the About Us section.",
    "Retrouvez toute l'actualit\xE9 de Mindquest et notre s\xE9lection d'articles pour conna\xEEtre nos actualit\xE9s, nos communiqu\xE9s de presse et nos t\xE9moignages.":
      "Find all the latest news about Mindquest and our selection of articles to find out about our news, press releases and testimonials.",
    "Retrouvez toute l'actualit\xE9 de Mindquest et notre s\xE9lection d'articles pour consultants IT sur comment devenir freelance sur notre blog Mindquest Connect.":
      "Retrouvez toute l'actualit\xE9 de Mindquest et notre s\xE9lection d'articles pour consultants IT sur comment devenir freelance sur notre blog Mindquest Connect.",
    "Retrouvez toute l'actualit\xE9 de Mindquest et notre s\xE9lection d'articles pour consultants IT sur la comptabilit\xE9 en freelance sur notre blog Mindquest Connect.":
      "Retrouvez toute l'actualit\xE9 de Mindquest et notre s\xE9lection d'articles pour consultants IT sur la comptabilit\xE9 en freelance sur notre blog Mindquest Connect.",
    "Retrouvez toute l'actualit\xE9 de Mindquest et notre s\xE9lection d'articles pour consultants IT sur le d\xE9veloppement de sa carri\xE8re en CDI ou freelance sur notre blog Mindquest Connect.":
      "Find all the latest news from Mindquest and our selection of articles for IT Consultants about growing your career as permanent & freelance.",
    "Retrouvez toute l'actualit\xE9 de Mindquest et notre s\xE9lection d'articles pour consultants IT sur le quotidien d'un freelance sur notre blog Mindquest Connect.":
      "Retrouvez toute l'actualit\xE9 de Mindquest et notre s\xE9lection d'articles pour consultants IT sur le quotidien d'un freelance sur notre blog Mindquest Connect.",
    "Retrouvez toute l'actualit\xE9 de Mindquest et notre s\xE9lection d'articles pour consultants et freelances IT sur comment se financer sur notre blog Mindquest Connect.":
      "Retrouvez toute l'actualit\xE9 de Mindquest et notre s\xE9lection d'articles pour consultants et freelances IT sur comment se financer sur notre blog Mindquest Connect.",
    "Retrouvez toute l'actualit\xE9 de Mindquest et notre s\xE9lection d'articles pour consultants et freelances IT sur l'admin & le l\xE9gal sur notre blog Mindquest Connect.":
      "Retrouvez toute l'actualit\xE9 de Mindquest et notre s\xE9lection d'articles pour consultants et freelances IT sur l'admin & le l\xE9gal sur notre blog Mindquest Connect.",
    "Retrouvez toute l'actualit\xE9 de Mindquest et notre s\xE9lection d'articles pour consultants et freelances IT sur la fiscalit\xE9, les imp\xF4ts \xE0 la retraite sur notre blog Mindquest Connect.":
      "Retrouvez toute l'actualit\xE9 de Mindquest et notre s\xE9lection d'articles pour consultants et freelances IT sur la fiscalit\xE9, les imp\xF4ts \xE0 la retraite sur notre blog Mindquest Connect.",
    "Retrouvez toute l'actualit\xE9 de Mindquest et notre s\xE9lection d'articles pour consultants et freelances IT sur le portage salarial sur notre blog Mindquest Connect.":
      "Retrouvez toute l'actualit\xE9 de Mindquest et notre s\xE9lection d'articles pour consultants et freelances IT sur le portage salarial sur notre blog Mindquest Connect.",
    "Retrouvez toute l'actualit\xE9 de Mindquest et notre s\xE9lection d'articles pour consultants et freelances IT sur nos partenaires sur notre blog Mindquest Connect.":
      "Retrouvez toute l'actualit\xE9 de Mindquest et notre s\xE9lection d'articles pour consultants et freelances IT sur nos partenaires sur notre blog Mindquest Connect.",
    "Retrouvez toute l'actualit\xE9 de Mindquest et notre s\xE9lection d'articles pour consultants et talents en freelance dans l'informatique.":
      "Find all the latest news from Mindquest and our selection of articles for IT Consultants.",
    "Retrouvez toute l'actualit\xE9 de Mindquest et notre s\xE9lection d'articles pour d\xE9cideurs IT (Strat\xE9gie des talents, Recruter un consultant informatique, Risques & erreurs \xE0 \xE9viter, L\xE9gal, Enjeux DSI...).":
      "Find all the latest Mindquest news and our selection of articles for IT decision-makers (Talent strategy, Recruiting an IT consultant, Risks & mistakes to avoid, Legal, IT issues...).",
    "Retrouvez toute l'actualit\xE9 de Mindquest et notre s\xE9lection d'articles pour d\xE9cideurs IT sur la strat\xE9gie des talents sur notre blog Mindquest Connect.":
      "Find all the latest Mindquest news and our selection of articles for IT decision-makers on IT talent strategy.",
    "Retrouvez toute l'actualit\xE9 de Mindquest et notre s\xE9lection d'articles pour d\xE9cideurs IT sur le l\xE9gal sur notre blog Mindquest Connect":
      "Retrouvez toute l'actualit\xE9 de Mindquest et notre s\xE9lection d'articles pour d\xE9cideurs IT sur le l\xE9gal sur notre blog Mindquest Connect",
    "Retrouvez toute l'actualit\xE9 de Mindquest et notre s\xE9lection d'articles pour d\xE9cideurs IT sur le l\xE9gal sur notre blog Mindquest Connect.":
      "Retrouvez toute l'actualit\xE9 de Mindquest et notre s\xE9lection d'articles pour d\xE9cideurs IT sur le l\xE9gal sur notre blog Mindquest Connect.",
    "Retrouvez toute l'actualit\xE9 de Mindquest et notre s\xE9lection d'articles pour d\xE9cideurs IT sur le recrutement de consultants et talents informatiques sur notre blog Mindquest Connect.":
      "Find all the latest Mindquest news and our selection of articles for IT decision-makers on recruiting IT consultants and talent on our blog Mindquest Connect.",
    "Retrouvez toute l'actualit\xE9 de Mindquest et notre s\xE9lection d'articles pour d\xE9cideurs IT sur les enjeux DSI sur notre blog Mindquest Connect.":
      "Find all the latest Mindquest news and our selection of articles for IT decision-makers on CIO issues on our blog Mindquest Connect.",
    "Retrouvez toute l'actualit\xE9 de Mindquest et notre s\xE9lection d'articles pour d\xE9cideurs IT sur les risques et les erreurs \xE0 \xE9viter sur notre blog Mindquest Connect.":
      "Find all the latest Mindquest news and our selection of articles for IT decision-makers on risks and mistakes to avoid on our blog Mindquest Connect.",
    "Retrouvez toute l'actualit\xE9 de Mindquest et notre s\xE9lection d'articles pour d\xE9cideurs IT.":
      "Find all the latest news from Mindquest and our selection of articles for IT decision-makers.",
    "Retrouvez toute l'actualit\xE9 de Mindquest et notre s\xE9lection d'articles pour d\xE9cideurs IT.Retrouvez toute l'actualit\xE9 de Mindquest et notre s\xE9lection d'articles pour connaitre nos actualit\xE9s, nos communiqu\xE9s de presse et nos t\xE9moignages.":
      "Find all the latest news about us and our brand Mindquest.",
    "Retrouvez toute l\u2019actualit\xE9 de Club Freelance et notre s\xE9lection d\u2019articles pour les Freelances informatiques et d\xE9cideurs IT":
      "Find all the news of Mindquest and our selection of articles for IT Candidates and IT decision-makers",
    "Retrouvez toute l\u2019actualit\xE9 de Club Freelance et notre s\xE9lection d\u2019articles pour les Freelances informatiques et d\xE9cideurs IT.":
      "Find all the news of Club Mindquest and our selection of articles for IT Freelancers and IT decision-makers",
    "Retrouvez toutes les replays de nos webinars pour d\xE9cideurs et consultants IT pr\xE9sent\xE9s par Mindquest et ses partenaires comme l'Expert-Comptable.":
      "Find all the replays of our webinars for decision-makers and IT consultants presented by Mindquest and its partners such as the Chartered Accountant.",
    "Retrouvez toutes nos ressources et contenus pour d\xE9cideurs et recruteurs IT (replays de nos webinars, fiches pratiques, guides pratiques, livres blancs...)":
      "Find all our resources and content for decision-makers and IT recruiters (replays of our webinars, practical sheets, practical guides, white papers, etc.)",
    "Retrouvez toutes nos ressources et contenus pour d\xE9cideurs et recruteurs IT (replays de nos webinars, fiches pratiques, guides pratiques, livres blancs...).":
      "Find all our resources and content for IT decision-makers and recruiters (replays of our webinars, practical sheets, practical guides, white papers, etc.).",
    "Retrouvez toutes nos ressources et contenus pour talents et consultants IT (replays de nos webinars, fiches pratiques, guides pratiques, livres blancs...)":
      "Find all our resources and content for IT decision-makers and recruiters (replays of our webinars, practical sheets, practical guides, white papers, etc.).",
    "Retrouvez toutes nos ressources et contenus pour talents et consultants IT (replays de nos webinars, fiches pratiques, guides pratiques, livres blancs...).":
      "Find all our resources and content for IT decision-makers and recruiters (replays of our webinars, practical sheets, practical guides, white papers, etc.).",
    "Revenir \xE0 mes mots cl\xE9s": "Go back to my keywords",
    Revenue: "In revenue",
    "Revues de Presse : DSI - Mindquest Connect":
      "Press reviews: DSI - Mindquest Connect",
    "Revues de presse": "Press reviews",
    "Right to work status / citizenship;":
      "Right to work status / citizenship;",
    "Risques & erreurs \xE0 \xE9viter": "Tips & errors to avoid",
    Romanian: "Romanian",
    Roumain: "Romanian",
    Russe: "Russian",
    Russian: "Russian",
    "Rythme de travail": "Work rate",
    "R\xE9activit\xE9 pour proposer des consultants au bon niveau pour les missions.":
      "Responsiveness to propose consultants at the right level for assignments.",
    Récence: "Work pace",
    "R\xE9cup\xE9rez des mots-cl\xE9s IT": "Extract IT Keywords",
    Référence: "Reference",
    "R\xE9f\xE9rence du bon de commande": "Purchase order reference",
    "R\xE9f\xE9rence mis \xE0 jour avec succ\xE8s":
      "Reference successfully updated",
    Références: "Reference",
    Régie: "On-site",
    "R\xE9gie uniquement": "Exclusively on-site",
    Réinitialiser: "Reset",
    "R\xE9initialiser votre mot de passe": "Reset your password",
    "R\xE9initialisez votre mot de passe": "Reset your password",
    "R\xE9seaux sociaux": "Social networks",
    "S'abonner \xE0 notre newsletter IT": "Connect with our IT Newsletter",
    "S1:": "A1:",
    "S2:": "A2:",
    SAP: "SAP",
    "SAP SD MM Project Manager": "SAP SD MM Project Manager",
    SASU: "SASU",
    SMS: "SMS",
    "Saisir le mot de passe actuel": "Enter the current password",
    "Saisir votre mot de passe": "Enter your password",
    "Saisissez le mot de passe actuel": "Saisissez le mot de passe actuel",
    Salaire: "Salary",
    "Salaire Annuel": "Annual Salary",
    "Salaire annuel": "Annual salary",
    Salarié: "Permanent",
    "Salut {firstName} {lastName}": function(a) {
      return ["Hello ", a("firstName"), " ", a("lastName")];
    },
    "Sans  pr\xE9judice  de  ce  qui  pr\xE9c\xE8de,  la  Soci\xE9t\xE9  demeure  en droit  de  solliciter  des  dommages int\xE9r\xEAts.":
      "Without prejudice to the foregoing, Company remains entitled to claim damages.",
    "Sans pr\xE9judice de ce qui pr\xE9c\xE8de, la Soci\xE9t\xE9 demeure en droit de solliciter des dommages int\xE9r\xEAts.":
      "Without prejudice to the foregoing, Company remains entitled to claim damages.",
    "Sans pr\xE9judice de ce qui pr\xE9c\xE8de, l\u2019Utilisateur est inform\xE9 qu\u2019il demeure libre de ses choix et qu\u2019en tout \xE9tat de cause, la Soci\xE9t\xE9 ne saurait assumer aucun risque en ce qui le concerne.":
      "Without prejudice to the foregoing, User is informed that he/she remains free to choose and that in any event, Company cannot assume any risk in respect of him/her.",
    "Satisfaction surveys;": "Satisfaction surveys;",
    Sauvegarder: "save",
    "Score Smatch": "Smatch Score",
    "Se connecter": "Login",
    "Se d\xE9connecter": "Log out",
    "Se financer": "Finance",
    "Se souvenir de moi": "Remember me",
    "Search for a blog post from our selection of content, news, practical advice and resources for IT Talent and Decision Makers.":
      "Search for a blog post from our selection of content, news, practical advice and resources for IT Talent and Decision Makers.",
    "Search for all our articles and resources":
      "Search for all our articles and resources",
    Secteur: "Industry",
    "Secteur d\u2019activit\xE9": "Business sector",
    "Selected third parties including:": "Selected third parties including:",
    "Sending of e-mails": "Sending of e-mails",
    "Sends emails": "Sends emails",
    Senior: "Senior",
    "Senior (7 - 10)": "Senior (7 - 10)",
    "Senior (7-10)": "Senior",
    "Service  providers:  provision  of  services  on  behalf  of  the  Company  (for  example: maintenance of the website, host, system management and security);":
      "Service  providers:  provision  of  services  on  behalf  of  the  Company  (for  example: maintenance of the website, host, system management and security);",
    "Service providers: provision of services on behalf of the Company (for example: maintenance of the website, host, system management and security);":
      "Service providers: provision of services on behalf of the Company (for example: maintenance of the website, host, system management and security);",
    "Session cookies: are stored on your computer and are deleted when you leave your browser;":
      "Session cookies: are stored on your computer and are deleted when you leave your browser;",
    "Sex/Gender;": "Sex/Gender;",
    "Short sci-fi stories posted every month to promote an open debate on the future of tech.":
      "Short sci-fi stories posted every month to promote an open debate on the future of tech.",
    "Si nous ne sommes pas en mesure de vous accompagner dans cette recherche, vous serez inform\xE9s par email.":
      "If we are not able to assist you in this search, we will inform you via email.",
    "Si vous avez travaill\xE9 \xE0 un taux major\xE9 (astreintes, heures suppl\xE9mentaires major\xE9es...), merci d'utiliser l'espace heures et astreintes.":
      "If you worked under an increased rate, please use the Hours & Penalties.",
    "Si vous n\u2019\xEAtes pas redirig\xE9 dans 3mn, merci de nous contacter":
      "If you are not redirected in the next 3 minutes, please contact us",
    "Si vous \xEAtes interress\xE9 par ce profil ou un candidat similaire, nous vous invitons \xE0 soumettre une nouvelle offre ou \xE0 nous contacter":
      "If you are interested in this candidate or a similar profile, we invite you to submit a new offer or to contact us",
    "Signataire principal": "Principal Signatory",
    Signataires: "Signatories",
    "Signataires secondaires": "Secondary signatories",
    Signatures: "Signatures",
    Signé: "completed",
    "Sinon cliquez sur continuer.": "Otherwise click on continue.",
    "Sit back and relax. We're here to help you build the dream team for business success.":
      "Sit back and relax. We\u2019ve got you covered with the latest recruitment tips and emerging trends.",
    "Sit back, relax and enjoy our in-depth articles on IT talent management and industry trends. We're here to help you build the best possible team for business success.":
      "Sit back, relax and enjoy our in-depth articles on IT talent management and industry trends. We're here to help you build the best possible team for business success.",
    "Site analysis cookie": "Site analysis cookie",
    "Si\xE8ge social : 2 rue Kellermann - 59100 Roubaix, France":
      "Head office: 2 rue Kellermann - 59100 Roubaix, France",
    "Soci\xE9t\xE9 OVH": "OVH Company",
    "Some personal data are required, while others are optional, to access or benefit from certain products and services of the Company.":
      "Some personal data are required, while others are optional, to access or benefit from certain products and services of the Company.",
    "Souhaitez-vous ouvrir le vivier aux membres de votre \xE9quipe ?":
      "Would you like to open the pool to your team members?",
    "Souhaitez-vous ouvrir le vivier \xE0 vos collaborateurs ?":
      "Would you like to share this pool with your collaborators?",
    Soumettre: "Submit",
    "Soumettre au client": "Submit to client",
    "Sourcing Hub": "Sourcing Hub",
    "Sourcing Manager": "Sourcing Manager",
    "Soyez aussi complet que possible. La mise en valeur de vos comp\xE9tences et de votre profil augmentera vos chances de rencontrer l\u2019emploi de vos r\xEAves":
      "Be as comprehensive as you can. The more of your skills and talents you can showcase, the greater the chance of us getting you that dream job",
    "Soyez recontact\xE9 par l'un de nos expert en recrutement : un expert Mindquest vous appellera pour \xE9changer sur votre besoin et vous accompagner dans la cr\xE9ation de votre compte entreprise.":
      "Be contacted by one of our recruitment experts: a Mindquest expert will call you to discuss your needs and help you create your company account.",
    Spanish: "Spanish",
    Spécialité: "Specialty",
    Spécialités: "Specialties",
    Statistiques: "Statistics",
    "Stay connected to the latest news and tips for Tech & IT Consultants - Mindquest Connect":
      "Stay connected to the latest news and tips for Tech & IT Consultants - Mindquest Connect",
    "Stay connected with the Tech & IT sector - Mindquest Connect":
      "Stay connected with the Tech & IT sector - Mindquest Connect",
    "Stay tuned to the latest news and tips for IT Decision Makers - Mindquest Connect":
      "Stay tuned to the latest news and tips for IT Decision Makers - Mindquest Connect",
    "Strat\xE9gie des talents": "Talent strategy",
    Subscribe: "Subscribe",
    "Subscribe to our IT newsletter": "Subscribe to our IT newsletter",
    "Subscribe to our weekly newsletter to get the latest articles and technology news":
      "Subscribe to our weekly newsletter to get the latest articles and technology news",
    "Subsidiaries: for more details on their location, click on this <0>link</0> .":
      "Subsidiaries: for more details on their location, click on this <0>link</0> .",
    "Subsidiaries: for more details on their location, click on this link.":
      "Subsidiaries: for more details on their location, click on this link.",
    "Success Stories": "Success Stories",
    "Successful professional matches": "Successful placements",
    Succès: "Success",
    "Suite \xE0 la validation du Timesheet, le compte-rendu de mois {m}/{y} est disponible": function(
      a
    ) {
      return [
        "Following the timesheet approval of ",
        a("m"),
        "/",
        a("y"),
        " for the contract ",
        a("pID"),
        ", the report is available"
      ];
    },
    "Suite \xE0 la validation du Timesheet, le compte-rendu du de mois de {0} {year} est disponible": function(
      a
    ) {
      return [
        "Following the validation of the Timesheet, the report for the month of ",
        a("0"),
        " ",
        a("year"),
        " is available"
      ];
    },
    "Suite \xE0 la validation du Timesheet, le compte-rendu du de mois de {month}/{year} est disponible": function(
      a
    ) {
      return [
        "Following the validation of the Timesheet, the report for the month of ",
        a("month"),
        " / ",
        a("year"),
        " is available"
      ];
    },
    "Suivez toutes vos candidatures":
      "Track the progress of all your applications",
    "Suivez toutes vos missions": "Track all your vacancies",
    "Suivez vos candidatures dans votre espace personel":
      "Track the progress of all your applications",
    "Suivre les derni\xE8res infos Mindquest":
      "Follow the latest Mindquest news & benefits",
    "Suivre les derni\xE8res infos et avantages Mindquest":
      "Follow the latest Mindquest news & benefits",
    "Suivre les newsletters Mindquest": "Subscribe to Mindquest newsletters",
    Supplémentaire: "Additional",
    "Suppression du compte ou de vos donn\xE9es":
      "Delete my account and my personal informations",
    "Suppression en cours": "Your request will be effective soon",
    Supprimer: "Delete",
    "Supprimer la plage de jours": "Delete the range of days",
    "Supprimer la plage de jours/heures": "Delete the day/time range",
    "Supprimer le profil et vos donn\xE9es personnelles":
      "Delete the profile & your personal informations",
    "Supprimer le th\xE8me": "Delete the theme",
    "Supprimer le vivier": "Delete the pool",
    "Supprimer mon compte et mes donn\xE9es": "Delete my account and data",
    "Supprimer uniquement votre profil Mindquest":
      "Delete only your Mindquest profile",
    "Sur quoi avez-vous travaill\xE9 ?": "What did you work on?",
    "Sur un profil pseudonymis\xE9, le pr\xE9nom et votre photo sont les uniques \xE9l\xE9ments visibles<0/>sur votre identit\xE9":
      "On a pseudonymized profile, the first name and your photo are the only visible elements<0/>of your identity",
    "Surnames, forenames, gender, professional e-mail address;":
      "Surnames, forenames, gender, professional e-mail address;",
    "Syst\xE8mes Embarqu\xE9s": "Embedded systems",
    "S\xE9curit\xE9 des Syst\xE8mes d'Information":
      "Information Systems Security",
    "S\xE9lection d\u2019offres adapt\xE9es \xE0 votre profil. Accompagnement personnalis\xE9. Paiements \xE0 30 jours. Pour tous les Freelances du digital, de la tech et de l\u2019IT":
      "Selection of offers tailored to your profile. Personalized support. Payments at 30 days. For all digital, tech and IT candidates",
    Sélectionner: "Select",
    "S\xE9lectionner depuis la liste ou utiliser la detection automatique":
      "Select from list or try the Auto-detect",
    "S\xE9lectionner des certifications": "Select certifications",
    "S\xE9lectionner des comp\xE9tences": "Select skills",
    "S\xE9lectionner des domaines": "Select areas",
    "S\xE9lectionner des langues": "Select languages",
    "S\xE9lectionner des secteurs": "Select sectors",
    "S\xE9lectionner des sp\xE9cialit\xE9s": "Select specialties",
    "S\xE9lectionner l'exp\xE9rience demand\xE9e": "Select required experience",
    "S\xE9lectionner la Langue par d\xE9fault de votre compte":
      "Select the default language for your account",
    "S\xE9lectionner un secteur": "Select industry",
    "S\xE9lectionner un th\xE8me": "Select a theme",
    "S\xE9lectionnez la zone avant d'ajouter les mots-cl\xE9s":
      "Click on the field before selecting keywords",
    "S\xE9lectionnez votre mod\xE8le et g\xE9n\xE9rez un CV":
      "Your Favourite Resume will be accessible directly from your Dashboard",
    "S\xE9lectionnez votre mod\xE8le et g\xE9n\xE9rez un CV au design moderne, adapt\xE9 aux recuteurs et facile \xE0 diffuser":
      "Select your template and generate a CV with a modern design, adapted to the recruiters and easy to distribute",
    "S\xE9lectionnez votre mod\xE8le et g\xE9n\xE9rez un CV au design moderne, adapt\xE9 aux recuteurs et facile \xE0 diffuser.":
      "Select your template and generate a CV with a modern design, adapted to the recruiters and easy to distribute",
    "TOP 10 experts' articles": "TOP 10 experts' articles",
    "TOP 10 experts' articles -  Mindquest Connect":
      "TOP 10 experts' articles -  Mindquest Connect",
    Taches: "Tasks ",
    "Taille de l\u2019entreprise": "Company's size",
    Talent: "Talent",
    "Talent Community Specialist": "Talent Community Specialist",
    Talents: "Talent",
    "Talents recommand\xE9s": "Recommended candidates",
    "Taux journalier moyen": "Average daily rate",
    "Tech Magazine": "Tech Magazine",
    "Tech and pop culture merge for a quick break from work at any time of the day.":
      "Tech and pop culture meet for a quick break from work at any time of the day.",
    "Tech company": "Tech company",
    "Tech magazine": "Tech Magazine",
    "Technical information, including the Internet protocol (IP) address used to connect your computer to the Internet, your login information if applicable, geographical location, domain name from which you access the internet, browser type, and version;":
      "Technical information, including the Internet protocol (IP) address used to connect your computer to the Internet, your login information if applicable, geographical location, domain name from which you access the internet, browser type, and version;",
    "Technologies Microsoft": "Microsoft Technologies",
    "Temps partiel": "Part-time",
    "Temps plein": "Full-time",
    "Test & Assurance Qualit\xE9": "Testing & Quality Assurance",
    'The  Company  and  its  providers  use cookies  to  improve  your  digital  experience  and  ensure better navigation security. You can accept or refuse these different operations at any time by clicking on "accept" or "refuse" in the cookie configuration panel.':
      'The  Company  and  its  providers  use cookies  to  improve  your  digital  experience  and  ensure better navigation security. You can accept or refuse these different operations at any time by clicking on "accept" or "refuse" in the cookie configuration panel.',
    "The  Company  may  share  personal  data  collected  for  the  purposes  indicated  below  with  the following recipients:":
      "The  Company  may  share  personal  data  collected  for  the  purposes  indicated  below  with  the following recipients:",
    'The Company and its providers use cookies to improve your digital experience and ensure better navigation security. You can accept or refuse these different operations at any time by clicking on "accept" or "refuse" in the cookie configuration panel.':
      'The Company and its providers use cookies to improve your digital experience and ensure better navigation security. You can accept or refuse these different operations at any time by clicking on "accept" or "refuse" in the cookie configuration panel.',
    "The Company can offer you advertising content that can be based on your interests.":
      "The Company can offer you advertising content that can be based on your interests.",
    "The Company may share personal data collected for the purposes indicated below with the following recipients:":
      "The Company may share personal data collected for the purposes indicated below with the following recipients:",
    "The Company reserves the right to make any amendment to this Personal Data Policy at any time in accordance with this clause.":
      "The Company reserves the right to make any amendment to this Personal Data Policy at any time in accordance with this clause.",
    "The Company therefore invites you to consult this page regularly.":
      "The Company therefore invites you to consult this page regularly.",
    "The Company, as the Controller, requests and collects personal data concerning the individual concerned  when  the  latter wishes  to  acquire  the  products  and/or  services  offered  by  the Company.":
      "The Company, as the Controller, requests and collects personal data concerning the individual concerned  when  the  latter wishes  to  acquire  the  products  and/or  services  offered  by  the Company.",
    "The Company, as the Controller, requests and collects personal data concerning the individual concerned when the latter wishes to acquire the products and/or services offered by the Company.":
      "The Company, as the Controller, requests and collects personal data concerning the individual concerned when the latter wishes to acquire the products and/or services offered by the Company.",
    "The Company\u2019s communication campaigns;":
      "The Company\u2019s communication campaigns;",
    "The GDPR gives you the right to access information held about you. We also encourage you to contact us to ensure your data is accurate and complete. A subject access request should be submitted to Club Freelance at contact@club-freelance.com.":
      "GDPR gives you the right to access information held about you. We also encourage you to contact us to ensure your data is accurate and complete. A subject access request should be submitted to Club Freelance at contact@club-freelance.com.",
    "The main purpose of collecting, storing and processing this information and personal data is to:":
      "The main purpose of collecting, storing and processing this information and personal data is to:",
    "The manner in which the Company processes personal data, as defined below, which it collects  and  which  the  data  subjects,  as  defined  below, provide  with  their  consent  to enable the supply of, inter alia, the Company\u2019s products and services;":
      "The manner in which the Company processes personal data, as defined below, which it collects  and  which  the  data  subjects,  as  defined  below, provide  with  their  consent  to enable the supply of, inter alia, the Company\u2019s products and services;",
    "The manner in which the Company processes personal data, as defined below, which it collects and which the data subjects, as defined below, provide with their consent to enable the supply of, inter alia, the Company\u2019s products and services;":
      "The manner in which the Company processes personal data, as defined below, which it collects  and  which  the  data  subjects,  as  defined  below, provide  with  their  consent  to enable the supply of, inter alia, the Company\u2019s products and services;",
    "The name of the processor, as defined below;":
      "The name of the processor, as defined below;",
    "The person concerned has given his or her consent;":
      "The person concerned has given his or her consent;",
    "The processing is in pursuit of the legitimate interests of the controller.":
      "The processing is in pursuit of the legitimate interests of the controller.",
    "The purpose of this policy is to present to data subjects:":
      "The purpose of this policy is to present to data subjects:",
    "The right of access to the personal data Club Freelance processes on you:":
      "The right of access to the personal data Club Freelance processes on you:",
    "The right to complain": "The right to complain",
    "The right to data portability in certain circumstances:":
      "The right to data portability in certain circumstances:",
    "The right to erasure of your personal data in certain circumstances:":
      "The right to erasure of your personal data in certain circumstances:",
    "The right to object to the processing of your personal data that was based on our legitimate interest or for direct marketing purposes:":
      "The right to object to the processing of your personal data that was based on our legitimate interest or for direct marketing purposes:",
    "The right to rectification of your personal data:":
      "The right to rectification of your personal data:",
    "The right to withdraw consent:": "The right to withdraw consent:",
    "The rights of data subjects, as defined below;":
      "The rights of data subjects, as defined below;",
    "The site may occasionally contain links to the websites of our partners or third companies.":
      "The site may occasionally contain links to the websites of our partners or third companies.",
    "Their IT and human expertise led to the birth of Club Freelance. It can also help you.":
      "Their IT and human expertise led to the birth of Mindquest. It can also help you.",
    "There are numerous ways you can share your information with us - it all depends on what suits you. These may include:":
      "There are numerous ways you can share your information with us - it all depends on what suits you. These may include:",
    "These  elements  can  be  grouped  into  two  categories  according  to  the  data  subjects, namely Talents and Clients.":
      "These  elements  can  be  grouped  into  two  categories  according  to  the  data  subjects, namely Talents and Clients.",
    "These companies trust us with their IT talent needs":
      "These companies trust us for their IT talent needs",
    "These cookies allow us to assess the performance of the site and measure the audience. They also make it possible to find out which pages the users visit on our website. This information is collected by these cookies in anonymised form.":
      "These cookies allow us to assess the performance of the site and measure the audience. They also make it possible to find out which pages the users visit on our website. This information is collected by these cookies in anonymised form.",
    "These cookies are essential for the functioning of the site. In accordance with the applicable law, cookies strictly necessary for the operation of the website are exempted from collecting users\u2019 consent.":
      "These cookies are essential for the functioning of the site. In accordance with the applicable law, cookies strictly necessary for the operation of the website are exempted from collecting users\u2019 consent.",
    "These elements can be grouped into two categories according to the data subjects, namely Talents and Clients.":
      "These elements can be grouped into two categories according to the data subjects, namely Talents and Clients.",
    "Third  party  cookies:  cookies  created  and  used  on  the  website  by  external providers.":
      "Third  party  cookies:  cookies  created  and  used  on  the  website  by  external providers.",
    "Third party cookies: cookies created and used on the website by external providers.":
      "Third party cookies: cookies created and used on the website by external providers.",
    "Third-party links": "Third-party links",
    "This Privacy Notice sets out the basis on which we collect, store, use and disclose personal data we receive in writing, through our Websites, or through the recruitment services that we provide. It therefore applies to personal data that you provide to Club Freelance telephonically, electronically (including email) and in person.":
      "This Privacy Notice sets out the basis on which we collect, store, use and disclose personal data we receive in writing, through our Websites, or through the recruitment services that we provide. It therefore applies to personal data that you provide to Club Freelance telephonically, electronically (including email) and in person.",
    "This Privacy Policy was last updated in January 2019. The Privacy policy may be updated from time to time and should be read in the context of any additional specific information such as that provided in privacy policies applicable to specific businesses or local areas as displayed on the relevant Website or distributed to you from time to time.":
      "This Privacy Policy was last updated in January 2019. The Privacy policy may be updated from time to time and should be read in the context of any additional specific information such as that provided in privacy policies applicable to specific businesses or local areas as displayed on the relevant Website or distributed to you from time to time.",
    "This enables you to ask us to delete or remove personal information where there is no good reason for us continuing to process it. You also have the right to ask us to delete or remove your personal information where you have exercised your right to object to processing (see below).":
      "This enables you to ask us to delete or remove personal information where there is no good reason for us continuing to process it. You also have the right to ask us to delete or remove your personal information where you have exercised your right to object to processing (see below).",
    "This enables you to ask us to suspend the processing of personal information about you, in certain circumstances for example if you want us to establish its accuracy or the reason for processing it.":
      "This enables you to ask us to suspend the processing of personal information about you, in certain circumstances for example if you want us to establish its accuracy or the reason for processing it.",
    "This information is necessary for the Company to perform the services properly and to enable it to comply with its legal obligations.":
      "This information is necessary for the Company to perform the services properly and to enable it to comply with its legal obligations.",
    "This right enables you to object to us processing your personal data including where we do so for our legitimate interests, or for direct marketing purposes.":
      "This right enables you to object to us processing your personal data including where we do so for our legitimate interests, or for direct marketing purposes.",
    "Thoughts and announcements from our leadership team. Their IT and human expertise led to the birth of Club Freelance, and it can also help you.":
      "Thoughts and announcements from our leadership team. Their IT and human expertise led to the birth of Club Freelance, and it can also help you.",
    "Thus, the collection and processing of personal data carried out by the Company in the course of  the  operation  of  its  business  and  use  of  the  goods  and  services, including  the  website operated   by   the   Company   at <0>https://mindquest.io</0> (\u2018the <1>Site</1> \u2019),   are   governed   by this confidentiality policy (hereinafter referred to as \u2018the <2>Policy</2>\u2019).":
      "Thus, the collection and processing of personal data carried out by the Company in the course of  the  operation  of  its  business  and  use  of  the  goods  and  services, including  the  website operated   by   the   Company   at <0>https://mindquest.io</0> (\u2018the <1>Site</1> \u2019),   are   governed   by this privacy policy (hereinafter referred to as \u2018the <2>Policy</2>\u2019).",
    "Thus, the collection and processing of personal data carried out by the Company in the course of  the  operation  of  its  business  and  use  of  the  goods  and  services, including  the  website operated   by   the   Company   at<0>https://mindquest.io</0> (\u2018the <1>Site</1> \u2019),   are   governed   by this confidentiality policy (hereinafter referred to as \u2018the <2>Policy</2>\u2019).":
      "Thus, the collection and processing of personal data carried out by the Company in the course of  the  operation  of  its  business  and  use  of  the  goods  and  services, including  the  website operated   by   the   Company   at <0>https://mindquest.io</0> (\u2018the <1>Site</1> \u2019),   are   governed   by this privacy policy (hereinafter referred to as \u2018the <2>Policy</2>\u2019).",
    "Thus, the collection and processing of personal data carried out by the Company in the course of the operation of its business and use of the goods and services, including the website operated by the Company at<0>https://mindquest.io</0> (\u2018the <1>Site</1> \u2019), are governed by this confidentiality policy (hereinafter referred to as \u2018the <2>Policy</2>\u2019).":
      "Thus, the collection and processing of personal data carried out by the Company in the course of  the  operation  of  its  business  and  use  of  the  goods  and  services, including  the  website operated   by   the   Company   at <0>https://mindquest.io</0> (\u2018the <1>Site</1> \u2019),   are   governed   by this privacy policy (hereinafter referred to as \u2018the <2>Policy</2>\u2019).",
    Thème: "Theme",
    "Time limit to respond": "Time limit to respond",
    Timesheet: "Timesheet",
    "Timesheet Accept\xE9": "Timesheet Accepted",
    "Timesheet Ouvert": "Timesheet Open",
    "Timesheet Rejet\xE9": "Timesheet Rejected",
    "Timesheet Soumis": "Timesheet Submitted",
    "Timesheet enregistr\xE9": "Timesheet saved",
    "Timesheet envoy\xE9": "Timesheet sent",
    "Timesheet envoy\xE9 au client": "Timesheet submitted to the client",
    "Timesheet rejet\xE9e": "Timesheet rejected",
    "Timesheet valid\xE9": "Timesheet validated",
    "Timesheet vide": "Timesheet blank",
    "Timesheet {month}/{year} du contrat num\xE9ro: {placementID} a \xE9t\xE9 rejet\xE9 par le client. Merci d'effetuer les modifications n\xE9cessaires": function(
      a
    ) {
      return [
        "Timesheet ",
        a("month"),
        "/",
        a("year"),
        " of contract number: ",
        a("placementID"),
        " was rejected by the client. Please make the necessary changes"
      ];
    },
    "Timesheet {month}/{year} du contrat num\xE9ro: {placementID} a \xE9t\xE9 valid\xE9 par le client": function(
      a
    ) {
      return [
        "Timesheet ",
        a("month"),
        "/",
        a("year"),
        " of contract number: ",
        a("placementID"),
        " was validated by the client"
      ];
    },
    "Timesheet {m}/{y} du contrat num\xE9ro: {pID} a \xE9t\xE9 rejet\xE9 par le client. Merci d'effetuer les modifications n\xE9cessaires": function(
      a
    ) {
      return [
        "The timesheet of ",
        a("m"),
        "/",
        a("y"),
        " for the contract ",
        a("pID"),
        " has been rejected, please make the appropriate changes"
      ];
    },
    "Timesheet {m}/{y} du contrat num\xE9ro: {pID} a \xE9t\xE9 valid\xE9 par le client": function(
      a
    ) {
      return [
        "The timesheet of ",
        a("m"),
        "/",
        a("y"),
        " for the contract ",
        a("pID"),
        " has been validated"
      ];
    },
    Timesheets: "Timesheets",
    "Tips & Tricks": "Tips & Tricks",
    "Tips for your daily life as a freelancer":
      "Tips for your daily life as a freelancer",
    "Tips for your daily life as a freelancer - Mindquest Connect":
      "Tips for your daily life as a freelancer - Mindquest Connect",
    Titre: "Title",
    "To answer any questions you have about a job or the job market;":
      "To answer any questions you have about a job or the job market;",
    "To answer any questions you have about a vacancy or a candidate or your company\u2019s contract with Club Freelance;":
      "To answer any questions you have about a vacancy or a candidate or your company\u2019s contract with Club Freelance;",
    "To collect any money due, or allegedly due, to Club Freelance or any Club Freelance client (or Club Freelance client\u2019s client);":
      "To collect any money due, or allegedly due, to Club Freelance or any Club Freelance client (or Club Freelance client\u2019s client);",
    "To communicate with you after your company has retained a Club Freelance candidate to make sure all is going well and to remedy, or attempt to remedy, any problems;":
      "To communicate with you after your company has retained a Club Freelance candidate to make sure all is going well and to remedy, or attempt to remedy, any problems;",
    "To communicate with you or your company after you have started a job to make sure all is going well or to remedy, or attempt to remedy, any problems;":
      "To communicate with you or your company after you have started a job to make sure all is going well or to remedy, or attempt to remedy, any problems;",
    "To contact you about candidates for jobs with whom Club Freelance has a relationship;":
      "To contact you about candidates for jobs with whom Club Freelance has a relationship;",
    "To contact you or your company about jobs that Club Freelance is filling or may fill for Club Freelance clients;":
      "To contact you or your company about jobs that Club Freelance is filling or may fill for Club Freelance clients;",
    "To determine the appropriate retention period for personal data, we consider the amount, nature, and sensitivity of the personal data, the potential risk of harm from unauthorised use or disclosure of your personal data, the purposes for which we process your personal data and whether we can achieve those purposes through other means, and the applicable legal requirements.":
      "To determine the appropriate retention period for personal data, we consider the amount, nature, and sensitivity of the personal data, the potential risk of harm from unauthorised use or disclosure of your personal data, the purposes for which we process your personal data and whether we can achieve those purposes through other means, and the applicable legal requirements.",
    "To enable the Company to pursue the processing purposes specified herein;":
      "To enable the Company to pursue the processing purposes specified herein;",
    "To establish, exercise or defend any legal claims.":
      "To establish, exercise or defend any legal claims.",
    "To establish, exercise or defend any legal claims; and To assist you or your company if you are dissatisfied or dislike the job, or any aspect of it.":
      "To establish, exercise or defend any legal claims; and To assist you or your company if you are dissatisfied or dislike the job, or any aspect of it.",
    "To fill an open vacancy at your company;":
      "To fill an open vacancy at your company;",
    "To fulfil contractual obligations;": "To fulfil contractual obligations;",
    "To fulfill any aspect your company\u2019s contract with Club Freelance;":
      "To fulfill any aspect your company\u2019s contract with Club Freelance;",
    "To help find you a job;": "To help find you a job;",
    "To maintain, expand and develop our business we need to record the personal data of prospective candidates and client contacts.":
      "To maintain, expand and develop our business we need to record the personal data of prospective candidates and client contacts.",
    "To negotiate and fulfill any aspect of your company\u2019s contract with Club Freelance;":
      "To negotiate and fulfill any aspect of your company\u2019s contract with Club Freelance;",
    "To obtain or inquire about any property (including computers and confidential business information) owned, or allegedly owned, by Club Freelance or any Club Freelance client (or Club Freelance\u2019s client\u2019s client);":
      "To obtain or inquire about any property (including computers and confidential business information) owned, or allegedly owned, by Club Freelance or any Club Freelance client (or Club Freelance\u2019s client\u2019s client);",
    "To provide you or your company with information about the job market;":
      "To provide you or your company with information about the job market;",
    "To provide you with information about the job market;":
      "To provide you with information about the job market;",
    "To resolve any issue with the issuance, payment, collection or enforcement of a Club Freelance invoice;":
      "To resolve any issue with the issuance, payment, collection or enforcement of a Club Freelance invoice;",
    "To set cookies on your browser, you will find below the browsers:":
      "To set cookies on your browser, you will find below the browsers:",
    "Today Web UI Kit is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book\u2026":
      "Today Web UI Kit is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book\u2026",
    "Top Ten": "Top Ten",
    Total: "Total",
    Totale: "Total",
    "Toujours \xE0 l'\xE9coute des besoins et professionnel.":
      "Professional and always attentive to needs.",
    Tous: "All",
    "Tous  les  frais  support\xE9s  par  l'Utilisateur  pour  acc\xE9der  au  service  (mat\xE9riel  informatique, logiciels, connexion Internet, etc.) sont \xE0 sa charge. La Soci\xE9t\xE9 ne prend pas en charge ces co\xFBts d\u2019acc\xE8s.":
      "Users shall bear all the costs incurred in accessing the Platform (hardware, software, internet connection, etc.) The Company does not cover these access costs.",
    "Tous droits r\xE9serv\xE9s Club Freelance Limited - soci\xE9te de recrutement sp\xE9cialis\xE9e dans l'informatique.":
      "All rights reserved Club Freelance Limited - specialist IT recruitment company.",
    "Tous les frais support\xE9s par l'Utilisateur pour acc\xE9der au service (mat\xE9riel informatique, logiciels, connexion Internet, etc.) sont \xE0 sa charge. La Soci\xE9t\xE9 ne prend pas en charge ces co\xFBts d\u2019acc\xE8s.":
      "Users shall bear all the costs incurred in accessing the Platform (hardware, software, internet connection, etc.) The Company does not cover these access costs.",
    "Tous les talents": "All Talents",
    "Tous les th\xE8mes": "All themes",
    "Tous les types d'heures": "All types of hours",
    "Tous nos conseils pour les freelances.":
      "All our tips for IT professionals",
    "Tous vos collaborateurs": "All your collaborators",
    "Tous vos th\xE8mes": "All your themes",
    "Toutefois, en cas de d\xE9faut dans la s\xE9curit\xE9 des donn\xE9es et de la Plateforme, la Soci\xE9t\xE9 ne saurait \xEAtre  tenue  \xE0  aucun  dommage-int\xE9r\xEAt  ou  r\xE9paration  \xE0 l\u2019\xE9gard  de  quiconque ;  ce  que  les Utilisateurs reconnaissent et acceptent.":
      "However, in the event of a defect in the security of the data and of the Platform, the Company shall  not  be  liable  for  any  damages  or  compensation  in  respect  of  any  person;  what  User recognises and accept.",
    "Toutefois, en cas de d\xE9faut dans la s\xE9curit\xE9 des donn\xE9es et de la Plateforme, la Soci\xE9t\xE9 ne saurait \xEAtre tenue \xE0 aucun dommage-int\xE9r\xEAt ou r\xE9paration \xE0 l\u2019\xE9gard de quiconque ; ce que les Utilisateurs reconnaissent et acceptent.":
      "However, in the event of a defect in the security of the data and of the Platform, the Company shall  not  be  liable  for  any  damages  or  compensation  in  respect  of  any  person;  what  User recognises and accept.",
    "Toutefois, la Soci\xE9t\xE9 se r\xE9serve le droit, sans pr\xE9avis, ni indemnit\xE9, de fermer temporairement ou d\xE9finitivement la Plateforme notamment pour effectuer une mise \xE0 jour, des op\xE9rations de maintenance, des modifications ou changements sur les m\xE9thodes op\xE9rationnelles, les serveurs et les heures d\u2019accessibilit\xE9, sans que cette liste ne soit limitative.":
      "The Company reserves the right, without notice or indemnity, to close the Platform temporarily or permanently, in particular to carry out updates, maintenance operations, changes or changes to operational methods, servers and accessibility times, but this list is not exhaustive.",
    "Toutes les offres archiv\xE9es": "All archived vacancies",
    "Toutes les offres en cours": "All active offers",
    "Toutes nos missions": "Find a job",
    Travaillées: "Worked",
    Trier: "Sort",
    "Triez les comp\xE9tances par ordre d\u2019importance":
      "Sort the skills in order of importance",
    "Trouver un consultant IT": "Find an IT expert",
    "Trouver un freelance": "Find the right candidate",
    "Trouver un talent": "Find a talent",
    "Trouver une mission": "Find a job",
    "Trouver une mission freelance": "Find your next job",
    "Trouver une offre": "Find an offer",
    "Trouvez d\xE8s maintenant les profils les plus pertinents gr\xE2ce \xE0 notre algorithme de matching SMATCH":
      "Find the most relevant profiles now thanks to our SMATCH matching algorithm",
    "Trouvez le job IT qui vous correspond vraiment":
      "and take your career to new heights",
    "Trouvez les plus belles offres IT et les meilleurs consultants Data, D\xE9veloppement, Cloud, Gestion de projet, CRM, Support, BI, Webdesign, ERP, SAP, Oracle...":
      "Your partner for IT jobs and Talent recruitment. Permanent or contract: find the best IT experts to accelerate your business projects. A dedicated account manager and sole point of contact. No prepayments.",
    "Trouvez votre consultant IT avec notre algorithme de matching SMATCH":
      "Find your IT consultant with our SMATCH matching algorithm",
    "Trouvez votre consultant IT avec notre<0/>algorithme de matching SMATCH":
      "Find your IT consultant with our <0/> SMATCH matching algorithm",
    "Trouvez votre futur talent IT en utilisant notre<0/>algorithme de matching SMATCH":
      "Find your IT talent with our<0/>SMATCH matching algorithm",
    "Trouvez votre prochain job IT":
      "Join the Mindquest Community and take your career to new heights",
    "Tr\xE8s grande \xE9coute du besoin et profil des candidats tr\xE8s adapt\xE9.":
      "Very good listening to the needs, and very suitable candidate profiles.",
    Turkish: "Turkish",
    Turque: "Turkish",
    Type: "Type",
    "Type de contract": "Employment Type",
    "Type de contrat": "Employment type",
    "Type de diplome": "Degree type",
    Tâche: "Task",
    Tâches: "Tasks",
    "T\xE2ches r\xE9alis\xE9es": "Completed tasks",
    "T\xE9l. +33 (0)8 99 70 17 61": "Tel +33 (0)8 99 70 17 61",
    Télécharger: "Download",
    "T\xE9l\xE9charger ce mod\xE9le": "Download this template",
    "T\xE9l\xE9charger la ressource": "Download resource",
    "T\xE9l\xE9charger le guide d\u2019utilisation": "Download user guide",
    "T\xE9l\xE9charger le template de facture de Mindquest":
      "Download Mindquest Invoice Template",
    "T\xE9l\xE9charger ton CV favori": "Download your favourite template",
    "T\xE9l\xE9charger votre CV favori":
      "Download your favourite resume template",
    Télécomunication: "Telecommunication",
    Téléphone: "Phone",
    "T\xE9l\xE9phone : +33 652 72 16 50": "Phone : +33 652 72 16 50",
    Télétravail: "Remote",
    Témoignages: "Testimonials",
    URGENT: "URGENT",
    "URL LinkedIn": "URL LinkedIn",
    "UX /UI & Graphic Design": "UX /UI & Graphic Design",
    Un: "A",
    "Un Account Manager sp\xE9cialis\xE9 sur votre secteur vous rappelle pour pr\xE9ciser votre besoin":
      "Our Account Manager gets in touch to determine the specifics of your project",
    "Un Key Account Manager d\xE9di\xE9":
      "A dedicated account manager and sole point of contact",
    "Un Key Account Manager d\xE9di\xE9 vous guidera dans vos projets":
      "A dedicated Key Account Manager will guide you through the process",
    "Un accompagnement personnalis\xE9 par nos \xE9quipes":
      "Personalised support by our tech-savvy career experts",
    "Un accompagnement<0/>personnalis\xE9 par nos \xE9quipes":
      "Personalised support by our tech-savvy career experts",
    "Un community specialist vous appellera pour compl\xE9ter votre profil.":
      "A  talent community specialist will call you to complete your profile",
    "Un compte a d\xE9j\xE0 \xE9t\xE9 cr\xE9\xE9 avec cet email":
      "An account has already been created with this email address",
    "Un conseiller vous appelera pour compl\xE9ter votre profil.":
      "An account manager will give you a call back to better understand your needs",
    "Un cv actualis\xE9 au format Word facilitera les \xE9changes avec nos \xE9quipes":
      "An updated CV in a Word format will make it easier for our team to contact you",
    "Un e-mail de validation vous a \xE9t\xE9 envoy\xE9 pour v\xE9rifier votre compte.":
      "A validation email has been sent to you to verify your account.",
    "Un email de confirmation vous a \xE9t\xE9 envoy\xE9 sur votre nouvelle adresse. Une fois confirm\xE9, le changement sera effectif dans un d\xE9lais de 24 heures":
      "A confirmation email has been sent to your new email address. Once confirmed, the change will be effective within the next 24 hours",
    "Un expert Mindquest vous appelera pour compl\xE9ter votre profil.":
      "An account manager will give you a call back to better understand your needs",
    "Un intitul\xE9 de poste": "Job title",
    "Un probleme de t\xE9l\xE9chargement de contrat":
      "Problem while downloading contract",
    "Un probleme est survenu lors de la creation de votre candidature":
      "An error has occurred while sending your application",
    "Un probleme est survenu, veuillez essayer plus tard":
      "Something went wrong, please try again later",
    "Un probl\xE8me est survenu": "Something went wrong",
    "Un probl\xE8me est survenu lors de la cr\xE9ation de besoin":
      "A problem has occurred while creating your request",
    "Un probl\xE8me est survenu lors de la cr\xE9ation de l'abonnement":
      "An error has occurred while subscribing",
    "Un probl\xE8me est survenu lors de la cr\xE9ation de l\u2019abonnement":
      "An error has occurred while subscribing",
    "Un probl\xE8me est survenu lors de la cr\xE9ation de votre candidature":
      "An error has occurred while creating your application",
    "Un probl\xE8me est survenu lors de la cr\xE9ation d\u2019alerte":
      "An error has occurred while creating the alert",
    "Un responsable de compte vous recontactera":
      "An account manager will contact you shortly",
    "Un r\xE9seau de partenaires pour notre communaut\xE9":
      "A network of partners for our community",
    "Un seul article trouv\xE9": "Only one article found",
    "Under Construction": "Under Construction",
    "Une Solution compl\xE8te de talent management":
      "A comprehensive talent management solution",
    "Une approche unique": "Our unique approach",
    "Une communaut\xE9 au coeur de notre service.":
      "Our talent community is at the very heart of our service",
    "Une double expertise": "Our combined expertise",
    "Une erreur est survenue, veuillez r\xE9essayer ult\xE9rieurement":
      "An error has occurred, please try again later",
    "Une erreur s\u2019est produite, veuillez r\xE9essayer":
      "An error has occurred, please try again",
    "Une expertise technique des interlocuteurs qui permet une recherche de candidats tr\xE8s pointue !":
      "A technical expertise that allows a very precise search for candidates!",
    "Une fois le candidat choisi, nous vous accompagnons dans le process d\u2019onboarding et tout au long de la mission":
      "Once you have picked your candidate, we assist you with the onboarding",
    "Une fois le timesheet rejet\xE9, vous ne pourrez plus revenir en arri\xE8re. Merci de v\xE9rifier les informations suivantes avec attention.":
      "Once the timesheet has been rejected, you will not be able to go back. Please check the following information carefully.",
    "Une fois le timesheet valid\xE9, vous ne pourrez plus revenir en arri\xE8re. Merci de v\xE9rifier les informations suivantes avec attention.":
      "Once the timesheet has been validated, you will not be able to go back. Please check the following information carefully.",
    "Une organisation agile": "An agile organisation",
    "Une puissante Market Intelligence":
      "Providing valuable market intelligence",
    "Une question d'ordre juridique ?": "A legal question ?",
    "Une recherche trop pr\xE9cise peut vous faire passer \xE0 c\xF4t\xE9 d\u2019une opportunit\xE9 en or. Ces informations nous aident \xE9galement \xE0 vous recommander de nouveaux projets.":
      "Don\u2019t be too picky! You never know what golden opportunities you might be missing when you are too narrow in your search  ",
    "Une shortlist des meilleurs candidats pour tous vos besoins IT":
      "A shortlist of top candidates within 48h",
    "Une s\xE9lection d\u2019offres adapt\xE9es \xE0 votre profil":
      "Targeted, high-quality offers that check all your boxes",
    "Une s\xE9lection d\u2019offres<0/>adapt\xE9es \xE0 votre profil":
      "Targeted, high-quality offers that check all your boxes",
    "Une vision 360 du march\xE9": "A 360-degree vision of the market",
    "Updated on 1 February 2022": "Updated on 1 February 2022",
    "Uploader ma fiche de poste": "Upload a job decription",
    "Uploader mon CV": "Upload your resume",
    "Uploader mon CV<0><1/><2>Importez un CV au format .docx, . doc ou . pdf. Vos donn\xE9es seront automatiquement extraites pour compl\xE9ter rapidement votre profil (recommand\xE9)</2><3/></0>":
      "Upload my CV <0> <1 /> <2> Upload a CV in .docx format,. doc or. pdf. Your data will be automatically extracted to quickly complete your profile (recommended) </2> <3 /> </0>",
    "Utilisez cette case pour indiquer des heures suppl\xE9mentaires ou un tarif major\xE9 \xE0 votre client et \xE0 notre service de facturation":
      "Use this box to indicate extra-hours or an increased rate to your client and our Invoicing service",
    "Utilisez les champs ci-dessus pour ajouter des heures":
      "Use the above fields to add hours",
    "Utilisez notre algorithme SMATCH pour recruter les meilleurs talents Tech & IT en CDI ou Freelance (Data, D\xE9veloppement, Cloud, Gestion de projet, CRM, Support, BI, ERP, SAP, Oracle, Infra...).":
      "Use our SMATCH algorithm to recruit the best Tech & IT talents on permanent or freelance contracts (Data, Development, Cloud, Project Management, CRM, Support, BI, ERP, SAP, Oracle, Infra...).",
    Valider: "Confirm",
    "Valider Timesheet": "Cofirm timesheet",
    "Valider le timesheet": "Confirm the timesheet",
    Validés: "Confirmed",
    "Veillez entrer un email valide": "Please enter a valid email address",
    "Veuillez accepter la politique de confidentialit\xE9":
      "Please accept our privacy policy",
    "Veuillez accepter notre politique de confidentialit\xE9":
      "Please accept our privacy policy",
    "Veuillez ajouter une d\xE9scription de la mission":
      "A description is required",
    "Veuillez enregistrer avant de passer \xE0 l\u2019\xE9tape suivante":
      "Please save before moving to the next step",
    "Veuillez entrer une address valide": "Please enter a valid address",
    "Veuillez entrer votre mot de passe pour continuer":
      "Please enter your password to continue",
    "Veuillez entrer votre nom": "Please enter your last name",
    "Veuillez entrer votre pr\xE9nom": "Please enter your first name",
    "Veuillez indiquer votre localisation": "Please indicate your location",
    "Veuillez noter que ce commentaire sera visible par le consultant":
      "Please note that this comment will be visible by the consultant",
    "Veuillez noter que ces sites internet ont leur propre politique de confidentialit\xE9 et que la Soci\xE9t\xE9 d\xE9cline toute responsabilit\xE9 quant \xE0 l\u2019utilisation faite par ces sites des informations collect\xE9es lorsque vous cliquez sur ces liens. La Soci\xE9t\xE9 vous invite \xE0 prendre connaissance de politiques de confidentialit\xE9 de ces sites avant de leur transmettre vos donn\xE9es personnelles.":
      "Please note that these websites have their own privacy policy and that Company accepts no responsibility for the use by these websites of the information collected when you click on these links. Company invites you to acquaint yourself with the confidentiality policies of these sites before sending them your personal data.",
    "Veuillez patienter ...": "Please wait ...",
    "Veuillez selectionner au moin un filtre pour cr\xE9er une alerte":
      "Please select at least one filter to create an alert",
    "Veuillez valider la ReCaptcha": "I am not a robot",
    "Veuillez valider la certification ouverte avant l\u2019enregistrement":
      "Please validate the opened certification before saving",
    "Veuillez valider la formation ouverte avant l\u2019enregistrement":
      "Please validate the opened education before saving",
    "Veuillez valider l\u2019exp\xE9rience ouverte avant l\u2019enregistrement":
      "Please validate the opened experience before saving",
    "Videos qui peuvent vous int\xE9resser":
      "Videos you might be interested in",
    Vidéos: "Videos",
    Ville: "City",
    "Ville de r\xE9sidence": "City of residence",
    "Viviers Mindquest": "Talent Pool Mindquest",
    "Voir Mon CV": "View my resume",
    "Voir ce vivier": "View talent pool",
    "Voir ces top talents": "Consult mindquest pool",
    "Voir la FAQ": "FAQ",
    "Voir la video": "See the video",
    "Voir le Timesheet": "See the Timesheet",
    "Voir le profil": "See profile",
    "Voir le profil complet": "View full profile",
    "Voir les mod\xE8les": "See templates",
    "Voir les offres": "See offers",
    "Voir les ressources": "See resources",
    "Voir l\u2019offre": "See offer",
    "Voir plus": "See more",
    "Voir plus de vid\xE9os": "More videos",
    "Voir plus d\u2019articles": "More articles",
    "Voir ses offres": "See offers",
    "Voir tous les talents": "See all talents",
    "Voir toutes les offres en cours": "View all active offers",
    "Voir toutes mes missions": "See more",
    "Vos collaborateur": "Your collaborators",
    "Vos coordonn\xE9es nous permettront de vous contacter d\xE8s lors qu\u2019une opportunit\xE9 correspond \xE0 votre profil.":
      "Let us know where to best reach you when we have an opportunity for you",
    "Vos donn\xE9es LinkedIn ont \xE9t\xE9 import\xE9es avec succ\xE8s. Veuillez les enregistrer.":
      "Your LinkedIn data has been successfully imported. Please save your progress",
    "Vos futures connexions \xE0 votre espace personnel se feront avec votre nouvelle adresse email":
      "Your next connections to your personal space will be done with your new email address",
    "Votre CV est en cours de g\xE9n\xE9ration . . .":
      "Your Resume is being generated . . . ",
    "Votre CV favori sera accessible directement depuis votre Dashboard":
      "Your favourite RESUME will be accessible directly from your Dashboard",
    "Votre Profil pseudonymis\xE9 sera accecible aux d\xE9cideurs IT vous s\xE9lectionnant<0/>au sein de leurs viviers talents. Ils pourront visualiser vos exp\xE9riences et les<1/>autres contenus professionnels que vous avec ajout\xE9s sur votre profil.":
      "Your pseudonymized Profile will be accessible to IT decision makers selecting you<0/>from their talent pools. They will be able to view your experiences and the<1/>other professional content you have added to your profile.",
    "Votre Talent Community Specialist vous rappelle pour comprendre vos besoins":
      "One of our talent community specialists will call you to better understand your needs and expectations",
    "Votre Timesheet du mois n\u2019est pas ouvert?":
      "Is your Timesheet of the month not open?",
    "Votre dashboard est en cours de cr\xE9ation et sera accessible dans quelques instants.":
      "Your dashboard is being generated and will be available in a few minutes",
    "Votre demande a bien \xE9t\xE9 envoy\xE9e pour validation!":
      "Your request has been sent for validation!",
    "Votre demande de collaboration a bien \xE9t\xE9 renvoy\xE9e":
      "Your request for collaboration has been sent again",
    "Votre demande est bien enregistr\xE9e": "Your request has been registered",
    "Votre domaine d\u2019expertise": "Area of expertise",
    "Votre futur collaborateur recevra un email permettant de se connecter ou de s'inscrire afin d'avoir acc\xE8s \xE0 vos diff\xE9rents viviers. N'oubliez pas d'activer en collaboratif, les viviers que vous souhaitez partager.":
      "Your future collaborator will receive an email allowing them to connect or register in order to have access to your different pools. Don't forget to activate the pools you want to share.",
    "Votre message": "Your message",
    "Votre partenaire pour donner de l\u2019\xE9lan \xE0 vos projets ou \xE0 votre carri\xE8re Tech & IT. CDI ou contrat : Le match parfait entre entreprises et talents IT. D\xE9couvrez nos Conditions G\xE9n\xE9rales d'Utilisation.":
      "Your partner to boost your business projects or your Tech & IT career. Permanent or contract: The perfect match between companies and IT talent. Find out more about our Terms and Conditions.",
    "Votre partenaire pour donner de l\u2019\xE9lan \xE0 vos projets ou \xE0 votre carri\xE8re Tech & IT. CDI ou contrat : Le match parfait entre entreprises et talents IT. D\xE9couvrez notre politique de confidentialit\xE9.":
      "Your partner to boost your business projects or your Tech & IT career. Permanent or contract: The perfect match between companies and IT talent. Read our privacy policy.",
    "Votre profil est complet \xE0 {0}%, Pour g\xE9n\xE9rer votre CV Mindquest, terminez de remplir les informations indispensables.": function(
      a
    ) {
      return [
        "Your profile is ",
        a("0"),
        "% complete. To generate your Mindquest resume, finish filling out the required information."
      ];
    },
    "Votre profil est maintenant public": "Your profile is now public",
    "Votre profil est presque complet. Un profil compl\xE9t\xE9 \xE0 100% permettra \xE0 nos recruteurs de vous proposer les offres les plus adapt\xE9s.":
      "Your profile is almost complete. A 100% completed profile will allow our recruiters to propose you the most adapted offers.",
    "Votre profil est rempli \xE0": "Your profile is completed at",
    "Votre profil est rempli \xE0 {0}%, compl\xE9tez-le pour g\xE9n\xE9rer votre CV Club Freelance.": function(
      a
    ) {
      return [
        "Your profile is ",
        a("0"),
        "% complete. Finish filling it in to generate your Mindquest resume."
      ];
    },
    "Votre profil est rempli \xE0 {0}%, compl\xE9tez-le pour g\xE9n\xE9rer votre CV Club Freelance. Ajoutez votre": function(
      a
    ) {
      return [
        "Your profile is ",
        a("0"),
        "% complete. Finish filling it in to generate your Mindquest resume. Add your"
      ];
    },
    "Votre profil est rempli \xE0 {0}%, compl\xE9tez-le pour g\xE9n\xE9rer votre CV Mindquest.": function(
      a
    ) {
      return [
        "Your profile is ",
        a("0"),
        "% complete. Finish filling it in to generate your Mindquest resume."
      ];
    },
    "Votre profil n'est plus public": "Your profile is no longer public",
    "Votre profil n'est rempli termin\xE9 , compl\xE9tez-le pour g\xE9n\xE9rer votre CV Club Freelance.":
      "Your profile is not completed, complete it to generate your Mindquest CV.",
    "Votre statut": "Your status",
    "Votre token est expir\xE9 ou invalide": "Expired or invalid token",
    "Voudriez-vous laisser un message ?": "Would you like to send a message ?",
    "Voulez vous contribuer?": "Fancy contributing?",
    "Voulez-vous ajouter des informations sur votre projet?":
      "Do you want to add information about your project?",
    "Vous avez d\xE9j\xE0 confirm\xE9 votre demande, elle sera trait\xE9e dans les plus brefs d\xE9lais":
      "You have already confirmed your request, it will be processed as soon as possible",
    "Vous avez d\xE9j\xE0 postul\xE9 pour cette mission": "Already applied",
    "Vous avez d\xE9passer le nombre limit\xE9 de vivier":
      "You have exceeded the limited number of Talent pools",
    "Vous avez finalis\xE9 votre inscription":
      "You have completed your registration",
    "Vous avez oubli\xE9 d\u2019\xE9crire votre message !":
      "You forgot to write your message!",
    "Vous avez un besoin en comp\xE9tences informatiques (talent it, consultant informatique) ? Vous cherchez une mission ou un job ? N\u2019h\xE9sitez pas \xE0 nous contacter.":
      "Do you need IT skills (IT talent, IT consultant)? Are you looking for an assignment or a job? Do not hesitate to contact us.",
    "Vous avez un besoin informatique ? Vous cherchez une mission ? N\u2019h\xE9sitez pas \xE0 nous contacter.":
      "You are looking for an IT position or a top candidate ? Feel free to get in touch.",
    "Vous cherchez un talent ou une opportunit\xE9 IT ? Nous connectons les meilleurs profils aux meilleurs offres pour un match parfait entre entreprise et talents IT.":
      "Are you looking for a talent or an IT opportunity? We connect the best profiles to the best offers for a perfect match between business and IT talents.",
    "Vous cr\xE9ez votre profil et acc\xE9dez \xE0 votre espace personnel pour poster votre premier besoin, en seulement quelques minutes":
      "Create a profile and post your first vacancy, all in just a few minutes",
    "Vous devez compl\xE8ter votre inscription pour pouvoir postuler \xE0 cette mission":
      "You must complete your sign up before applying to this mission",
    "Vous devez compl\xE9ter votre inscription pour postuler \xE0 cette offre":
      "You must complete your registration to apply for this offer",
    "Vous devez vous connecter avec un compte entreprise pour avoir poster un besoin":
      "Action restricted to Company accounts",
    "Vous devez vous connecter avec un compte entreprise pour continuer":
      "You must sign in with a business account to continue",
    "Vous devez vous connecter avec un compte entreprise pour continuer.":
      "You must sign in with a business account to continue.",
    "Vous devez vous connecter pour pouvoir postuler":
      "You need to be logged in to apply",
    "Vous n'avez pas re\xE7u votre code ?": "Haven't received your code?",
    'Vous ne pouvez int\xE9grer que 15 talents \xE0 votre vivier par d\xE9faut "Mes recherches de talent IT"':
      "You can only add 15 talents to your default pool 'My IT talent searches'",
    "Vous ne pouvez pas ajouter une exp\xE9rience vide":
      "You cannot add an empty experience",
    "Vous ne pouvez pas d\xE9placer la carte vers cette colonne":
      "You cannot move the map to this column",
    "Vous ne pouvez pas modifier cette opportunit\xE9":
      "You cannot edit this opportunity",
    "Vous ne puvez pas ajouter votre email \xE0 la liste des collaborateurs":
      "You can not add your email to your collaborators list",
    "Vous n\u2019avez pas encore de document": "You don't have any document",
    'Vous pourrez ajouter un commentaire apr\xE8s avoir cliqu\xE9 sur "Continuer"':
      'You can add a comment after clicking on "continue"',
    'Vous pourrez ajouter un commentaire apr\xE8s avoir cliqu\xE9 sur "Suivant"':
      "You can add a comment after clicking on Next ",
    "Vous pouvez d\xE9sormais remplir votre Timesheet {m}/{y} du contrat num\xE9ro: {pID}": function(
      a
    ) {
      return [
        "You can now fill out the Timesheet ",
        a("m"),
        "/",
        a("y"),
        " for the contract number ",
        a("pID")
      ];
    },
    "Vous pouvez g\xE9rer l\u2019ensemble du process de s\xE9lection et les entretiens via votre espace client, et en mode collaboratif avec votre \xE9quipe":
      "Manage the whole selection process from our platform, with support from our team",
    "Vous pouvez maintenant suivre le candidat":
      "You can now follow the candidate",
    "Vous pouvez maintenant suivre le candidat {candidateBhId}": function(a) {
      return ["You can now follow the candidate ", a("candidateBhId")];
    },
    "Vous pouvez maintenant suivre le candidat {id}": function(a) {
      return ["You can now follow the candidate ", a("id")];
    },
    "Vous pouvez suivre vos candidatures dans votre profil":
      "Track the progress of your applications in your personal space",
    "Vous pouvez supprimer ou renommer vos th\xE8mes":
      "You can delete or rename your themes",
    "Vous pouvez voir votre alerte dans votre profil":
      "You can see your alert in your profile",
    "Vous recevez une shortlist de candidats qualifi\xE9s (en moyenne sous 48h)":
      "You receive a shortlist of qualified candidates within 48h",
    "Vous recherchez des profils de consultants IT exp\xE9riment\xE9s ? Trouvez les d\xE8s maintenant avec notre algorithme de matching SMATCH":
      "Looking for experienced IT consultants? Find them now with our SMATCH matching algorithm",
    "Vous risquez de perdre vos donn\xE9es": "You risk losing your data",
    "Vous serez redig\xE9 automatiquement vers votre dashboard.":
      "You'll be automatically redirected to your dashboard",
    "Vous souhaitez connaitre la disponibilit\xE9 de":
      "You want to know the availability of",
    "Vous souhaitez coopter quelqu\u2019un de votre entourage ?":
      "Would you like to recommend us a friend?",
    "Vous souhaitez en savoir plus sur notre fonctionnement ?":
      "Want more information regarding our model and services?",
    "Vous souhaitez rejoindre Club Freelance ?":
      "Connecting top IT talent and the best industry jobs",
    "Vous souhaitez \xEAtre accompagn\xE9(e) en portage salarial ? D\xE9couvrez les solutions de nos partenaires.":
      "Do you want to be accompanied by a wage portage? Discover the solutions of our partners.",
    "Vous \xEAtes d\xE9j\xE0 abonn\xE9 aux newsletter Mindquest Connect":
      "You have already subscribed to Mindquest Connect newsletter",
    "Vous \xEAtes d\xE9j\xE0 abonn\xE9 \xE0 la newsletter Mindquest Connect":
      "You have already subscribed to Mindquest Connect newsletter",
    "Vous \xEAtes d\xE9sormais abonn\xE9 aux Mindquest Connect":
      "You are now subscribed to Mindquest Connect",
    "Vous \xEAtes d\xE9sormais abonn\xE9 aux Mission Control Center":
      "You subscribed to Mission Control Center successfully",
    "Vous \xEAtes d\xE9sormais abonn\xE9 \xE0 la newsletter de Club Freelance":
      "You are now subscribed to Mindquest's newsletter",
    "Vous \xEAtes d\xE9sormais abonn\xE9 \xE0 la newsletter de Mindquest":
      "You are now subscribed to the Mindquest newsletter",
    "Vous \xEAtes freelance ?": "Ready to make a move?",
    "Vous \xEAtes sur le point de soumettre un Timesheet vide.":
      "You are about to submit an empty Timesheet",
    "Vous \xEAtes sur le point de soumettre votre Timesheet {0} au client. Une fois le Timesheet valid\xE9, vous ne pourrez plus le modifier. Merci de v\xE9rifier vos informations avant l\u2019envoi.": function(
      a
    ) {
      return [
        "You are about to submit your Timesheet ",
        a("0"),
        " to the client. Once the Timesheet has been validated, you will no longer be able to modify it. Please verify your information before sending."
      ];
    },
    "Vue mois": "Month view",
    "Vue th\xE8me": "Theme view",
    "Vue type": "Type view",
    "V\xE9rifier la disponibilit\xE9": "Check for availability",
    "Want to join Club Freelance ?": "Want to join Mindquest?",
    "We also receive personal data about Candidates from other sources. Depending on the relevant circumstances and applicable local laws and requirements, these may include personal data received in the following situations:":
      "We also receive personal data about Candidates from other sources. Depending on the relevant circumstances and applicable local laws and requirements, these may include personal data received in the following situations:",
    "We are committed to ensuring that when we collect and use information about Website Users, our Candidates and Clients, we do so in accordance with all data protection legislation including the GDPR.":
      "We are committed to ensuring that when we collect and use information about Website Users, our Candidates and Clients, we do so in accordance with all data protection legislation including the GDPR.",
    "We collect your data automatically via cookies, in line with cookie settings in your browser. If you are also a Candidate of Club Freelance, we may use data from your use of our websites to enhance other aspects of our communications with or service to you.":
      "We collect your data automatically via cookies, in line with cookie settings in your browser. If you are also a Candidate of Club Freelance, we may use data from your use of our websites to enhance other aspects of our communications with or service to you.",
    "We collect your personal data in two primary ways:":
      "We collect your personal data in two primary ways:",
    "We do not much collect information about Clients. We generally only need to have your contact details or the details of individual contacts at your organisation (such as their names, telephone numbers and email addresses) to enable us to provide our services to you and to ensure that our relationship runs smoothly.":
      "We do not much collect information about Clients. We generally only need to have your contact details or the details of individual contacts at your organisation (such as their names, telephone numbers and email addresses) to enable us to provide our services to you and to ensure that our relationship runs smoothly.",
    "We have put in place several technical and organisational measures aimed at protecting the confidentiality, the integrity and the availability of your personal data.":
      "We have put in place several technical and organisational measures aimed at protecting the confidentiality, the integrity and the availability of your personal data.",
    "We may also disclose your personal information to third parties:":
      "We may also disclose your personal information to third parties:",
    "We may collect, store, and use the following categories of personal information about you:":
      "We may collect, store, and use the following categories of personal information about you:",
    "We may need to request specific information from you to help us confirm your identity and ensure your right to access your personal data (or to exercise any of your other rights). This is a security measure to ensure that personal data is not disclosed to any person who has no right to receive it. We may also contact you to ask you for further information in relation to your request to speed up our response.":
      "We may need to request specific information from you to help us confirm your identity and ensure your right to access your personal data (or to exercise any of your other rights). This is a security measure to ensure that personal data is not disclosed to any person who has no right to receive it. We may also contact you to ask you for further information in relation to your request to speed up our response.",
    "We may obtain data about you from cookies. These are small text files that are placed on your computer by websites that you visit. They are widely used in order to make websites work, or work more efficiently, as well as to provide information to the owners of the site. Cookies also enable us to deliver more personalised content.":
      "We may obtain data about you from cookies. These are small text files that are placed on your computer by websites that you visit. They are widely used in order to make websites work, or work more efficiently, as well as to provide information to the owners of the site. Cookies also enable us to deliver more personalised content.",
    "We may obtain information about you from searching for potential Candidates from third party sources, such as LinkedIn and other job sites;":
      "We may obtain information about you from searching for potential Candidates from third party sources, such as LinkedIn and other job sites;",
    "We may obtain this information from you, from our Client or from public sources and this includes:":
      "We may obtain this information from you, from our Client or from public sources and this includes:",
    "We may send you updates and information about products and services which may be of interest to you. If you have consented to receive marketing, you may opt out at a later date. You have a right at any time to stop us from contacting you for marketing purposes. If you no longer wish to be contacted for marketing purposes, please unsubscribe from our next email.":
      "We may send you updates and information about products and services which may be of interest to you. If you have consented to receive marketing, you may opt out at a later date. You have a right at any time to stop us from contacting you for marketing purposes. If you no longer wish to be contacted for marketing purposes, please unsubscribe from our next email.",
    "We only bill if you find your freelancer":
      "We only bill you once you find the right candidate ",
    "We try to respond to all legitimate requests within one month. Occasionally it may take us longer than a month if your request is particularly complex or you have made a number of requests. In this case, we will notify you and keep you updated.":
      "We try to respond to all legitimate requests within one month. Occasionally it may take us longer than a month if your request is particularly complex or you have made a number of requests. In this case, we will notify you and keep you updated.",
    "We understand our legal duty to retain accurate data and only retain personal data for as long as we need it for our legitimate business interests (or other appropriate legal basis) and that you are happy for us to do so. Accordingly, we run data routines to remove data that we no longer have a need to retain.":
      "We understand our legal duty to retain accurate data and only retain personal data for as long as we need it for our legitimate business interests (or other appropriate legal basis) and that you are happy for us to do so. Accordingly, we run data routines to remove data that we no longer have a need to retain.",
    "We use your data to help us to improve your experience of using our website, we may therefore for example launch a search function and may then analyse your recent job search criteria to help us to present jobs that we think you may be interested in.":
      "We use your data to help us to improve your experience of using our website, we may therefore for example launch a search function and may then analyse your recent job search criteria to help us to present jobs that we think you may be interested in.",
    "We will always ensure that there are adequate levels of protection and a similar legislation with regards to data protection. Each country might have different laws and regulations with regards to data protection. Overseas transfers outside the EEA will only be possible when we have the appropriate safeguards are in place.":
      "We will always ensure that there are adequate levels of protection and a similar legislation with regards to data protection. Each country might have different laws and regulations with regards to data protection. Overseas transfers outside the EEA will only be possible when we have the appropriate safeguards are in place.",
    "We will only use your personal data when the law allows us to. Most commonly, we will use your personal data in the following circumstances:":
      "We will only use your personal data when the law allows us to. Most commonly, we will use your personal data in the following circumstances:",
    "We will share your personal information with all entities in our group as part of our global recruitment activities, regular reporting activities on company performance, for system maintenance support and hosting of data.":
      "We will share your personal information with all entities in our group as part of our global recruitment activities, regular reporting activities on company performance, for system maintenance support and hosting of data.",
    "We will usually keep data for a maximum of 5 years and for full details of our retention strategy you can request it by contacting us at contact@club-freelance.com.":
      "We will usually keep data for a maximum of 5 years and for full details of our retention strategy you can request it by contacting us at contact@club-freelance.com.",
    "Web Development": "Web Development",
    "Webinars & Ressources": "Webinars & Ressources",
    "Webinars & Ressources - Contenu pour Consultants IT - Mindquest Connect":
      "Webinars & Ressources - Contenu pour Consultants IT - Mindquest Connect",
    "Webinars & Ressources - Contenu pour D\xE9cideurs IT - Mindquest Connect":
      "Webinars & Ressources - Contenu pour D\xE9cideurs IT - Mindquest Connect",
    "Webinars & Ressources - Mindquest Connect":
      "Webinars & Ressources - Mindquest Connect",
    "Webinars & Ressources - Replays - Mindquest Connect":
      "Webinars & Ressources - Replays - Mindquest Connect",
    "Webinars & Ressources - Revues de Presse : Cloud - Mindquest Connect":
      "Webinars & Ressources - Revues de Presse : Cloud - Mindquest Connect",
    "Webinars & Ressources - Revues de Presse : Cybers\xE9curit\xE9 - Mindquest Connect":
      "Webinars & Ressources - Revues de Presse : Cybers\xE9curit\xE9 - Mindquest Connect",
    "Webinars & Ressources - Revues de Presse : DSI - Mindquest Connect":
      "Webinars & Ressources - Revues de Presse : DSI - Mindquest Connect",
    "Webinars & Ressources - Revues de Presse : Data & ERP - Mindquest Connect":
      "Webinars & Ressources - Revues de Presse : Data & ERP - Mindquest Connect",
    "Webinars & Ressources - Revues de Presse : D\xE9veloppement - Mindquest Connect":
      "Webinars & Ressources - Revues de Presse : D\xE9veloppement - Mindquest Connect",
    "Webinars & Ressources - Revues de Presse : Intelligence Artificielle - Mindquest Connect":
      "Webinars & Ressources - Revues de Presse : Intelligence Artificielle - Mindquest Connect",
    "Webinars & Ressources - Revues de Presse : Recrutement & IT - Mindquest Connect":
      "Webinars & Ressources - Revues de Presse : Recrutement & IT - Mindquest Connect",
    "Webinars & replay": "Webinars & replay",
    "Website user": "Website user",
    "Website users": "Website users",
    "What are our legitimate business interests in collecting and retaining your personal data?":
      "What are our legitimate business interests in collecting and retaining your personal data?",
    "What kind of Personal Data do we collect?":
      "What kind of Personal Data do we collect?",
    "What measures we put in place to safeguard your personal data?":
      "What measures we put in place to safeguard your personal data?",
    "What we may need from you": "What we may need from you",
    WhatsApp: "WhatsApp",
    "When you visit our website, there is certain information that we may automatically collect, whether or not you decide to use our services. This includes your IP address, the date and the times and frequency with which you access the website and the way you browse its content. We will also collect data from you when you contact us via the website.":
      "When you visit our website, there is certain information that we may automatically collect, whether or not you decide to use our services. This includes your IP address, the date and the times and frequency with which you access the website and the way you browse its content. We will also collect data from you when you contact us via the website.",
    "Where we have obtained your consent to process your personal data for certain activities (for example, for our marketing arrangements or automatic profiling), you may withdraw this consent at any time by contacting Club Freelance and we will cease to carry out the particular activity that you previously consented. There may be circumstances where Club Freelance will still need to process your data for legal or official reasons. Where this is the case, we will tell you and we will restrict the data to only what is necessary for those specific reasons.":
      "Where we have obtained your consent to process your personal data for certain activities (for example, for our marketing arrangements or automatic profiling), you may withdraw this consent at any time by contacting Club Freelance and we will cease to carry out the particular activity that you previously consented. There may be circumstances where Club Freelance will still need to process your data for legal or official reasons. Where this is the case, we will tell you and we will restrict the data to only what is necessary for those specific reasons.",
    "Where we need to collect personal data by law, or under the terms of a contract we have with you and you fail to provide that data when requested, we may not be able to perform the contract we have or are trying to enter into with you for the purposes of recruitment services. In this case, we may be unable to provide the recruitment services you have requested but we will notify you if this is the case at the time.":
      "Where we need to collect personal data by law, or under the terms of a contract we have with you and you fail to provide that data when requested, we may not be able to perform the contract we have or are trying to enter into with you for the purposes of recruitment services. In this case, we may be unable to provide the recruitment services you have requested but we will notify you if this is the case at the time.",
    "Who do we share your personal data with?":
      "Who do we share your personal data with?",
    "Without  them,  the  Company  might  not  be  able  to  provide  all the  goods  and/or  services requested and ordered by the individual concerned.":
      "Without  them,  the  Company  might  not  be  able  to  provide  all the  goods  and/or  services requested and ordered by the individual concerned.",
    "Without them, the Company might not be able to provide all the goods and/or services requested and ordered by the individual concerned.":
      "Without them, the Company might not be able to provide all the goods and/or services requested and ordered by the individual concerned.",
    "You have the right to make a complaint at any time to a supervisory body which in the UK is the Information Commissioner\u2019s Office (ICO), the UK supervisory authority for data protection issues (www.ico.org.uk). We would, however, appreciate the chance to deal with your concerns before you approach the ICO, so please contact us in the first instance.":
      "You have the right to make a complaint at any time to a supervisory body which in the UK is the Information Commissioner\u2019s Office (ICO), the UK supervisory authority for data protection issues (www.ico.org.uk). We would, however, appreciate the chance to deal with your concerns before you approach the ICO, so please contact us in the first instance.",
    "You have the right to request correction of the personal information that we hold about you. This enables you to have any incomplete or inaccurate information we hold about you corrected.":
      "You have the right to request correction of the personal information that we hold about you. This enables you to have any incomplete or inaccurate information we hold about you corrected.",
    "You will not have to pay a fee to access your personal data (or to exercise any of the other rights). However, we may charge a reasonable fee if your request is clearly unfounded, repetitive or excessive. Alternatively, we may refuse to comply with your request in these circumstances.":
      'You will not have to pay a fee to access your personal data (or to exercise "any of the other rights). However, we may charge a reasonable fee if your request is clearly unfounded, repetitive or excessive. Alternatively, we may refuse to comply with your request in these circumstances.',
    "Your referees may disclose personal information about you;":
      "Your referees may disclose personal information about you;",
    "Zone g\xE9ographique souhait\xE9e": "Desired geographic area",
    "Zones g\xE9ographiques souhait\xE9es": "Desired geographic areas",
    "a ajout\xE9 le talent": "added the talent",
    "a cr\xE9e le vivier": "created the pool",
    "a supprim\xE9 le talent": "removed the talent",
    "a supprim\xE9 le vivier": "removed the pool",
    actionCookie: "actionCookie",
    actualité: "News",
    "adresse email": "Enter email address",
    "ajout d'un nouveau signataire principal":
      "adding a new principal signatory",
    "ajout d'un nouveau signataire secondaire":
      "Adding a new secondary signatory",
    "ajoutez un commentaire...": "Add a comment...",
    "analytics and search engine providers that assist us in the improvement and optimisation of our site;":
      "analytics and search engine providers that assist us in the improvement and optimisation of our site;",
    "any administrative or judicial authority shall: upon request under the applicable law.":
      "any administrative or judicial authority shall: upon request under the applicable law.",
    août: "august",
    au: "at",
    "au client . veuillez s'il vous plait indiquer en quelques lignes les actions effectu\xE9es ce mois .<0/>Note : ce bilan d'activit\xE9 sera visible par le client sur votre compte-rendu de Timesheet":
      "to the client . please indicate in a few lines the actions carried out this month .<0/>Note : this activity report will be visible by the client on your Timesheet report",
    "aucun r\xE9sultat ne correspond \xE0 vos crit\xE8res de recherche":
      "no results match your search criteria",
    "aucun timesheet disponible": "no timesheet available",
    avril: "april",
    "blog, club freelance, Conseils pratiques, consultant IT, freelance informatique":
      "blog, Mindquest, tips, IT consultant, freelance IT",
    "blog, club freelance, Conseils pratiques, entreprise IT, freelance informatique":
      "blog, Mindquest, tips, company IT, freelance IT computer, freelance IT",
    "blog, club freelance, D\xE9cryptages": "blog, Mindquest, Decryptions",
    "blog, club freelance, Success stories, freelance informatique":
      "blog, Mindquest, Success stories, IT freelance",
    "blog, club freelance, actualit\xE9s, freelance informatique, actualit\xE9 freelance":
      "blog, Mindquest, news, IT freelance, freelance news",
    "blog, club freelance, recherche, freelance informatique":
      "blog, Mindquest, research, IT freelance",
    "blog, club freelance, talents IT, actualit\xE9s, freelances IT":
      "blog, Mindquest, IT talents, news, IT freelancers",
    candidat: "candidate",
    "ces mots cl\xE9s seront pris en compte lors de la prochaine mise \xE0 jour.":
      "these keywords will be taken into account in the next update.",
    "champ requis": "required field",
    "charcher par Id, client ou signature": "check by Id, client or signature",
    "chercher par id, consultant": "search by ID, candidate",
    "chercher par id, entreprise ou signataire":
      "search by ID, company or approval",
    "chercher un talent": "Search a talent",
    "clients, business partners, suppliers and sub-contractors for the performance and compliance with obligations of any contract we enter into with them or you;":
      "clients, business partners, suppliers and sub-contractors for the performance and compliance with obligations of any contract we enter into with them or you;",
    "club freelance, privacy policy": "Mindquest, privacy policy",
    "code de confirmation": "confirmation code",
    "code invalide": "invalid code",
    comments: "comments",
    completed: "completed",
    "compl\xE9tez-le pour g\xE9n\xE9rer votre CV Club Freelance. Ajoutez votre":
      "complete it to generate your Mindquest CV. Add your",
    confirmer: "confirm",
    "conseils pratiques": "practical advice",
    consultant: "Candidate",
    "contact, club freelance, freelance informatique":
      "contact, mindquest, freelance IT",
    "credit reference agencies, our insurance broker, compliance partners and other sub-contractors for the purpose of assessing your suitability for a role where this is a condition of us entering into a contract with you.":
      "credit reference agencies, our insurance broker, compliance partners and other sub-contractors for the purpose of assessing your suitability for a role where this is a condition of us entering into a contract with you.",
    "c\u2019est le seul moyen de vous rejoindre!":
      "It is the only way we can reach out to you!",
    "dans le plus breff d\xE9lais.": "as soon as possible.",
    "dashboard, club freelance, entreprise, talents, recrutement,":
      "dashboard, Mindquest, entreprise, talents, recrutement",
    "dd/MM/yyyy": "dd/MM/yyyy",
    "de ventes": "earned",
    "de v\xE9rifier sa disponibilt\xE9. Nous reviendrons vers vous":
      "to check its availability. We will come back to you",
    "demande d\xE9ja confirm\xE9": "Request already confirmed",
    disponibilite: "availability",
    "disponibilit\xE9 \xE0 confirmer": "availability to be confirmed",
    "disponible dans un jour": "available in a day",
    "disponible dans {diffDays} jours": function(a) {
      return ["available in ", a("diffDays"), " days"];
    },
    "disponible dans {diffMonths} mois": function(a) {
      return ["available in ", a("diffMonths"), " months"];
    },
    "disponible dans {diffYears} ans": function(a) {
      return ["available in ", a("diffYears"), " years"];
    },
    "disponible imm\xE9diatement": "available immediately",
    décembre: "december",
    décryptages: "decryption",
    "email invalide": "invalid email",
    "en attente": "pending",
    entreprise: "employer",
    "entrer la taille de votre entreprise": "Your company's size",
    "entrer votre nom": "enter you last name",
    "entrer votre pr\xE9nom": "enter yout first name",
    "entrez l\u2019email de r\xE9ception": "Enter your email address",
    "envoi du feuille de temps": "Timesheet submission",
    "et qualifier son profil ?": "and qualify his/her profile?",
    "et trouvez votre prochaine mission IT":
      "and take your career to new heights",
    "euros / jour": "euros / day",
    fermée: "closed",
    filters: "filters",
    firstVisitOrigin: "firstVisitOrigin",
    fr: "en",
    février: "february",
    heures: "hours",
    "https://monsite.fr": "https://mysite.fr",
    "https://www.linkedin.com/in/user/": "https://www.linkedin.com/in/user/",
    "il n'y a pas d'historique disponible pour le moment":
      "there is no history available at the moment",
    "il n\u2019y a pas d\u2019historique disponible pour le moment":
      "there is no history available at the moment",
    inconnue: "unknown",
    indéterminé: "undetermined",
    "information about your visit, including the full Uniform Resource Locators (URL), clickstream to, through and from our site (including date and time), pages you viewed or searched for, page response times, download errors, length of visits to certain pages, page interaction information (such as scrolling, clicks, and mouse-overs).":
      "information about your visit, including the full Uniform Resource Locators (URL), clickstream to, through and from our site (including date and time), pages you viewed or searched for, page response times, download errors, length of visits to certain pages, page interaction information (such as scrolling, clicks, and mouse-overs).",
    "informatique et consultants IT": "and match them with innovative brands",
    "int\xE9ress\xE9 par ce candidat": "More info",
    j: "j",
    "j'ai compris": "Understood",
    janvier: "january",
    "jj/mm/aaaa": "dd/mm/yyyy",
    jour: "day",
    jours: "days",
    juillet: "july",
    juin: "june",
    "j\u2019ai compris": "Understood",
    "l'ajout du nouveau signataire secondaire a \xE9t\xE9 effectu\xE9 avec succ\xE8s":
      "the new secondary signatory was successfully added",
    "la feuille de temps a \xE9t\xE9 soumise au client pour validation":
      "the timecard is sent to your client for validation",
    "la feuille de temps est enregistr\xE9e en brouillon, vous pouvez la modifier \xE0 tout moment":
      "the timecard is saved in draft, you can edit it at any time",
    "la feuille de temps est rejet\xE9e": "The Timesheet has been rejected",
    "la feuille de temps est valid\xE9e": "the timecard is validated",
    "la feuille de temps n'a pas \xE9t\xE9 enregistr\xE9e":
      "the timecard has not been saved",
    "la suppression du signataire secondaire est faite avec succ\xE8s":
      "The secondary approval has been deleted successfully",
    "le fichier vous a \xE9t\xE9 envoy\xE9 par email":
      "the file has been sent to you via email",
    "le probl\xE8me sera bient\xF4t bient\xF4t r\xE9solu":
      "the problem will soon be solved",
    "le probl\xE8me sera bient\xF4t r\xE9solu":
      "Don't worry, it all will be solved shortly!",
    "login or password invalid": "login or password invalid",
    "login ou mot de passe invalide": "login or password invalid",
    "login, club freelance, account, connexion": "login, mindquest, account",
    mai: "may",
    mars: "march",
    "mindquest, CGU, conditions g\xE9n\xE9rales d'utilisation":
      "mindquest, CGU, Terms of Service",
    "mindquest, privacy policy": "mindquest, privacy policy",
    missions: "Job Opportunities",
    "missions freelance, Data, D\xE9veloppement, Cloud, Gestion de projet, CRM, Support, BI, Webdesign, ERP, SAP, Oracle":
      "missions, Data, Development, Cloud, Project management, CRM, Support, BI, Webdesign, ERP, SAP, Oracle",
    "missions, Talent Community Specialist, Freelance informatique":
      "missions, Talent Community Specialist, IT Freelance",
    mois: "month",
    "monemail@email.com": "myemail@mail.me",
    "monemail@mail.com": "myemail@mail.me",
    "mot de passe actuel est invalide": "current password is invalid",
    "mot de passe incorrect": "Incorrect password",
    "nombre d'heurs doit etre entre 0 et 10":
      "the number of hours should be between 0 and 10",
    "nombre de minutes doit etre entre 0 et 59":
      "number of hours must be between 0 and 10",
    "nombre de minutes doit \xEAtre entre 0 et 59":
      "number of minutes must be between 0 and 59",
    normal: "normal",
    "nouveau mot de passe est invalide": "new password is invalid",
    novembre: "november",
    "num\xE9ro de t\xE9l\xE9phone non valide": "Invalid phone number",
    octobre: "october",
    "offre disponible": "available offer",
    "offres disponibles": "available offers",
    "option 1": "option 1",
    "option 2": "option 2",
    optionnel: "optional",
    ou: "or",
    "ou inscrivez-vous maintenant":
      "Don\u2019t have an account yet? Click on Join Mindquest",
    ouvert: "open",
    "par cat\xE9gorie: {0}": function(a) {
      return ["by category: ", a("0")];
    },
    "par email": "by email",
    "par tag: {0}": function(a) {
      return ["by tag: ", a("0")];
    },
    "plus d\u2019expertises": "More areas of expertise",
    "politique de confidentialit\xE9": "privacy policy",
    "politique de confidentialit\xE9 des donn\xE9es": "privacy policy",
    "pour poursuivre votre inscription.": "to continue your registration.",
    "profil ajout\xE9": "added profile",
    "profil trouv\xE9": "profile found",
    "profils ajout\xE9": "profile found",
    "profils ajout\xE9s": "added profiles",
    "profils trouv\xE9": "profile found",
    "profils trouv\xE9s": "profiles found",
    "publier mon profil<0/>(pseudonymis\xE9)":
      "publish my profile<0/>(pseudonymized)",
    rechercher: "search",
    "rejet du feuille de temps": "timesheet rejection",
    rejeter: "reject",
    rejeté: "rejected",
    rejetée: "rejected",
    "revenir \xE0 la page d\u2019accueil": "back to home page",
    "r\xE9essayer svp": "try again",
    résultats: "results",
    "s'abonner": "Subscribe",
    "selon vos mots-cl\xE9s": "according to your keywords",
    sent: "sent",
    septembre: "september",
    "sign up, club freelance, communaut\xE9, compte, Freelances IT":
      "sign up, mindquest, communaut\xE9, compte, Freelances IT",
    "signataire principal": "principal signatory",
    soumettre: "Submit",
    special: "special",
    "subcontractors and other service providers including email marketing specialists, event organisers, payment and other financial service providers;":
      "subcontractors and other service providers including email marketing specialists, event organisers, payment and other financial service providers;",
    success: "success",
    "suppression du signataire secondaire": "Delete secondary approval",
    "techniques utilis\xE9s sur cette mission":
      "environment used on this mission",
    "tips and tricks": "tips and tricks",
    tous: "All",
    "transport en commun": "public transport",
    type: "type",
    "un compte entreprise.": "a company account.",
    "user dashboard": "user dashboard",
    "validation du feuille de temps": "timesheet validation",
    valider: "validate",
    validé: "validated",
    "veuillez ajouter vos soft skills s\xE9par\xE9es par des virgules.":
      "please add your soft skills separated by commas.",
    "vivier de": "Pool of",
    voiture: "car",
    "votre compte est maintenant valid\xE9": "Your account is now active",
    "votre mot cl\xE9 doit contenir 3 caract\xE8res au minimum":
      "your keyword must contain at least 3 characters",
    "votre responsable de compte vous contactera dans les plus brefs d\xE9lais.":
      "your account manager will contact you as soon as possible",
    "vous a \xE9t\xE9 envoy\xE9": "has been sent to you",
    "vous avez confirm\xE9 votre demande, le changement sera effectif dans un d\xE9lais de 24 heures":
      "you have confirmed your request, the change will be effective within 24 hours",
    "vous avez d\xE9ja confirm\xE9 votre demande":
      "you have already confirmed your request",
    "vous devez ajouter un commentaire": "Add a comment",
    "vous devez fixer les champs erron\xE9s avant":
      "you must fix the wrong fields before",
    "vous devez pr\xE9ciser la taille de votre entreprise":
      "Specify your company's size",
    "vous devez pr\xE9ciser le code postal avant de lancer le matching":
      "you must specify the postal code before launching the matching",
    "vous devez pr\xE9ciser le rythme de travail": "specify the rythm of work",
    "vous devez pr\xE9ciser le type du contrat": "specify the type of contract",
    "vous devez s\xE9lectionner des mot-cl\xE9s avant de lancer le matching":
      "you must select keywords before launching the matching",
    "warning!": "warning!",
    "{0, plural, one {# Article \xE0 lire} other {# Articles \xE0 lire}}": function(
      a
    ) {
      return [
        a("0", "plural", {
          one: ["#", " Article to read"],
          other: ["#", " Articles to read"]
        })
      ];
    },
    "{0, plural, one {# Articles to read} other { # Articles to read}}": function(
      a
    ) {
      return [
        a("0", "plural", {
          one: ["#", " Articles to read"],
          other: [" ", "#", " Articles to read"]
        })
      ];
    },
    "{0, plural, one {# Articles to read} other {# Articles to read}}": function(
      a
    ) {
      return [
        a("0", "plural", {
          one: ["#", " Articles to read"],
          other: ["#", " Articles to read"]
        })
      ];
    },
    "{0, plural, one {# Articles to read} other {}}": function(a) {
      return [a("0", "plural", { one: ["#", " Articles to read"], other: "" })];
    },
    "{0, plural, one {# jour} other {# jours}}": function(a) {
      return [a("0", "plural", { one: ["#", " day"], other: ["#", " days"] })];
    },
    "{0, plural, one {# mois} other {# mois}}": function(a) {
      return [
        a("0", "plural", { one: ["#", " month"], other: ["#", " months"] })
      ];
    },
    "{0, plural, one {Resultat} other {resultats}}": function(a) {
      return [a("0", "plural", { one: "Result", other: "results" })];
    },
    "{0, plural, one {R\xE9sultat} other {r\xE9sultats}}": function(a) {
      return [a("0", "plural", { one: "result", other: "results" })];
    },
    "{0, plural, one {{1}} other {{2}}}": function(a) {
      return [a("0", "plural", { one: [a("1")], other: [a("2")] })];
    },
    "{0}": function(a) {
      return [a("0")];
    },
    "{btnText}": function(a) {
      return [a("btnText")];
    },
    "{candidateStatus}": function(a) {
      return [a("candidateStatus")];
    },
    "{days, plural, one {# jour} other {# jours}}": function(a) {
      return [
        a("days", "plural", { one: ["#", " day"], other: ["#", " days"] })
      ];
    },
    "{months, plural, one {# mois} other {# mois}}": function(a) {
      return [
        a("months", "plural", { one: ["#", " month"], other: ["#", " months"] })
      ];
    },
    "{nResults} r\xE9sultats sont trouv\xE9s": function(a) {
      return [a("nResults"), " results found"];
    },
    "{sortBy}": function(a) {
      return [a("sortBy")];
    },
    "{sortPool}": function(a) {
      return [a("sortPool")];
    },
    "{type} qui peuvent vous int\xE9resser": function(a) {
      return [a("type"), " you might be interested in"];
    },
    "\xC0 l\u2019\xE9tude": "Under review",
    "\xC0 partir de {0}": function(a) {
      return ["From ", a("0")];
    },
    "\xC0 plein temps": "Full-time",
    "\xC0 propos de Mindquest": "About Mindquest",
    "\xC0 temps partiel": "Part-time",
    "\xC0 t\xE9l\xE9charger gratuitement": "Free download",
    "\xC9num\xE9rez toutes vos exp\xE9riences professionnelles pertinentes afin que nous puissions nous faire une id\xE9e plus pr\xE9cise de votre profil":
      "Now let\u2019s dive a bit deeper. List all your relevant previous work experiences for us to get a better sense of your profile",
    "\xC9quipes innovation & support": "Innovation & support",
    "\xC9tape 1: Int\xE9grez votre fiche de poste":
      "Step 1: Add your job description",
    "\xC9tape 2: S\xE9lectionnez vos mots-cl\xE9s IT dans les zones pr\xE9vues":
      "Step 2: Select and add your keywords",
    "\xC9tape suivante": "Next step",
    "\xCAtes-vous s\xFBr de vouloir supprimer ce certificat?":
      "Are you sure you want to delete this certificate?",
    "\xCAtes-vous s\xFBr de vouloir supprimer ce collaborateur ?":
      "Are you sure you want to delete this collaborator ?",
    "\xCAtes-vous s\xFBr de vouloir supprimer ce vivier?":
      "Are you sure you want to delete this pool?",
    "\xCAtes-vous s\xFBr de vouloir supprimer cette exp\xE9rience?":
      "Are you sure you want to delete this experience?",
    "\xCAtes-vous s\xFBr de vouloir supprimer cette formation?":
      "Are you sure you want to delete this training?",
    "\xCAtes-vous s\xFBr de vouloir supprimer cette plage de jours/heures ?":
      "Are you sure you want to delete this range of days/hours?",
    "\xCAtes-vous s\xFBr de vouloir supprimer le th\xE8me ainsi que les plages de journ\xE9es associ\xE9es ?":
      "Are you sure you want to delete the theme and the associated day ranges?",
    "\xCAtre contact\xE9 par :": "Be contacted by :",
    "\xCAtre rappel\xE9 par un conseiller": "Request a call back",
    "\xCAtre rappel\xE9 par un expert": "Request a call back",
    à: "at",
    "\xE0 partir de": "since",
    "\xE0 partir du": "since",
    "\xEAtre rappel\xE9 par un expert": "Request a call back",
    "\u201CClub Freelance propose un service simple et efficace, facilit\xE9 par un espace personnel virtuel et la possibilit\xE9 de g\xE9rer toute sa documentation en ligne. Pour moi, c\u2019est la mani\xE8re la plus simple pour \xE9mettre une facture et se faire payer. En temps et en heure.\u201C":
      "\u201CMindquest offers a clear and straightforward process. Everything can be done online, and you have at your disposal templates for all the required documentation. It\u2019s incredibly easy to raise an invoice and get paid. And always on time.\u201C",
    "\u201CJe suis tr\xE8s satisfaite de Club Freelance. Retour rapide sur la mission suite \xE0 un entretien, contrat envoy\xE9 dans la foul\xE9e et aucun probl\xE8me de facturation. L\u2019\xE9quipe est tr\xE8s disponible et la communication avec l\u2019ensemble des interlocuteurs est facile et agr\xE9able.\u201C":
      "\u201CI am really satisfied with Mindquest's services. Fast feedback after interviews, with the contract sent shortly after and no payment issues. The team is always available and working with them is easy and pleasant.\u201C",
    "\u201CJe suis tr\xE8s satisfaite de Mindquest. Retour rapide sur la mission suite \xE0 un entretien, contrat envoy\xE9 dans la foul\xE9e et aucun probl\xE8me de facturation. L\u2019\xE9quipe est tr\xE8s disponible et la communication avec l\u2019ensemble des interlocuteurs est facile et agr\xE9able.\u201C":
      "I am really satisfied with Mindquest's services. Fast feedback after interviews, with the contract sent shortly after and no payment issues. The team is always available and working with them is easy and pleasant.",
    "\u201CLe service du Club Freelance est rapide et efficace. Les membres de l\u2019\xE9quipe savent ce qu\u2019ils recherchent avec leurs partenaires: aucun contact n\u2019est destin\xE9 \xE0 cr\xE9er une base de donn\xE9es de CV mais \xE0 proposer des emplois int\xE9ressants. Je recommande fortement.\u201C":
      "\u201CMindquest's service is fast and efficient. The team knows what they are doing and they waste no one's time. They don't call you to build a database of CVs, but to actually offer you interesting jobs. I strongly recommend working with them.\u201C",
    "\u201CLe service du Mindquest est rapide et efficace. Les membres de l\u2019\xE9quipe savent ce qu\u2019ils recherchent avec leurs partenaires: aucun contact n\u2019est destin\xE9 \xE0 cr\xE9er une base de donn\xE9es de CV mais \xE0 proposer des emplois int\xE9ressants. Je recommande fortement.\u201C":
      "Mindquest's service is fast and efficient. The team knows what they are doing and they waste no one's time. They don't call you to build a database of CVs, but to actually offer you interesting jobs. I strongly recommend working with them.",
    "\u201CMindquest propose un service simple et efficace, facilit\xE9 par un espace personnel virtuel et la possibilit\xE9 de g\xE9rer toute sa documentation en ligne. Pour moi, c\u2019est la mani\xE8re la plus simple pour \xE9mettre une facture et se faire payer. En temps et en heure.\u201C":
      "Mindquest offers a clear and straightforward process. Everything can be done online, and you have at your disposal templates for all the required documentation. It\u2019s incredibly easy to raise an invoice and get paid. And always on time."
  }
};
