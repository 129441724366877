import React, { Component } from "react";
import { Mutation, withApollo } from "react-apollo";
import { withI18n } from "@lingui/react";
import { withRouter } from "react-router-dom";
import * as PropTypes from "prop-types";
import { Validation, FormGroup, Button, PopUp } from "cf-neo-ui";
import { t, Trans } from "@lingui/macro";
import "../styles.scss";
import { inject, observer } from "mobx-react";
import styles from "./styles.module.scss";
import { UPDATE_TIMESHEET } from "../mutations";
import publicIp from "public-ip";
import { SINGLE_TIME_SHEETS_QUERY } from "../queries";

@inject("newTimesheetStore")
@observer
class RejectTimesheetPopUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: ""
    };
    publicIp
      .v4()
      .then(res => {
        this.ip = res;
      })
      .catch(() => {});
  }

  isValid = () => {
    const { message } = this.state;
    return !!message;
  };

  render() {
    const { message } = this.state;
    const { newTimesheetStore, device, i18n } = this.props;
    const { openClosePopUps, timesheet } = newTimesheetStore;
    return (
      <PopUp
        mobile={device === "mobile"}
        onClose={() => {
          this.setState({ message: "" });
          openClosePopUps("rejectTimesheet");
        }}
      >
        <div
          style={{
            position: "relative",
            height: "360px",
            width: device === "mobile" ? "100%" : "380px"
          }}
        >
          <div
            style={{
              fontFamily: "Montserrat",
              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "16px",
              color: "#242424",
              marginBottom: "26px"
            }}
          >
            <Trans>Rejeter Timesheet</Trans>
          </div>
          <div
            style={{
              fontFamily: "Montserrat",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "14px",
              color: "#242424",
              marginBottom: "26px"
            }}
          >
            <Trans>En rejetant le timesheet, celui-ci sera renvoyé au consultant pour
            modification. Merci d'indiquer en commentaire la raison de ce rejet
            de la façon la plus précise possible.</Trans>
          </div>
          <FormGroup>
            <label
              style={{
                position: "relative",
                fontFamily: "Montserrat",
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: "14px",
                color: "#242424"
              }}
            >
              <Trans>Message</Trans>
            </label>
            <Validation errorMessage="" valid={true}>
              <textarea
                className={styles.textArea}
                id="addMessage"
                placeholder={i18n._(t`Que faut-il clarifier ?`)}
                value={message}
                onChange={e => this.setState({ message: e.target.value })}
                style={{ paddingLeft: "10px", marginTop: "10px" }}
              />
            </Validation>
          </FormGroup>
          <div>
            <span
              style={{
                fontFamily: "Montserrat",
                fontSize: "10px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "20px",
                color: "#000000"
              }}
            >
              <Trans>* Ce commentaire sera visible par le consultant </Trans>
            </span>
          </div>
          <Mutation
            mutation={UPDATE_TIMESHEET}
            variables={{
              id: timesheet.id,
              action: "reject",
              ip: this.ip,
              messages: [message && { text: message, type: "message" }].filter(
                n => n
              )
            }}
            refetchQueries={[
              {
                query: SINGLE_TIME_SHEETS_QUERY,
                variables: { id: timesheet.id }
              }
            ]}
            onCompleted={() => {
              this.setState({ message: "" });
              openClosePopUps("timesheetRejectedSuccessfully");
            }}
            onError={errors => {}}
          >
            {(mutation, { loading }) => (
              <div
                style={{
                  width: "100%",
                  padding: "20px 0"
                }}
              >
                <Button
                  variant="primary"
                  size="small"
                  onClick={() => {
                    if (this.isValid()) {
                      openClosePopUps("rejectTimesheet");
                      return mutation();
                    }
                    return null;
                  }}
                  style={{ width: "100%" }}
                >
                  <Trans>Rejeter</Trans>
                </Button>
              </div>
            )}
          </Mutation>
        </div>
      </PopUp>
    );
  }
}

RejectTimesheetPopUp.wrappedComponent.propTypes = {
  newTimesheetStore: PropTypes.shape({
    openClosePopUps: PropTypes.func,
    timesheet: PropTypes.shape({})
  }).isRequired,
  device: PropTypes.string
};
export default withApollo(withI18n()(withRouter(RejectTimesheetPopUp)));
