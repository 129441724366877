import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import moment from "moment";
import { withI18n } from "@lingui/react";
import { Trans, t } from "@lingui/macro";
import {
  Row,
  Col,
  Button,
  FormCard,
  Icon,
  FormGroup,
  TextInput,
  Validation,
  DatePicker,
  Modal
} from "cf-neo-ui";
import validate from "../../../../utils/validators";
import "../styles.scss";
import theme from "../../../../configs/theme";
import runtimeVars from "../../../../configs/runTimeVars";
import Location from "../../../../components/location/location";
import classes from "../../../../components/alert-modal/alertModal.module.scss";

@inject("sessionStore", "appStore")
@observer
class singleFormation extends Component {
  constructor(props) {
    super(props);
    const initProps = { ...props };
    delete initProps.sessionStore;
    this.state = {
      ...initProps,
      isVisible: false,
      isdegreeValid: true,
      isschoolValid: true,
      isStartDateValid: true,
      isEndDateValid: true,
      isDegreeTypeValid: true,
      isRefNameValid: true,
      isRefPhoneValid: true,
      isRefEmailValid: true,
      isAddressValid: true,
      isdiplomeTypeValid: true,
      degreeValidationMessage: "",
      schoolValidationMessage: "",
      startDateValidationMessage: "",
      endDateValidationMessage: "",
      degreeTypeValidationMessage: "",
      refNameValidationMessage: "",
      refPhoneValidationMessage: "",
      refEmailValidationMessage: "",
      diplomeTypeValidationMessage: "",
      todayDate: moment(new Date()).valueOf()
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const newProps = { ...nextProps };
    delete newProps.sessionStore;
    this.setState({ ...newProps });
  }

  onDeleteHandler = () => {
    this.setState({ isEditing: false });
    const { sessionStore, onValidate } = this.props;
    const {
      candidateEducation,
      changecandidateEducation,
      changeGeneralSaveCase
    } = sessionStore;

    changeGeneralSaveCase(5);
    onValidate();
    const { id } = this.state;
    const arr = candidateEducation.slice();

    for (let key = 0; key < arr.length; key += 1) {
      const education = arr[key];
      if (education.id === id) {
        arr.splice(key, 1);
        changecandidateEducation(arr);
      }
    }
  };
  onToastHandler = () => {
    this.setState({ isVisible: true });
  };

  validateAddress(value) {
    const { i18n } = this.props;
    const res = validate(value, ["required"], i18n);
    let mesg = "";
    if (!res.isValid) mesg = i18n._(t`Non renseigné`);
    this.setState({
      isAddressValid: res.isValid,
      addressValidationMessage: mesg
    });
    return res.isValid;
  }

  onCancelHandler = () => {
    const { isNew } = this.state;
    if (isNew) {
      this.onDeleteHandler();
    } else {
      const { sessionStore } = this.props;
      const { candidateEducation, changecandidateEducation } = sessionStore;
      const arr = candidateEducation.slice();
      changecandidateEducation(arr);
    }
  };

  onEditHandler = () => {
    this.setState({ isEditing: true });
  };
  getLocationHandler = (address, formattedAddress) => {
    let { city } = this.state;
    city = {
      address1: formattedAddress.address || address,
      city: formattedAddress.city,
      countryID: formattedAddress.country,
      zip: formattedAddress.zip,
      state: formattedAddress.state,
      lat: formattedAddress.lat,
      lng: formattedAddress.lng
    };
    this.setState({ city });
    this.forceUpdate();
    this.validateAddress(address);
  };
  companyAdressChangeHandler = () => {
    const { city } = this.state;
    this.setState({ city });
    this.forceUpdate();
  };
  isFormValid = () => {
    const {
      city,
      degree,
      school,
      startDate,
      endDate,
      diplomeType
    } = this.state;

    let valid = true;
    if (!this.validatedegree(degree)) valid = false;
    if (!this.validateAddress(city)) valid = false;
    if (!this.validatediplomeType(diplomeType)) valid = false;
    if (!this.validateschool(school)) valid = false;
    if (!this.validateStartDate(startDate)) valid = false;
    if (!this.validateEndDate(endDate)) valid = false;

    return valid;
  };
  onSaveHandler = () => {
    if (!this.isFormValid()) {
      return;
    }
    const { sessionStore, onValidate } = this.props;

    const {
      candidateEducation,
      changecandidateEducation,
      changeGeneralSaveCase
    } = sessionStore;

    const newcandidateEducation = candidateEducation.slice();
    const { id } = this.state;

    for (let key = 0; key < newcandidateEducation.length; key += 1) {
      const education = newcandidateEducation[key];
      const { _id } = education;
      if (education.id === id) {
        const {
          city,
          degree,
          school,
          startDate,
          endDate,
          diplomeType
        } = this.state;
        newcandidateEducation[key] = {
          _id,
          id,
          city,
          degree,
          school,
          startDate,
          endDate,
          diplomeType,
          isNew: false,
          isEditing: false
        };
      }
      changecandidateEducation(newcandidateEducation);
      changeGeneralSaveCase(5);
      onValidate();
    }
  };

  degreeChangeHandler = e => {
    this.setState({ degree: e.target.value });
    this.validatedegree(e.target.value);
  };

  diplomeTypeChangeHandler = e => {
    this.setState({ diplomeType: e.target.value });
    this.validatediplomeType(e.target.value);
  };

  schoolChangeHandler = e => {
    this.setState({ school: e.target.value });
    this.validateschool(e.target.value);
  };

  startDateChangeHandler = value => {
    this.setState({ startDate: value });
    this.validateStartDate(value);
  };

  endDateChangeHandler = value => {
    this.setState({ endDate: value });
    this.validateEndDate(value);
  };

  degreeTypeChangeHandler = e => {
    this.setState({ summary: e.target.value });
    this.validateDegreeType(e.target.value);
  };

  validatediplomeType = value => {
    const { i18n } = this.props;
    const res = validate(value, ["required"], i18n);
    this.setState({
      isdiplomeTypeValid: res.isValid,
      diplomeTypeValidationMessage: res.message
    });
    return res.isValid;
  };

  validatedegree = value => {
    const { i18n } = this.props;
    const res = validate(
      value,
      ["required", { maxlength: 70 }, { minlength: 3 }],
      i18n
    );
    this.setState({
      isdegreeValid: res.isValid,
      degreeValidationMessage: res.message
    });
    return res.isValid;
  };

  validateschool = value => {
    const { i18n } = this.props;
    const res = validate(value, ["required"], i18n);
    this.setState({
      isschoolValid: res.isValid,
      schoolValidationMessage: res.message
    });
    return res.isValid;
  };

  validateStartDate = value => {
    const { i18n } = this.props;
    const { endDate, todayDate } = this.state;

    const res = validate(value, ["required"], i18n);
    if (res.isValid === false || Number.isNaN(value) === true) {
      this.setState({
        isStartDateValid: false,
        startDateValidationMessage: i18n._(t`Champ requis`)
      });
      return false;
    }
    // if (endDate === "" || Number.isNaN(endDate)) {
    //   if (value <= todayDate) {
    //     this.setState({
    //       isStartDateValid: true,
    //       startDateValidationMessage: ""
    //     });
    //     return true;
    //   }

    //   // this.setState({
    //   //   isStartDateValid: false,
    //   //   startDateValidationMessage: i18n._(
    //   //     t`Cette date ne peut pas être dans le future`
    //   //   )
    //   // });
    //   return false;
    // }

    if (value <= endDate && endDate <= todayDate) {
      this.setState({
        isStartDateValid: true,
        startDateValidationMessage: ""
      });
      return true;
    }

    if (value && endDate && value >= todayDate && value >= endDate) {
      this.setState({
        isStartDateValid: false,
        startDateValidationMessage: i18n._(
          t`La date de début doit être toujours antérieur à la date de fin`
        )
      });
      return false;
    }

    if (value >= todayDate) {
      this.setState({
        isStartDateValid: false,
        startDateValidationMessage: i18n._(
          t`Cette date ne peut pas être dans le future`
        )
      });
      return false;
    }

    this.setState({
      isStartDateValid: true,
      startDateValidationMessage: ""
    });
    return true;
  };

  validateEndDate = value => {
    const { i18n } = this.props;
    const { startDate, todayDate } = this.state;

    const res = validate(value, ["required"], i18n);
    // if (res.isValid === false || Number.isNaN(value) === true) {
    //   this.setState({
    //     isEndDateValid: false,
    //     endDateValidationMessage: i18n._(t`Champ requis`)
    //   });
    //   return false;
    // }
    if (startDate === "" || Number.isNaN(startDate)) {
      if (value <= todayDate) {
        this.setState({
          isEndDateValid: true,
          endDateValidationMessage: ""
        });
        return true;
      }

      return false;
    }

    if (startDate <= value && value <= todayDate) {
      this.setState({
        isEndDateValid: true,
        endDateValidationMessage: ""
      });
      return true;
    }

    if (value && startDate >= value && value <= todayDate) {
      this.setState({
        isEndDateValid: false,
        endDateValidationMessage: i18n._(
          t`La date de fin doit être toujours ultérieur à la date de début`
        )
      });
      return false;
    }

    this.setState({
      isEndDateValid: true,
      endDateValidationMessage: ""
    });
    return true;
  };
  onToastHandlerClose = () => {
    this.setState({ isVisible: false });
  };
  disableStartDays = day => {
    const { endDate, todayDate } = this.state;

    if (endDate) {
      return day > endDate;
    }
    return day > todayDate;
  };

  disableEndDays = day => {
    const { startDate, todayDate } = this.state;
    return day < startDate || day > todayDate;
  };

  displayButtons = () => {
    const { showButton, i18n } = this.props;
    const { isVisible } = this.state;
    if (showButton === "true") {
      return (
        <div className="experience-buttons">
          <Button size="small" onClick={this.onToastHandler}>
            <Trans>Supprimer</Trans>
          </Button>
          <Modal
            centred
            isVisible={isVisible}
            onClose={this.onToastHandlerClose}
          >
            <div className={classes.alertModal}>
              <div className={classes.header}>
                <div className={classes.headerIcon}>
                  <Icon
                    size="tiny"
                    type="bell"
                    color={theme.colors.mainColor}
                    color2={theme.colors.lightMainColor}
                  />
                </div>
                <Trans>Alerte Suppression</Trans>
              </div>
              <div className={classes.body}>
                <Row style={{ marginLeft: "30px" }}>
                  {i18n._(
                    t`Êtes-vous sûr de vouloir supprimer cette formation?`
                  )}
                </Row>
              </div>
              <div className={classes.footer}>
                <Button
                  size="small"
                  variant="secondary"
                  onClick={this.onToastHandlerClose}
                >
                  <Trans>Annuler</Trans>
                </Button>
                <Button
                  size="small"
                  style={{ marginLeft: "10px" }}
                  onClick={this.onDeleteHandler}
                >
                  <Trans>Supprimer</Trans>
                </Button>
              </div>
            </div>
          </Modal>
          <Button variant="secondary" size="small" onClick={this.onEditHandler}>
            <Trans>Modifier</Trans>
          </Button>
        </div>
      );
    }
    return null;
  };

  render() {
    const {
      city,
      degree,
      school,
      startDate,
      endDate,
      isdegreeValid,
      isschoolValid,
      isStartDateValid,
      isEndDateValid,
      isdiplomeTypeValid,
      degreeValidationMessage,
      schoolValidationMessage,
      startDateValidationMessage,
      endDateValidationMessage,
      diplomeTypeValidationMessage,
      addressValidationMessage,
      isAddressValid,
      isEditing,
      diplomeType
    } = this.state;
    const { i18n, appStore } = this.props;
    const isMobile = appStore.width <= 1350;
    const isFormationMobile = appStore.width <= 850;
    let formationDegreeStyle = isFormationMobile ? "pre-wrap" : "nowrap";
    const orientation = appStore.width <= 348 ? "vertical" : "horizontal";
    const { currentLanguage } = appStore;
    return (
      <FormCard>
        <Row className="rowExperience">
          <Col>
            {isEditing ? (
              <div>
                <div>
                  <Row>
                    <Col>
                      <FormGroup>
                        <label className="form-label">
                          <Trans>Nom du diplome</Trans>
                        </label>
                        <Validation
                          errorMessage={degreeValidationMessage}
                          valid={isdegreeValid}
                        >
                          <TextInput
                            type="text"
                            className="form-input"
                            icon="briefcase"
                            iconColor={theme.colors.lightMainColor}
                            placeholder=""
                            value={degree}
                            onChange={this.degreeChangeHandler}
                          />
                        </Validation>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <label className="form-label">
                          <Trans>Etablissement</Trans>
                        </label>
                        <Validation
                          errorMessage={schoolValidationMessage}
                          valid={isschoolValid}
                        >
                          <TextInput
                            type="text"
                            spellCheck="false"
                            className="form-input"
                            placeholder=""
                            value={school}
                            onChange={this.schoolChangeHandler}
                          />
                        </Validation>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <label className="form-label" htmlFor="diplomeType">
                          <Trans>Type de diplome</Trans>
                        </label>
                        <Validation
                          errorMessage={diplomeTypeValidationMessage}
                          valid={isdiplomeTypeValid}
                        >
                          <TextInput
                            type="text"
                            spellCheck="false"
                            className="form-input"
                            placeholder=""
                            value={diplomeType}
                            onChange={this.diplomeTypeChangeHandler}
                          />
                        </Validation>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <label
                          className="form-label"
                          htmlFor="experienceEnterprise"
                        >
                          <Trans>Ville</Trans>
                        </label>
                        <Validation
                          errorMessage={addressValidationMessage}
                          valid={isAddressValid}
                        >
                          <Location
                            id="address"
                            onChange={this.companyAdressChangeHandler}
                            value={
                              city
                                ? `${city.city}, ${city.state}, ${city.countryID}`
                                : ""
                            }
                            getLocation={(Address, FormattedAddress) =>
                              this.getLocationHandler(Address, FormattedAddress)
                            }
                            valid={isAddressValid}
                          />
                        </Validation>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <label className="form-label">
                          <Trans>Date de début</Trans>
                        </label>
                        <Validation
                          errorMessage={startDateValidationMessage}
                          valid={isStartDateValid}
                        >
                          <DatePicker
                            disabledDaysMatcher={day =>
                              this.disableStartDays(day)
                            }
                            dateFormat="dd/MM/yyyy"
                            defaultDate={
                              startDate
                                ? moment
                                    .unix(startDate / 1000)
                                    .format("DD/MM/YYYY")
                                : ""
                            }
                            onChange={value =>
                              this.startDateChangeHandler(
                                moment(value).valueOf()
                              )
                            }
                            locale={currentLanguage || runtimeVars.APP_LANG}
                            acceptWhenOutsideClick
                            autoComplete="off"
                          />
                        </Validation>
                      </FormGroup>
                    </Col>

                    <Col>
                      <FormGroup>
                        <label className="form-label">
                          <Trans>Date de fin</Trans>
                        </label>
                        <Validation
                          errorMessage={endDateValidationMessage}
                          valid={isEndDateValid}
                        >
                          <DatePicker
                            // disabledDaysMatcher={day =>
                            //   this.disableEndDays(day)
                            // }
                            dateFormat="dd/MM/yyyy"
                            defaultDate={
                              endDate
                                ? moment
                                    .unix(endDate / 1000)
                                    .format("DD/MM/YYYY")
                                : ""
                            }
                            onChange={value =>
                              this.endDateChangeHandler(moment(value).valueOf())
                            }
                            locale={currentLanguage || runtimeVars.APP_LANG}
                            acceptWhenOutsideClick
                            autoComplete="off"
                          />
                        </Validation>
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
                <div
                  className="experience-buttons"
                  style={{ marginTop: "10px" }}
                >
                  <Button
                    variant="secondary"
                    size="small"
                    onClick={this.onCancelHandler}
                  >
                    <Trans>Annuler</Trans>
                  </Button>
                  <Button size="small" onClick={this.onSaveHandler}>
                    <Trans>Valider</Trans>
                  </Button>
                </div>
              </div>
            ) : (
              <div>
                <div>
                  <Row>
                    <Col lg={4} xl={4} className="info">
                      <div
                        style={{
                          color: "#d3354a",
                          marginRight: "2%",
                          fontWeight: "bold",
                          fontSize: "15px",
                          whiteSpace: formationDegreeStyle
                        }}
                      >
                        <Icon
                          className="icon-margin-right"
                          type="flag"
                          size="tiny"
                          width={20}
                          heigth={20}
                          color={theme.colors.mainColor}
                          color2={theme.colors.lightMainColor}
                        />
                        {degree}
                      </div>
                      <br />
                      <div>
                        <Icon
                          className="icon-margin-right"
                          type="calendar"
                          size="tiny"
                          width={20}
                          heigth={20}
                          color={theme.colors.mainColor}
                          color2={theme.colors.lightMainColor}
                        />
                        {moment.unix(startDate / 1000).format("DD/MM/YYYY")} -{" "}
                        {endDate
                          ? moment.unix(endDate / 1000).format("DD/MM/YYYY")
                          : i18n._(t`Aujourd’hui`)}
                      </div>
                      <br />
                      <div style={{ whiteSpace: formationDegreeStyle }}>
                        <Icon
                          className="icon-margin-right"
                          type="map-marker"
                          size="tiny"
                          width={20}
                          heigth={20}
                          color={theme.colors.mainColor}
                          color2={theme.colors.lightMainColor}
                        />
                        {school}
                      </div>
                      <br />
                      {diplomeType && (
                        <div style={{ whiteSpace: formationDegreeStyle }}>
                          <Icon
                            className="icon-margin-right"
                            type="briefcase"
                            size="tiny"
                            width={20}
                            heigth={20}
                            color={theme.colors.mainColor}
                            color2={theme.colors.lightMainColor}
                          />
                          {diplomeType}
                        </div>
                      )}
                    </Col>
                  </Row>
                  <br />

                  {this.displayButtons()}
                </div>
              </div>
            )}
          </Col>
        </Row>
      </FormCard>
    );
  }
}

singleFormation.wrappedComponent.propTypes = {
  sessionStore: PropTypes.shape({
    candidateEducation: PropTypes.shape({}),
    changecandidateEducation: PropTypes.func
  }).isRequired,
  appStore: PropTypes.shape({
    currentLanguage: PropTypes.string,
    width: PropTypes.number
  }).isRequired,
  showButton: PropTypes.string,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  onValidate: PropTypes.func.isRequired
};

export default withI18n()(singleFormation);
