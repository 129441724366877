import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { Button, Row, Col, TagList, SwitchButton } from "cf-neo-ui";
import { withI18n } from "@lingui/react";
import { Trans, t } from "@lingui/macro";
import classes from "./SearchBar.module.scss";
import "./autocompleteStyle.scss";

@inject("sessionStore", "candidatesSearchStore", "appStore")
@observer
class SearchBar extends Component {
  closeTagHandler = val => {
    const { candidatesSearchStore } = this.props;
    candidatesSearchStore.removeSkill(val);
    candidatesSearchStore.setSkillOptions();
  };

  closeTag2Handler = () => {
    const { candidatesSearchStore } = this.props;
    candidatesSearchStore.changeEmploymentPreference({ value: null });
  };

  closeTag3Handler = () => {
    const { candidatesSearchStore } = this.props;
    candidatesSearchStore.changeExperience({ value: null });
  };

  sorterChangeHandler = (value, direction) => {
    const { candidatesSearchStore } = this.props;

    return direction === "up"
      ? candidatesSearchStore.changeSortBy(value, "ASC")
      : candidatesSearchStore.changeSortBy(value, "DESC");
  };

  getExperience = ex => {
    const { i18n } = this.props;
    switch (ex) {
      case 1:
        return i18n._(t`Junior (0 - 3)`);
      case 2:
        return i18n._(t`Confirmed (3 - 7)`);
      case 3:
        return i18n._(t`Senior (7 - 10)`);
      case 4:
        return i18n._(t`Expert (10+)`);
      default:
        return i18n._(t`Indifferent`);
    }
  };

  getEmploymentPreference = employmentPreference => {
    const { i18n } = this.props;
    switch (employmentPreference) {
      case "Contract":
        return i18n._(t`Freelance/Contract`);
      case "Permanent":
        return i18n._(t`Permanent`);
      default:
        return i18n._(t`Indifferent`);
    }
  };

  renderSearchInput = () => {
    const { candidatesSearchStore } = this.props;
    const { skills, employmentPreference, experience } = candidatesSearchStore;
    let exp = "Indifferent";
    let empl = "Indifferent";
    if (experience) exp = this.getExperience(experience);
    if (employmentPreference)
      empl = this.getEmploymentPreference(employmentPreference);
    if ((skills && skills.length) || employmentPreference || experience)
      return (
        <div>
          <div className={classes.filterMissions}>
            <Trans>Filtres Sélectionnés :</Trans>
          </div>
          {skills && skills.length ? (
            <TagList
              tags={skills}
              className={classes.tagList}
              variant="secondary"
              closable
              onClose={this.closeTagHandler}
            />
          ) : (
            <div />
          )}
          {employmentPreference ? (
            <TagList
              tags={[empl]}
              className={classes.tagList}
              variant="secondary"
              closable
              onClose={this.closeTag2Handler}
            />
          ) : (
            <div />
          )}
          {experience && experience !== 0 ? (
            <TagList
              tags={[exp]}
              className={classes.tagList}
              variant="secondary"
              closable
              onClose={this.closeTag3Handler}
            />
          ) : (
            <div />
          )}
        </div>
      );
    else return <div />;
  };

  renderSignUpBtn = () => {
    const { backToTheStart } = this.props;

    return (
      <Button icon="chevron-right" onClick={backToTheStart} id="cta_mi_sb_join">
        <Trans>Revenir à mes mots clés</Trans>
      </Button>
    );
  };
  switchHandler = () => {
    const { candidatesSearchStore } = this.props;
    candidatesSearchStore.changePublicProfile(
      !candidatesSearchStore.publicProfile
    );
  };
  renderSearchPublicCandidates = () => {
    const { candidatesSearchStore } = this.props;

    const { i18n } = this.props;
    return (
      <div className={classes.searchCandidate}>
        <SwitchButton
          style={{
            marginRight: "10px",
            marginLeft: "10px"
          }}
          checked={candidatesSearchStore.publicProfile}
          onSwitch={async () => {
            await this.switchHandler();
          }}
        />
        <span>{i18n._(t` Afficher uniquement les profils publiques`)}</span>
      </div>
    );
  };
  renderSorters = () => {
    const { appStore } = this.props;

    return (
      <div className={classes.sortersList}>
        {appStore.width >= 1024 && this.renderSignUpBtn()}
      </div>
    );
  };

  renderFilterButton = () => {
    const { candidatesSearchStore } = this.props;
    const { setSideFilter } = candidatesSearchStore;

    return (
      <Button
        icon="sliders"
        variant="secondary"
        iconPosition="left"
        className={classes.respFiltersBtn}
        onClick={setSideFilter}
      >
        <Trans>Critères</Trans>
      </Button>
    );
  };

  renderSearchBar = () => {
    return (
      <Row className={classes.searchBar}>
        <Col lg={2} className={classes.search}>
          {this.renderSearchInput()}
        </Col>
        <Col lg={6} className={classes.search}>
          {this.renderSearchPublicCandidates()}
        </Col>
        <Col lg={4}>{this.renderSorters()}</Col>
      </Row>
    );
  };

  renderResponsiveSearchBar = () => {
    return (
      <Row className={classes.searchBar}>
        <Col xs={6} className={classes.search}>
          {this.renderSearchInput()}
        </Col>
        <Col xs={3}>{this.renderFilterButton()}</Col>
        <Col xs={3} className={classes.respSignUpBtn}>
          {this.renderSearchPublicCandidates()}
        </Col>
        <Col xs={3} className={classes.respSignUpBtn}>
          {this.renderSignUpBtn()}
        </Col>
        <Col xs={6} className={classes.respSorters}>
          {this.renderSorters()}
        </Col>
      </Row>
    );
  };

  render() {
    const { appStore } = this.props;
    return appStore.width >= 1024
      ? this.renderSearchBar()
      : this.renderResponsiveSearchBar();
  }
}

SearchBar.wrappedComponent.propTypes = {
  backToTheStart: PropTypes.func,
  sessionStore: PropTypes.shape({
    authToken: PropTypes.string
  }).isRequired,
  candidatesSearchStore: PropTypes.shape({
    changeSortBy: PropTypes.func,
    searchSubmit: PropTypes.func,
    addToFilters: PropTypes.func,
    setSideFilter: PropTypes.func,
    removeSkill: PropTypes.func,
    setSkillOptions: PropTypes.func,
    publicProfile: PropTypes.bool
  }).isRequired,
  appStore: PropTypes.shape({ width: PropTypes.number }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired
};

export default withI18n()(withRouter(SearchBar));
