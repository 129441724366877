import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withI18n } from "@lingui/react";
import { inject, observer } from "mobx-react";
import { Mutation, Query } from "react-apollo";
import gql from "graphql-tag";
import { Trans, t } from "@lingui/macro";
import { Button, Row, Col, ImportFile, toaster, Spinner } from "cf-neo-ui";
import "../styles.scss";
import staticMetaTags from "../../../../configs/staticPagesMetaTags";
import metaTags from "../../../../utils/editMetaData";
import urls from "../../../../utils/urls";
import { GET_USER_INFO } from "../../../../components/hoc/queries";

const UPDATE_CANDIDATE_MUTATION = gql`
  mutation UpdateCandidateMutation(
    $_id: ID!
    $email: String
    $firstName: String
    $lastName: String
    $pictureUrl: String
    $address: AddressInput
    $searchAddress: AddressInput
    $link: String
    $title: String
    $cv: Upload
    $candidateWorkHistory: [ExperienceCreateInput]
  ) {
    updateCandidate(
      input: {
        _id: $_id
        email: $email
        firstName: $firstName
        lastName: $lastName
        pictureUrl: $pictureUrl
        address: $address
        searchAddress: $searchAddress
        link: $link
        title: $title
        cv: $cv
        candidateWorkHistory: $candidateWorkHistory
      }
    ) {
      account {
        id
        name
      }
      candidate {
        _id
        email
        profilePhoto
        occupation
        companyURL
        firstName
        lastName
        cv
        address {
          city
        }
        searchAddress {
          city
        }
      }
    }
  }
`;

const CANDIDATE_QUERY = gql`
  query CandidateQuery {
    candidate {
      id
      cv
    }
  }
`;
@inject("sessionStore", "appStore")
@observer
class MyProfileResumeUploader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      saveCase: false
    };
  }

  onErrorHandler = msg => {
    const { i18n } = this.props;
    toaster.error({
      title: i18n._(t`Erreur`),
      description: msg
    });
  };

  onOauthDoneHandler() {
    this.setState({ saveCase: true });
    const { changeGeneralSaveCase } = sessionStore;
    changeGeneralSaveCase(1);
  }

  handleUpload = fileUploaded => {
    const { sessionStore } = this.props;
    sessionStore.changeCv(fileUploaded);
    this.setState({ saveCase: true });
    const { changeGeneralSaveCase } = sessionStore;
    changeGeneralSaveCase(1);
  };

  updateCandidateCompletedHandler() {
    const { i18n, appStore, sessionStore } = this.props;
    toaster.success({
      title: i18n._(t`Mon profil`),
      description: i18n._(t`Enregistrement effectué avec succès`)
    });
    this.setState({ saveCase: false });
    const { changeGeneralSaveCase } = sessionStore;
    changeGeneralSaveCase(null);
    // appStore.refreshLayout();
  }

  render() {
    const { i18n, sessionStore } = this.props;
    const { saveCase } = this.state;

    const {
      isAuthenticatedByLinkedin,
      _id,
      email,
      pictureUrl,
      title,
      link,
      firstName,
      lastName,
      address,
      searchAddress,
      candidateWorkHistory,
      authToken,
      cv
    } = sessionStore;

    let isValid = false;
    if (isAuthenticatedByLinkedin || (cv && cv.name)) isValid = true;

    const candidateWorkHistoryToSend = [];
    for (let key = 0; key < candidateWorkHistory.length; key += 1) {
      const experience = candidateWorkHistory[key];
      candidateWorkHistoryToSend.push({
        id: experience.id.toString(),
        candidateId: _id,
        title: experience.title,
        companyName: experience.companyName,
        startDate: experience.startDate,
        endDate: experience.endDate ? experience.endDate : null,
        summary: experience.summary,
        refName: experience.refName,
        refPhone: experience.refPhone,
        refEmail: experience.refEmail
      });
    }
    const meta = staticMetaTags(i18n).resume;
    return (
      <div className="profile profile-profile">
        {metaTags(
          urls.myResumeUploader(),
          meta.title,
          meta.description,
          meta.openGraphImage.facebookOpenGraphImageUrl,
          meta.openGraphImage.linkedInOpenGraphImageUrl,
          meta.keywords
        )}
        <br />
        <div className="with-padding">
          <Row>
            <Col className="center form-card-content">
              <div className="center-txt text-bold">
                <Trans>Uploader mon CV</Trans>
              </div>
              <br />
              <div className="center" style={{ width: "100%" }}>
                <ImportFile
                  defaultAvatar="/defaultAvatar.webp"
                  onChange={(e, fileUploaded) =>
                    this.handleUpload(e, fileUploaded)
                  }
                  accept=".doc,.docx,.pdf"
                  withDropFile
                  dropFileProps={{
                    text: i18n._(t`...ou simplement deposer votre CV ici`),
                    style: { height: "200px" },
                    onFilesAccepted: files => this.handleUpload(files[0])
                  }}
                  maxSize={1e7}
                  message1={i18n._(
                    t`Ce format de fichier n’est pas pris en charge. Veuillez choisir l’une des options suivantes :`
                  )}
                  message2={i18n._(
                    t`Nous vous recommandons fortement d’utiliser le format Word.`
                  )}
                >
                  <Trans>Importer mon CV</Trans>
                </ImportFile>
              </div>
              <Query query={CANDIDATE_QUERY} fetchPolicy="network-only">
                {({ loading, error, data }) => {
                  if (error || loading) return null;
                  if (data && data.candidate && data.candidate.cv)
                    return (
                      <a
                        className="download-ancor"
                        href={data.candidate.cv}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Trans>Voir Mon CV</Trans>
                      </a>
                    );
                  return null;
                }}
              </Query>
            </Col>
          </Row>
          <div className="next-buttons">
            <Mutation
              mutation={UPDATE_CANDIDATE_MUTATION}
              variables={{
                _id,
                cv,
                ...(isAuthenticatedByLinkedin && {
                  email,
                  pictureUrl,
                  title,
                  link,
                  firstName,
                  lastName,
                  candidateWorkHistory: candidateWorkHistoryToSend,
                  address,
                  searchAddress
                })
              }}
              refetchQueries={[
                {
                  query: GET_USER_INFO,
                  variables: { token: authToken }
                }
              ]}
              onCompleted={data => this.updateCandidateCompletedHandler(data)}
              onError={errors => {
                errors.graphQLErrors.forEach(({ message, data }) => {
                  if (data && data.isCustomError) {
                    this.onErrorHandler(message);
                  }
                });
              }}
            >
              {(mutation, { loading }) => (
                <Button
                  disabled={!isValid || loading || !saveCase}
                  onClick={mutation}
                >
                  {loading ? (
                    <Spinner
                      type="pointed-circular"
                      color="#FFFFFF"
                      size={12}
                    />
                  ) : (
                    <Trans>Enregistrer</Trans>
                  )}
                </Button>
              )}
            </Mutation>
          </div>
          <br />
        </div>
      </div>
    );
  }
}

MyProfileResumeUploader.wrappedComponent.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  sessionStore: PropTypes.shape({
    changeCv: PropTypes.func,
    isAuthenticatedByLinkedin: PropTypes.bool,
    _id: PropTypes.string,
    email: PropTypes.string,
    pictureUrl: PropTypes.string,
    title: PropTypes.string,
    link: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    address: PropTypes.string,
    searchAddress: PropTypes.string,
    candidateWorkHistory: PropTypes.shape({}),
    cv: PropTypes.shape({
      name: PropTypes.string
    }),
    authToken: PropTypes.string
  }).isRequired,
  appStore: PropTypes.shape({
    width: PropTypes.number,
    refreshLayout: PropTypes.func
  }).isRequired
};

export default withI18n()(MyProfileResumeUploader);
