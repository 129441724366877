import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withI18n } from "@lingui/react";
import { Tab, Tabs, Col, Notification } from "cf-neo-ui";
import { t, Trans } from "@lingui/macro";
import { inject, observer } from "mobx-react";
import { Query } from "react-apollo";
import classes from "./Timesheets.module.scss";
import TimesheetsTab from "./timesheets-tab";
import ReportsTab from "./reports-tab";
import SignaturesTab from "./signature-tab";
import OpaqueScreen from "./OpaqueScreen";
import metaTags from "../../utils/editMetaData";
import configs from "../../configs/club-freelance-globals";

@inject("sessionStore", "appStore", "modalStore", "timesheetStore", "appStore")
@observer
class Timesheets extends Component {
  getQueryVariables = () => {
    const { sessionStore } = this.props;
    if (sessionStore.account && sessionStore.account.role === "CLIENT")
      return { id: parseInt(sessionStore.client.id, 10), role: "CLIENT" };
    if (sessionStore.account && sessionStore.account.role === "CANDIDATE") {
      return { id: parseInt(sessionStore.candidate.id, 10), role: "CANDIDATE" };
    }
    return null;
  };
  toDocs = currentLanguage => {
    window.open(
      currentLanguage == "en" ? configs.cvTemplateUrlEn : configs.cvTemplateUrl
    );
  };
  changeTab = tab => {
    const { timesheetStore } = this.props;
    const { changeTheTab } = timesheetStore;
    changeTheTab(tab);
  };

  guideLink = () => {
    const { sessionStore, appStore } = this.props;
    const { currentLanguage } = appStore;
    const role =
      sessionStore && sessionStore.account && sessionStore.account.role;
    if (role === "CLIENT") {
      if (currentLanguage === "fr")
        return "https://mindquest.eb-help.com/help/collections/guide-utilistateurs";
      return "https://drive.google.com/file/d/1PsZB7VIOO9cBjFu5-uy4cMVOn3g6coc6/view?usp=sharing";
    }
    if (currentLanguage === "fr")
      return "https://mindquest.eb-help.com/help/collections/guide-utilistateurs";
    return "https://drive.google.com/file/d/1TGysq4bet0XJYAB6s7bJ3nImZ4QosMJA/view?usp=sharing";
  };

  render() {
    const { i18n, appStore, sessionStore, timesheetStore } = this.props;
    const { activatedTab } = timesheetStore;

    const client =
      sessionStore &&
      sessionStore.account &&
      sessionStore.account.role === "CLIENT";
    var url = "";
    if (typeof window !== "undefined"  ) {
      url = window.location.href;
    }

    return (
      <div className={classes.container}>
        {metaTags(url, null, null, null, null, null, null, null, false)}

        <a
          className={classes.guidePDF}
          name="download"
          type="button"
          target="_blank"
          rel="noopener noreferrer"
          title={i18n._(t`Télécharger la ressource`)}
          href={this.guideLink()}
          download
        >
          <Trans>Télécharger le guide d’utilisation</Trans>
        </a>
        <Col lg={8} md={8} sm={12} xs={6}>
          <div className={classes.alert}>
            {appStore.width < 768 ? (
              <Notification
                notificationTitle={i18n._(t`Ressources`)}
                text={i18n._(
                  t`Découvrez nos modèles de facture, de CV et template de note de frais`
                )}
                buttonLabel={i18n._(t`Voir les modèles`)}
                onClickButton={() => this.toDocs(appStore.currentLanguage)}
                h={150}
              />
            ) : (
              <Notification
                notificationTitle={i18n._(t`Ressources`)}
                text={i18n._(
                  t`Découvrez nos modèles de facture, de CV et template de note de frais`
                )}
                buttonLabel={i18n._(t`Voir les modèles`)}
                onClickButton={() => this.toDocs(appStore.currentLanguage)}
                h={80}
              />
            )}
          </div>
        </Col>
        <Tabs
          activeTab={activatedTab}
          onChange={activeTab => this.changeTab(activeTab)}
        >
          <Tab name="timesheets_tab" tab={i18n._(t`Timesheets`)}>
            <TimesheetsTab />
          </Tab>
          <Tab name="reports_tab" tab={i18n._(t`Comptes rendus`)}>
            <ReportsTab />
          </Tab>
          {client && (
            <Tab name="signatures_tab" tab={i18n._(t`Signataires`)}>
              <SignaturesTab />
            </Tab>
          )}
        </Tabs>
      </div>
    );
  }
}

Timesheets.wrappedComponent.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  sessionStore: PropTypes.shape({
    account: PropTypes.shape({
      role: PropTypes.string
    }),
    candidate: PropTypes.shape({
      id: PropTypes.number
    }),
    client: PropTypes.shape({
      id: PropTypes.number
    })
  }).isRequired,
  modalStore: PropTypes.shape({
    signatairePopUp: PropTypes.bool,
    changeSignatairePopUp: PropTypes.func
  }).isRequired,
  timesheetStore: PropTypes.shape({
    changeTheTab: PropTypes.func,
    activatedTab: PropTypes.string
  }).isRequired,
  appStore: PropTypes.shape({
    currentLanguage: PropTypes.string
  }).isRequired
};

export default withI18n()(Timesheets);
