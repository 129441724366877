import { t } from "@lingui/macro";
import staticPagesMetaTags from "../../../configs/staticPagesMetaTags";
import runtimeVars from "../../../configs/runTimeVars";

const { PARTENAIRES_URL, PORTAGE_SALARIALE_URL } = runtimeVars;

export const links1 = (i18n, currentLanguage) => {
  const metas = staticPagesMetaTags(i18n);
  let linksToBeReturned = [
    {
      url: "/missions",
      title: metas.missions.title,
      name: i18n._(t`Trouver une mission freelance`)
    },
    {
      url: "/blog/it-consultant",
      title: metas.blog.advicesConsultant.title,
      name: i18n._(t`Nos conseils pour les freelance`)
    },
    {
      url: "/signup?type=consultant",
      title: metas.signUp.title,
      name: i18n._(t`Rejoindre le club`)
    }
  ];

  if (currentLanguage === "fr")
    linksToBeReturned.push({
      url: PARTENAIRES_URL,
      name: i18n._(t`Nos partenaires`),
      external: true
    });

  if (currentLanguage === "fr")
    linksToBeReturned.push({
      url: PORTAGE_SALARIALE_URL,
      name: i18n._(t`Nos partenaires portage`),
      external: true
    });

  return linksToBeReturned;
};

export const links2 = i18n => {
  const metas = staticPagesMetaTags(i18n);
  return [
    {
      url: "/candidates",
      title: metas.signUp.title,
      name: i18n._(t`Trouver un freelance`)
    },
    {
      url: "/blog/it-decision-makers",
      title: metas.blog.advicesEnterprise.title,
      name: i18n._(t`Nos conseils pour recruter en freelance`)
    },
    {
      url: "conditionalRedirect",
      title: i18n._(t`Déposer un projet`),
      name: i18n._(t`Déposer un projet`)
    }
  ];
};

export const links3 = i18n => {
  const metas = staticPagesMetaTags(i18n);
  return [
    {
      url: "/about",
      title: metas.about.title,
      name: i18n._(t`Qui sommes nous?`)
    },
    {
      url:
        "https://www.welcometothejungle.com/fr/companies/club-freelance,https://www.welcometothejungle.com/en/companies/club-freelance",
      title: metas.signUp.title,
      name: i18n._(t`Nous rejoindre`),
      external: true
    },
    {
      url: "/contact",
      title: metas.contact.title,
      name: i18n._(t`Nous contacter`)
    },
    {
      url: "/blog",
      title: metas.blog.main.title,
      name: i18n._(t`Le blog`)
    }
  ];
};
