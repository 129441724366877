import React, { Component } from "react";
import { withApollo } from "react-apollo";
import { withI18n } from "@lingui/react";
import { withRouter } from "react-router-dom";
import * as PropTypes from "prop-types";
import { Row, Icon, PopUp } from "cf-neo-ui";
import { t, Trans } from "@lingui/macro";
import "../styles.scss";
import { inject, observer } from "mobx-react";

@inject("newTimesheetStore")
@observer
class LimitOfThemesPopUp extends Component {
  render() {
    const { device, newTimesheetStore } = this.props;
    const { openClosePopUps } = newTimesheetStore;
    return (
      <PopUp
        mobile={device === "mobile"}
        withCloseButton={true}
        onClose={() => openClosePopUps("limitOfThemes")}
      >
        <div
          style={{
            position: "relative",
            height: "110px",
            width: device === "mobile" ? "100%" : "380px"
          }}
        >
          <div style={{ borderBottom: "1px solid" }}>
            <Row
              style={{
                marginBottom: "10px"
              }}
            >
              <span style={{ margin: "0 15px" }}>
                <Icon
                  type="Bell"
                  color="#8D0417"
                  color2="#D3354A"
                  width={14}
                  height={14}
                />
              </span>
              <span>
                <div
                  style={{
                    fontFamily: "Montserrat",
                    fontStyle: "normal",
                    fontWeight: 600,
                    fontSize: "16px",
                    color: "#242424"
                  }}
                >
                  <Trans>Limite de thèmes atteint</Trans>
                </div>
              </span>
            </Row>
          </div>

          <div
            style={{
              padding: device === "mobile" ? "20px 5px" :"20px 20px 5px 20px",
              width: device === "mobile" ? "100%" :"300px",
              margin: "0 auto",
              textAlign: "center",
              fontFamily: "Montserrat",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "14px",
              color: "#242424"
            }}
          >
            <Trans>Le nombre limite de thèmes crées a été atteint.</Trans>
          </div>
          <div
            style={{
              padding: device === "mobile" ? "5px" :"5px 20px 20px 20px",
              width: device === "mobile" ? "100%" :"300px",
              margin: "0 auto",
              textAlign: "center",
              fontFamily: "Montserrat",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "12px",
              color: "#242424"
            }}
          >
            <Trans>Vous pouvez supprimer ou renommer vos thèmes</Trans>
          </div>
        </div>
      </PopUp>
    );
  }
}

LimitOfThemesPopUp.wrappedComponent.propTypes = {
  newTimesheetStore: PropTypes.shape({
    openClosePopUps: PropTypes.func
  }).isRequired,
  device: PropTypes.string
};
export default withApollo(withI18n()(withRouter(LimitOfThemesPopUp)));
