import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Query } from "react-apollo";
import { inject, observer } from "mobx-react";
import {
  AutoCompleteV2,
  Button,
  Row,
  Col,
  InputSkeleton,
  TagList
} from "cf-neo-ui";
import { withI18n } from "@lingui/react";
import { Trans, t } from "@lingui/macro";
import classes from "./SearchBar.module.scss";
import "./autocompleteStyle.scss";
import { GET_CATEGORIES, GET_SKILLS } from "../../../../queries";
import domaineExpertise from "../../../../../../configs/domaineExpertise";

@inject("sessionStore", "missionsSearchStore", "appStore")
@observer
class SearchBar extends Component {
  closeTagHandler = val => {
    const { missionsSearchStore, onDeleteTag } = this.props;
    const { removeSkill, setSkillOptions } = missionsSearchStore;
    removeSkill(val);
    setSkillOptions();
    onDeleteTag(true);
  };

  closeTag2Handler = val => {
    const { i18n, missionsSearchStore, onDeleteTag } = this.props;
    missionsSearchStore.removeCategory(domaineExpertise(i18n, val, "key"));
    missionsSearchStore.setCategoryOptions();
    onDeleteTag(true);
  };

  closeTag3Handler = val => {
    const { missionsSearchStore, onDeleteTag } = this.props;
    const { removeSpecialty, setSpecialtyOptions } = missionsSearchStore;
    removeSpecialty(val);
    setSpecialtyOptions();
    onDeleteTag(true);
  };

  closeTag4Handler = val => {
    const { missionsSearchStore, onDeleteTag } = this.props;
    const { removeLocation } = missionsSearchStore;
    removeLocation(val);
    onDeleteTag(true);
  };

  closeTag5Handler = val => {
    const { missionsSearchStore, onDeleteTag } = this.props;
    const {
      removeBusinessSector,
      setBusinessSectorOptions
    } = missionsSearchStore;
    removeBusinessSector(val);
    setBusinessSectorOptions();
    onDeleteTag(true);
  };

  renderSearchInput = () => {
    const { missionsSearchStore, i18n } = this.props;

    return (
      <Query query={GET_SKILLS}>
        {({ loading: loadingSkills, error: errorSkills, data: dataSkills }) => (
          <Query query={GET_CATEGORIES}>
            {({
              loading: loadingCategories,
              error: errorCategories,
              data: dataCategories
            }) => {
              if (loadingCategories || loadingSkills)
                return <InputSkeleton radius={10} />;
              if (errorCategories || errorSkills)
                return `Error! ${errorCategories.message}`;

              const suggestions = {};
              suggestions.categories = dataCategories.categories.map(item =>
                domaineExpertise(i18n, item.name)
              );
              suggestions.skills = dataSkills.skills.map(item => item.name);

              return (
                <AutoCompleteV2
                  id="mi_sb"
                  clearOnSelect
                  options={suggestions}
                  maxSuggestion={6}
                  min={1}
                  radius={10}
                  placeholder={i18n._(
                    t`Rechercher un domaine, une compétence...`
                  )}
                  icon="search"
                  onEnterPressed={e =>
                    missionsSearchStore.searchSubmit(e.target.value)
                  }
                  onSelect={(val, filter) => {
                    if (filter === "categories") {
                      missionsSearchStore.addCategory(
                        domaineExpertise(i18n, val, "key")
                      );
                      missionsSearchStore.setCategoryOptions();
                    }
                    if (filter === "skills") {
                      missionsSearchStore.addSkill(val);
                      missionsSearchStore.setSkillOptions();
                    }
                  }}
                />
              );
            }}
          </Query>
        )}
      </Query>
    );
  };

  renderFilterButton = () => {
    const { missionsSearchStore } = this.props;
    const { setSideFilter } = missionsSearchStore;

    return (
      <Button
        icon="sliders"
        variant="secondary"
        iconPosition="left"
        className={classes.respFiltersBtn}
        onClick={setSideFilter}
      >
        <Trans>Critères</Trans>
      </Button>
    );
  };

  renderSearchBar = () => {
    const { i18n, missionsSearchStore } = this.props;
    const {
      skills,
      categories,
      specialties,
      cityLabels,
      businessSectors
    } = missionsSearchStore;
    const categoriesToShow = [];
    missionsSearchStore.categories.map(cat =>
      categoriesToShow.push(domaineExpertise(i18n, cat))
    );

    return (
      <Row className={classes.searchBar}>
        <Col lg={12} className={classes.search}>
          {this.renderSearchInput()}
        </Col>

        {skills.length ? (
          <div
            style={{
              color: "#8f8f8f",
              marginLeft: "20px",
              marginTop: "20px",
              textAlign: "left",
              display: "inline"
            }}
          >
            <TagList
              tags={skills}
              variant="secondary"
              closable
              onClose={this.closeTagHandler}
            />
          </div>
        ) : (
          <div />
        )}
        {categories.length ? (
          <div
            style={{
              color: "#8f8f8f",
              marginLeft: "20px",
              marginTop: "20px",
              textAlign: "left",
              display: "inline"
            }}
          >
            <TagList
              tags={categoriesToShow}
              variant="secondary"
              closable
              onClose={this.closeTag2Handler}
            />
          </div>
        ) : (
          <div />
        )}
        {specialties.length ? (
          <div
            style={{
              color: "#8f8f8f",
              marginLeft: "20px",
              marginTop: "20px",
              textAlign: "left",
              display: "inline"
            }}
          >
            <TagList
              tags={specialties}
              variant="secondary"
              closable
              onClose={this.closeTag3Handler}
            />
          </div>
        ) : (
          <div />
        )}
        {cityLabels.length ? (
          <div
            style={{
              color: "#8f8f8f",
              marginLeft: "20px",
              marginTop: "20px",
              textAlign: "left",
              display: "inline"
            }}
          >
            <TagList
              tags={cityLabels}
              variant="secondary"
              closable
              onClose={this.closeTag4Handler}
            />
          </div>
        ) : (
          <div />
        )}
        {businessSectors.length ? (
          <div
            style={{
              color: "#8f8f8f",
              marginLeft: "20px",
              marginTop: "20px",
              textAlign: "left",
              display: "inline"
            }}
          >
            <TagList
              tags={businessSectors}
              variant="secondary"
              closable
              onClose={this.closeTag5Handler}
            />
          </div>
        ) : (
          <div />
        )}
      </Row>
    );
  };

  renderResponsiveSearchBar = () => {
    const { missionsSearchStore } = this.props;
    const {
      skills,
      categories,
      specialties,
      cityLabels,
      businessSectors
    } = missionsSearchStore;
    return (
      <Row className={classes.searchBar}>
        <Col sm={12} className={classes.search}>
          {this.renderSearchInput()}
        </Col>
        {skills.length ? (
          <div
            style={{
              color: "#8f8f8f",
              marginLeft: "20px",
              marginTop: "20px",
              textAlign: "left",
              display: "inline"
            }}
          >
            <TagList
              tags={skills}
              variant="secondary"
              closable
              onClose={this.closeTagHandler}
            />
          </div>
        ) : (
          <div />
        )}
        {categories.length ? (
          <div
            style={{
              color: "#8f8f8f",
              marginLeft: "20px",
              marginTop: "20px",
              textAlign: "left",
              display: "inline"
            }}
          >
            <TagList
              tags={categories}
              variant="secondary"
              closable
              onClose={this.closeTag2Handler}
            />
          </div>
        ) : (
          <div />
        )}
        {specialties.length ? (
          <div
            style={{
              color: "#8f8f8f",
              marginLeft: "20px",
              marginTop: "20px",
              textAlign: "left",
              display: "inline"
            }}
          >
            <TagList
              tags={specialties}
              variant="secondary"
              closable
              onClose={this.closeTag3Handler}
            />
          </div>
        ) : (
          <div />
        )}
        {cityLabels.length ? (
          <div
            style={{
              color: "#8f8f8f",
              marginLeft: "20px",
              marginTop: "20px",
              textAlign: "left",
              display: "inline"
            }}
          >
            <TagList
              tags={cityLabels}
              variant="secondary"
              closable
              onClose={this.closeTag4Handler}
            />
          </div>
        ) : (
          <div />
        )}
        {businessSectors.length ? (
          <div
            style={{
              color: "#8f8f8f",
              marginLeft: "20px",
              marginTop: "20px",
              textAlign: "left",
              display: "inline"
            }}
          >
            <TagList
              tags={businessSectors}
              variant="secondary"
              closable
              onClose={this.closeTag5Handler}
            />
          </div>
        ) : (
          <div />
        )}
      </Row>
    );
  };

  render() {
    const { appStore } = this.props;
    return appStore.width >= 1024
      ? this.renderSearchBar()
      : this.renderResponsiveSearchBar();
  }
}

SearchBar.wrappedComponent.propTypes = {
  sessionStore: PropTypes.shape({
    authToken: PropTypes.string
  }).isRequired,
  onDeleteTag: PropTypes.func,
  missionsSearchStore: PropTypes.shape({
    changeSortBy: PropTypes.func,
    searchSubmit: PropTypes.func,
    addToFilters: PropTypes.func,
    setSideFilter: PropTypes.func
  }).isRequired,
  appStore: PropTypes.shape({ width: PropTypes.number }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired
};

export default withI18n()(withRouter(SearchBar));
