import React, { Component } from "react";
import { withApollo } from "react-apollo";
import { withI18n } from "@lingui/react";
import { withRouter } from "react-router-dom";
import * as PropTypes from "prop-types";
import { TypeCard, TypeCardForMobile, ItemMenuNew, Row, Col } from "cf-neo-ui";
import { inject, observer } from "mobx-react";
import { t } from "@lingui/macro";

@inject("newTimesheetStore")
@observer
class TypeVue extends Component {
  constructor(props) {
    super(props);
  }

  onClickEdit = (e, key) => {
    console.log("edit: ", key);
  };

  onClickDelete = (e, key) => {
    console.log("delete: ", key);
  };
  render() {
    const { newTimesheetStore, i18n } = this.props;
    const { listOfTypes } = newTimesheetStore;
    return (
      <div>
        {listOfTypes &&
          listOfTypes.length &&
          listOfTypes.map(element => {
            if (element.total != 0)
              return (
                <TypeCard
                  style={{ marginBottom: "10px" }}
                  itemColor="#96081C"
                  color={element.type === "normal" ? "#C47E87" : "#96081C"}
                  hoursType={
                    element.type === "normal"
                      ? i18n._(t`Heures normales`)
                      : i18n._(t`Heures spéciales`)
                  }
                  workingType={
                    element.type === "normal"
                      ? ""
                      : element.type === "extra"
                      ? i18n._(t`Supplémentaire`)
                      : i18n._(t`Astreinte`)
                  }
                  workingPlace={
                    element.place === "on-site"
                      ? i18n._(t`Présentiel`)
                      : i18n._(t`Télétravail`)
                  }
                  totalHours={`${Math.floor(
                    element.total / 60
                  )}h${element.total % 60}m`}
                  withThreePoints={false}
                >
                  <ItemMenuNew
                    label={i18n._(t`Modifier`)}
                    value="EDIT"
                    onClick={this.onClickEdit}
                  />
                  <ItemMenuNew
                    label={i18n._(t`Supprimer`)}
                    value="DELETE"
                    onClick={this.onClickDelete}
                  />
                </TypeCard>
              );
          })}
      </div>
    );
  }
}

TypeVue.wrappedComponent.propTypes = {
  newTimesheetStore: PropTypes.shape({
    listOfTypes: PropTypes.shape({}),
    themes: PropTypes.shape({}),
    addTheme: PropTypes.func,
    openClosePopUps: PropTypes.func,
    addPeriod: PropTypes.func
  }).isRequired
};
export default withApollo(withI18n()(withRouter(TypeVue)));
