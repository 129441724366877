import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import * as PropTypes from "prop-types";
import { Query } from "react-apollo";
import { t } from "@lingui/macro";
import { withI18n } from "@lingui/react/index";
import { inject, observer } from "mobx-react";
import Article from "./article/Article";
import Loading from "./LoadingSingleBlog/Loading";
import { SinglePostQuery } from "../query";
import decode from "../../../utils/Utf8Text";
import metaTags from "../../../utils/editMetaData";
import urls from "../../../utils/urls";
import ldJson from "./ldJson";

@inject("appStore")
@observer
class BlogSinglePage extends Component {
  componentDidMount() {
    window.scroll(0, 0);
  }

  render() {
    const { match, i18n, appStore } = this.props;
    const { currentLanguage } = appStore;
    let language = new URLSearchParams(this.props.location.search).get(
      "language"
    );
    if (language && currentLanguage != language)
      appStore.changeLanguage(language);
    return (
      <div>
        <Query
          query={SinglePostQuery}
          variables={{
            id: match.params.id,
            language: language || currentLanguage
          }}
        >
          {({ loading, error, data }) => {
            if (error || (data && !data.post))
              return (
                <div className="error_container">
                  <span>
                    {i18n._(t` Oups! il semblerait que vos données aient du mal à se
                  synchroniser. Veuillez rafraichir la page dans un moment`)}
                  </span>
                  <img
                    style={{
                      margin: 20
                    }}
                    src={"/assets/svg/download.svg"}
                    alt="error"
                  />
                </div>
              );
            if (loading)
              return (
                <div>
                  <Loading />
                </div>
              );
            const { post } = data;
            return (
              <div>
                {metaTags(
                  urls.singleBlog(post.id, language || currentLanguage),
                  decode(post.title) + " - Mindquest",
                  decode(post.meta_description),
                  post.featured_media.source_url,
                  post.featured_media.source_url,
                  decode(post.meta_keys),
                  ldJson(i18n, post),
                  language || currentLanguage,
                  language && currentLanguage != language ? false : true
                )}
                <Article
                  id={post.id}
                  categories={post.categories}
                  tags={post.tags}
                  title={decode(post.title)}
                  cover={
                    post.featured_image2_url ||
                    (post.featured_media && post.featured_media.source_url)
                  }
                  blogger={post.author}
                  addDate={post.date}
                  updateDate={post.modified}
                  content={post.content}
                  relatedPosts={post.related_posts}
                />
              </div>
            );
          }}
        </Query>
      </div>
    );
  }
}

BlogSinglePage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string
    })
  }).isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  appStore: PropTypes.shape({
    currentLanguage: PropTypes.string
  }).isRequired
};

export default withI18n()(withRouter(BlogSinglePage));
