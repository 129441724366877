import gql from "graphql-tag";

export const GET_USER_INFO = gql`
  query getUserInfoQuery($token: String!) {
    getAccountInfo(token: $token) {
      token
      account {
        id
        name
        role
        isCompleted
        isVerified
        path
        siteLanguage
      }
      candidate {
        id
        _id
        email
        name
        occupation
        firstName
        lastName
        phone
        dayRateLow
        companyURL
        dateAvailable
        categories
        skills
        customTextBlock3
        customTextBlock5
        specialties
        status
        spokenLanguages
        experience
        customText21
        customInt10
        profilePhoto
        preferredContact
        employmentPreference
        receiveNewsletterTalent
        receiveNewsletterClient
        lastInfo
        customText15
        salary
        salaryLow
        cv
        address {
          address1
          city
          countryID
          zip
          state
          lat
          lng
        }
        searchAddress {
          address1
          city
          state
          countryID
          lat
          lng
        }
        customTextBlock2
        customTextBlock4
        publicProfil
      }
      client {
        _id
        id
        email
        companyName
        activityArea
        currentPosition
        sizeOfTheCompany
        isESN
        password
        firstName
        lastName
        companyID
        lastInfo
        phonenumber
        profilePhoto
        customTextBlock1
        preferredContact
        receiveNewsletterTalent
        receiveNewsletterClient
      }
      candidateWorkHistory {
        _id
        id
        candidateId
        isCurrent
        companyName
        title
        startDate
        endDate
        summary
        refName
        refPhone
        refEmail
        contractType
        companyAddress {
          address1
          city
          zip
          state
          countryID
          lat
          lng
        }
        BusinessSector
        skillExperience
        specialty
      }
      scrappedLinkdin {
        firstName
        lastName
        occupation
        location
        skills
        spokenLanguages
        educations {
          title
          degree
          url
          fieldOfStudy
          startDate
          endDate
        }
        experiences {
          title
          url
          companyName
          location
          description
          period
          duration
          startDate
          endDate
        }
      }
      candidateEducation {
        _id
        id
        candidateId
        city {
          address1
          city
          state
          countryID
          lat
          lng
        }
        degree
        school
        startDate
        endDate
        diplomeType
        comments
        certification
      }
      candidateCertification {
        _id
        id
        candidateId
        city {
          address1
          city
          state
          countryID
          lat
          lng
        }
        degree
        school
        startDate
        endDate
        diplomeType
        comments
        certification
      }
    }
  }
`;
