import React, { Component } from "react";
import { Mutation, withApollo } from "react-apollo";
import { withI18n } from "@lingui/react";
import { withRouter } from "react-router-dom";
import * as PropTypes from "prop-types";
import {
  Validation,
  FormGroup,
  Button,
  PopUp,
  TotalHoursForMobile,
  Icon,
  TextInput,
  Textarea
} from "cf-neo-ui";
import { t, Trans } from "@lingui/macro";
import "../styles.scss";
import { inject, observer } from "mobx-react";
import styles from "./styles.module.scss";
import UserIcon from "../icons/UserIcon";
import { UPDATE_TIMESHEET } from "../mutations";
import { SINGLE_TIME_SHEETS_QUERY } from "../queries";
import classes from "./style.module.scss";
@inject("newTimesheetStore")
@observer
class ValidateTimesheetPopUp extends Component {
  constructor(props) {
    super(props);
    const { newTimesheetStore } = this.props;
    const { timesheet } = newTimesheetStore;
    const purchaseOrderText = timesheet.conversation?.messages
      .filter(message => {
        const purchaseOrder = message.details.find(
          detail => detail.type === "purchaseOrder"
        );
        return purchaseOrder && purchaseOrder.text;
      })[0]
      ?.details.find(detail => detail.type === "purchaseOrder").text;

    this.state = {
      message: "",
      purchaseOrder:
        purchaseOrderText != null ? purchaseOrderText : timesheet.purchaseOrder,
      disabledPurchaseorder: true
    };
  }
  activatepurchaseOrderfield = () => {
    this.setState({ disabledPurchaseorder: false });
  };
  handlePurchaseOrderChange = event => {
    this.setState({ purchaseOrder: event.target.value });
  };

  render() {
    const { message, purchaseOrder, disabledPurchaseorder } = this.state;
    const { newTimesheetStore, device, i18n } = this.props;
    const {
      openClosePopUps,
      timesheet,
      normalHours,
      specialHours
    } = newTimesheetStore;
    const { candidate } = timesheet;
    const projectText = timesheet.conversation?.messages
      .filter(message => {
        const project = message.details.find(
          detail => detail.type === "projects"
        );
        return project && project.text;
      })[0]
      ?.details.find(detail => detail.type === "projects").text;

    const tasksText = timesheet.conversation?.messages
      .filter(message => {
        const tasks = message.details.find(detail => detail.type === "tasks");
        return tasks && tasks.text;
      })[0]
      ?.details.find(detail => detail.type === "tasks").text;

    return (
      <PopUp
        mobile={device === "mobile"}
        onClose={() => {
          this.setState({ message: "" });
          openClosePopUps("validateTimesheet");
        }}
      >
        <div
          style={{
            position: "relative",
            height: "690px",
            width: device === "mobile" ? "100%" : "380px"
          }}
        >
          <div
            style={{
              fontFamily: "Montserrat",
              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "16px",
              color: "#242424",
              marginBottom: "15px"
            }}
          >
            <Trans>Valider Timesheet </Trans>
          </div>
          <div
            style={{
              fontFamily: "Montserrat",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "14px",
              color: "#242424",
              marginBottom: "15px"
            }}
          >
            <Trans>
              Une fois le timesheet validé, vous ne pourrez plus revenir en
              arrière. Merci de vérifier les informations suivantes avec
              attention.
            </Trans>
          </div>
          <div
            style={{
              padding: "0 35px"
            }}
          >
            <span style={{ verticalAlign: "middle", marginRight: "10px" }}>
              <UserIcon />
            </span>
            <span
              style={{
                verticalAlign: "middle",
                fontFamily: "Montserrat",
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: "14px",
                color: "#242424"
              }}
            >
              IT Consultant:{" "}
              {candidate
                ? `${candidate.firstName} ${candidate.lastName}`.toUpperCase()
                : ""}
            </span>
          </div>
          <div
            style={{
              position: "relative",
              padding: "7px 30px"
            }}
          >
            <TotalHoursForMobile
              normalHours={`${Math.floor(normalHours / 60)}`}
              specialHours={`${Math.floor(specialHours / 60)}`}
              normalHoursText={i18n._(t`Heures normales`)}
              specialHoursText={i18n._(t`Heures spéciales`)}
            />
          </div>
          <div style={{ paddingTop: "16px", display: "inline-flex" }}>
            <div>
              <p
                style={{
                  fontFamily: "Montserrat",
                  fontSize: "13px",
                  fontStyle: "normal",
                  color: "#d3354a",
                  fontWeight: "bolder"
                }}
              >
                <Trans>Projet :</Trans>
              </p>
            </div>
            <div>
              <p
                style={{
                  fontFamily: "Montserrat",
                  fontSize: "13px",
                  fontStyle: "normal",
                  fontWeight: "bold",
                  marginLeft: "4px"
                }}
              >
                {" "}
                {projectText}{" "}
              </p>
            </div>
          </div>

          <div>
            <p
              style={{
                fontFamily: "Montserrat",
                fontSize: "13px",
                fontStyle: "normal",
                color: "#d3354a",
                fontWeight: "bolder"
              }}
            >
              <Trans>Tâches réalisées </Trans>
            </p>
            <Textarea
              resize={false}
              size="large"
              style={{
                backgroundColor: "	#D0D0D0",
                fontSize: "14px",
                fontFamily: "Montserrat",
                fontWeight: "normal"
              }}
              disabled={true}
              value={tasksText}
            />
          </div>
          <div className={classes.col}>
            <p
              style={{
                fontFamily: "Montserrat",
                fontSize: "13px",
                fontStyle: "normal",
                color: "#d3354a",
                fontWeight: "bolder",
                display: "flex"
              }}
            >
              <Trans>Bon de commande</Trans>
              <div className={classes.tooltip}>
                <Icon type="info" width={16} color="#8d0417" color2="#d3354a" />
                <span className={classes.tooltipText}>
                  <Trans>
                    {" "}
                    Il s'agit du dernier numéro de bon de commande lié à votre
                    contrat connu dans notre système. S'il n'y en a pas vous
                    pouvez ignorer ce champ.
                  </Trans>
                </span>
              </div>
            </p>
            <Textarea
              placeholder={i18n._(t`Pas de numéro de bon de commande indiqué`)}
              onChange={this.handlePurchaseOrderChange}
              defaultValue={purchaseOrder}
              disabled={disabledPurchaseorder}
              resize={false}
              style={{
                backgroundColor: disabledPurchaseorder ? "#D0D0D0" : "white",
                fontSize: "14px",
                fontFamily: "Montserrat",
                fontWeight: "normal"
              }}
            />
            <div style={{ display: "flex" }}>
              <Trans>
                <p>Ce numéro est manquant ou erroné ? </p>
                <p
                  style={{
                    color: "#d3354a",
                    cursor: "pointer",
                    textDecoration: "underline"
                  }}
                  onClick={() => this.activatepurchaseOrderfield()}
                >
                  Je le suggère
                </p>
              </Trans>
            </div>
          </div>
          <FormGroup style={{ paddingTop: "1px" }}>
            <label
              style={{
                position: "relative",
                fontFamily: "Montserrat",
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: "14px",
                color: "#242424"
              }}
            >
              <Trans>Message</Trans>
            </label>
            <Validation errorMessage="" valid={true}>
              <textarea
                className={styles.textArea}
                id="addMessage"
                placeholder={i18n._(t`Voudriez-vous laisser un message ?`)}
                value={message}
                onChange={e => this.setState({ message: e.target.value })}
                style={{ paddingLeft: "10px", marginTop: "10px" }}
              />
            </Validation>
          </FormGroup>
          <div>
            <span
              style={{
                fontFamily: "Montserrat",
                fontSize: "10px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "20px",
                color: "#000000"
              }}
            >
              <Trans>* Ce commentaire sera visible par le consultant </Trans>
            </span>
          </div>
          <Mutation
            mutation={UPDATE_TIMESHEET}
            variables={{
              id: timesheet.id,
              action: "approve", //draft, send, validate, approve, reject
              ip: this.ip,
              messages: [message && { text: message, type: "message" }].filter(
                n => n
              ),
              purchaseOrder: purchaseOrder
            }}
            refetchQueries={[
              {
                query: SINGLE_TIME_SHEETS_QUERY,
                variables: { id: timesheet.id }
              }
            ]}
            onCompleted={() => {
              this.setState({ message: "" });
              openClosePopUps("timesheetValidatedSuccessfully");
            }}
            onError={errors => {}}
          >
            {(mutation, { loading }) => (
              <div
                style={{
                  width: "100%",
                  padding: "13px 0"
                }}
              >
                <Button
                  variant="primary"
                  size="small"
                  onClick={() => {
                    openClosePopUps("validateTimesheet");
                    return mutation();
                  }}
                  style={{ width: "100%" }}
                >
                  <Trans>Valider</Trans>
                </Button>
              </div>
            )}
          </Mutation>
        </div>
      </PopUp>
    );
  }
}

ValidateTimesheetPopUp.wrappedComponent.propTypes = {
  newTimesheetStore: PropTypes.shape({
    openClosePopUps: PropTypes.func,
    timesheet: PropTypes.shape({}),
    normalHours: PropTypes.number,
    specialHours: PropTypes.number
  }).isRequired,
  device: PropTypes.string
};
export default withApollo(withI18n()(withRouter(ValidateTimesheetPopUp)));
