import React, { Component } from "react";
import { Mutation } from "react-apollo";
import { withI18n } from "@lingui/react";
import * as PropTypes from "prop-types";
import { Validation, FormGroup, Button, PopUp, LoggedHoursV2 } from "cf-neo-ui";
import { t, Trans } from "@lingui/macro";
import "../styles.scss";
import { inject, observer } from "mobx-react";
import styles from "./styles.module.scss";
import { SEND_MESSAGE_TIMESHEET } from "../mutations";
import { SINGLE_TIME_SHEETS_QUERY } from "../queries";

@inject("newTimesheetStore", "appStore")
@observer
class AddMessagePopUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: ""
    };
  }

  validate = () => {
    const { message } = this.state;
    return !!message;
  };

  render() {
    const { message } = this.state;
    const { newTimesheetStore, device, i18n } = this.props;
    const { openClosePopUps, rangeDetails, timesheet } = newTimesheetStore;
    return (
      <PopUp
        mobile={device === "mobile"}
        onClose={() => {
          openClosePopUps("addMessage");
          this.setState({ message: "" });
        }}
      >
        <div
          style={{
            position: "relative",
            height: "100%",
            width: device === "mobile" ? "100%" : "380px"
          }}
        >
          <div
            style={{
              fontFamily: "Montserrat",
              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "16px",
              color: "#242424",
              marginBottom: "26px"
            }}
          >
            <Trans>Ajouter un message</Trans>
          </div>
          <div
            style={{
              listStyleType: "none",
              position: "relative",
              fontFamily: "Montserrat",
              fontStyle: "normal",
              fontWeight: 500,
              fontSize: "16px",
              color: "#242424",
              marginBottom: "10px"
            }}
          >
            <span style={{ verticalAlign: "middle" }}>
              <Trans>Thème</Trans>:{" "}
            </span>
            <span
              style={{
                height: "20px",
                width: "20px",
                backgroundColor: rangeDetails.themeColor,
                borderRadius: "50%",
                display: "inline-block",
                verticalAlign: "middle"
              }}
            />
            <span style={{ verticalAlign: "middle" }}>{` ${
              rangeDetails && rangeDetails.themeName
                ? rangeDetails.themeName
                : ""
            }`}</span>
          </div>
          <div>
            <LoggedHoursV2
              type={
                rangeDetails && rangeDetails.type ? rangeDetails.type : "normal"
              }
              period={
                rangeDetails && rangeDetails.period ? rangeDetails.period : ""
              }
              hours={
                rangeDetails && rangeDetails.hours ? rangeDetails.hours : ""
              }
              message={
                rangeDetails && rangeDetails.description
                  ? rangeDetails.description
                  : ""
              }
            />
          </div>
          <FormGroup style={{ marginTop: "30px" }}>
            <label
              style={{
                position: "relative",
                fontFamily: "Montserrat",
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: "14px",
                color: "#242424"
              }}
            >
              <Trans>Message:</Trans>
            </label>
            <Validation errorMessage="" valid={true}>
              <textarea
                className={styles.textArea}
                id="addMessage"
                placeholder={i18n._(t`Que faut-il clarifier ?`)}
                value={message}
                onChange={e => this.setState({ message: e.target.value })}
                style={{ paddingLeft: "10px", marginTop: "10px" }}
              />
            </Validation>
            <label
              style={{
                position: "relative",
                fontFamily: "Montserrat",
                fontStyle: "normal",
                fontWeight: "bold",
                fontSize: "11px",
                color: "#cb1a31"
              }}
            >
              <Trans>
                Cette annotation sera visible par le canidat dans le module de
                chat mais pas sur le compte-rendu de Timesheet
              </Trans>
            </label>
          </FormGroup>
          <Mutation
            mutation={SEND_MESSAGE_TIMESHEET}
            variables={{
              timesheetID: timesheet.id,
              messages: [
                { text: message, type: "comment" },
                { text: rangeDetails.type, type: "type" },
                { text: rangeDetails.period, type: "period" },
                { text: rangeDetails.hours, type: "hours" },
                { text: rangeDetails.description, type: "description" },
                { text: rangeDetails.themeName, type: "themeName" },
                { text: rangeDetails.themeColor, type: "themeColor" }
              ]
            }}
            refetchQueries={[
              {
                query: SINGLE_TIME_SHEETS_QUERY,
                variables: { id: timesheet.id }
              }
            ]}
            onCompleted={() => {
              openClosePopUps("messageSentSuccessfully");
              this.setState({ message: "" });
            }}
            onError={errors => {
              console.log("errors", errors);
              errors.graphQLErrors.forEach(({ message, data }) => {
                if (message === "UNAUTHENTICATED") {
                  logout();
                  clearSearch();
                }
                if (data && data.isCustomError) {
                  this.onErrorHandler(message);
                }
              });
            }}
          >
            {(mutation, { loading }) => (
              <div
                style={{
                  width: "100%",
                  paddingTop: "20px"
                }}
              >
                <Button
                  variant="primary"
                  size="small"
                  onClick={() => {
                    if (this.validate()) {
                      openClosePopUps("addMessage");
                      return mutation();
                    }
                    return null;
                  }}
                  style={{ width: "100%" }}
                >
                  <Trans>Ajouter</Trans>
                </Button>
              </div>
            )}
          </Mutation>
        </div>
      </PopUp>
    );
  }
}

AddMessagePopUp.wrappedComponent.propTypes = {
  newTimesheetStore: PropTypes.shape({
    openClosePopUps: PropTypes.func,
    rangeDetails: PropTypes.shape({}),
    timesheet: PropTypes.shape({})
  }).isRequired,
  device: PropTypes.string
};
export default withI18n()(AddMessagePopUp);
