import React, { Component } from "react";
import { withApollo } from "react-apollo";
import { withI18n } from "@lingui/react";
import { withRouter } from "react-router-dom";
import * as PropTypes from "prop-types";
import {
  Col,
  Row,
  DateRangePicker,
  SelectNewVersion,
  SwitchButton3,
  TextInputV2,
  IconCircle,
  Validation,
  TextInput,
  FormGroup,
  Icon
} from "cf-neo-ui";
import { t, Trans } from "@lingui/macro";
import "../styles.scss";
import { inject, observer } from "mobx-react";

@inject("newTimesheetStore")
@observer
class AddThemeForMobile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      normalHours: 0,
      specialHours: 0
    };
  }
  componentDidMount() {}

  addNewTheme = () => {
    const { newTimesheetStore } = this.props;
    const { openClosePopUps } = newTimesheetStore;
    openClosePopUps("addTheme");
  };

  render() {
    const {} = this.state;
    return (
      <div style={{ position: "relative", padding: "30px 0" }}>
        <span
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            fontFamily: "Montserrat",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "16px",
            letterSpacing: "0.3px",

            color: "#1E293B"
          }}
        >
          <Trans>Tous les thèmes</Trans>
        </span>

        {/*<span*/}
        {/*  style={{*/}
        {/*    position: "absolute",*/}
        {/*    top: "0",*/}
        {/*    right: "0",*/}
        {/*    cursor: "pointer"*/}
        {/*  }}*/}
        {/*  onClick={() => this.addNewTheme()}*/}
        {/*>*/}
        {/*  <Icon*/}
        {/*    type="plus"*/}
        {/*    width={10}*/}
        {/*    height={10}*/}
        {/*    color="#96081C"*/}
        {/*    color2="#96081C"*/}
        {/*  />*/}
        {/*  <span*/}
        {/*    style={{*/}
        {/*      marginLeft: "7px",*/}
        {/*      fontFamily: "Montserrat",*/}
        {/*      fontStyle: "normal",*/}
        {/*      fontWeight: 400,*/}
        {/*      fontSize: "12px",*/}
        {/*      letterSpacing: "0.5px",*/}
        {/*      color: "#96081C"*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    Ajouter thème*/}
        {/*  </span>*/}
        {/*</span>*/}

        <span style={{ position: "absolute", top: "20px", left: "0" }}>
          <span style={{ marginRight: "5px" }}>
            <svg
              width="7"
              height="10"
              viewBox="0 0 7 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.5 6.91358C3.68565 6.91358 3.8637 6.84074 3.99497 6.71109C4.12625 6.58143 4.2 6.40558 4.2 6.22222C4.2 6.03886 4.12625 5.86301 3.99497 5.73336C3.8637 5.6037 3.68565 5.53086 3.5 5.53086C3.31435 5.53086 3.1363 5.6037 3.00503 5.73336C2.87375 5.86301 2.8 6.03886 2.8 6.22222C2.8 6.40558 2.87375 6.58143 3.00503 6.71109C3.1363 6.84074 3.31435 6.91358 3.5 6.91358ZM3.5 0.691358C3.22152 0.691358 2.95445 0.800617 2.75754 0.995099C2.56062 1.18958 2.45 1.45336 2.45 1.7284V3.11111H5.95C6.22848 3.11111 6.49555 3.22037 6.69246 3.41485C6.88938 3.60933 7 3.87311 7 4.14815V8.2963C7 8.57134 6.88938 8.83511 6.69246 9.02959C6.49555 9.22407 6.22848 9.33333 5.95 9.33333H1.05C0.771523 9.33333 0.504451 9.22407 0.307538 9.02959C0.110625 8.83511 0 8.57134 0 8.2963V4.14815C0 3.87311 0.110625 3.60933 0.307538 3.41485C0.504451 3.22037 0.771523 3.11111 1.05 3.11111H1.75V1.7284C1.75 1.27 1.93437 0.830372 2.26256 0.506235C2.59075 0.182098 3.03587 0 3.5 0C3.96413 0 4.40925 0.182098 4.73744 0.506235C5.06563 0.830372 5.25 1.27 5.25 1.7284C5.25 1.82007 5.21313 1.908 5.14749 1.97283C5.08185 2.03765 4.99283 2.07407 4.9 2.07407C4.80717 2.07407 4.71815 2.03765 4.65251 1.97283C4.58687 1.908 4.55 1.82007 4.55 1.7284C4.55 1.45336 4.43938 1.18958 4.24246 0.995099C4.04555 0.800617 3.77848 0.691358 3.5 0.691358ZM1.05 3.80247C0.957174 3.80247 0.86815 3.83889 0.802513 3.90372C0.736875 3.96854 0.7 4.05647 0.7 4.14815V8.2963C0.7 8.38798 0.736875 8.4759 0.802513 8.54073C0.86815 8.60556 0.957174 8.64198 1.05 8.64198H5.95C6.04283 8.64198 6.13185 8.60556 6.19749 8.54073C6.26313 8.4759 6.3 8.38798 6.3 8.2963V4.14815C6.3 4.05647 6.26313 3.96854 6.19749 3.90372C6.13185 3.83889 6.04283 3.80247 5.95 3.80247H1.05Z"
                fill="#575A61"
              />
            </svg>
          </span>
          <span
            style={{
              fontFamily: "Montserrat",
              fontStyle: "normal",
              fontWeight: 300,
              fontSize: "12px",
              letterSpacing: "0.5px",
              color: "#575A61"
            }}
          >
            <Trans>Le client Mindquest a accès à vos thèmes</Trans>
          </span>
        </span>
      </div>
    );
  }
}

AddThemeForMobile.wrappedComponent.propTypes = {
  newTimesheetStore: PropTypes.shape({
    openClosePopUps: PropTypes.func
  }).isRequired
};
export default withApollo(withI18n()(withRouter(AddThemeForMobile)));
