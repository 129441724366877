import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter, NavLink } from "react-router-dom";
import { withI18n } from "@lingui/react";
import { H4, BlogPosterSkeleton } from "cf-neo-ui";
import { t, Trans } from "@lingui/macro";
import { Query } from "react-apollo";
import { inject, observer } from "mobx-react";
import Slider from "./slider/Slider";
import PlusButton from "../plus-button/PlusButton";
import DisplayBlogCards from "../blog-cards-display/BlogCardsDisplay";
import LoadingBlogCards from "../blog-cards-display/LoadingBlogCardDisplay";
import TwitterPosts from "./twitter-card/TwitterCard";
import DisplayBlogVideos from "../blog-videos-display/VideosDisplay";
import LoadingBlogVideos from "../blog-videos-display/LoadingVideosDisplay";
import Books from "../../../components/testimonial-carousel";
import classes from "./MainBlog.module.scss";
import decode from "../../../utils/Utf8Text";
import Routes from "../../layout/routes";
import { latestPostsQuery, categoriesRefEn, categoriesRefFr } from "../query";
import staticPagesMetaTags from "../../../configs/staticPagesMetaTags";
import metaTags from "../../../utils/editMetaData";
import urls from "../../../utils/urls";
import ldJson from "./ldJson";

// const catalogs = {
//   en: catalogEn
// };

@inject("appStore")
@observer
class MainBlog extends Component {
  constructor(props) {
    super(props);
    this.latestPostsPage = 2;
    this.latestVideoPage = 1;
    this.state = {
      render: false
    };
  }

  componentDidMount() {
    this.setState({ render: true });
    window.scroll(0, 0);
  }

  showMoreVideos = fetchMore => {
    const { appStore } = this.props;
    const { currentLanguage } = appStore;
    let categoriesRef = categoriesRefFr;
    if (currentLanguage === "en") categoriesRef = categoriesRefEn;
    this.latestVideoPage += 1;
    return fetchMore({
      fetchPolicy: "cache-and-network",
      query: latestPostsQuery,
      variables: {
        notifyOnNetworkStatusChange: true,
        page: this.latestVideoPage,
        categoryIds: categoriesRef.videos,
        perPage: 2,
        language: currentLanguage
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return { ...prev, posts: [...prev.posts, ...fetchMoreResult.posts] };
      }
    });
  };

  showMoreArticles = fetchMore => {
    const { appStore } = this.props;
    const { currentLanguage } = appStore;
    let categoriesRef = categoriesRefFr;
    if (currentLanguage === "en") categoriesRef = categoriesRefEn;
    this.latestPostsPage += 1;
    return fetchMore({
      fetchPolicy: "cache-and-network",
      query: latestPostsQuery,
      variables: {
        notifyOnNetworkStatusChange: true,
        page: this.latestPostsPage,
        excludeCategories: `${categoriesRef.videos},${categoriesRef.featured},${categoriesRef.resources}`,
        perPage: 2,
        language: currentLanguage
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return { ...prev, posts: [...prev.posts, ...fetchMoreResult.posts] };
      }
    });
  };

  toResources = post => {
    window.open(post.download_link, "_blank");
    // history.push(`/blog/resources/${id}`);
  };

  postsToBooks = posts => {
    const { i18n } = this.props;
    const books = [];
    posts.map(post =>
      books.push({
        id: post.id,
        image: post.featured_image2_url
          ? post.featured_image2_url
          : post.featured_media && post.featured_media.source_url,
        name: "",
        job: "",
        paragraph: decode(post.title),
        buttonText: i18n._(t`Accéder à la ressource`),
        onClickButton: () => this.toResources(post)
      })
    );
    return books;
  };

  render() {
    const { i18n, appStore } = this.props;
    const { currentLanguage } = appStore;
    let categoriesRef = categoriesRefFr;
    if (currentLanguage === "en") categoriesRef = categoriesRefEn;

    const { render } = this.state;
    const meta =
      currentLanguage === "fr"
        ? staticPagesMetaTags(i18n).blog.main
        : staticPagesMetaTags(i18n).blogEn.main;

    return (
      <div>
        {metaTags(
          urls.blog(),
          meta.title,
          meta.description,
          meta.openGraphImage.facebookOpenGraphImageUrl,
          meta.openGraphImage.linkedInOpenGraphImageUrl,
          meta.keywords,
          ldJson
        )}
        <div className={classes.limitedBody}>
          <section>
            {render ? (
              <Query
                query={latestPostsQuery}
                variables={{
                  categoryIds: categoriesRef.featured,
                  language: currentLanguage
                }}
              >
                {({ loading, error, data }) => {
                  if (error || loading)
                    return (
                      <BlogPosterSkeleton className={classes.loadingFeatured} />
                    );
                  return <Slider slides={data.posts} />;
                }}
              </Query>
            ) : (
              <BlogPosterSkeleton className={classes.loadingFeatured} />
            )}
          </section>
          <section className={classes.inline}>
            <section>
              <h1 className={classes.heading}>
                <Trans>Restez connectés avec le secteur de Tech & IT</Trans>
              </h1>
              <h2 className={classes.paragraph}>
                <Trans>
                  Retrouvez nos actualités, nos fiches métiers, notre sélection
                  d'articles pour talents et consultants IT, décideurs et
                  recruteurs IT et tous nos webinars et ressources pour recruter
                  le profil ou trouver la mission informatique idéal.
                </Trans>
              </h2>
              <H4 className={classes.cardsHeading}>
                <NavLink
                  to={Routes.HbfLayoutRoutes.about.path}
                  className={classes.cardsHeading}
                >
                  <Trans>Qui sommes-nous ?</Trans>
                </NavLink>
              </H4>
              <Query
                query={latestPostsQuery}
                variables={{
                  page: 1,
                  excludeCategories: `${categoriesRef.videos},${categoriesRef.featured},${categoriesRef.resources}`,
                  perPage: 4,
                  language: currentLanguage
                }}
                fetchPolicy="cache-and-network"
              >
                {// eslint-disable-next-line no-unused-vars
                ({ loading, error, data, fetchMore, networkStatus }) => {
                  if (error)
                    return (
                      <div className="error_container">
                        <span>
                          {i18n._(t` Oups! il semblerait que vos données aient du mal à se
                  synchroniser. Veuillez rafraichir la page dans un moment`)}
                        </span>
                        <img
                          style={{
                            margin: 20
                          }}
                          src={"/assets/svg/download.svg"}
                          alt="error"
                        />
                      </div>
                    );
                  if (!loading && !data.posts.length)
                    return (
                      <div className={classes.carouselHloder}>
                        <Trans>
                          Il n’y a pas des posts pour le moment dans cette
                          section
                        </Trans>
                      </div>
                    );
                  if (networkStatus === 1)
                    return <LoadingBlogCards cardsNumber={4} />;
                  return (
                    <div>
                      {data && <DisplayBlogCards articles={data.posts} />}
                      {networkStatus === 3 && (
                        <LoadingBlogCards cardsNumber={2} />
                      )}
                      {data &&
                        data.posts[0] &&
                        this.latestPostsPage < data.posts[0].totalPages && (
                          <PlusButton
                            onClick={() => this.showMoreArticles(fetchMore)}
                          >
                            <Trans>More articles</Trans>
                          </PlusButton>
                        )}
                    </div>
                  );
                }}
              </Query>
            </section>
            <TwitterPosts className={classes.tweets} />
          </section>
          <section>
            <Query
              query={latestPostsQuery}
              variables={{
                page: 1,
                perPage: 2,
                categoryIds: categoriesRef.videos,
                language: currentLanguage
              }}
              fetchPolicy="cache-and-network"
            >
              {({ data, fetchMore, networkStatus }) => {
                if (data && data.posts.length)
                  return (
                    <div>
                      <H4>
                        <Trans>Vidéos</Trans>
                      </H4>
                      <p className={classes.paragraph}>
                        <Trans>Nos conseils en vidéo</Trans>
                      </p>
                      <DisplayBlogVideos videos={data.posts} />
                      {networkStatus === 3 && (
                        <LoadingBlogVideos cardsNumber={2} />
                      )}
                      {this.latestVideoPage < data.posts[0].totalPages && (
                        <PlusButton
                          onClick={() => this.showMoreVideos(fetchMore)}
                        >
                          <Trans>Voir plus de vidéos</Trans>
                        </PlusButton>
                      )}
                    </div>
                  );
                return null;
              }}
            </Query>
          </section>
        </div>
        <Query
          query={latestPostsQuery}
          variables={{
            page: 1,
            perPage: 6,
            categoryIds: categoriesRef.resources,
            language: currentLanguage
          }}
        >
          {({ data }) => {
            if (data && data.posts.length) {
              return (
                <div style={{ marginBottom: "30px" }}>
                  <div className={classes.limitedBody}>
                    <section>
                      <h2>
                        <Trans>Ressources</Trans>
                      </h2>
                      <p className={classes.paragraph}>
                        <Trans>À télécharger gratuitement</Trans>
                      </p>
                    </section>
                  </div>
                  <Books
                    testimonials={this.postsToBooks(data.posts)}
                    pLines={2}
                  />
                </div>
              );
            }
            return null;
          }}
        </Query>
      </div>
    );
  }
}

MainBlog.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  appStore: PropTypes.shape({
    currentLanguage: PropTypes.string
  }).isRequired
};

export default withI18n()(withRouter(MainBlog));
