import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withI18n } from "@lingui/react";
import { t } from "@lingui/macro";
import { AutoComplete, TagList } from "cf-neo-ui";
import { inject, observer } from "mobx-react/index";
import classes from "../SideFilters.module.scss";
import theme from "../../../../../configs/theme";

@inject("missionsSearchStore")
@observer
class SkillsWidget extends Component {
  constructor(props) {
    super(props);
    props.missionsSearchStore.initSkillOptions(props.options);
  }

  selectHandler = val => {
    const { missionsSearchStore } = this.props;
    missionsSearchStore.addSkill(val);
    missionsSearchStore.setSkillOptions();
  };

  closeTagHandler = val => {
    const { missionsSearchStore } = this.props;
    missionsSearchStore.removeSkill(val);
    missionsSearchStore.setSkillOptions();
  };

  render() {
    const { i18n, missionsSearchStore } = this.props;
    return (
      <div>
        <AutoComplete
          key={missionsSearchStore.skills.slice().length}
          restrictedToList
          clearOnSelect
          options={missionsSearchStore.skillOptions.slice()}
          maxSuggestion={6}
          min={1}
          iconColor={theme.colors.mainColor}
          iconColor2={theme.colors.lightMainColor}
          icon="search"
          placeholder={i18n._(t`Chercher des compétences`)}
          onSelect={this.selectHandler}
        />
        <TagList
          tags={missionsSearchStore.skills}
          variant="secondary"
          closable
          className={classes.tagList}
          onClose={this.closeTagHandler}
        />
      </div>
    );
  }
}

SkillsWidget.wrappedComponent.propTypes = {
  missionsSearchStore: PropTypes.PropTypes.shape({
    skills: PropTypes.shape({}),
    skillOptions: PropTypes.shape({}),
    removeSkill: PropTypes.func,
    setSkillOptions: PropTypes.func,
    addSkill: PropTypes.func,
    initSkillOptions: PropTypes.func
  }).isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default withI18n()(SkillsWidget);
