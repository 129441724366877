import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import moment from "moment";
import { withI18n } from "@lingui/react";
import { Trans, t } from "@lingui/macro";
import {
  Row,
  Col,
  Button,
  FormCard,
  Icon,
  FormGroup,
  TextInput,
  AutoComplete,
  DatePicker,
  Validation,
  RadioGroup,
  InputSkeleton,
  Radio,
  Checkbox,
  Divider,
  TagList,
  Tag,
  Modal,
  ToastCard
} from "cf-neo-ui";
import validate from "../../../utils/validators";
import "./styles.scss";
import theme from "../../../configs/theme";
import runtimeVars from "../../../configs/runTimeVars";
import gql from "graphql-tag";
import { Query } from "react-apollo";
import { GET_BUSINESS_SECTORS } from "../../missions-page/queries";
import Location from "../../../components/location/location";
import classes from "../../../components/alert-modal/alertModal.module.scss";

const GET_SPECIALTIES = gql`
  {
    specialties {
      name
    }
  }
`;
const GET_SKILLS = gql`
  {
    skills {
      name
    }
  }
`;

@inject("sessionStore", "appStore")
@observer
class SingleExperience extends Component {
  constructor(props) {
    super(props);
    const initProps = { ...props };
    delete initProps.sessionStore;
    this.state = {
      ...initProps,
      isTitleValid: true,
      isCompanyNameValid: true,
      isStartDateValid: true,
      isEndDateValid: true,
      isVisible: false,
      isSummaryValid: true,
      isRefNameValid: true,
      isRefPhoneValid: true,
      isRefEmailValid: true,
      isContractTypeValid: true,
      titleValidationMessage: "",
      companyNameValidationMessage: "",
      startDateValidationMessage: "",
      endDateValidationMessage: "",
      summaryValidationMessage: "",
      refNameValidationMessage: "",
      refPhoneValidationMessage: "",
      refEmailValidationMessage: "",
      contractTypeValidationMessage: "",
      secteurValidationMessage: "",
      isSecteurValid: "",
      todayDate: moment(new Date()).valueOf()
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const newProps = { ...nextProps };
    delete newProps.sessionStore;
    this.setState({ ...newProps });
  }

  onDeleteHandler = () => {
    this.setState({ isEditing: false });
    const { sessionStore, onValidate } = this.props;
    const { candidateWorkHistory, changeCandidateWorkHistory } = sessionStore;
    onValidate();
    const { id } = this.state;
    const arr = candidateWorkHistory.slice();

    for (let key = 0; key < arr.length; key += 1) {
      const experience = arr[key];
      if (experience.id === id) {
        arr.splice(key, 1);
        changeCandidateWorkHistory(arr);
      }
    }
  };
  onToastHandler = () => {
    this.setState({ isVisible: true });
  };
  onToastHandlerClose = () => {
    this.setState({ isVisible: false });
  };
  onCancelHandler = () => {
    const { isNew } = this.state;
    if (isNew) {
      this.onDeleteHandler();
    } else {
      const { sessionStore } = this.props;
      const { candidateWorkHistory, changeCandidateWorkHistory } = sessionStore;
      this.state = {
        title: sessionStore.title,
        companyName: sessionStore.companyName,
        startDate: sessionStore.startDate,
        endDate: sessionStore.endDate,
        summary: sessionStore.summary,
        refName: sessionStore.refName,
        refPhone: sessionStore.refPhone,
        refEmail: sessionStore.refEmail,
        contractType: sessionStore.contractType,
        skillExperience: sessionStore.skillExperience,
        specialty: sessionStore.specialty,
        BusinessSector: sessionStore.BusinessSector,
        companyAddress: sessionStore.companyAddress,
        isEditing: false
      };
      this.setState({ isEditing: false });
      const arr = candidateWorkHistory.slice();

      changeCandidateWorkHistory(arr);
    }
  };

  onEditHandler = () => {
    this.setState({ isEditing: true });
    const { sessionStore } = this.props;
    const { changeGeneralSaveCase } = sessionStore;
    changeGeneralSaveCase(4);
  };

  onSaveHandler = () => {
    if (!this.isFormValid()) {
      return;
    }
    const { sessionStore, onValidate } = this.props;

    const {
      candidateWorkHistory,
      changeGeneralSaveCase,
      changeCandidateWorkHistory
    } = sessionStore;

    const newCandidateWorkHistory = candidateWorkHistory.slice();
    const { id } = this.state;

    for (let key = 0; key < newCandidateWorkHistory.length; key += 1) {
      const experience = newCandidateWorkHistory[key];
      if (experience.id === id) {
        const {
          isCurrent,
          title,
          companyName,
          startDate,
          endDate,
          summary,
          refName,
          refPhone,
          refEmail,
          contractType,
          companyAddress,
          skillExperience,
          BusinessSector,
          specialty
        } = this.state;
        const { _id } = experience;
        newCandidateWorkHistory[key] = {
          _id,
          id,
          isCurrent,
          title,
          companyName,
          startDate,
          endDate,
          summary,
          refName,
          refPhone,
          refEmail,
          contractType,
          isNew: false,
          isEditing: false,
          skillExperience,
          specialty,
          BusinessSector,
          companyAddress
        };
      }
      changeCandidateWorkHistory(newCandidateWorkHistory);
      changeGeneralSaveCase(4);
      onValidate();
    }
  };

  refEmailChangeHandler = e => {
    this.setState({ refEmail: e.target.value });
    this.validateRefEmail(e.target.value);
  };

  isCurrentChangeHandler = () => {
    const { isCurrent } = this.state;
    if (!isCurrent) {
      this.setState({ endDate: "" });
    }
    this.setState({ isCurrent: !isCurrent });
  };

  titleChangeHandler = e => {
    this.setState({ title: e.target.value });
    this.validateTitle(e.target.value);
  };

  contractTypeChangeHandler = value => {
    this.setState({ contractType: value });
    this.validateContractType(value);
  };

  companyNameChangeHandler = e => {
    this.setState({ companyName: e.target.value });
    this.validateCompanyName(e.target.value);
  };

  startDateChangeHandler = value => {
    this.setState({ startDate: value });
    this.validateStartDate(value);
  };

  endDateChangeHandler = value => {
    this.setState({ endDate: value });
    this.validateEndDate(value);
  };

  summaryChangeHandler = e => {
    this.setState({ summary: e.target.value });
    this.validateSummary(e.target.value);
  };

  refNameChangeHandler = e => {
    this.setState({ refName: e.target.value });
    this.validateRefName(e.target.value);
  };

  refPhoneChangeHandler = e => {
    this.setState({ refPhone: e.target.value });
    this.validateRefPhone(e.target.value);
  };
  specialtyAddHandler = value => {
    const { specialty } = this.state;
    if (!specialty.includes(value)) {
      let specialties = specialty.slice();
      specialties.push(value);
      this.setState({ specialty: specialties });
    }
  };

  specialtyRemoveHandler = value => {
    const { specialty } = this.state;
    var index = specialty.indexOf(value);
    if (index !== -1) {
      let specialtiess = specialty.slice();
      specialtiess.splice(index, 1);
      this.setState({ specialty: specialtiess });
    }
  };

  BusinessSectorChangeHandler = value => {
    const { BusinessSector } = this.state;
    if (!BusinessSector.includes(value)) {
      let BusinessSectors = BusinessSector.slice();
      BusinessSectors.push(value);
      this.setState({ BusinessSector: BusinessSectors });
    }
  };

  businessSectorRemoveHandler = value => {
    const { BusinessSector } = this.state;
    var index = BusinessSector.indexOf(value);
    if (index !== -1) {
      BusinessSector.splice(index, 1);
    }
    this.setState({ businessSectors: BusinessSector });
  };

  skillAddHandler = value => {
    const { skillExperience } = this.state;
    if (!skillExperience.includes(value)) {
      let skillExperiences = skillExperience.slice();
      skillExperiences.push(value);
      this.setState({ skillExperience: skillExperiences });
    }
  };

  skillRemoveHandler = value => {
    const { skillExperience } = this.state;
    var index = skillExperience.indexOf(value);
    if (index !== -1) {
      skillExperience.splice(index, 1);
    }
    this.setState({ skillExperience: skillExperience });
  };

  getLocationHandler = (address, formattedAddress) => {
    let { companyAddress } = this.state;
    companyAddress = {
      address1: address,
      city: formattedAddress.city,
      countryID: formattedAddress.country,
      zip: formattedAddress.zip,
      state: formattedAddress.state,
      lat: formattedAddress.lat,
      lng: formattedAddress.lng
    };
    this.setState({ companyAddress });
    this.forceUpdate();
    this.validateAddress(address);
  };

  isFormValid = () => {
    const {
      title,
      isCurrent,
      companyName,
      startDate,
      endDate,
      summary,
      refName,
      refPhone,
      refEmail,
      contractType
    } = this.state;

    let valid = true;
    if (!this.validateTitle(title)) valid = false;
    if (!this.validateCompanyName(companyName)) valid = false;
    if (!this.validateStartDate(startDate)) valid = false;
    if (!isCurrent) if (!this.validateEndDate(endDate)) valid = false;
    if (!this.validateSummary(summary)) valid = false;
    if (!this.validateRefName(refName)) valid = false;
    if (!this.validateRefPhone(refPhone)) valid = false;
    if (!this.validateRefEmail(refEmail)) valid = false;
    if (!this.validateContractType(contractType)) valid = false;
    return valid;
  };

  validateContractType = value => {
    const { i18n } = this.props;
    const res = validate(value, ["required"], i18n);
    this.setState({
      isContractTypeValid: res.isValid,
      contractTypeValidationMessage: res.message
    });
    return res.isValid;
  };

  validateTitle = value => {
    const { i18n } = this.props;
    const res = validate(
      value,
      ["required", value, { maxlength: 50 }, { minlength: 3 }],
      i18n
    );
    this.setState({
      isTitleValid: res.isValid,
      titleValidationMessage: res.message
    });
    return res.isValid;
  };

  validateCompanyName = value => {
    const { i18n } = this.props;
    const res = validate(
      value,
      ["required", { maxlength: 100 }, { minlength: 2 }],
      i18n
    );
    this.setState({
      isCompanyNameValid: res.isValid,
      companyNameValidationMessage: res.message
    });
    return res.isValid;
  };
  validateSummary = value => {
    const { i18n } = this.props;
    const res = validate(value, ["required", { maxlength: 1000 }], i18n);
    this.setState({
      isSummaryValid: res.isValid,
      summaryValidationMessage: res.message
    });
    return res.isValid;
  };

  validateRefName = value => {
    const { i18n } = this.props;
    const res = validate(value, ["name", { maxlength: 50 }], i18n);
    this.setState({
      isRefNameValid: res.isValid,
      refNameValidationMessage: res.message
    });
    return res.isValid;
  };

  validateRefEmail = value => {
    const { i18n } = this.props;
    const res = validate(value, ["email"], i18n);
    this.setState({
      isRefEmailValid: res.isValid,
      refEmailValidationMessage: res.message
    });
    return res.isValid;
  };

  validateRefPhone = value => {
    const { i18n } = this.props;
    const res = validate(
      value,
      [{ maxlength: 13 }, { minlength: 8 }, "phoneNumber"],
      i18n
    );
    this.setState({
      isRefPhoneValid: res.isValid,
      refPhoneValidationMessage: res.message
    });
    return res.isValid;
  };

  validateAddress(value) {
    const { i18n } = this.props;
    const res = validate(value, [], i18n);
    let mesg = "";
    if (!res.isValid) mesg = i18n._(t`Non renseigné`);
    this.setState({
      isAddressValid: res.isValid,
      addressValidationMessage: mesg
    });
    return res.isValid;
  }

  validateStartDate = value => {
    const { i18n } = this.props;
    const { endDate, todayDate, isCurrent } = this.state;

    const res = validate(value, ["required"], i18n);
    if (res.isValid === false || Number.isNaN(value) === true) {
      this.setState({
        isStartDateValid: false,
        startDateValidationMessage: i18n._(t`Champ requis`)
      });
      return false;
    }
    if (endDate === "" || Number.isNaN(endDate)) {
      if (value <= todayDate) {
        this.setState({
          isStartDateValid: true,
          startDateValidationMessage: ""
        });
        return true;
      }

      this.setState({
        isStartDateValid: false,
        startDateValidationMessage: i18n._(
          t`Cette date ne peut pas être dans le future`
        )
      });
      return false;
    }

    if (value <= endDate && endDate <= todayDate) {
      this.setState({
        isStartDateValid: true,
        startDateValidationMessage: ""
      });
      return true;
    }

    if (!isCurrent && value >= todayDate && value >= endDate) {
      this.setState({
        isStartDateValid: false,
        startDateValidationMessage: i18n._(
          t`La date de début doit être toujours antérieur à la date de fin`
        )
      });
      return false;
    }

    if (!isCurrent && value <= todayDate && value >= endDate) {
      this.setState({
        isStartDateValid: false,
        startDateValidationMessage: i18n._(
          t`La date de début doit être toujours antérieur à la date de fin`
        )
      });
      return false;
    }

    if (value >= todayDate) {
      this.setState({
        isStartDateValid: false,
        startDateValidationMessage: i18n._(
          t`Cette date ne peut pas être dans le future`
        )
      });
      return false;
    }

    this.setState({
      isStartDateValid: true,
      startDateValidationMessage: ""
    });
    return true;
  };
  companyAdressChangeHandler = v => {
    const { companyAddress } = this.state;
    this.setState({ companyAddress });
    this.forceUpdate();
  };
  validateEndDate = value => {
    const { i18n } = this.props;
    const { startDate, todayDate } = this.state;

    const res = validate(value, ["required"], i18n);
    if (res.isValid === false || Number.isNaN(value) === true) {
      this.setState({
        isEndDateValid: false,
        endDateValidationMessage: i18n._(t`Champ requis`)
      });
      return false;
    }
    if (startDate === "" || Number.isNaN(startDate)) {
      if (value <= todayDate) {
        this.setState({
          isEndDateValid: true,
          endDateValidationMessage: ""
        });
        return true;
      }

      this.setState({
        isEndDateValid: false,
        endDateValidationMessage: i18n._(
          t`Cette date ne peut pas être dans le future`
        )
      });
      return false;
    }

    if (value >= todayDate) {
      this.setState({
        isEndDateValid: false,
        endDateValidationMessage: i18n._(
          t`Cette date ne peut pas être dans le future`
        )
      });
      return false;
    }

    if (startDate <= value && value <= todayDate) {
      this.setState({
        isEndDateValid: true,
        endDateValidationMessage: ""
      });
      return true;
    }

    if (startDate >= value && value <= todayDate) {
      this.setState({
        isEndDateValid: false,
        endDateValidationMessage: i18n._(
          t`La date de fin doit être toujours ultérieur à la date de début`
        )
      });
      return false;
    }

    this.setState({
      isEndDateValid: true,
      endDateValidationMessage: ""
    });
    return true;
  };

  disableStartDays = day => {
    const { endDate, todayDate } = this.state;

    if (endDate) {
      return day > endDate;
    }
    return day > todayDate;
  };

  disableEndDays = day => {
    const { startDate, todayDate, isVisible } = this.state;
    return day < startDate || day > todayDate;
  };

  displayButtons = () => {
    const { showButtons, i18n } = this.props;
    const { isVisible } = this.state;
    if (showButtons === "true") {
      return (
        <div className="experience-buttons">
          <Button size="small" onClick={this.onToastHandler}>
            <Trans>Supprimer</Trans>
          </Button>
          <Modal
            centred
            isVisible={isVisible}
            onClose={this.onToastHandlerClose}
          >
            <div className={classes.alertModal}>
              <div className={classes.header}>
                <div className={classes.headerIcon}>
                  <Icon
                    size="tiny"
                    type="bell"
                    color={theme.colors.mainColor}
                    color2={theme.colors.lightMainColor}
                  />
                </div>
                <Trans>Alerte Suppression</Trans>
              </div>
              <div className={classes.body}>
                <Row style={{ marginLeft: "30px" }}>
                  {i18n._(
                    t`Êtes-vous sûr de vouloir supprimer cette expérience?`
                  )}
                </Row>
              </div>
              <div className={classes.footer}>
                <Button
                  size="small"
                  variant="secondary"
                  onClick={this.onToastHandlerClose}
                >
                  <Trans>Annuler</Trans>
                </Button>
                <Button
                  size="small"
                  style={{ marginLeft: "10px" }}
                  onClick={this.onDeleteHandler}
                >
                  <Trans>Supprimer</Trans>
                </Button>
              </div>
            </div>
          </Modal>
          <Button variant="secondary" size="small" onClick={this.onEditHandler}>
            <Trans>Modifier</Trans>
          </Button>
        </div>
      );
    }
    return null;
  };

  render() {
    const {
      id,
      title,
      isCurrent,
      companyName,
      startDate,
      endDate,
      summary,
      isEditing,
      refName,
      refPhone,
      refEmail,
      isTitleValid,
      isAddressValid,
      companyAddress,
      isCompanyNameValid,
      isStartDateValid,
      isEndDateValid,
      isSummaryValid,
      isRefNameValid,
      isRefPhoneValid,
      isRefEmailValid,
      isContractTypeValid,
      titleValidationMessage,
      companyNameValidationMessage,
      startDateValidationMessage,
      endDateValidationMessage,
      summaryValidationMessage,
      refNameValidationMessage,
      refPhoneValidationMessage,
      refEmailValidationMessage,
      contractTypeValidationMessage,
      contractType,
      skillExperience,
      BusinessSector,
      specialty,
      isVisible
    } = this.state;
    const { i18n, appStore, showButtons } = this.props;
    const orientation = appStore.width <= 348 ? "vertical" : "horizontal";
    const { currentLanguage } = appStore;
    const isMobile = appStore.width <= 1350;

    return (
      <FormCard>
        <Row className="rowExperience">
          <Col>
            {isEditing ? (
              <div>
                <div>
                  <Row>
                    <Col>
                      <Checkbox
                        name="iscurrent"
                        value="iscurrent"
                        checked={isCurrent}
                        label={i18n._(t`J’occupe actuellement ce poste`)}
                        onClickButton={this.isCurrentChangeHandler}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <label className="form-label">
                          <Trans>Titre</Trans>
                        </label>
                        <Validation
                          errorMessage={titleValidationMessage}
                          valid={isTitleValid}
                        >
                          <TextInput
                            type="text"
                            className="form-input"
                            icon="briefcase"
                            iconColor={theme.colors.lightMainColor}
                            placeholder=""
                            value={title}
                            onChange={this.titleChangeHandler}
                          />
                        </Validation>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <label className="form-label">
                          <Trans>Entreprise</Trans>
                        </label>
                        <Validation
                          errorMessage={companyNameValidationMessage}
                          valid={isCompanyNameValid}
                        >
                          <TextInput
                            type="text"
                            spellCheck="false"
                            className="form-input"
                            placeholder=""
                            value={companyName}
                            onChange={this.companyNameChangeHandler}
                          />
                        </Validation>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <label className="form-label" htmlFor="expertise">
                          <Trans>Secteur</Trans>
                        </label>
                        <div>
                          <Query query={GET_BUSINESS_SECTORS}>
                            {({ loading, error, data }) => {
                              if (loading) return <InputSkeleton />;
                              if (error) return `${i18n._(t`Erreur`)} !`;
                              const allbusinessSectors = [];
                              data.businessSectors.map(item =>
                                allbusinessSectors.push(item.name)
                              );
                              return (
                                <div className="withSrcollBar">
                                  <AutoComplete
                                    id="buisinessSector"
                                    autoComplete="off"
                                    clearOnSelect
                                    options={allbusinessSectors}
                                    iconColor="#8d0417"
                                    iconColor2="#d3354a"
                                    icon="bars"
                                    placeholder={i18n._(
                                      t`Sélectionner des secteurs`
                                    )}
                                    onSelect={val =>
                                      this.BusinessSectorChangeHandler(val)
                                    }
                                  />
                                </div>
                              );
                            }}
                          </Query>
                        </div>

                        <TagList
                          tags={BusinessSector}
                          variant="secondary"
                          closable="true"
                          clickable={false}
                          className="tag-list"
                          onClose={val => this.businessSectorRemoveHandler(val)}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <label
                          className="form-label"
                          htmlFor="experienceEnterprise"
                        >
                          <Trans>Localisation</Trans>
                        </label>

                        <Location
                          id="address"
                          onChange={this.companyAdressChangeHandler}
                          value={companyAddress ? companyAddress.address1 : ""}
                          getLocation={(Address, FormattedAddress) =>
                            this.getLocationHandler(Address, FormattedAddress)
                          }
                          valid={isAddressValid}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <FormGroup>
                      <Col>
                        <label className="form-label" htmlFor="contractType">
                          <Trans>Type de contrat</Trans>
                        </label>
                        <Validation
                          errorMessage={contractTypeValidationMessage}
                          valid={isContractTypeValid}
                        >
                          <RadioGroup
                            className="contractType-radio-group"
                            name="contractTypeRadioGrp"
                            orientation={orientation}
                            defaultSelected={contractType}
                            onChange={this.contractTypeChangeHandler}
                          >
                            <Radio label={i18n._(t`CDI`)} value="Permanent" />
                            <Radio
                              label={i18n._(t`FreelanceType`)}
                              value="Contract"
                            />
                          </RadioGroup>
                        </Validation>
                      </Col>
                    </FormGroup>
                  </Row>

                  <Row>
                    <Col>
                      <FormGroup>
                        <label className="form-label">
                          <Trans>Date de début</Trans>
                        </label>
                        <Validation
                          errorMessage={startDateValidationMessage}
                          valid={isStartDateValid}
                        >
                          <DatePicker
                            disabledDaysMatcher={day =>
                              this.disableStartDays(day)
                            }
                            dateFormat="dd/MM/yyyy"
                            defaultDate={
                              startDate
                                ? moment
                                    .unix(startDate / 1000)
                                    .format("DD/MM/YYYY")
                                : ""
                            }
                            onChange={value =>
                              this.startDateChangeHandler(
                                moment(value).valueOf()
                              )
                            }
                            locale={currentLanguage || runtimeVars.APP_LANG}
                            acceptWhenOutsideClick
                            autoComplete="off"
                          />
                        </Validation>
                      </FormGroup>
                    </Col>
                    {!isCurrent && (
                      <Col>
                        <FormGroup>
                          <label className="form-label">
                            <Trans>Date de fin</Trans>
                          </label>
                          <Validation
                            errorMessage={endDateValidationMessage}
                            valid={isEndDateValid}
                          >
                            <DatePicker
                              disabledDaysMatcher={day =>
                                this.disableEndDays(day)
                              }
                              dateFormat="dd/MM/yyyy"
                              defaultDate={
                                endDate
                                  ? moment
                                      .unix(endDate / 1000)
                                      .format("DD/MM/YYYY")
                                  : ""
                              }
                              onChange={value =>
                                this.endDateChangeHandler(
                                  moment(value).valueOf()
                                )
                              }
                              locale={currentLanguage || runtimeVars.APP_LANG}
                              acceptWhenOutsideClick
                              autoComplete="off"
                            />
                          </Validation>
                        </FormGroup>
                      </Col>
                    )}
                  </Row>

                  <Row>
                    <Col>
                      <FormGroup>
                        <label className="form-label">
                          <Trans>Déscription de la mission</Trans>
                        </label>
                        <Validation
                          errorMessage={summaryValidationMessage}
                          valid={isSummaryValid}
                        >
                          <textarea
                            onChange={this.summaryChangeHandler}
                            value={summary}
                          />
                        </Validation>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6} xl={6}>
                      <FormGroup>
                        <label className="form-label" htmlFor="jobExpertise">
                          <Trans>Compétence</Trans>
                        </label>
                        <div>
                          <Query query={GET_SKILLS}>
                            {({ loading, error, data }) => {
                              if (loading) return <InputSkeleton />;
                              if (error)
                                return `${i18n._(t`Erreur`)} !${error.message}`;
                              var allSkills = [];
                              data.skills.map(skill => {
                                allSkills.push(skill.name);
                                allSkills = [...new Set(allSkills)];
                              });
                              return (
                                <div className="withSrcollBar">
                                  <AutoComplete
                                    id="skills"
                                    autoComplete="off"
                                    clearOnSelect
                                    options={allSkills}
                                    iconColor="#8d0417"
                                    iconColor2="#d3354a"
                                    icon="bars"
                                    placeholder={i18n._(
                                      t`Sélectionner des compétences`
                                    )}
                                    onSelect={val => this.skillAddHandler(val)}
                                  />
                                </div>
                              );
                            }}
                          </Query>
                        </div>
                        <TagList
                          tags={skillExperience}
                          variant="secondary"
                          closable="true"
                          clickable={false}
                          className="tag-list"
                          onClose={val => this.skillRemoveHandler(val)}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={6} xl={6}>
                      <FormGroup>
                        <label className="form-label" htmlFor="jobSpecialities">
                          <Trans>Environnement technique</Trans>
                        </label>
                        <div>
                          <Query query={GET_SKILLS}>
                            {({ loading, error, data }) => {
                              if (loading) return <InputSkeleton />;
                              if (error)
                                return `${i18n._(t`Erreur`)} !${error.message}`;
                              let specialtiesData = [];
                              data.skills.map(skill => {
                                specialtiesData.push(skill.name);
                                specialtiesData = [...new Set(specialtiesData)];
                              });
                              // const { specialties: specialtiesData } = data;
                              return (
                                <div className="withSrcollBar">
                                  <AutoComplete
                                    id="jobSpecialities"
                                    clearOnSelect
                                    autoComplete="off"
                                    options={specialtiesData.map(item => item)}
                                    iconColor="#FA324A"
                                    iconColor2="#BF142A"
                                    icon="bars"
                                    placeholder={i18n._(
                                      t`Sélectionner des spécialités`
                                    )}
                                    onSelect={val =>
                                      this.specialtyAddHandler(val)
                                    }
                                  />
                                </div>
                              );
                            }}
                          </Query>
                        </div>
                        <TagList
                          tags={specialty}
                          variant="secondary"
                          closable="true"
                          clickable={false}
                          className="tag-list"
                          onClose={val => this.specialtyRemoveHandler(val)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <FormGroup>
                        <label className="form-label">
                          <Trans>Référence</Trans>
                        </label>
                        <Row>
                          <Col>
                            <Validation
                              errorMessage={refNameValidationMessage}
                              valid={isRefNameValid}
                            >
                              <TextInput
                                type="text"
                                spellCheck="false"
                                className="form-input"
                                placeholder={i18n._(t`Prénom et Nom`)}
                                value={refName}
                                onChange={this.refNameChangeHandler}
                              />
                            </Validation>
                          </Col>
                          <Col>
                            <Validation
                              errorMessage={refPhoneValidationMessage}
                              valid={isRefPhoneValid}
                            >
                              <TextInput
                                type="phone"
                                className="form-input"
                                placeholder={i18n._(t`Téléphone`)}
                                value={refPhone}
                                onChange={this.refPhoneChangeHandler}
                              />
                            </Validation>
                          </Col>
                          <Col>
                            <Validation
                              errorMessage={refEmailValidationMessage}
                              valid={isRefEmailValid}
                            >
                              <TextInput
                                type="email"
                                spellCheck="false"
                                className="form-input"
                                placeholder={i18n._(t`Email`)}
                                value={refEmail}
                                onChange={this.refEmailChangeHandler}
                              />
                            </Validation>
                          </Col>
                        </Row>
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
                <div className="experience-buttons">
                  <Button size="small" onClick={this.onCancelHandler}>
                    <Trans>Annuler</Trans>
                  </Button>
                  <Button
                    variant="secondary"
                    size="small"
                    onClick={this.onSaveHandler}
                  >
                    <Trans>Valider</Trans>
                  </Button>
                </div>
              </div>
            ) : (
              <div>
                <Row style={{ marginRight: "-15px", marginLeft: "-15px" }}>
                  <Col lg={5} xl={5} className="info" style={{ border: 0 }}>
                    <div style={{ display: "flex" }}>
                      <Icon
                        className="icon-margin-right"
                        type="flag"
                        size="tiny"
                        width={20}
                        heigth={20}
                        color={theme.colors.mainColor}
                        color2={theme.colors.lightMainColor}
                      />
                      <div
                        style={{
                          color: "#d3354a",
                          marginRight: "2%",
                          fontWeight: "bold",
                          fontSize: "15px"
                        }}
                      >
                        {title}
                      </div>
                      {contractType === "Contract" ? (
                        <div>(Freelance)</div>
                      ) : null}
                      {contractType === "Permanent" ? <div>(CDI)</div> : null}
                    </div>
                    <br />
                    <Row>
                      {!showButtons ? (
                        <>
                          <Col lg={5} xl={5}>
                            <div style={{ display: "flex" }}>
                              <picture className="iconCompany">
                                <img src="/iconCompany.webp" alt="company" />
                                <i />
                              </picture>
                              {companyName}
                            </div>
                          </Col>
                          <Col lg={7} xl={7}>
                            <div style={{ display: "flex" }}>
                              {companyAddress && (
                                <Icon
                                  className="iconlocation"
                                  type="map-marker"
                                  size="tiny"
                                  width={20}
                                  heigth={20}
                                  color={theme.colors.mainColor}
                                  color2={theme.colors.lightMainColor}
                                />
                              )}
                              {companyAddress
                                ? companyAddress.zip
                                  ? companyAddress.city +
                                    " ( " +
                                    companyAddress.zip +
                                    " )"
                                  : companyAddress.city +
                                    " ( " +
                                    companyAddress.countryID +
                                    " )"
                                : null}
                            </div>
                          </Col>
                        </>
                      ) : (
                        <Col lg={7} xl={7}>
                          <div style={{ display: "flex" }}>
                            {companyAddress && (
                              <Icon
                                className="iconlocation"
                                type="map-marker"
                                size="tiny"
                                width={20}
                                heigth={20}
                                color={theme.colors.mainColor}
                                color2={theme.colors.lightMainColor}
                              />
                            )}
                            {companyAddress
                              ? companyAddress.zip
                                ? companyAddress.city +
                                  " ( " +
                                  companyAddress.zip +
                                  " )"
                                : companyAddress.city +
                                  " ( " +
                                  companyAddress.countryID +
                                  " )"
                              : null}
                          </div>
                        </Col>
                      )}
                    </Row>
                    <br />
                    {BusinessSector && (
                      <div>
                        {BusinessSector.length > 0 && (
                          <div>
                            <Icon
                              className="icon-margin-right"
                              type="briefcase"
                              size="tiny"
                              width={20}
                              heigth={20}
                              color={theme.colors.mainColor}
                              color2={theme.colors.lightMainColor}
                            />
                            {BusinessSector.map(secteur => `${secteur}   `)}
                          </div>
                        )}
                      </div>
                    )}
                    <br />
                    {(startDate || endDate) && (
                      <div>
                        <Icon
                          className="icon-margin-right"
                          type="calendar"
                          size="tiny"
                          width={20}
                          heigth={20}
                          color={theme.colors.mainColor}
                          color2={theme.colors.lightMainColor}
                        />
                        {startDate
                          ? moment.unix(startDate / 1000).format("DD/MM/YYYY")
                          : ""}{" "}
                        -{" "}
                        {endDate
                          ? moment.unix(endDate / 1000).format("DD/MM/YYYY")
                          : i18n._(t`Aujourd’hui`)}
                      </div>
                    )}
                    <br />
                    {!showButtons ? (
                      <>
                        {refName || refPhone || refEmail ? (
                          <Row
                            style={{
                              display: "inline-block",
                              marginRight: "-15px",
                              marginLeft: "1px",
                              marginTop: "10px"
                            }}
                          >
                            <div
                              style={{ color: "#696969", marginBottom: "10px" }}
                            >
                              <Trans>Contact de références</Trans>:
                            </div>

                            {refName && (
                              <div
                                style={{ display: "flex", marginBottom: "5px" }}
                              >
                                <div className="reference">
                                  <Trans>Nom et prénom</Trans>:
                                </div>{" "}
                                {refName}
                              </div>
                            )}

                            {refPhone && (
                              <span
                                style={{ display: "flex", marginBottom: "5px" }}
                              >
                                <span className="reference">
                                  <Trans>Téléphone</Trans>:
                                </span>{" "}
                                {refPhone}
                              </span>
                            )}

                            {refEmail && (
                              <span
                                style={{ display: "flex", marginBottom: "5px" }}
                              >
                                <span className="reference">
                                  <Trans>Email</Trans>:
                                </span>{" "}
                                {refEmail}
                              </span>
                            )}
                          </Row>
                        ) : (
                          <Row
                            style={{
                              marginRight: "-15px",
                              marginLeft: "1px",
                              marginTop: "10px"
                            }}
                          >
                            <span
                              style={{ marginRight: "5px", color: "#696969" }}
                            >
                              <Trans>Contact de références</Trans>:
                            </span>

                            <span>
                              <Trans>Aucun contact indiqué</Trans>
                            </span>
                          </Row>
                        )}
                      </>
                    ) : null}
                  </Col>
                  <Divider
                    orientation={isMobile ? "horizontal" : "vertical"}
                    color="red"
                    type="dashed"
                  />
                  <Col lg={6} xl={6}>
                    <div className="summary" style={{ border: 0 }}>
                      {(
                        <text
                          style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            WebkitLineClamp: "2",
                            WebkitBoxOrient: "vertical"
                          }}
                        >
                          {summary}
                        </text>
                      ) || (
                        <div>
                          <Trans>
                            Veuillez ajouter une déscription de la mission
                          </Trans>
                        </div>
                      )}
                    </div>
                    <br />
                    <Row style={{ padding: "10px" }}>
                      <Col lg={6} xl={6}>
                        {skillExperience && (
                          <div>
                            <span className="reference">
                              <Trans>Compétences</Trans>
                            </span>
                            <div>
                              {skillExperience.length > 0 ? (
                                skillExperience.map(Skill => {
                                  return (
                                    <Tag
                                      text={Skill}
                                      className="scraping-tag-list"
                                      closable={false}
                                      clickable={false}
                                    />
                                  );
                                })
                              ) : (
                                <span
                                  style={{
                                    color: "rgb(82 81 81)",
                                    marginLeft: "2%",
                                    marginTop: "2%",
                                    fontSize: "small"
                                  }}
                                >
                                  <Trans>Ajouter vos Compétences </Trans> <br />
                                  <Trans>Obtenues sur cette mission </Trans>
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </Col>
                      <Col lg={6} xl={6}>
                        {specialty && (
                          <div>
                            <span className="reference">
                              <Trans>Environnement technique</Trans>
                            </span>
                            <div>
                              {specialty.length > 0 ? (
                                specialty.map(spe => {
                                  return (
                                    <Tag
                                      style={{ color: "white" }}
                                      text={spe}
                                      className="scraping-tag-list2"
                                      closable={false}
                                      clickable={false}
                                    />
                                  );
                                })
                              ) : (
                                <span
                                  style={{
                                    color: "rgb(82 81 81)",
                                    marginLeft: "2%",
                                    marginTop: "2%",
                                    fontSize: "small"
                                  }}
                                >
                                  <Trans>Ajouter les environnements </Trans>{" "}
                                  <br />
                                  <Trans>
                                    techniques utilisés sur cette mission{" "}
                                  </Trans>
                                </span>
                              )}
                            </div>{" "}
                          </div>
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <br />

                {this.displayButtons()}
              </div>
            )}
          </Col>
        </Row>
      </FormCard>
    );
  }
}

SingleExperience.wrappedComponent.propTypes = {
  sessionStore: PropTypes.shape({
    _id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    changeTitle: PropTypes.func,
    changeLink: PropTypes.func,
    isCandidateWorkHistoryValid: PropTypes.bool,
    changeCandidateWorkHistory: PropTypes.func,
    changeStep: PropTypes.func,
    changeExperience: PropTypes.func,
    changeAddressSouhaite: PropTypes.func,
    addSpokenLanguage: PropTypes.func,
    removeSpokenLanguage: PropTypes.func,
    addCategory: PropTypes.func,
    removeCategory: PropTypes.func,
    addSkill: PropTypes.func,
    removeSkill: PropTypes.func,
    addSpecialties: PropTypes.func,
    removeSpecialties: PropTypes.func,
    candidateWorkHistory: PropTypes.arrayOf(PropTypes.number).isRequired,
    spokenLanguages: PropTypes.shape({}),
    categories: PropTypes.shape({}),
    skills: PropTypes.shape({}),
    title: PropTypes.string,
    link: PropTypes.string,
    specialties: PropTypes.shape({}),
    experience: PropTypes.shape({}),
    resetFields: PropTypes.func
  }).isRequired,
  appStore: PropTypes.shape({
    currentLanguage: PropTypes.string,
    width: PropTypes.number
  }).isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  showButtons: PropTypes.string,
  onValidate: PropTypes.func.isRequired
};

export default withI18n()(SingleExperience);
