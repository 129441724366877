import React, { Component } from "react";
import { withApollo } from "react-apollo";
import { withI18n } from "@lingui/react";
import { withRouter } from "react-router-dom";
import * as PropTypes from "prop-types";
import { Row, Icon, Button, PopUp } from "cf-neo-ui";
import { t, Trans } from "@lingui/macro";
import "../styles.scss";
import { inject, observer } from "mobx-react";

@inject("newTimesheetStore")
@observer
class DeleteThemePopUp extends Component {
  constructor(props) {
    super(props);
  }

  deleteTheme = () => {
    const { newTimesheetStore } = this.props;
    const { openClosePopUps, deleteTheme, themeToDelete } = newTimesheetStore;
    deleteTheme(themeToDelete);
    openClosePopUps("deleteTheme");
  };

  return = () => {
    const { newTimesheetStore } = this.props;
    const { openClosePopUps } = newTimesheetStore;
    openClosePopUps("deleteTheme");
  };

  render() {
    const { device } = this.props;
    return (
      <PopUp mobile={device === "mobile"} withCloseButton={false}>
        <div
          style={{
            position: "relative",
            height: "160px",
            width: "100%"
          }}
        >
          <div style={{ borderBottom: "1px solid" }}>
            <Row
              style={{
                marginBottom: "10px"
              }}
            >
              <span style={{ margin: "0 15px" }}>
                <Icon
                  type="Bell"
                  color="#8D0417"
                  color2="#D3354A"
                  width={14}
                  height={14}
                />
              </span>
              <span>
                <div
                  style={{
                    fontFamily: "Montserrat",
                    fontStyle: "normal",
                    fontWeight: 600,
                    fontSize: "16px",
                    color: "#242424"
                  }}
                >
                  <Trans>Supprimer le thème</Trans>
                </div>
              </span>
            </Row>
          </div>

          <div
            style={{
              padding: "20px 40px 20px 40px",
              width: "300px",
              textAlign: "center",
              margin: "0 auto",
              fontFamily: "Montserrat",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "14px",
              color: "#242424"
            }}
          >
            <Trans>Êtes-vous sûr de vouloir supprimer le thème ainsi que les plages de
            journées associées ?  </Trans>
          </div>

          <div style={{ padding: "0 30px" }}>
            <div
              style={{
                float: "right"
              }}
            >
              <Button
                variant="primary"
                size="small"
                onClick={() => this.deleteTheme()}
              >
                <Trans>Confirmer</Trans>
              </Button>
            </div>
            <div
              style={{
                float: "left"
              }}
            >
              <Button
                variant="secondary"
                size="small"
                onClick={() => this.return()}
              >
                <Trans>Retour</Trans>
              </Button>
            </div>
          </div>
        </div>
      </PopUp>
    );
  }
}

DeleteThemePopUp.wrappedComponent.propTypes = {
  newTimesheetStore: PropTypes.shape({
    openClosePopUps: PropTypes.func
  }).isRequired,
  device: PropTypes.string
};
export default withApollo(withI18n()(withRouter(DeleteThemePopUp)));
