import React, { Component } from "react";
import { withApollo } from "react-apollo";
import { withI18n } from "@lingui/react";
import { withRouter } from "react-router-dom";
import * as PropTypes from "prop-types";
import { TextInputV2, Validation, FormGroup, Button, PopUp } from "cf-neo-ui";
import { t, Trans } from "@lingui/macro";
import "../styles.scss";
import { inject, observer } from "mobx-react";

@inject("newTimesheetStore")
@observer
class AddThemePopUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      themeName: "",
      isValidthemeName: true,
      isValidthemeNameMessage: ""
    };
  }

  addNewTheme = () => {
    const { themeName } = this.state;
    const { newTimesheetStore, i18n } = this.props;
    const {
      addTheme,
      openClosePopUps,
      changeThemeToPreselect,
      themeToPreselect,
    } = newTimesheetStore;
    if (!themeName || themeName.trim().length === 0)
      this.setState({
        isValidthemeName: false,
        isValidthemeNameMessage: i18n._(t`Ce champs est requis`)
      });
    else {
      const res = addTheme({
        name: themeName
      });
      if (res === "success") {
        openClosePopUps("addTheme");
        if (themeToPreselect) changeThemeToPreselect(themeName);
        this.setState({ themeName: "" });
      } else
        this.setState({
          isValidthemeName: false,
          isValidthemeNameMessage: i18n._(t`Ce nom de thème existe déjà !`)
        });
    }
  };

  render() {
    const { themeName, isValidthemeName, isValidthemeNameMessage } = this.state;
    const { newTimesheetStore, device, i18n } = this.props;
    const { openClosePopUps } = newTimesheetStore;
    return (
      <PopUp
        mobile={device === "mobile"}
        onClose={() => openClosePopUps("addTheme")}
      >
        <div
          style={{
            position: "relative",
            height: isValidthemeName ? "160px" : "170px",
            width: device === "mobile" ? "100%" : "380px"
          }}
        >
          <div
            style={{
              fontFamily: "Montserrat",
              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "16px",
              color: "#242424",
              marginBottom: "26px"
            }}
          >
            <Trans>Ajouter un nouveau thème</Trans>
          </div>
          <FormGroup>
            <label
              style={{
                fontFamily: "Montserrat",
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: "14px",
                color: "#242424"
              }}
            >
              <Trans>Nom du thème</Trans>
            </label>
            <Validation
              errorMessage={isValidthemeNameMessage}
              valid={isValidthemeName}
            >
              <TextInputV2
                id="newTheme"
                placeholder={i18n._(t`Ajoutez le nom du nouveau thème`)}
                value={themeName}
                onChange={e =>
                  this.setState({
                    themeName: e.target.value,
                    isValidthemeName: true,
                    isValidthemeNameMessage: ""
                  })
                }
                style={{ paddingLeft: "10px", marginTop: "10px" }}
              />
            </Validation>
          </FormGroup>
          <div
            style={{
              float: "right",
              padding: "20px 0"
            }}
          >
            <Button
              variant="primary"
              size="small"
              onClick={() => this.addNewTheme()}
            >
              <Trans>Créer</Trans>
            </Button>
          </div>
        </div>
      </PopUp>
    );
  }
}

AddThemePopUp.wrappedComponent.propTypes = {
  newTimesheetStore: PropTypes.shape({
    openClosePopUps: PropTypes.func,
    addTheme: PropTypes.func
  }).isRequired,
  device: PropTypes.string
};
export default withApollo(withI18n()(withRouter(AddThemePopUp)));
