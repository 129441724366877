import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withApollo } from "react-apollo";
import { Button, Header } from "cf-neo-ui";
import ResponsiveHeader from "cf-neo-ui/lib/header/src/ResponsiveHeader";
import { inject, observer } from "mobx-react";
import { withI18n } from "@lingui/react";
import { t, Trans } from "@lingui/macro";
import Routes from "../routes";
import classes from "./styles.module.scss";
import { LOGOUT_MUTATION } from "./mutation";
import runtimeVars from "../../../configs/runTimeVars";

const { PARTENAIRES_URL } = runtimeVars;

@inject("sessionStore", "appStore", "postANeedStore", "candidatesSearchStore")
@observer
class CFHeader extends Component {
  logoutClickHandler = () => {
    const {
      sessionStore,
      history,
      postANeedStore,
      client,
      candidatesSearchStore,
      apolloClient
    } = this.props;
    const { Home } = Routes.HbfLayoutRoutes;
    postANeedStore.reset();
    apolloClient.mutate({
      mutation: LOGOUT_MUTATION,
      variables: { token: sessionStore.authToken }
    });
    sessionStore.logout();
    candidatesSearchStore.clearSearch();
    localStorage.removeItem("Experiences");
    localStorage.removeItem("Certification");
    localStorage.removeItem("PATH");
    client.cache.reset();
    history.push(Home.path);
  };

  handleSelectedLanguage = async language => {
    const { appStore } = this.props;
    const { changeLanguage } = appStore;
    changeLanguage(language);
  };

  getHeaderProps = currentLanguage => {
    const { i18n, history } = this.props;
    const {
      Home,
      consultant,
      MissionsPage,
      client,
      about,
      SearchCandidatesPage
    } = Routes.HbfLayoutRoutes;
    const { Blog } = Routes.BlogLayoutRoutes;

    // check currentLanguage, if currentLanguage === "fr" add partners to menuItemChild
    let menuItemChild = [
      {
        key: "../consultant",
        name: i18n._(t`Pourquoi nous rejoindre ? `),
        clickable: true,
        onClick: () => history.push(consultant.path)
      }
    ];

    if (currentLanguage === "fr")
      menuItemChild.push({
        key: "../partenaire",
        name: i18n._(t`Nos partenaires`),
        clickable: true,
        onClick: () => window.open(PARTENAIRES_URL, "_blank")
      });

    menuItemChild.push({
      key: "../missions",
      name: i18n._(t`Toutes nos missions`),
      clickable: true,
      onClick: () => history.push(MissionsPage.path)
    });

    const menuItems = [
      {
        key: "consultant",
        name: i18n._(t`Je suis un freelance`),
        clickable: false,
        child: menuItemChild
      },
      {
        key: "client",
        name: i18n._(t`Je suis une entreprise`),
        clickable: false,
        child: [
          {
            key: "../entreprise",
            name: i18n._(t`Notre accompagnement`),
            clickable: true,
            onClick: () => history.push(client.path)
          },
          {
            key: "../candidates",
            name: i18n._(t`Trouver un talent`),
            clickable: true,
            onClick: () => history.push(SearchCandidatesPage.path)
          }
        ]
      },
      {
        key: "about",
        name: i18n._(t`À propos de Mindquest`),
        clickable: true,
        onClick: () => history.push(about.path)
      },
      {
        key: "blog",
        name: i18n._(t`Blog`),
        clickable: true,
        onClick: () => history.push(Blog.path)
        // onClick: () => window.open(configs.uk_blog)
      }
    ];

    if (currentLanguage === "fr")
      menuItems.push({
        key: "finance",
        name: i18n._(t`Offre Finance`),
        clickable: true,
        onClick: () =>
          window.open("https://visite.mindquest.io/finance-mindquest", "_blank")
      });

    return {
      logoSrc: "/assets/images/logo_mind_quest.webp",
      onClickLogo: () => history.push(Home.path),
      logoAlt: "mindquest logo",
      className: `layout-header`,
      style: { "max-width": "none", paddingLeft: "10%" },
      menuItems
    };
  };

  renderHeader = () => {
    const { history, sessionStore, appStore } = this.props;
    const { HbLayoutRoutes, LpbLayoutRoutes } = Routes;
    const { SignUp, Login } = HbLayoutRoutes;
    const { currentLanguage } = appStore;
    let user;
    if (sessionStore.account) {
      user = sessionStore.account.role;
    } else {
      user = "NOT CONNECTED";
    }

    return (
      <Header
        {...this.getHeaderProps(currentLanguage)}
        style={{ maxWidth: "none", paddingLeft: "10%", height: "90px" }}
      >
        <div>
          {sessionStore.authToken && user === "CANDIDATE" && (
            <div className={classes.headerRightBtns}>
              <div style={{ display: "inline-block", margin: "0 20px" }}>
                <span
                  href="#"
                  onClick={() => this.handleSelectedLanguage("fr")}
                  className={`language ${
                    currentLanguage === "fr" ? "selected" : ""
                  }`}
                >
                  FR
                </span>
                /
                <span
                  href="#"
                  onClick={() => this.handleSelectedLanguage("en")}
                  className={`language ${
                    currentLanguage === "en" ? "selected" : ""
                  }`}
                >
                  EN
                </span>
              </div>
              <Button
                size="small"
                variant="primary"
                className="dash-btn-mq"
                onClick={() => history.push(LpbLayoutRoutes.Dashboard.path)}
              >
                <Trans>Dashboard</Trans>
              </Button>
              <Button
                onClick={this.logoutClickHandler}
                size="small"
                variant="tertiary"
                className="logout-btn-mq"
              >
                <Trans>Se déconnecter</Trans>
              </Button>
            </div>
          )}
          {sessionStore.authToken && user === "CLIENT" && (
            <div className={classes.headerRightBtns}>
              <div style={{ display: "inline-block", margin: "0 20px" }}>
                <span
                  href="#"
                  onClick={() => this.handleSelectedLanguage("fr")}
                  className={`language ${
                    currentLanguage === "fr" ? "selected" : ""
                  }`}
                >
                  FR
                </span>
                /
                <span
                  href="#"
                  onClick={() => this.handleSelectedLanguage("en")}
                  className={`language ${
                    currentLanguage === "en" ? "selected" : ""
                  }`}
                >
                  EN
                </span>
              </div>
              <Button
                size="small"
                variant="primary"
                className="dash-btn-mq"
                onClick={() =>
                  history.push(LpbLayoutRoutes.DashboardCompany.path)
                }
              >
                <Trans>Dashboard</Trans>
              </Button>
              <Button
                onClick={this.logoutClickHandler}
                size="small"
                variant="tertiary"
                className="logout-btn-mq"
              >
                <Trans>Se déconnecter</Trans>
              </Button>
            </div>
          )}

          {!sessionStore.authToken && (
            <div className={classes.headerRightBtns}>
              <div style={{ display: "inline-block", margin: "0 20px" }}>
                <span
                  href="#"
                  onClick={() => this.handleSelectedLanguage("fr")}
                  className={`language ${
                    currentLanguage === "fr" ? "selected" : ""
                  }`}
                >
                  FR
                </span>
                /
                <span
                  href="#"
                  onClick={() => this.handleSelectedLanguage("en")}
                  className={`language ${
                    currentLanguage === "en" ? "selected" : ""
                  }`}
                >
                  EN
                </span>
              </div>
              <Button
                size="small"
                variant="tertiary"
                className="login-btn-mq"
                onClick={() => history.push(Login.path)}
              >
                <Trans>Se connecter</Trans>
              </Button>
              <Button
                onClick={() => history.push(`${SignUp.path}?type=consultant`)}
                size="small"
                className="join-btn-mq"
                variant="primary"
                id="cta_header_join"
              >
                <Trans>Rejoindre le club</Trans>
              </Button>
            </div>
          )}
        </div>
      </Header>
    );
  };

  renderResponsiveHeader = () => {
    const { history, sessionStore, appStore } = this.props;
    const { currentLanguage } = appStore;
    const { HbLayoutRoutes, LpbLayoutRoutes } = Routes;
    const { SignUp, Login } = HbLayoutRoutes;
    let user;
    if (sessionStore.account) {
      user = sessionStore.account.role;
    } else {
      user = "NOT CONNECTED";
    }

    return (
      <div className={classes.responsiveHeaderWrapper}>
        <ResponsiveHeader {...this.getHeaderProps(currentLanguage)}>
          <div className={classes.responsiveHeaderRightBtns}>
            {sessionStore.authToken && user === "CANDIDATE" && (
              <div>
                <div style={{ display: "block", margin: "20px" }}>
                  <span
                    href="#"
                    onClick={() => this.handleSelectedLanguage("fr")}
                    className={`language ${
                      currentLanguage === "fr" ? "selected" : ""
                    }`}
                  >
                    FR
                  </span>
                  /
                  <span
                    href="#"
                    onClick={() => this.handleSelectedLanguage("en")}
                    className={`language ${
                      currentLanguage === "en" ? "selected" : ""
                    }`}
                  >
                    EN
                  </span>
                </div>
                <Button
                  variant="tertiary"
                  className="dash-btn-mq"
                  onClick={() => history.push(LpbLayoutRoutes.Dashboard.path)}
                >
                  <Trans>Dashboard</Trans>
                </Button>
                <Button
                  onClick={this.logoutClickHandler}
                  variant="tertiary"
                  className="logout-btn-mq"
                >
                  <Trans>Se déconnecter</Trans>
                </Button>
              </div>
            )}

            {sessionStore.authToken && user === "CLIENT" && (
              <div>
                <div style={{ display: "block", margin: "20px" }}>
                  <span
                    href="#"
                    onClick={() => this.handleSelectedLanguage("fr")}
                    className={`language ${
                      currentLanguage === "fr" ? "selected" : ""
                    }`}
                  >
                    FR
                  </span>
                  /
                  <span
                    href="#"
                    onClick={() => this.handleSelectedLanguage("en")}
                    className={`language ${
                      currentLanguage === "en" ? "selected" : ""
                    }`}
                  >
                    EN
                  </span>
                </div>
                <Button
                  variant="primary"
                  className="dash-btn-mq"
                  onClick={() =>
                    history.push(LpbLayoutRoutes.DashboardCompany.path)
                  }
                >
                  <Trans>Dashboard</Trans>
                </Button>
                <Button
                  variant="tertiary"
                  className="logout-btn-mq"
                  onClick={this.logoutClickHandler}
                >
                  <Trans>Se déconnecter</Trans>
                </Button>
              </div>
            )}

            {!sessionStore.authToken && (
              <div>
                <div style={{ display: "block", margin: "20px" }}>
                  <span
                    href="#"
                    onClick={() => this.handleSelectedLanguage("fr")}
                    className={`language ${
                      currentLanguage === "fr" ? "selected" : ""
                    }`}
                  >
                    FR
                  </span>
                  /
                  <span
                    href="#"
                    onClick={() => this.handleSelectedLanguage("en")}
                    className={`language ${
                      currentLanguage === "en" ? "selected" : ""
                    }`}
                  >
                    EN
                  </span>
                </div>
                <Button
                  variant="tertiary"
                  className="login-btn-mq"
                  onClick={() => history.push(Login.path)}
                >
                  <Trans>Se connecter</Trans>
                </Button>
                <Button
                  variant="primary"
                  className="join-btn-mq"
                  onClick={() => history.push(`${SignUp.path}?type=consultant`)}
                >
                  <Trans>Rejoindre le club</Trans>
                </Button>{" "}
              </div>
            )}
          </div>
        </ResponsiveHeader>
      </div>
    );
  };

  render() {
    const { appStore } = this.props;
    return appStore.width < 1024
      ? this.renderResponsiveHeader()
      : this.renderHeader();
  }
}

CFHeader.wrappedComponent.propTypes = {
  candidatesSearchStore: PropTypes.shape({
    clearSearch: PropTypes.func
  }).isRequired,
  sessionStore: PropTypes.shape({
    logout: PropTypes.func,
    account: PropTypes.shape({
      role: PropTypes.string,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    }),
    authToken: PropTypes.string
  }).isRequired,
  postANeedStore: PropTypes.shape({
    reset: PropTypes.func
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  appStore: PropTypes.shape({
    changeWidth: PropTypes.func,
    width: PropTypes.number,
    changeLanguage: PropTypes.func,
    currentLanguage: PropTypes.string
  }).isRequired,
  apolloClient: PropTypes.shape({
    mutate: PropTypes.func
  }).isRequired,
  client: PropTypes.shape({
    cache: PropTypes.shape({
      reset: PropTypes.func
    })
  }).isRequired
};

export default withApollo(withI18n()(withRouter(CFHeader)));
