import * as PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import React, { Component } from "react";
import { ApolloConsumer, Mutation, withApollo } from "react-apollo";
import {
  Button,
  Col,
  Divider,
  FormCard,
  FormGroup,
  Row,
  Icon,
  Spinner,
  TextInput,
  toaster,
  Validation
} from "cf-neo-ui";
import { withRouter } from "react-router-dom";
import { t, Trans } from "@lingui/macro";
import { withI18n } from "@lingui/react";
import classes from "./PublicProfileModal.scss";
import theme from "../../configs/theme";
import { UPDATE_CANDIDATE_MUTATION } from "../../containers/layout/lpbLayout/sider/queries";
import { GET_USER_INFO } from "../hoc/queries";
import { LOGOUT_MUTATION } from "../../containers/layout/hbfLayout/mutation";

@inject("sessionStore", "appStore", "modalStore", "candidatesSearchStore")
@observer
class PublicProfileModal extends Component {
  constructor(props) {
    super(props);
    const { sessionStore } = this.props;
    this.state = {
      publicProfil: sessionStore.publicProfil
    };
  }

  componentDidMount() {
    window.scroll(0, 0);
  }

  publicProfileEnableHandler = () => {
    const { sessionStore } = this.props;
    sessionStore.changePublicProfil(true);
    this.setState({ publicProfil: true });
  };

  mutationCompleted = () => {
    const { i18n, close } = this.props;
    close();
    toaster.success({
      title: i18n._(t`Profil Pseudonymisé`),
      description: i18n._(t`Votre profil est maintenant public`)
    });
  };

  onErrorHandler = msg => {
    const { i18n } = this.props;
    toaster.error({
      title: i18n._(t`Erreur`),
      description: msg
    });
  };
  closeModal = () => {
    const { i18n, close } = this.props;
    close();
  };
  render() {
    const { close, sessionStore, candidatesSearchStore } = this.props;
    const { _id, authToken, logout } = sessionStore;
    const { clearSearch } = candidatesSearchStore;
    return (
      <div className="single-candidate-page-modal-session single-candidate-page-modal-container single-candidate-page-modal-signup-selection">
        <div
          className={classes.headerProfilModal}
          style={{ borderRadius: "0px", borderBottom: "1px solid #dadee3" }}
        >
          <div className="modalheader">
            <div className="headerIcon">
              <Icon
                size="small"
                type="user"
                color={theme.colors.mainColor}
                color2={theme.colors.lightMainColor}
              />
            </div>
            <div className="modal-title" style={{ marginLeft: "45px" }}>
              {" "}
              <Trans>
                Activer mon profil pseudonymisé pour les décideurs IT
              </Trans>{" "}
            </div>
            <a className="closeIcon">
              <Icon
                onClick={this.closeModal}
                size="tiny"
                type="close"
                color={theme.colors.mainColor}
                color2={theme.colors.lightMainColor}
              />
            </a>
          </div>
        </div>
        <FormCard style={{ borderRadius: "0px" }}>
          <Row>
            <Col
              className="single-candidate-page-modal-form-card-content single-candidate-page-modal-center"
              style={{ textAlign: "center", marginTop: "35px" }}
            >
              <Trans>
                Votre Profil pseudonymisé sera accecible aux décideurs IT vous
                sélectionnant
                <br />
                au sein de leurs viviers talents. Ils pourront visualiser vos
                expériences et les
                <br />
                autres contenus professionnels que vous avec ajoutés sur votre
                profil.
              </Trans>
              <br />
              <br />
              <>
                <div className="red-text">
                  <Trans>
                    Sur un profil pseudonymisé, le prénom et votre photo sont
                    les uniques éléments visibles
                    <br />
                    sur votre identité
                  </Trans>
                </div>
              </>
            </Col>
          </Row>
          <Row>
            <Col className="single-candidate-page-modal-form-card-content single-candidate-page-modal-center">
              <div
                className="single-candidate-page-modal-center"
                style={{ marginTop: "-25px" }}
              >
                <ApolloConsumer>
                  {apolloClient => (
                    <Mutation
                      mutation={UPDATE_CANDIDATE_MUTATION}
                      variables={{
                        _id,
                        publicProfil: true
                      }}
                      refetchQueries={[
                        {
                          query: GET_USER_INFO,
                          variables: { token: authToken }
                        }
                      ]}
                      onCompleted={this.mutationCompleted}
                      onError={errors => {
                        errors.graphQLErrors.forEach(({ message }) => {
                          if (message === "UNAUTHENTICATED") {
                            apolloClient.mutate({
                              mutation: LOGOUT_MUTATION,
                              variables: { token: sessionStore.authToken }
                            });
                            logout();
                            clearSearch();
                          }
                          if (message) {
                            this.onErrorHandler(message);
                          }
                        });
                      }}
                    >
                      {(mutation, { loading }) => (
                        <Button
                          onClick={async () => {
                            await this.publicProfileEnableHandler();
                            return mutation();
                          }}
                        >
                          {loading ? (
                            <Spinner
                              type="pointed-circular"
                              color="#FFFFFF"
                              size={12}
                            />
                          ) : (
                            <Trans>J'ai compris</Trans>
                          )}
                        </Button>
                      )}
                    </Mutation>
                  )}
                </ApolloConsumer>
              </div>
            </Col>
          </Row>
        </FormCard>
      </div>
    );
  }
}

PublicProfileModal.wrappedComponent.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  candidateBhId: PropTypes.number,
  appStore: PropTypes.shape({
    width: PropTypes.number
  }).isRequired,
  sessionStore: PropTypes.shape({
    id: PropTypes.number,
    _id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    authToken: PropTypes.string,
    logout: PropTypes.func,
    publicProfil: PropTypes.bool,
    changePublicProfil: PropTypes.func
  }).isRequired,
  candidatesSearchStore: PropTypes.shape({
    clearSearch: PropTypes.func
  }).isRequired,
  close: PropTypes.func,
  modalStore: PropTypes.shape({
    changeProfileActivationPopUpVisibility: PropTypes.func
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string
  }).isRequired,
  apolloClient: PropTypes.shape({
    mutate: PropTypes.func
  }).isRequired
};

export default withI18n()(withRouter(PublicProfileModal));
