import React, { Component } from "react";
import { withApollo } from "react-apollo";
import { withI18n } from "@lingui/react";
import { withRouter } from "react-router-dom";
import * as PropTypes from "prop-types";
import { Button, PopUp, Icon } from "cf-neo-ui";
import { t, Trans } from "@lingui/macro";
import "../styles.scss";
import { inject, observer } from "mobx-react";
import styles from "./styles.module.scss";
import locals from "../../../helpers/locals";

@inject("newTimesheetStore", "appStore", "sessionStore")
@observer
class DayDetailsPopUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: "",
      toBeDeleted: []
    };
  }

  validate = () => {
    const { message } = this.state;
    const { newTimesheetStore } = this.props;
    const {
      openClosePopUps,
      addDescriptionToPeriod,
      rangeDetails
    } = newTimesheetStore;
    addDescriptionToPeriod(rangeDetails, message, rangeDetails.themeName);
    openClosePopUps("dayDetails");
  };

  toHoursAndMinutes = totalMinutes => {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    return `${this.padToTwoDigits(hours)}h${this.padToTwoDigits(minutes)}m`;
  };

  padToTwoDigits = num => num.toString().padStart(2, "0");

  deleteThemePerDay = item => {
    const { toBeDeleted } = this.state;
    const newItems = [...toBeDeleted];
    newItems.push(item);
    this.setState({ toBeDeleted: newItems });
  };

  render() {
    const {
      newTimesheetStore,
      device,
      appStore,
      sessionStore,
      i18n
    } = this.props;
    const {
      openClosePopUps,
      dayDetails,
      timesheet,
      deleteTimeRange,
      warnings,
      editable
    } = newTimesheetStore;
    const { account } = sessionStore;
    const { currentLanguage } = appStore;
    const { toBeDeleted } = this.state;

    const dayDetailsToDisplay = dayDetails.filter(
      item1 =>
        !toBeDeleted.some(
          item2 =>
            item1.theme &&
            item2.themeName === item1.theme.name &&
            item2.day === item1.day
        )
    );
    const warningLabel = {
      MAX_TIME_PER_DAY: i18n._(t`MAX_TIME_PER_DAY`),
      MAX_NORMAL_TIME_PER_DAY: i18n._(t`MAX_NORMAL_TIME_PER_DAY`),
      MAX_SPECIAL_TIME_PER_DAY: i18n._(t`MAX_SPECIAL_TIME_PER_DAY`),
      MAX_THEMES_PER_DAY: i18n._(t`MAX_THEMES_PER_DAY`),
      ABNORMAL_TIME_PER_DAY: i18n._(t`ABNORMAL_TIME_PER_DAY`)
    };
    const warningsToShow =
      dayDetailsToDisplay && dayDetailsToDisplay[0]
        ? warnings.filter(e => e.day == dayDetailsToDisplay[0].day)
        : [];

    let th = "";

    return (
      <PopUp
        mobile={device === "mobile"}
        onClose={() => {
          openClosePopUps("dayDetails");
          this.setState({ toBeDeleted: [] });
        }}
      >
        <div
          style={{
            position: "relative",
            height: "400px",
            width: device === "mobile" ? "100%" : "380px"
          }}
        >
          <div
            style={{
              fontFamily: "Montserrat",
              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "16px",
              color: "#232a3e",
              marginBottom: "15px"
            }}
          >
            {dayDetails &&
              dayDetails.length &&
              ("0" + dayDetails[0].day).slice(-2)}{" "}
            {locals[currentLanguage].months.long[
              timesheet.month - 1
            ].toLowerCase()}{" "}
            {timesheet.year}
          </div>
          <div className="popup-details">
            {warningsToShow && warningsToShow.length > 0 && (
              <div
                style={{
                  fontFamily: "Montserrat",
                  fontStyle: "normal",
                  fontWeight: "bold",
                  fontSize: "12px",
                  marginBottom: "15px"
                }}
              >
                {warningsToShow.map(warning => {
                  return (
                    <p
                      style={{
                        color: warning.critical ? "#a01125" : "#f1ba52"
                      }}
                    >{`${warningLabel[warning.warning]} (${Math.floor(
                      warning.number / 60
                    )}h${warning.number % 60}m)`}</p>
                  );
                })}
              </div>
            )}

            {dayDetailsToDisplay &&
            dayDetailsToDisplay[0] &&
            dayDetailsToDisplay[0].theme ? (
              <>
                <div
                  style={{
                    fontFamily: "Montserrat",
                    fontStyle: "normal",
                    fontWeight: 500,
                    fontSize: "16px",
                    color: "#232a3e",
                    marginBottom: "15px"
                  }}
                >
                  <Trans>Plages d'heures sélectionnées :</Trans>
                </div>
                <table style={{ width: "100%" }}>
                  {dayDetailsToDisplay &&
                    dayDetailsToDisplay.length &&
                    dayDetailsToDisplay.map(ele => {
                      return (
                        <>
                          <tr
                            style={{
                              fontFamily: "Montserrat",
                              fontStyle: "normal",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "#232a3e",
                              height: "30px"
                            }}
                          >
                            <td>
                              <span style={{ marginRight: "10px" }}>
                                <Icon
                                  type="menu-burger"
                                  width={14}
                                  color={ele.theme.color}
                                  color2={ele.theme.color}
                                  filled
                                />
                              </span>
                              <span
                                title={ele.theme.name}
                                style={{
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  display: "inline-block",
                                  width: "220px",
                                  whiteSpace: "nowrap"
                                }}
                              >
                                {ele.theme.name}
                              </span>
                            </td>
                            <td>
                              {this.toHoursAndMinutes(
                                ele.hours * 60 + ele.minutes
                              )}
                            </td>
                            {account.role === "CANDIDATE" &&
                            editable("CANDIDATE") ? (
                              <td>
                                <span
                                  onClick={() =>
                                    this.deleteThemePerDay({
                                      themeName: ele.theme.name,
                                      day: ele.day
                                    })
                                  }
                                  style={{
                                    float: "right",
                                    marginLeft: "10px",
                                    cursor: "pointer"
                                  }}
                                >
                                  <Icon
                                    type="delete"
                                    width={16}
                                    color="#8d0417"
                                    color2="#d3354a"
                                    filled
                                  />
                                </span>
                                {/* // todo  */}
                                {/*  <span style={{float: "right"}}>*/}
                                {/*  <Icon*/}
                                {/*    type="edit"*/}
                                {/*    width={16}*/}
                                {/*    color="#8d0417"*/}
                                {/*    color2="#d3354a"*/}
                                {/*    filled*/}
                                {/*  />*/}
                                {/*</span>*/}
                              </td>
                            ) : null}
                          </tr>
                        </>
                      );
                    })}
                </table>
              </>
            ) : (
              <div
                style={{
                  fontFamily: "Montserrat",
                  fontStyle: "normal",
                  fontWeight: 500,
                  fontSize: "16px",
                  color: "#232a3e",
                  marginBottom: "15px"
                }}
              >
                <Trans>Il n’y a pas de temps enregistré ce jour.</Trans>
              </div>
            )}
          </div>
          {account.role === "CANDIDATE" && (
            <div style={{ position: "absolute", bottom: 0, width: "100%" }}>
              {device === "mobile" && (
                <div
                  style={{
                    float: "left",
                    padding: "20px 20px 20px 0"
                  }}
                >
                  <span
                    style={{
                      paddingTop: "20px",
                      fontFamily: "Montserrat",
                      fontSize: "14px",
                      textDecoration: "underline",
                      cursor: "pointer",
                      fontWeight: "normal",
                      color: "#8f8f8f",
                      marginTop: "20px"
                    }}
                    onClick={() => {
                      openClosePopUps("dayDetails");
                      openClosePopUps("addTimeManually");
                    }}
                  >
                    <Trans>Ajouter une nouvelle plage</Trans>
                  </span>
                </div>
              )}
              <div
                style={{
                  float: "right",
                  padding: "20px 0"
                }}
              >
                <Button
                  variant="primary"
                  size="small"
                  onClick={() => {
                    deleteTimeRange(toBeDeleted);
                    openClosePopUps("dayDetails");
                  }}
                >
                  <Trans>confirmer</Trans>
                </Button>
              </div>
            </div>
          )}
        </div>
      </PopUp>
    );
  }
}

DayDetailsPopUp.wrappedComponent.propTypes = {
  newTimesheetStore: PropTypes.shape({
    openClosePopUps: PropTypes.func,
    deleteTimeRange: PropTypes.func,
    rangeDetails: PropTypes.shape({}),
    warnings: PropTypes.shape({}),
    timesheet: PropTypes.shape({}),
    dayDetails: PropTypes.shape({})
  }).isRequired,
  sessionStore: PropTypes.shape({
    account: PropTypes.shape({
      role: PropTypes.string
    })
  }).isRequired,
  device: PropTypes.string,
  appStore: PropTypes.shape({
    currentLanguage: PropTypes.string
  }).isRequired
};
export default withApollo(withI18n()(withRouter(DayDetailsPopUp)));
