import React, { Component } from "react";
import {
  Row,
  Col,
  ContentInsertionBlock,
  SkillsSelector,
  H5,
  H2,
  P,
  FormCard,
  SmatchSpinner,
  Button,
  toaster,
  Pagination
} from "cf-neo-ui";
import { withI18n } from "@lingui/react";
import classes from "./SearchCandidatesBody.module.scss";
import SideFilters from "./side-filters/SideFilters";
import Reassurance from "./reassurance/Reassurance";
import LeftSide from "./left-side/leftSide";
import { Query, withApollo } from "react-apollo";
import {
  GET_KEY_WORDS,
  ADD_NEW_KEY_WORDS,
  GET_SKILLS,
  GET_S_CANDIDATES
} from "../queries";
import { t, Trans } from "@lingui/macro";
import { inject, observer } from "mobx-react";
import * as PropTypes from "prop-types";
import LoadingMissions from "../../../components/LoadingMissions/LoadingMissions";
import CandidatesListForUsers from "./candidates-list/CandidatesListForUsers";
import SearchBar from "./search-bar/SearchBar";

const isOnePage = (pageSize, total) => {
  const pagesNbr = Math.floor((total - 1) / pageSize) + 1;
  return pagesNbr > 1;
};

@inject("candidatesSearchStore", "sessionStore")
@observer
class SearchCandidatesBody extends Component {
  constructor(props) {
    super(props);
    this.state = {
      test: null,
      description: null
    };
    this.getKeyWords = this.getKeyWords.bind(this);
  }

  getQueryVariables = () => {
    const { candidatesSearchStore, sessionStore } = this.props;
    const { description } = this.state;
    const { account, client, candidate } = sessionStore;
    const user = {
      id: null,
      role: null,
      email: null
    };
    if (account) {
      user.email = account.role === "CLIENT" ? client.email : candidate.email;
      user.id =
        account.role === "CLIENT"
          ? parseInt(client.id)
          : parseInt(candidate.id);
      user.role = account.role === "CLIENT" ? "CLIENT" : "CANDIDATE";
    }
    const {
      sortByDistance,
      sortByScore,
      skip,
      limit,
      fSkills,
      fEmploymentPreference,
      fLocations,
      fRadius,
      fExperience,
      primarySkills,
      secondarySkills,
      zipCode,
      publicProfile
    } = candidatesSearchStore;
    const temp = [];
    fLocations.map(location => {
      const theOb = {
        address1: location.address,
        city: location.city,
        countryID: location.country,
        lat: location.lat,
        lng: location.lng,
        state: location.state,
        zip: location.zip
      };
      temp.push(theOb);
      return null;
    });

    const filter = {
      skills: fSkills.length ? fSkills.slice() : null,
      employmentPreference: fEmploymentPreference,
      distance: fRadius ? parseInt(fRadius, 10) : null,
      experience: fExperience ? parseInt(fExperience, 10) : null,
      publicProfile: publicProfile ? publicProfile : null
    };

    return {
      primarySkills,
      secondarySkills,
      zipCode,
      limit,
      skip,
      sortByDistance,
      sortByScore,
      filter,
      user,
      description
    };
  };

  getKeyWords = async desc => {
    const { client, candidatesSearchStore } = this.props;
    const {
      changeJobDescription,
      changeWords,
      changeDisabled,
      changeSkillsSelectorData
    } = candidatesSearchStore;
    changeDisabled(true);
    changeJobDescription(desc);
    this.setState({ description: desc });
    try {
      const { data } = await client.query({
        query: GET_KEY_WORDS,
        variables: {
          description: desc
        },
        fetchPolicy: "network-only"
      });
      if (data) {
        const { keyWords } = data;
        const wordList = [];
        keyWords.map(w => {
          if (![].some(word => word.text === w)) wordList.push({ text: w });
        });
        changeSkillsSelectorData(null);
        changeWords(wordList.concat([]));
        changeDisabled(false);
        this.forceUpdate();
      }
    } catch (error) {}
  };

  skillsClassification = data => {
    const { candidatesSearchStore, i18n } = this.props;
    const {
      changeStep,
      changePrimarySkills,
      changeSecondarySkills,
      changeZipCode,
      changeSkillsSelectorData
    } = candidatesSearchStore;
    changeSkillsSelectorData(data);
    const primary = [];
    const secondary = [];
    data.keyWordsList.map(keyWord => {
      if (keyWord.main) primary.push(keyWord.text);
      if (keyWord.secondary) secondary.push(keyWord.text);
    });
    if (!primary || !primary.length)
      toaster.error({
        title: i18n._(t`Alerte !`),
        description: i18n._(
          t`vous devez sélectionner des mot-clés avant de lancer le matching`
        ),
        showButton: true,
        buttonText: i18n._(t`J'ai compris`),
        onClickButton: () => {}
      });
    else if (primary.length > 10)
      toaster.error({
        title: i18n._(t`Alerte !`),
        description: i18n._(
          t`Jusqu’à 10 mots-clés indispensables peuvent être sélectionnés.`
        ),
        showButton: true,
        buttonText: i18n._(t`J'ai compris`),
        onClickButton: () => {}
      });
    else if (secondary && secondary.length > 10)
      toaster.error({
        title: i18n._(t`Alerte !`),
        description: i18n._(
          t`Jusqu’à 10 mots-clés importants peuvent être sélectionnés.`
        ),
        showButton: true,
        buttonText: i18n._(t`J'ai compris`),
        onClickButton: () => {}
      });
    else if (!data.zipCode) {
      toaster.error({
        title: i18n._(t`Alerte !`),
        description: i18n._(
          t`vous devez préciser le code postal avant de lancer le matching`
        ),
        showButton: true,
        buttonText: i18n._(t`J'ai compris`),
        onClickButton: () => {}
      });
    } else {
      changePrimarySkills(primary);
      changeSecondarySkills(secondary);
      changeZipCode(data.zipCode);
      changeStep(2);
    }
  };

  // toastCardManger = data => {
  //   const { i18n } = this.props;
  //   if (data) {
  //     const numberOfMainKeyWords = data.keyWordsList.filter(
  //       keyword => keyword["main"] === true
  //     ).length;
  //     const numberOfSecondaryKeyWords = data.keyWordsList.filter(
  //       keyword => keyword["secondary"] === true
  //     ).length;
  //     if (numberOfMainKeyWords > 10) {
  //       toaster.error({
  //         title: i18n._(t`warning!`),
  //         description: i18n._(
  //           t`Jusqu’à 10 mots-clés indispensables peuvent être sélectionnés.`
  //         ),
  //         showButton: true,
  //         buttonText: i18n._(t`J'ai compris`),
  //         onClickButton: () => {}
  //       });
  //     }
  //     if (numberOfSecondaryKeyWords > 10) {
  //       toaster.error({
  //         title: i18n._(t`warning!`),
  //         description: i18n._(
  //           t`Jusqu’à 10 mots-clés importants peuvent être sélectionnés.`
  //         ),
  //         showButton: true,
  //         buttonText: i18n._(t`J'ai compris`),
  //         onClickButton: () => {}
  //       });
  //     }
  //   }
  // };

  addNewKeyWords = async keywords => {
    const { client, i18n, candidatesSearchStore } = this.props;
    try {
      const {
        words,
        changeWords,
        changeSkillsSelectorData,
        skillsSelectorData
      } = candidatesSearchStore;
      const { loading, error, data } = await client.query({
        query: ADD_NEW_KEY_WORDS,
        variables: {
          description: keywords
        },
        fetchPolicy: "network-only"
      });

      if (data) {
        const { addNewKeyWords } = data;
        const { newWords, keyWords } = addNewKeyWords;
        if (
          skillsSelectorData &&
          skillsSelectorData.keyWordsList &&
          skillsSelectorData.keyWordsList.length > 0
        ) {
          const keyWordsList = [];
          keyWords.map(w => {
            if (!skillsSelectorData.keyWordsList.some(word => word.text === w))
              keyWordsList.push({ text: w, main: false, secondary: false });
          });
          const newSkillsSelectorData = skillsSelectorData;
          newSkillsSelectorData.keyWordsList = keyWordsList.concat(
            skillsSelectorData.keyWordsList
          );
          changeSkillsSelectorData(newSkillsSelectorData);
        }

        const wordList = [];
        keyWords.map(w => {
          if (!words.some(word => word.text === w)) wordList.push({ text: w });
        });
        changeWords(wordList.concat(words));

        if (newWords && newWords.length > 0)
          toaster.error({
            title: i18n._(t`La liste des mots non pris en compte`),
            description: i18n._(
              t`ces mots clés seront pris en compte lors de la prochaine mise à jour.`
            ),
            expiresIn: 0,
            tagList: newWords,
            showButton: true,
            buttonText: i18n._(t`j'ai compris`)
          });
      }
    } catch (error) {}
  };

  render() {
    const { candidatesSearchStore, i18n } = this.props;
    const {
      step,
      changeStep,
      changeResultsCount,
      changeFirstSearch,
      clearFilters,
      words,
      disabled,
      firstSearch,
      zipCode,
      skillsSelectorData,
      changeSkillsSelectorData
    } = candidatesSearchStore;
    let skillsSelectorDataVar = skillsSelectorData;
    return (
      <section>
        <div className={classes.block}>
          {step === 1 && (
            <div style={{ padding: "10px 20px" }}>
              <Row noGape>
                <Col
                  xl={5}
                  lg={5}
                  md={5}
                  sm={5}
                  xs={6}
                  style={{ marginBottom: "20px" }}
                >
                  <div style={{ padding: "10px 0", minHeight: "110px" }}>
                    <H2 style={{ fontSize: "20px" }}>
                      <Trans>Étape 1: Intégrez votre fiche de poste</Trans>
                    </H2>
                    <P>
                      <Trans>
                        Recherchez les consultants grâce au contenu de votre
                        fiche de poste ou en ajoutant directement votre mots
                        clés IT
                      </Trans>
                    </P>
                  </div>
                  <FormCard
                    style={{
                      height: "100%",
                      padding: "20px 40px"
                    }}
                  >
                    <Query query={GET_SKILLS}>
                      {({ loading, error, data }) => {
                        if (loading)
                          return (
                            <ContentInsertionBlock
                              onClickButton={description =>
                                this.getKeyWords(description)
                              }
                              onEnterPressedInputText={keywords => {
                                changeSkillsSelectorData(skillsSelectorDataVar);
                                this.addNewKeyWords(keywords);
                              }}
                              onSelect={keywords => {
                                changeSkillsSelectorData(skillsSelectorDataVar);
                                this.addNewKeyWords(keywords);
                              }}
                              placeholderTextArea={i18n._(
                                t`Collez ici le contenu de votre fiche de poste...`
                              )}
                              placeholderInputText={i18n._(
                                t`Entrez ici vos mots-clés IT`
                              )}
                              textButton={i18n._(t`Récupérez des mots-clés IT`)}
                              dividerText={i18n._(t`Ou`)}
                              field="INPUT_TEXT" // INPUT_TEXT or AUTO_COMPLETE
                              buttonDisabled={disabled}
                            />
                          );
                        if (error)
                          return (
                            <ContentInsertionBlock
                              onClickButton={description =>
                                this.getKeyWords(description)
                              }
                              onEnterPressedInputText={keywords => {
                                changeSkillsSelectorData(skillsSelectorDataVar);
                                this.addNewKeyWords(keywords);
                              }}
                              onSelect={keywords => {
                                changeSkillsSelectorData(skillsSelectorDataVar);
                                this.addNewKeyWords(keywords);
                              }}
                              placeholderTextArea={i18n._(
                                t`Collez ici le contenu de votre fiche de poste...`
                              )}
                              placeholderInputText={i18n._(
                                t`Entrez ici vos mots-clés IT`
                              )}
                              textButton={i18n._(t`Récupérez des mots-clés IT`)}
                              dividerText={i18n._(t`Ou`)}
                              field="INPUT_TEXT" // INPUT_TEXT or AUTO_COMPLETE
                              buttonDisabled={disabled}
                            />
                          );
                        const allSkills = [];
                        data.skills.map(skill => allSkills.push(skill.name));
                        const options = {
                          groupA: allSkills
                        };
                        return (
                          <ContentInsertionBlock
                            onClickButton={description =>
                              this.getKeyWords(description)
                            }
                            onEnterPressedInputText={keywords => {
                              changeSkillsSelectorData(skillsSelectorDataVar);
                              this.addNewKeyWords(keywords);
                            }}
                            onSelect={keywords => {
                              changeSkillsSelectorData(skillsSelectorDataVar);
                              this.addNewKeyWords(keywords);
                            }}
                            placeholderTextArea={i18n._(
                              t`Collez ici le contenu de votre fiche de poste...`
                            )}
                            placeholderInputText={i18n._(
                              t`Entrez ici vos mots-clés IT`
                            )}
                            textButton={i18n._(t`Récupérez des mots-clés IT`)}
                            dividerText={i18n._(t`Ou`)}
                            field="AUTO_COMPLETE" // INPUT_TEXT or AUTO_COMPLETE
                            skillsList={options}
                            buttonDisabled={disabled}
                          />
                        );
                      }}
                    </Query>
                  </FormCard>
                </Col>
                <Col
                  xl={7}
                  lg={7}
                  md={7}
                  sm={7}
                  xs={6}
                  style={{ marginBottom: "20px" }}
                >
                  <div style={{ padding: "10px 0", minHeight: "110px" }}>
                    <H2 style={{ fontSize: "20px" }}>
                      <Trans>
                        Étape 2: Sélectionnez vos mots-clés IT dans les zones
                        prévues
                      </Trans>
                    </H2>
                    <P>
                      <Trans>
                        Cliquez sur le cadre "Mots-clés indispensables" ou
                        "Mots-clés importants" puis sélectionnez les mots-clés
                        IT souhaités
                      </Trans>
                    </P>
                  </div>
                  <FormCard
                    style={{
                      height: "100%",
                      padding: "20px 30px",
                      marginTop: "20px"
                    }}
                  >
                    <SkillsSelector
                      Words={words}
                      lastList={skillsSelectorData}
                      zipCode={zipCode}
                      t1={i18n._(t`Mots-clés indispensables*`)}
                      t2={i18n._(t`Mots-clés importants`)}
                      t3={i18n._(t`Code postal*`)}
                      t4={i18n._(
                        t`Sélectionnez la zone avant d'ajouter les mots-clés`
                      )}
                      t5={i18n._(
                        t`Ajouter le code postal permettra de mettre en avant les profils les plus proches`
                      )}
                      onChange={data => {
                        skillsSelectorDataVar = data;
                        // this.toastCardManger(data);
                      }}
                      message1={i18n._(
                        t`Jusqu’à 10 mots-clés indispensables peuvent être sélectionnés.`
                      )}
                      message2={i18n._(
                        t`Jusqu’à 10 mots-clés secondaires peuvent être sélectionnés.`
                      )}
                      maxSecondary={10}
                      maxPrimary={10}
                    />
                  </FormCard>
                </Col>
              </Row>
              <Row>
                <div
                  style={{ padding: "10px 0 20px 0", margin: "auto" }}
                  onClick={() =>
                    this.skillsClassification(skillsSelectorDataVar)
                  }
                >
                  <Button>
                    <Trans>Lancer le matching</Trans>
                  </Button>
                </div>
              </Row>
            </div>
          )}
          {step === 2 && (
            <Query
              query={GET_S_CANDIDATES}
              variables={this.getQueryVariables()}
              fetchPolicy="network-only"
            >
              {({ loading, error, data }) => {
                if (loading) {
                  if (firstSearch)
                    return (
                      <Row noGape>
                        <Col
                          xs={6}
                          sm={4}
                          md={4}
                          lg={4}
                          xl={4}
                          noGutter
                          className="right-side-column"
                        >
                          <LeftSide />
                        </Col>
                        <Col xs={6} sm={8} md={8} lg={8} xl={8}>
                          <div className={classes.smatchSpinner}>
                            <SmatchSpinner
                              text1={i18n._(
                                t`Notre algorithme de matching SMATCH est en pleine recherche de profils correspondants au sein de nos communautés`
                              )}
                              text2=""
                              title={i18n._(t`Quicktip`)}
                            />
                          </div>
                        </Col>
                      </Row>
                    );
                  return (
                    <Row noGape>
                      <SideFilters />
                      <Col
                        noGutter
                        lg={9}
                        xl={9}
                        md={9}
                        sm={12}
                        xs={6}
                        style={{ height: "max-content" }}
                      >
                        <SearchBar
                          backToTheStart={() => {
                            changeResultsCount(0);
                            changeStep(1);
                            changeFirstSearch(true);
                            clearFilters();
                          }}
                        />
                        <div
                          // eslint-disable-next-line no-return-assign
                          ref={node => (this.node = node)}
                        >
                          <LoadingMissions
                            cardsCount={12}
                            colClassName={classes.missionCard}
                            containerClassName={classes.missionsListing}
                          />
                        </div>
                      </Col>
                    </Row>
                  );
                }

                if (error) return <div>Error</div>;
                const { sCandidates } = data;

                const resultsCount = sCandidates.length
                  ? sCandidates[0].total
                  : 0;
                // Refactor this, because it is the origin of the error "Cannot update during an existing state transition..."
                candidatesSearchStore.changeResultsCount(resultsCount);

                const paginationProps = {
                  pageSize: candidatesSearchStore.limit,
                  total: candidatesSearchStore.resultsCount,
                  current: candidatesSearchStore.page,
                  onChange: current => {
                    candidatesSearchStore.changePage(current);
                    // scrollIntoView is web api function, read more here https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollIntoView
                    this.node.scrollIntoView({
                      behavior: "smooth",
                      block: "start",
                      inline: "nearest"
                    });
                  }
                };

                if (!candidatesSearchStore.resultsCount)
                  return (
                    <Row noGape>
                      <SideFilters />
                      <Col
                        noGutter
                        lg={9}
                        xl={9}
                        md={9}
                        sm={12}
                        xs={6}
                        style={{ height: "max-content" }}
                      >
                        <SearchBar
                          backToTheStart={() => {
                            changeResultsCount(0);
                            changeStep(1);
                            changeFirstSearch(true);
                            clearFilters();
                          }}
                        />
                        <div
                          // eslint-disable-next-line no-return-assign
                          ref={node => (this.node = node)}
                        >
                          <p className={classes.noCandidate}>
                            <Trans>
                              Désolé, pas de candidats pour ces critères
                            </Trans>
                          </p>
                        </div>
                      </Col>
                    </Row>
                  );
                if (firstSearch === true) changeFirstSearch(false);

                return (
                  <Row noGape>
                    <SideFilters />
                    <Col
                      noGutter
                      lg={9}
                      xl={9}
                      md={9}
                      sm={12}
                      xs={6}
                      style={{ height: "max-content" }}
                    >
                      <SearchBar
                        backToTheStart={() => {
                          changeResultsCount(0);
                          changeStep(1);
                          changeFirstSearch(true);
                          clearFilters();
                        }}
                      />
                      <div
                        // eslint-disable-next-line no-return-assign
                        ref={node => (this.node = node)}
                      >
                        <CandidatesListForUsers candidates={sCandidates} />
                        {isOnePage(
                          candidatesSearchStore.limit,
                          candidatesSearchStore.resultsCount
                        ) ? (
                          <Row noGape>
                            <Col className={classes.pagination}>
                              <Pagination {...paginationProps} />
                            </Col>
                          </Row>
                        ) : (
                          ""
                        )}
                      </div>
                    </Col>
                  </Row>
                );
              }}
            </Query>
          )}
          {/*{step === 3 && (*/}
          {/*  <Row noGape>*/}
          {/*    <SideFilters />*/}
          {/*    <CandidatesList*/}
          {/*      primarySkills={primarySkills}*/}
          {/*      secondarySkills={secondarySkills}*/}
          {/*      zipCode={zipCode}*/}
          {/*      backToTheStart={() => {*/}
          {/*        changeResultsCount(0);*/}
          {/*        changeStep(1);*/}
          {/*        this.setState({ firstSearch: true });*/}
          {/*      }}*/}
          {/*    />*/}
          {/*  </Row>*/}
          {/*)}*/}
          <Reassurance />
        </div>
      </section>
    );
  }
}

SearchCandidatesBody.wrappedComponent.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  sessionStore: PropTypes.shape({
    id: PropTypes.number,
    account: PropTypes.shape({}),
    client: PropTypes.shape({}),
    candidate: PropTypes.shape
  }).isRequired,
  candidatesSearchStore: PropTypes.shape({
    changeResultsCount: PropTypes.func,
    changeSkillsSelectorData: PropTypes.func,
    skillsSelectorData: PropTypes.shape({}),
    changeJobDescription: PropTypes.func,
    changeWords: PropTypes.func,
    changePrimarySkills: PropTypes.func,
    changeSecondarySkills: PropTypes.func,
    changeZipCode: PropTypes.func,
    changeFirstSearch: PropTypes.func,
    changeDisabled: PropTypes.func,
    words: PropTypes.shape({}),
    primarySkills: PropTypes.shape({}),
    secondarySkills: PropTypes.shape({}),
    zipCode: PropTypes.string,
    disabled: PropTypes.bool,
    firstSearch: PropTypes.bool,
    step: PropTypes.number,
    experience: PropTypes.number,
    changeStep: PropTypes.func,
    fSkills: PropTypes.shape({}),
    fEmploymentPreference: PropTypes.string,
    fLocations: PropTypes.shape({}),
    fRadius: PropTypes.number
  }).isRequired
};

export default withI18n()(withApollo(SearchCandidatesBody));
